import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iFoodSafetyBG, util } from "../../../Imports";
import * as Control from "../SharedControl";
const ShipmentInfo = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const FSBGT = iFoodSafetyBG.FSBD_Constants.FSBGT;

  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, FSBGT);
  };
  const handleChange = (name, value) => {
    props.handleChange(name, value, FSBGT);
  };
  const loadComLkp = useCallback(async () => {}, []);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="container my-2">
      <div className="row container-bg">
        <div className="col-md-4">
          <FormInput
            name="CustomsNumber"
            type="text"
            label="CustomsNumber"
            required={true}
            disabled={false}
            value={transaction[FSBGT].CustomsNumber}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="CustomsDate"
            type="date"
            label="CustomsDate"
            selectedDate={util.SelectedDate(transaction[FSBGT].CustomsDate)}
            required={true}
            disabled={false}
            onChange={(date) => {
              props.handleChange("CustomsDate", util.SetDate(date), FSBGT);
            }}
            maxDate={new Date()}
          />
        </div>
        <Control.ExporterCountry transaction={transaction[FSBGT]} changeControl={changeControl} />
        <Control.TransportMedia transaction={transaction[FSBGT]} changeControl={changeControl} />
        <Control.ItemsCount transaction={transaction[FSBGT]} changeControl={changeControl} isRequired={true} />
        <Control.TotalAmount transaction={transaction[FSBGT]} changeControl={changeControl} isRequired={true} />
      </div>
    </div>
  );
};

export default ShipmentInfo;
