import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput, { SelectInput } from "../Common/Validation/FormInput";
import { accountAction, enums, master } from "../Imports";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const UserPassword = ({ name, label, value, handleChange, props }) => {
  return (
    <FormInput
      name={name}
      type="password"
      placeholder=""
      errorMessage={props.t("passwordValidation")}
      label={props.t(label)}
      pattern="(?=^.{8,}$)(?=.*[!@#$%^&*]+)(?![.\n])(?=(.*[0-9]))(?=.*[A-Z])(?=.*[a-z]).*$"
      required={true}
      value={value}
      onChange={handleChange}
    />
  );
};
export const UserConfirmPassword = ({ name, currentPassField, handleChange, props }) => {
  return (
    <FormInput
      name={name}
      type="password"
      placeholder=""
      errorMessage={props.t("confirmPasswordValidation")}
      label={props.t("confirmPasspord")}
      pattern={currentPassField ? "^" + currentPassField.replaceAll("$", "\\$") + "$" : currentPassField}
      required={true}
      onChange={handleChange}
    />
  );
};
export const FirstNameEnglish = ({ value, handleChange, props, isDisable }) => {
  return (
    <FormInput
      name="nameEn"
      type="text"
      label={props.t("firstNameInEnglish")}
      placeholder=""
      required={true}
      errorMessage={props.t("enter") + props.t("firstNameInEnglish")}
      value={value}
      disabled={isDisable}
      onChange={handleChange}
    />
  );
};
export const LastNameEnglish = ({ value, handleChange, props, isDisable }) => {
  return (
    <FormInput
      name="lastNameEn"
      type="text"
      label={props.t("lastNameInEnglish")}
      placeholder=""
      required={true}
      errorMessage={props.t("enter") + props.t("lastNameInEnglish")}
      value={value}
      disabled={isDisable}
      onChange={handleChange}
    />
  );
};
export const FirstNameArabic = ({ value, handleChange, props, isDisable }) => {
  return (
    <FormInput
      name="nameAr"
      dir="rtl"
      type="text"
      label={props.t("firstNameInArabic")}
      placeholder=""
      pattern="^[\u0621-\u064A\u0660-\u0669 ]+$"
      errorMessage={props.t("enter") + props.t("firstNameInArabic")}
      value={value}
      disabled={isDisable}
      onChange={handleChange}
    />
  );
};
export const LastNameArabic = ({ value, handleChange, props, isDisable }) => {
  return (
    <FormInput
      name="lastNameAr"
      dir="rtl"
      type="text"
      label={props.t("lastNameInArabic")}
      placeholder=""
      disabled={isDisable}
      pattern="^[\u0621-\u064A\u0660-\u0669 ]+$"
      errorMessage={props.t("enter") + props.t("lastNameInArabic")}
      value={value}
      onChange={handleChange}
    />
  );
};
export const UserEmail = ({ value, handleChange, props, isDisable = false }) => {
  const isEmailExist = useSelector((state) => state.account.isEmailExist);
  const dispatch = useDispatch();
  const handleChangeEmail = (e) => {
    const value = e.target.value;
    if (value.length > 10) {
      dispatch(accountAction.isEmailExist(value));
    }
    handleChange(e);
  };
  return (
    <>
      <FormInput
        name="email"
        type="email"
        label={props.t("email")}
        placeholder=""
        required={true}
        // pattern={constants.email_regx}
        disabled={isDisable}
        errorMessage={props.t("email") + props.t("incorrect")}
        value={value}
        onChange={handleChangeEmail}
      />
      {isEmailExist && (
        <span className="red customError">
          {props.t("email")} {props.t("alreadyExist")}
        </span>
      )}
    </>
  );
};

export const UserMobileNumber = ({ value, handleChange, props, isDisable }) => {
  const onChangePhone = (value) => {
    handleChange({ target: { name: "mobileNo", value: value } });
  };
  return (
    <div className="form-group">
      <label>
        {props.t("mobileNo")} <span className="red"> *</span>
      </label>
      <FormInput
        name="mobileNo"
        type="text"
        placeholder="05x-xxxxxxx"
        required={true}
        maxLength={20}
        value={value}
        onChange={handleChange}
        disabled={isDisable}
      />
      {/* <PhoneInput
        country={"ae"}
        preferredCountries={["ae", "us", "gb"]}
        value={value}
        enableSearch={false}
        countryCodeEditable={false}
        enableAreaCodes={false}
        autoFormat={false}
        onChange={onChangePhone}
        inputStyle={{}}
        inputProps={{
          name: "mobileNo",
          required: true,
          pattern: ".{10,}",
        }}
      /> */}
    </div>
  );
};

export const UserFaxNumber = ({ value, handleChange, props, isDisable }) => {
  const onChangeFax = (value) => {
    handleChange({ target: { name: "fax", value: value } });
  };
  return (
    <div className="form-group">
      <label>
        {props.t("fax")} <span className="red"> *</span>
      </label>
      <FormInput name="fax" type="text" placeholder="05x-xxxxxxx" required={false} maxLength={20} value={value} onChange={handleChange} disabled={isDisable} />

      {/* <label> {props.t("fax")}</label>
      <PhoneInput
        country={"ae"}
        preferredCountries={["ae", "us", "gb"]}
        value={value}
        enableSearch={false}
        countryCodeEditable={false}
        enableAreaCodes={false}
        onChange={onChangeFax}
        autoFormat={false}
        defaultErrorMessage="incorrect format"
        inputProps={{
          name: "fax",
        }}
      /> */}
    </div>
  );
};
export const UserEmiratesID = ({ value, handleChange, props, isDisable, isRequired }) => {
  isRequired = isRequired ?? true;
  const dispatch = useDispatch();
  const isEmirateIDExist = useSelector((state) => state.account.isEmirateIDExist);
  const onChangeEmirateId = (e) => {
    const value = e.target.value;
    if (value.length === 18) {
      dispatch(accountAction.isEmirateIDExist(value));
    }
    handleChange(e);
  };
  return (
    <>
      <FormInput
        name="reporterEID"
        type="text"
        label={props.t("emiratesID")}
        placeholder="784-xxxx-xxxxxxx-x"
        pattern="^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$"
        required={isRequired}
        maxLength={18}
        disabled={isDisable}
        errorMessage={props.t("emiratesID") + props.t("incorrect")}
        value={value}
        onChange={onChangeEmirateId}
      />
      {isEmirateIDExist && (
        <span className="red customError">
          {props.t("emiratesID")} {props.t("alreadyExist")}
        </span>
      )}
    </>
  );
};
export const UserPassportNo = ({ value, handleChange, props, isDisable, isRequired }) => {
  const dispatch = useDispatch();
  isRequired = isRequired ?? true;

  const isPassportExist = useSelector((state) => state.account.isPassportExist);

  const onChangePassportNo = (e) => {
    const value = e.target.value;
    if (value.length > 3) {
      dispatch(accountAction.isPassportExist(value));
    }
    handleChange(e);
  };
  return (
    <>
      <FormInput
        name="passportNo"
        type="text"
        dir="ltr"
        label={props.t("passportNumber")}
        placeholder=""
        required={isRequired}
        errorMessage={props.t("passportNumber") + props.t("incorrect")}
        value={value}
        disabled={isDisable}
        onChange={onChangePassportNo}
      />
      {isPassportExist && (
        <span className="red customError">
          {props.t("passportNumber")} {props.t("alreadyExist")}
        </span>
      )}
    </>
  );
};
export const OrgUnitTypeLkpControl = ({ localVal, changeControl, departmentId, isDisable = false }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getOrgUnitTypeLkp(departmentId));
  }, [departmentId]);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="UnitTypeCode" label="Type" required={true} disabled={isDisable} value={localVal} onChange={changeControl} data={data} />;
};
const getGovtEntityUnitType = (deptId) => {
  if (deptId.toString() === enums.DepartmentEnum.FoodSafetyDepartment.toString()) {
    return enums.UnitTypeEnum.FocalPoint;
  } else if (deptId.toString() === enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment.toString()) {
    return enums.UnitTypeEnum.AgricultureDepartment;
  } else {
    return enums.UnitTypeEnum.GovernmentEntity;
  }
};
export const GovtEntityLkpControl = ({ localVal, changeControl, unityType, departmentId, isDisable = false }) => {
  const [govEntityData, setGovEntityData] = useState([]);
  const loadComLkp = useCallback(async () => {
    const ut = unityType ? unityType : getGovtEntityUnitType(departmentId);
    setGovEntityData(await master.getGovtEntity(0, ut, departmentId));
  }, [unityType]);

  useEffect(() => {
    if (unityType !== "") loadComLkp();
  }, [loadComLkp]);
  return (
    <SelectInput name="GovtEntityId" label="govEntity" required={true} disabled={isDisable} value={localVal} onChange={changeControl} data={govEntityData} />
  );
};
export const DepartmentInput = ({ initialState, changeControl, isDisable }) => {
  const [departmentData, setDepartmentData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setDepartmentData(await master.getDepartmentLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <SelectInput
      name="DepartmentId"
      label="Department"
      required={true}
      disabled={isDisable}
      value={initialState.DepartmentId}
      onChange={changeControl}
      data={departmentData}
    />
  );
};
