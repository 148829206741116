import { OwnerShipTypeEnum } from "./enums";
import { IncidentsHoldingsDTO } from "./IAnimalHealth";

export const AP_Constants = {
  APT: "AgriculturePestTransactionDTO",
  APTD: "AgriculturePestDetailDTO",
};

export class AgriculturePestTransactionDTO {
  constructor(
    IncidentsTransactionId,
    IncidentsHoldingId,
    IncidentHoldings,
    IncidentSourceId,
    IncidentSourceLkp,
    AgriculturePestDetail,
    Importance,
    VisitRequired
  ) {
    this.IncidentsTransactionId = IncidentsTransactionId ?? 0;
    this.IncidentsHoldingId = IncidentsHoldingId ?? 0;
    this.IncidentHoldings = IncidentHoldings ?? new IncidentsHoldingsDTO();
    this.IncidentSourceId = IncidentSourceId ?? 0;
    this.IncidentSourceLkp = IncidentSourceLkp ?? null;
    this.AgriculturePestDetail = AgriculturePestDetail ?? [];
    this.Importance = Importance ?? null;
    this.VisitRequired = VisitRequired ?? null;
  }
  setHoldingType(holdingTypeId) {
    this.IncidentHoldings.IsFarm =
      holdingTypeId === OwnerShipTypeEnum.Farm.toString();
  }
}

export class AgriculturePestDetailDTO {
  constructor(
    PlantTypeId,
    PlantTypeName,
    BlightDescriptionName,
    BlightDescriptionId,
    PlantTypeOther,
    Uid
  ) {
    this.PlantTypeId = PlantTypeId ?? 0;
    this.PlantTypeName = PlantTypeName ?? null;
    this.BlightDescriptionName = BlightDescriptionName ?? "";
    this.BlightDescriptionId = BlightDescriptionId ?? 0;
    this.PlantTypeOther = PlantTypeOther;
    this.Uid = Uid ?? Date.now();
  }
}

export const IncidentTransactionState = {
  AgriculturePestTransactionDTO: new AgriculturePestTransactionDTO(),
  AgriculturePestDetailDTO: [],
};
