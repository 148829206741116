import React from "react";
import "../../../Theme/css/loader.css";

const LoaderTemplate = (props) => {
  function cancelLoading() {
    props.changeLoading(false);
  }
  return (
    <div className="page-loader-wrapper">
      <div className="loader">
        <div className="preloader">
          <div
            className="spinner-border"
            style={{
              width: "3rem",
              height: "3rem",
              color: "var(--maincolor) ",
            }}
            role="status"
          ></div>
        </div>
        <p>
          <span data-tran="_pleaseWait">يرجى الانتظار</span>
        </p>
        <div>
          <span onClick={cancelLoading} style={{ cursor: "pointer" }}>
            <span data-tran="_cancel" style={{ color: "var(--maincolor)" }}>
              إلغاء
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoaderTemplate;
