import React from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const PopupOver = (props) => {
  return (
    <div>
      <OverlayTrigger
        rootClose={true}
        placement="bottom"
        trigger="click"
        overlay={<Popover>{props.body}</Popover>}
      >
        {props.button}
      </OverlayTrigger>
    </div>
  );
};

export default PopupOver;
