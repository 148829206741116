import React, { useCallback, useEffect, useState } from "react";
import { SelectInput } from "../../Common/Validation/FormInput";
import { master, swal } from "../../Imports";
import { DepartmentUnitTypeDTO, OrganizationUnitsLkpDTO } from "../../../Interface/IIncidentTrans";

const RoleSetting = (props) => {
  const initialState = {
    DepartmentId: 0,
    UnitTypeCode: 0,
    GovtEntityId: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [departmentData, setDepartmentData] = useState([]);
  const [unitTypeData, setUnitTypeData] = useState([]);
  const [govEntityData, setGovEntityData] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const loadComLkp = useCallback(async () => {
    setDepartmentData(await master.getAllDepartmentLkp(0));
    setUnitTypeData(await master.getUnitTypeEnumLkp());
    setOrgData((await master.getAllOrganization(0, 0, "")).data.ResponseData);
  }, []);
  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeDepartment = async (e) => {
    handleChange(e);
  };
  const handleChangeUnitType = async (e) => {
    handleChange(e);
    setGovEntityData(await master.getAllGovernmentalEntityLkp(0, 0, 0));
  };
  const handleGovtEntity = async (e) => {
    handleChange(e);
  };

  const handleSearch = async () => {
    console.log(localVal);
    setOrgData((await master.getAllOrganization(localVal.DepartmentId, localVal.GovtEntityId, localVal.UnitTypeCode)).data.ResponseData);
  };
  const handleSubmit = async () => {
    var data = new OrganizationUnitsLkpDTO(localVal.GovtEntityId, new DepartmentUnitTypeDTO(localVal.DepartmentId, localVal.UnitTypeCode));

    var res = await master.addOrganization(data);
    if (res) {
      swal(props.t("success"), props.t("submitSuccessFully"), "success");
    } else {
      swal("Warning", "Already exist", "warning");
    }
  };
  useEffect(() => {
    loadComLkp();
  }, []);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-3">
          <SelectInput
            name="DepartmentId"
            label="Department"
            required={true}
            disabled={false}
            value={localVal.DepartmentId}
            onChange={handleChangeDepartment}
            data={departmentData}
          />
        </div>
        <div className="col-md-3">
          <SelectInput
            name="UnitTypeCode"
            label="UnitType"
            required={true}
            disabled={false}
            value={localVal.UnitTypeCode}
            onChange={handleChangeUnitType}
            data={unitTypeData}
          />
        </div>
        <div className="col-md-3">
          <SelectInput
            name="GovtEntityId"
            label="GovtEntity"
            required={true}
            disabled={false}
            value={localVal.GovtEntityId}
            onChange={handleGovtEntity}
            data={govEntityData}
          />
        </div>
        <div className="col-md-3 mt-4">
          <button className="btn btn-success ml-2 mr-2 " type="button" onClick={handleSubmit}>
            {props.t("add")}
          </button>
          <button className="btn btn-success ml-2 mr-2 " type="button" onClick={handleSearch}>
            {props.t("search")}
          </button>
        </div>

        <div className="col-md-12 mt-5">
          <table className="table table-responsive table-bordered">
            {orgData.length > 0 &&
              orgData.map((x) => (
                <tr>
                  <td>{x.DepartmentUnitType.UnitTypeCode}</td>
                  <td>{x.DepartmentUnitType.DepartmentsLkp.NameAr}</td>
                  <td>{x.GovernmentalEntityLkp.NameAr}</td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoleSetting;
