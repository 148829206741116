import React from "react";
import StatusCount from "./StatusCount";
import Charts from "./Charts";
const Agriculture = (props) => {
  return (
    <div>
      <div className="row d-flex flex-wrap">
        <span className="d-flex m-3">{props.t("Incident")}</span>
        <StatusCount {...props} />
      </div>
      <div className="row d-flex flex-wrap">
        <Charts {...props} />
      </div>
    </div>
  );
};

export default Agriculture;
