import React, { useCallback, useEffect, useState } from "react";
import "./Theme/css/font-awesome.min.css";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { ToastContainer } from "react-toastify";
import AppRouters from "./Components/Routes/AppRouters";
import LoaderTemplate from "./Components/Common/Loader/LoaderTemplate";
import axios from "axios";
import ReactErrorBoundary from "./ErrorHandling/ErrorBoundary";

function App() {
  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next") || "ar";
  const [lang, setLang] = useState(currentLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const changeLangGlobal = () => {
    const l = i18next.language === "en" ? "ar" : "en";
    i18next.changeLanguage(l);
    window.location.reload(false);
  };
  const changeLoading = (isLoad) => {
    setIsLoading(isLoad);
  };
  const changeLanguage = useCallback((lang) => {
    setLang(lang);
    if (lang === "ar") {
      require("bootstrap/dist/css/bootstrap.rtl.min.css");
      require("./Theme/css/ar.css");
    } else {
      require("bootstrap/dist/css/bootstrap.min.css");
      require("./Theme/css/en.css");
    }
  }, []);
  useEffect(() => {
    //setIsLoading(true);
    document.body.dir = currentLanguage;
    document.documentElement.lang =
      document.head.dir =
      document.documentElement.dir =
        currentLanguage === "ar" ? "rtl" : "ltr";
    changeLanguage(currentLanguage);
    //setIsLoading(false);
    return () => {};
  }, [currentLanguage, changeLanguage]);

  // axios.defaults.baseURL = "http://localhost/BioSec_API/api/";
  //axios.defaults.baseURL = "https://localhost:7171/api/";
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.common["Authorization"] = cookies.get("AccessToken")
    ? `Bearer ${cookies.get("AccessToken")}`
    : "";
  // axios.defaults.headers.common["x-moccae-token"] =
  //   "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJzY3JpYmVkQVBJcyI6Ilt7XCJVSWRcIjpcIjc3MTBiZjlkLTNhYjUtNDI2OS04MWU5LTRjMTVjNTEzOWRlMVwiLFwiUHJvamVjdE5hbWVcIjpcImJpb3NlY1wifV0iLCJ0eXBlIjoieC1tb2NjYWUtYXBpa2V5IiwiaXNzIjoiaHR0cHM6Ly9hcGltLm1vY2NhZS5nb3YuYWUiLCJhdWQiOiJodHRwczovL2FwaW0ubW9jY2FlLmdvdi5hZSJ9.YlM30qpMiXFo1moJVyLGS05-6URHYzdX0kSEAU6nFV8";
  axios.defaults.headers.common["lang"] = cookies.get("i18next");
  axios.defaults.headers.post["Content-Type"] = "application/json";
  //axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    (request) => {
      //setIsLoading(true);
      // console.log(request);
      // Edit request config
      return request;
    },
    (error) => {
      console.log(error);
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // console.log(response);
      // Edit response config
      //setIsLoading(false);
      return response;
    },
    (error) => {
      console.log(error);
      //toast("something went wrong, Please try again", { type: "error" });
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  return (
    <div>
      <ToastContainer
        position={lang === "ar" ? "top-left" : "top-right"}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={lang === "ar"}
        pauseOnFocusLoss
        theme="colored"
        //draggable
        pauseOnHover
        // limit={1}
      />
      {isLoading && <LoaderTemplate changeLoading={changeLoading} />}
      <ReactErrorBoundary>
        <AppRouters
          currentLanguage={currentLanguage}
          t={t}
          changeLangGlobal={changeLangGlobal}
          changeLanguage={changeLanguage}
          setLoading={changeLoading}
        />
      </ReactErrorBoundary>
    </div>
  );
}

export default App;
