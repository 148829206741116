import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const TooltipUI = (prop) => {
  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => <Tooltip {...props}>{prop.innerText}</Tooltip>}
      placement="bottom"
    >
      {prop.children}
      {/* <Button variant="warning">Tooltip Button</Button> */}
    </OverlayTrigger>
  );
};

export default TooltipUI;
