import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { enums, encrypt, validation, auth, iIncident, animalBorderGateAction, transAction, swal, iAnimalBG } from "../../Imports";
import { useDispatch, useSelector } from "react-redux"; 
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import Reporter from "../Common/Reporter";
import GPSLocation from "../Common/LocationDetail/GPSLocation";
import EmirateGovEntity from "../Common/LocationDetail/EmirateGovEntity";
import ShipmentInitialData from "./ShipmentInitialData";
import AnimalDetail from "./AnimalDetail";
import LabTestDetails from "./LabTestDetails";
import ShipmentDetail from "./ShipmentDetail";
import { useCallback } from "react";

const AnimalBorderGate = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  followup = parseInt(encrypt.decrypt(followup));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  const getIncident = useCallback(async () => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    getIncident();
  }, [id, dispatch]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await animalBorderGateAction.getAnimalBorderGateTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;
      handleChangeDTO(res, iAnimalBG.ABD_Constants.ABGT);
      handleChangeDTO(res.AnimalBorderGateAnimalDetail, iAnimalBG.ABD_Constants.ABGAD);
      handleChangeDTO(res.AnimalBorderGateShipmentDetail, iAnimalBG.ABD_Constants.ABGSD);
      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);
      validation.enableDisableForm("initialDetail_abg", true);
      validation.enableDisableForm("animalDetail_abg", true);
      validation.enableDisableForm("labTestDetail_abg", true);
      validation.enableDisableForm("shipmentDetail_abg", true);
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iAnimalBG.AnimalBorderGateTransactionDTO();
    data = transaction.AnimalBorderGateTransactionDTO;

    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.AnimalBorderGate, followup);
    data.AnimalBorderGateAnimalDetail = transaction.AnimalBorderGateAnimalDetailDTO;
    data.AnimalBorderGateShipmentDetail = transaction.AnimalBorderGateShipmentDetailDTO;

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }

    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await animalBorderGateAction.createAnimalBorderGate(data);
    else if (followup > 0) {
      res = await animalBorderGateAction.createFollowUpAnimalBorderGate(data);
    } else {
      res = await animalBorderGateAction.updateAnimalBorderGate(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };

  const validateDetailForm_user = (e) => {
    const actionBtnForm = validation.validateForm("actionbuttonForm");
    const form1 = validation.validateForm("initialDetail_abg", "animalDetailForm");
    const form2 = validation.validateForm("animalDetail_abg", "animalDetailForm");
    const form3 = validation.validateForm("labTestDetail_abg", "animalDetailForm");
    const form4 = validation.validateForm("shipmentDetail_abg", "animalDetailForm");

    if (!actionBtnForm || !form1 || !form2 || !form3 || !form4) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.AnimalBorderGateAnimalDetailDTO.length === 0) {
      toast(props.t("AnimalDetailsRequired"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }

    return true;
  };
  const formValidation = () => {
    return validateDetailForm_user();
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("animalBorderGateIncident")}</h3>
            </div>
            <div className="col-sm-12  ">
              <Tabs defaultActiveKey="mainDetail">
                <Tab eventKey="mainDetail" title={props.t("IncidentInformation")}>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}
                    <div className="container initialDetail_abg">
                      <ShipmentInitialData handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                      <div className="container-bg my-2">
                        <div className="row">
                          <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                          <EmirateGovEntity
                            {...props}
                            unitType={enums.UnitTypeEnum.BorderGate}
                            department={enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment}
                            isDisabled={transaction.isUpdate}
                            value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                            emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                            handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                          /> 
                        </div>
                      </div>
                    </div>
                    <div className="container ">
                      <Reporter handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="animalDetail" title={props.t("AnimalDetails")}>
                  <div className="animalDetail_abg">
                    <AnimalDetail handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                  </div>
                </Tab>
                <Tab eventKey="LabTestDetail" title={props.t("LaboratoryTestsDetails")}>
                  <div className="labTestDetail_abg">
                    <LabTestDetails handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                  </div>
                </Tab>
                <Tab eventKey="shipmentDetail" title={props.t("ShipmentDetails")}>
                  <div className="shipmentDetail_abg">
                    <ShipmentDetail handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                  </div>
                  {/* <Attachment
                    required={true}
                    handleChangeDTO={handleChangeDTO}
                    code={enums.AttachmentEnum.Other}
                    canUploadFile={isGovtUser()}
                    {...props}
                  /> */}

                  {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                  {user.UnitType !== enums.UnitTypeEnum.MinistryUser && (
                    <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimalBorderGate;
