import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { iFoodSafety, master, util } from "../../../Imports";

const CompanyInfo = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const [activityTypeLkp, setActivityTypeLkp] = useState([]);
  const [countryLkp, setCountryLkp] = useState([]);
  const foodConst = iFoodSafety.FS_Constants.FSCD;
  const handleChange = (e) => {
    props.handleChange(e.target.name, e.target.value, foodConst);
  };
  const loadCompLkp = useCallback(async () => {
    setActivityTypeLkp(await master.getActivityTypeLkp(0));
    setCountryLkp(await master.getCountryLkp(0));
  }, []);
  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);
  return (
    <div className="my-2">
      <div className="row container-bg">
        <div className="col-md-4">
          <FormInput
            name="FacilityPhoneNo"
            type="text"
            label="FacilityPhoneNo"
            required={false}
            disabled={false}
            value={transaction[foodConst].FacilityPhoneNo}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="FacilityName"
            type="text"
            label="FacilityName"
            required={true}
            disabled={false}
            value={transaction[foodConst].FacilityName}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="PhoneNo"
            type="text"
            label="mobileNo"
            required={false}
            disabled={false}
            value={transaction[foodConst].PhoneNo}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="IncidentSource"
            type="text"
            label="IncidentSource2"
            required={true}
            disabled={false}
            value={transaction[foodConst].IncidentSource}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="FacilityResponsible"
            type="text"
            label="FacilityResponsible"
            required={false}
            disabled={false}
            value={transaction[foodConst].FacilityResponsible}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <SelectInput
            name="ActivityTypeId"
            label="ActivityType"
            required={true}
            disabled={false}
            value={transaction[foodConst].ActivityTypeId}
            onChange={handleChange}
            data={activityTypeLkp}
          />
        </div>
        <div className="col-md-4">
          <SelectInput
            name="CountryId"
            label="Country"
            required={true}
            disabled={false}
            value={transaction[foodConst].CountryId}
            onChange={handleChange}
            data={countryLkp}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="Address"
            type="text"
            label="Address"
            required={false}
            disabled={false}
            value={transaction[foodConst].Address}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="IncidentDate"
            type="date"
            label="IncidentDate"
            selectedDate={util.SelectedDate(
              transaction[foodConst].IncidentDate
            )}
            required={true}
            disabled={false}
            onChange={(date) => {
              props.handleChange("IncidentDate", util.SetDate(date), foodConst);
            }}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="DistributionArea"
            type="text"
            label="DistributionArea"
            required={true}
            disabled={false}
            value={transaction[foodConst].DistributionArea}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-12">
          <FormInput
            label="FacilityIncidentDescription"
            name="FacilityIncidentDescription"
            type="textarea"
            required={true}
            value={transaction[foodConst].FacilityIncidentDescription}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
