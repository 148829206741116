import axios from "axios";
import { URLs } from "../../Constants/Common";
//import { executeAsync } from "./Error";

export const getFollowUpIncident = async (id) => {
  return await axios.get(`${URLs.GetFollowUpIncident}?id=${id}`);
};
export const getCirculationHistory = async (transId) => {
  return await axios.get(`${URLs.GetCirculationHistory}?transactionId=${transId}`);
};
export const updateIncidentsRiskDegree = async (transId, riskDegree) => {
  return await axios.put(`${URLs.UpdateIncidentsRiskDegree}?transactionId=${transId}&riskDegree=${riskDegree}`, null);
};
export const updateLocationCoordinate = async (transId, lat, lng) => {
  return await axios.put(`${URLs.UpdateLocationCoordinate}?transactionId=${transId}&lat=${lat}&lng=${lng}`, null);
};
export const getIncidentsTransactionById = async (id) => {
  return await axios.get(`${URLs.GetIncidentsTransactionById}?id=${id}`);
};

export const getIncidentSearch = async (data) => {
  return await axios.post(URLs.GetIncidentsTransaction, data);
};

export const getIncidentsTransactionReport = async (data) => {
  return await axios.post(URLs.GetIncidentsTransactionReport, data);
};

export const validateFinalStepFromOtherEntities = async (transactionId) => {
  return await axios.get(`${URLs.ValidateFinalStepFromOtherEntities}?transactionId=${transactionId}`);
};
export const validateIncidentToOpen = async (transactionId) => {
  return await axios.get(`${URLs.ValidateIncidentToOpen}?transactionId=${transactionId}`);
};
