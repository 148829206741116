import React, { useEffect } from "react";
import { json, useNavigate, useSearchParams } from "react-router-dom";
import { auth, authAction, uaePassAction } from "../../Imports";
import { setUserPermission } from "../../../Constants/AuthToken";

const UAEPass = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const error = searchParams.get("error");
  const paramUUID = searchParams.get("uuid");

  const getAccessToken = async () => {
    props.setLoading(true);
    const res = await uaePassAction.getUserInfo(code);
    console.log("getUserProfile" + res.data.ResponseData);
    const userDetail = res.data.ResponseData;
    sessionStorage.setItem("uaepass_data", JSON.stringify(userDetail));
    await getTokenAndLogin(userDetail.uuid);

    props.setLoading(false);
  };

  const getTokenAndLogin = async (uuid) => {
    const userList = await authAction.getUserByEID(uuid);
    if (userList.data.ResponseData.length > 1) {
      console.log(userList);
      sessionStorage.setItem("uaepass_userList", JSON.stringify(userList.data.ResponseData));
      navigate("/multipleaccounts");
    } else {
      const checkAuth = await authAction.loginByUAEPass(uuid);

      if (checkAuth.data.ResponseData.AccessToken !== null) {
        setUserPermission(checkAuth.data.ResponseData.AccessToken, checkAuth.data.ResponseData.RefreshToken);
        const user = auth.getUserPermission();
        console.log("UAEPass:" + user.OrganizationId);
        if (user.OrganizationId === "" || user.OrganizationId === null) {
          navigate("/environmental-incident/MA==/MA==");
        } else navigate("/");
      } else {
        navigate("/profileLinking");
      }
    }
  };

  const getTokenAndLoginFromMobileApp = async (uuid) => {
    props.setLoading(true);
    const checkAuth = await authAction.loginByUAEPass(uuid);
    if (checkAuth.data.ResponseData.AccessToken !== null) {
      setUserPermission(checkAuth.data.ResponseData.AccessToken, checkAuth.data.ResponseData.RefreshToken);

      navigate("/environmental-incident/MA==/MA==");
      window.location.reload();
    } else navigate("/");

    props.setLoading(false);
  };

  useEffect(() => {
    if (error) {
      navigate("/?error=cancel");
      // util.swalOKCallBack(props.t("error"), props.t("CancelUAEPassLogin"), "error", () => {
      //   navigate("/?error=cancel");
      // });
    } else if (code) {
      getAccessToken();
    } else if (paramUUID) {
      getTokenAndLoginFromMobileApp(paramUUID);
    } else {
      navigate("/");
    }
    // navigate()
  }, [code, error]);

  return <div> </div>;
};

export default UAEPass;
