import React, { useState } from "react";
import { Link } from "react-router-dom";
import { util, encrypt, enums, validation } from "../../../Imports";
import CirculationUI from "../../Common/CirculationUI";
import TooltipUI from "../../../Common/UI/TooltipUI";
import FollowUpReportIncident from "../../AnimalHealth/UIComponent/FollowUpReportIncident";

const SearchGrid = (props) => {
  const [incidentId, setIncidentId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalFollowUp, setShowModalFollowUp] = useState(false);

  const handleShowModal = (incidentId) => {
    setShowModal(!showModal);
    setIncidentId(incidentId);
  };
  const handleShowModalFollowUp = (incidentId) => {
    setIncidentId(incidentId);
    setShowModalFollowUp(!showModalFollowUp);
  };
  return (
    <div>
      <table className="table table-striped table-hover text-center m-0">
        <thead>
          <tr>
            <th>{props.t("IncidentNumber")}</th>
            <th>{props.t("IncidentDate")}</th>
            <th>{props.t("incidnetType")} </th>
            <th>{props.t("IncidentStatus")} </th>
            <th>{props.t("reporterName")} </th>
            <IsShowProductName props={props} />
            <IsShowNotificationType props={props} />
            <th>{props.t("emirate")} </th>
            <th>{props.t("Circulation")} </th>
          </tr>
        </thead>
        <tbody>
          {props.data.Incidents.map((incident, index) => {
            return (
              <tr key={index}>
                <td>
                  <DynamicLink incident={incident} />
                </td>
                <td>
                  <ColumnCreateDate incident={incident} />
                </td>
                <td>
                  <ColumnIncidentType incident={incident} props={props} />
                </td>
                <td>
                  <ColumnStatus incident={incident} />
                </td>
                <td>
                  <ColumnUserName incident={incident} />
                </td>
                <ColumnProductName incident={incident} />
                <ColumnNotificationTypeName incident={incident} />
                <td>
                  <ColumnEmiratesName incident={incident} props={props} />
                </td>
                <td>
                  <ColumnCirculationHistoryIcon
                    incident={incident}
                    props={props}
                    handleShowModal={handleShowModal}
                    handleShowModalFollowUp={handleShowModalFollowUp}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {incidentId > 0 && <CirculationUI {...props} showModal={showModal} handleShowModal={handleShowModal} data={incidentId} />}
      {showModalFollowUp && (
        <FollowUpReportIncident
          {...props}
          data={{ Id: incidentId }}
          showModal={true}
          handleShowModal={() => {
            setShowModalFollowUp(!showModalFollowUp);
          }}
        />
      )}
    </div>
  );
};

export default SearchGrid;

export const DynamicLink = ({ incident }) => {
  return (
    <Link to={`${incident?.IncidentType?.IncidentURL}${encrypt.encrypt(incident.Id)}/${encrypt.encrypt(0)}`} target="_blank">
      {incident.RefNo}
    </Link>
  );
};
export const ColumnCreateDate = ({ incident }) => {
  return util.getProperDate(incident.CreatedDate, true);
};
export const ColumnGPSLocation = ({ trans }) => {
  return (
    <TooltipUI innerText={`North :${trans.Latitude} East :${trans.Longitude}`}>
      <a>
        <i className="ico icon-Group-290"></i>
      </a>
    </TooltipUI>
  );
};
export const IsShowProductName = ({ props }) => {
  return validation.isFoodSafetyDept() && <th>{props.t("ProductName")} </th>;
};
export const IsShowNotificationType = ({ props }) => {
  return validation.isFoodSafetyDept() && <th>{props.t("NotificationType")} </th>;
};
export const ColumnProductName = ({ incident, props }) => {
  return (
    <>
      {validation.isFoodSafetyDept() && (
        <td>{incident?.FoodSafetyTransaction.length > 0 ? incident?.FoodSafetyTransaction[0].FoodSafetyProductInfo?.ProductName : ""}</td>
      )}
    </>
  );
};
export const ColumnNotificationTypeName = ({ incident, props }) => {
  return (
    <>
      {validation.isFoodSafetyDept() && (
        <td>{incident?.FoodSafetyTransaction.length > 0 ? util.getTextFromObj(incident?.FoodSafetyTransaction[0].NotificationTypeLkp) : ""}</td>
      )}
    </>
  );
};
export const ColumnIncidentType = ({ incident, props }) => {
  const incidentType = util.getTextFromObj(incident.IncidentType);
  const extraName =
    incident.IncidentType.Code === enums.IncidentTypeEnum.AnimalHealth
      ? util.getTextFromObj(incident.AnimalHealthTransaction[0].IncidentHoldings.HoldingType)
      : incident.IncidentType.Code === enums.IncidentTypeEnum.AnimalLegalViolation
      ? util.getTextFromObj(incident.AnimalLegalViolationTransaction[0].ALVIncidentTypeLkp)
      : "";

  return (
    <>
      {incidentType}
      <div className="g-box ">{extraName}</div>
    </>
  );
};
export const ColumnStatus = ({ incident }) => {
  let status = incident.WorkFlowActionHistorys[incident.WorkFlowActionHistorys.length - 1].WorkFlowState.WorkFlowStateLkp;

  return (
    <span className="status-box " style={{ backgroundColor: status.Color }}>
      {util.getProperText(status.NameAr, status.NameEn)}
    </span>
  );
};
export const ColumnUserName = ({ incident }) => {
  return util.getProperText(incident.Users.UsersProfile.NameAr, incident.Users.UsersProfile.NameEn);
};
export const ColumnEmiratesName = ({ incident, props }) => {
  const EmirateName =
    incident.EmirateId !== null
      ? util.getProperText(incident.EmirateLkp?.NameAr, incident.EmirateLkp?.NameEn)
      : util.getProperText(incident.GovtAgency?.EmirateLkp?.NameAr, incident.GovtAgency?.EmirateLkp?.NameEn);
  return (
    <>
      {EmirateName}

      <div>
        <small className="file_desc">{incident.SharedDiseaseIncident ? props.t("sharedDisease") : ""}</small>
      </div>
    </>
  );
};
export const ColumnGovEntityName = ({ incident }) => {
  return <>{util.getTextFromObj(incident?.GovtAgency)}</>;
};
export const ColumnCirculationHistoryIcon = ({ incident, handleShowModal, handleShowModalFollowUp, props }) => {
  return (
    incident.IncidentCirculationHistory.length > 0 && (
      <>
        <i
          style={{ cursor: "pointer" }}
          onClick={() => handleShowModal(incident.Id)}
          title={props.t("ViewCirculationHistory")}
          className="fa fa-history mx-3"
          aria-hidden="true"
        ></i>

        <i
          style={{ cursor: "pointer" }}
          onClick={() => handleShowModalFollowUp(incident.Id)}
          title={props.t("FollowupReport")}
          className="fa fa-list-alt"
          aria-hidden="true"
        ></i>
      </>
    )
  );
};
