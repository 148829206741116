import React, { useCallback, useEffect, useState } from "react";
import ChartUI, { ChartType } from "../../../Common/UI/ChartUI";
import { enums, envDisasterAction, util } from "../../../Imports";

const MonthlyDashboard = (props) => {
  const openTask = [
    enums.WorkFlowStatusCodeEnum.New,
    enums.WorkFlowStatusCodeEnum.Confirmed,
    enums.WorkFlowStatusCodeEnum.Circulated,
    enums.WorkFlowStatusCodeEnum.FollowUp,
  ];
  const closedTask = [enums.WorkFlowStatusCodeEnum.FinallyClosed];
  const [statusCount, setStatusCount] = useState([]);
  const [perEmirate, setPerEmirate] = useState([]);
  const [noOfIncident, setNoOfIncident] = useState([]);
  const [incidentType, setIncidentType] = useState([]);
  const [dangerIncident, setDangerIncident] = useState([]);
  const [dangerIncidentDetail, setDangerIncidentDetail] = useState([]);
  const [incidentSource, setIncidentSource] = useState([]);
  const [incidentByEntity, setIncidentByEntity] = useState([]);
  const [comparisonYearly, setComparisonYearly] = useState([]);
  const [comparisonMonthly, setComparisonMonthly] = useState([]);
  const loadComLkp = useCallback(async () => {
    setStatusCount((await envDisasterAction.getIncidentStatus()).data.ResponseData);
    setPerEmirate((await envDisasterAction.getIncidentPerEmirate()).data.ResponseData);
    setNoOfIncident((await envDisasterAction.getNumberOfIncident()).data.ResponseData);
    setIncidentType((await envDisasterAction.getIncidentTypes()).data.ResponseData);
    setDangerIncident((await envDisasterAction.getIncidentDangerRisk()).data.ResponseData);
    setDangerIncidentDetail((await envDisasterAction.getIncidentDetailDangerRisk()).data.ResponseData);
    setIncidentByEntity((await envDisasterAction.getIncidentsByGovEntity()).data.ResponseData);
    setIncidentSource((await envDisasterAction.getIncidentsBySource()).data.ResponseData);
    setComparisonYearly((await envDisasterAction.getComparisonOpenCloseYearlyIncidents()).data.ResponseData);
    setComparisonMonthly((await envDisasterAction.getComparisonOpenCloseMonthlyIncidents()).data.ResponseData);
  }, []);

  const getYearlyIncidents = () => {
    const year = util.sortList(util.getUniqueListValues(comparisonYearly, "year"));
    const res = [];
    function CalcSum(year, taskType) {
      return comparisonYearly
        .filter((x) => x.year === year && taskType.includes(x.status))
        .map((x) => {
          return x.count;
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    function calcSumLoop(taskType) {
      const d = [];
      for (var i = 0; i < year.length; i++) {
        d.push(CalcSum(year[i], taskType));
      }
      return d;
    }
    res.push({
      label: props.t("OpenIncidents"),
      data: calcSumLoop(openTask),
      backgroundColor: ["#147548"],
    });
    res.push({
      label: props.t("ClosedIncidents"),
      data: calcSumLoop(closedTask),
      backgroundColor: ["#779f8c"],
    });

    return res;
  };
  const getMonthlyIncident = () => {
    const months = util.sortList(util.getUniqueListValues(comparisonMonthly, "month"));
    const res = [];
    function CalcSum(month, taskType) {
      return comparisonMonthly
        .filter((x) => x.month === month && taskType.includes(x.status))
        .map((x) => {
          return x.count;
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }
    function calcSumLoop(taskType) {
      const d = [];
      for (var i = 0; i < months.length; i++) {
        d.push(CalcSum(months[i], taskType));
      }
      return d;
    }
    res.push({
      label: props.t("OpenIncidents"),
      data: calcSumLoop(openTask),
      backgroundColor: ["#147548"],
    });
    res.push({
      label: props.t("ClosedIncidents"),
      data: calcSumLoop(closedTask),
      backgroundColor: ["#779f8c"],
    });
    //console.log(res);
    return res;
  };

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <>
      <div>
        <div className="page-title p-3 pl-4">
          <h3>{`${props.t("MonthlyDashboardBiosecSystem")}  
          - ${util.CurrentMonthYearDisplay(new Date().getMonth() - 1)}`}</h3>
        </div>
      </div>
      <div className=" ">
        <div className="row">
          <div className="col-md-5 chart-box">
            <ChartUI
              title={props.t("IncidentCount")}
              type={ChartType.line}
              labels={[
                props.t("Month") + util.CurrentMonthYearDisplay(new Date().getMonth() - 1),
                props.t("Month") + util.CurrentMonthYearDisplay(new Date().getMonth() - 2),
                props.t("Month") + util.CurrentMonthYearDisplay(new Date().getMonth() - 1, new Date().getFullYear() - 1),
                props.t("IncidentAvg") + " " + new Date().getFullYear(),
              ]}
              datasets={[
                {
                  label: props.t("Total"),
                  data: [noOfIncident?.lastMonthCount, noOfIncident?.SecondLastMonthCount, noOfIncident?.lastYearLastMonthCount, noOfIncident?.AvgCount],
                  borderColor: "#ba9041",
                  backgroundColor: "#ba9041",
                },
              ]}
            />
          </div>

          <div className="col-md-6 chart-box ">
            <ChartUI
              legendDisplay={true}
              title={props.t("IncidentStatus")}
              type={ChartType.horizontalBar}
              labels={[props.t("animal"), props.t("agricultural"), props.t("foodSafety")]}
              datasets={[
                {
                  label: props.t("NoActionTaken"),
                  data: [statusCount?.agriculture?.newStatus, statusCount?.agriculture?.closeStatus, statusCount?.agriculture?.pendingStatus],
                  borderColor: "#93ccf2",
                  backgroundColor: "#93ccf2",
                },
                {
                  label: props.t("Closed"),
                  data: [statusCount?.animal?.newStatus, statusCount?.animal?.closeStatus, statusCount?.animal?.pendingStatus],
                  borderColor: "#374ce0",
                  backgroundColor: "#374ce0",
                },
                {
                  label: props.t("Pending"),
                  data: [statusCount?.foodsafety?.newStatus, statusCount?.foodsafety?.closeStatus, statusCount?.foodsafety?.pendingStatus],
                  borderColor: "#6577f6",
                  backgroundColor: "#6577f6",
                },
              ]}
            />
          </div>

          <div className="col-md-5 chart-box">
            <ChartUI
              title={props.t("IncidentsType")}
              type={ChartType.bar}
              labels={[props.t("animal"), props.t("agricultural"), props.t("foodSafety")]}
              datasets={[
                {
                  label: props.t("Total"),
                  data: [incidentType.animal, incidentType.agriculture, incidentType.foodsafety],
                  borderColor: "#ba9041",
                  backgroundColor: "#ba9041",
                },
              ]}
            />
          </div>

          <div className="col-md-6 chart-box">
            <ChartUI
              title={props.t("IncidentByEmirate")}
              type={ChartType.bar}
              labels={[props.t("dubai"), props.t("abudhabi"), props.t("sharjah"), props.t("ajman"), props.t("rak"), props.t("fujairah"), props.t("uaq")]}
              datasets={[
                {
                  label: props.t("Total"),
                  data: [
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Dubai")[0]?.count,
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Abu Dhabi")[0]?.count,
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Sharjah")[0]?.count,
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Ajman")[0]?.count,
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Ras al-Khaimah")[0]?.count,
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Fujairah")[0]?.count,
                    perEmirate?.incidentCount?.filter((x) => x?.emirateName === "Umm al-Quwain")[0]?.count,
                  ],
                  borderColor: "#6577f6",
                  backgroundColor: "#6577f6",
                },
              ]}
            />
          </div>

          <div className="col-md-5 chart-box">
            <ChartUI
              title={props.t("HighRiskIncidents") + " - " + props.t("Pending")}
              type={ChartType.bar}
              labels={[props.t("animal"), props.t("agricultural"), props.t("foodSafety")]}
              datasets={[
                {
                  label: props.t("Total"),
                  data: [dangerIncident.animal, dangerIncident.agriculture, dangerIncident.foodsafety],
                  borderColor: "#ba9041",
                  backgroundColor: "#ba9041",
                },
              ]}
            />
          </div>
          <div className="col-md-6 chart-box box-max-height">
            <div className="table-header">{props.t("HighRiskIncidentsDetail")}</div>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th>{props.t("IncidentNumber")}</th>
                  <th>{props.t("CreatedDate")}</th>
                  <th>{props.t("emirate")}</th>
                  <th>{props.t("govEntity")}</th>
                </tr>
              </thead>
              <tbody>
                {dangerIncidentDetail?.incidentDetail?.map((x) => {
                  return (
                    <tr key={x.Id}>
                      <td>{x.RefNo}</td>
                      <td>{x.CreatedDate.split("T")[0]}</td>
                      <td>{util.getTextFromObj(x.EmirateLkp)}</td>
                      <td>{util.getTextFromObj(x.GovtAgency)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* <div className="more-detail">
            <a> More detail</a>
          </div> */}

          <div className="row ">
            {util.getUniqueListValues(incidentByEntity, "entity").map((x, i) => {
              var newCount = incidentByEntity.filter((t) => t.entity === x && t.status === "New")[0] ?? {};
              var closedCount = incidentByEntity.filter((t) => t.entity === x && t.status === "FinallyClosed")[0] ?? {};

              return (
                <div className="col-md-3  chart-box" key={i + 2000}>
                  <ChartUI
                    title={x}
                    type={ChartType.bar}
                    labels={[props.t("NoActionTaken"), props.t("Closed")]}
                    datasets={[
                      {
                        label: props.t("Total"),
                        data: [newCount ? newCount.count : 0, closedCount ? closedCount.count : 0],
                        borderColor: "#6577f6",
                        backgroundColor: "#6577f6",
                      },
                    ]}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="page-title p-3 pl-4 m-2">
        <h3>{`${props.t("IncidentSource")}`}</h3>
      </div>

      <div className="row">
        <div className="col-md-5 chart-box">
          <ChartUI
            title={props.t("IncidentSource")}
            legendDisplay={true}
            type={ChartType.pie}
            labels={[props.t("NonGovtUser"), props.t("GovtUser")]}
            datasets={[
              {
                label: props.t("Total"),
                data: [incidentSource.nonGovt, incidentSource.govt],
                borderColor: "#fff",
                backgroundColor: ["#ba9041", "#2a872a"],
                hoverOffset: 4,
              },
            ]}
          />
        </div>
      </div>
      <div className="page-title p-3 pl-4 m-2">
        <h3>{`${props.t("ComparisonBetweenOpenClosedReportsInTheYears")}`}</h3>
      </div>

      <div className="row">
        <div className="col-md-5 chart-box">
          <ChartUI
            title=""
            legendDisplay={true}
            type={ChartType.barStacked}
            labels={util.sortList(util.getUniqueListValues(comparisonYearly, "year"))}
            datasets={getYearlyIncidents()}
          />
        </div>
        <div className="col-md-6 chart-box">
          <ChartUI
            title=""
            legendDisplay={true}
            type={ChartType.barStacked}
            labels={util.sortList(util.getUniqueListValues(comparisonMonthly, "month")).map((x) => {
              return util.getMonthNames(x - 1);
            })}
            datasets={getMonthlyIncident()}
          />
        </div>
      </div>
    </>
  );
};

export default MonthlyDashboard;
