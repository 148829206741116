import React, { useState, useEffect, useCallback } from "react";
import { dashboardAction, validation } from "../../Imports";
import BoxUI from "../Shared/BoxUI";

const StatusCount = (props) => {
  const [animalStateCount, setAnimalStateCount] = useState({});
  const [LVStatCount, setLVStatCount] = useState([]);
  const emirateId = validation.getUserEmirateId();

  const loadComLkp = useCallback(async () => {
    setAnimalStateCount((await dashboardAction.getAnimalStateCount(emirateId)).data.ResponseData);
    setLVStatCount((await dashboardAction.getLeagalViolationStatCount(emirateId)).data.ResponseData);
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <>
      <BoxUI
        title={`${props.t("AnimalDiseasesIncidentsNonGov")}`}
        link="/IncidentDetail/AnimalHealth/AnimalDiseaseIncidentNovGov"
        count={animalStateCount?.AnimalDiseaseIncidentNovGovCount ?? 0}
      />
      <BoxUI
        title={`${props.t("AnimalDiseasesIncidentsGov")}`}
        link="/IncidentDetail/AnimalHealth/AnimalDiseaseIncident"
        count={animalStateCount?.AnimalDiseaseIncidentCount ?? 0}
      />
      <BoxUI title={`${props.t("SharedDiseaseIncidents")}`} link="/IncidentDetail/AnimalHealth/ShareDisease" count={animalStateCount?.ShareDiseaseCount ?? 0} />
      <BoxUI
        title={`${props.t("IllegalAnimalShipmentIncidents")}`}
        link="/IncidentDetail/AnimalHealth/BorderGate"
        count={animalStateCount?.BorderGateCount ?? 0}
      />

      <BoxUI
        title={`${props.t("LegalViolationsIncidentsAnimalWelfare")}`}
        link="/IncidentDetail/AnimalLegalViolation/AW"
        count={LVStatCount.filter((x) => x.Name === "AW")[0]?.Count ?? 0}
      />
      <BoxUI
        title={`${props.t("LegalViolationsIncidentsVeterinaryFacilities")}`}
        link="/IncidentDetail/AnimalLegalViolation/VF"
        count={LVStatCount.filter((x) => x.Name === "VF")[0]?.Count ?? 0}
      />
      <BoxUI
        title={`${props.t("LegalViolationsIncidentsVeterinaryCosmetics")}`}
        link="/IncidentDetail/AnimalLegalViolation/VP"
        count={LVStatCount.filter((x) => x.Name === "VP")[0]?.Count ?? 0}
      />
      <BoxUI
        title={`${props.t("LegalViolationsIncidentsPossessionDangerousAnimals")}`}
        link="/IncidentDetail/AnimalLegalViolation/PoDA"
        count={LVStatCount.filter((x) => x.Name === "PoDA")[0]?.Count ?? 0}
      />
    </>
  );
};

export default StatusCount;
