import cookies from "js-cookie";
import { encrypt, enums, swal, validation } from "../Components/Imports";
import { getUserPermission } from "./AuthToken";

const labelName = ["Companies", "Consumer", "FocalPoint", "MinistryUser", "BorderGate"];
export const pagesWOLoginShowLinks = ["login", "registration", "forgetpassword"];
export const pagesWOLogin = ["/login", "/registration", "/forgetpassword", "/loginlinked", "/profilelinking", "/multipleaccounts"];
export const getMonthNames = (noOfMonth) => {
  const name_ar = ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];
  const name_en = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return getLang() === "en" ? name_en[noOfMonth] : name_ar[noOfMonth];
};
export const CurrentMonthYearDisplay = (month, year) => {
  const currMonth = month ?? new Date().getMonth();
  const currYear = year ?? new Date().getFullYear();
  return `${getMonthNames(currMonth)} ${currYear}`;
};
export const getProperDate = (dtInput, isTimeRequired) => {
  isTimeRequired = isTimeRequired || false;
  let dt = dtInput.split("T")[0].split("-");
  dt = new Date(dt[0], dt[1] - 1, dt[2]).toLocaleDateString("en-GB");
  const timePart = dtInput.split("T")[1].substr(0, 8);
  if (isTimeRequired) return getLang() === "ar" ? timePart + " " + dt : dt + " " + timePart;
  return dt;
};
export const formatEID = (input) => {
  input = input.toString();
  let part1 = input.substring(0, 3);
  let part2 = input.substring(3, 7);
  let part3 = input.substring(7, 14);
  let part4 = input.substring(14);
  let formattedNumber = `${part1}-${part2}-${part3}-${part4}`;
  return formattedNumber;
};

export const getEmirateList = () => {
  const EmirateList_En = ["Dubai", "Abu Dhabi", "Sharjah", "Ajman", "Ras al Khaimah", "Fujairah", "Umm Al Quawain"];
  const EmirateList_Ar = ["دبي", "أبوظبي", "الشارقة", "عجمان", "رأس الخيمة", "الفجيرة", "أم القيوين"];
  return getLang() === "ar" ? EmirateList_Ar : EmirateList_En;
};
export const getLang = () => {
  return cookies.get("i18next") || "ar";
};
export const getProperText = (nameAr, nameEn) => {
  return getLang() === "ar" ? nameAr : nameEn;
};
export const getTextFromObj = (obj) => {
  if (obj === null || obj === undefined) return "";
  return getLang() === "ar" ? obj?.NameAr : obj?.NameEn;
};
export const sortList = (arr, isAscOrder) => {
  isAscOrder = isAscOrder || true;
  return arr.sort(function (a, b) {
    return isAscOrder ? a - b : b - a;
  });
};
export const stringEmpty = (str) => {
  if (str === null || str === undefined) return false;
  else if (str instanceof Date) return true;
  else if (typeof str === "string") return str !== undefined && str !== null && str !== "" && str !== "0001-01-01T00:00:00";
  else if (typeof str === "number") return str > 0;
  else if (typeof str === "object") return str.length > 0;
};

export const getObjectKeyFromValue = (obj, value) => {
  return Object.keys(obj).filter(function (key) {
    return obj[key] === value;
  })[0];
};
export const DateFormat = (date) => {
  if (date == null || date === undefined || date === "") return "";
  if (date instanceof Date) return date;
  else if (typeof date === "string") {
    return new Date(date.split("T")[0]);
  }
};
export const SetDate = (date) => {
  return date.toDateString();
  //return date.toDateString();
};
export const SelectedDate = (date) => {
  if (date) return new Date(date);
  else return date;
};
export const Obj2Array = (obj) => {
  return Object.keys(obj).map((key) => obj[key]);
};
export const Obj2ArrayKey = (obj) => {
  return Object.keys(obj).map((key) => key);
};
export const getUniqueListValues = (obj, filterName) => {
  var valueArray = obj.map((n) => n[filterName]);
  var setOfValue = new Set(valueArray);
  var uniqueValues = [...setOfValue];
  return uniqueValues;
};
export const getStatusListFilter = () => {
  const filter = [
    enums.WorkFlowStatusCodeEnum.New,
    enums.WorkFlowStatusCodeEnum.Circulated,
    enums.WorkFlowStatusCodeEnum.Closed,
    enums.WorkFlowStatusCodeEnum.Confirmed,
    enums.WorkFlowStatusCodeEnum.Final,
    enums.WorkFlowStatusCodeEnum.FinallyClosed,
    enums.WorkFlowStatusCodeEnum.FollowUp,
    enums.WorkFlowStatusCodeEnum.Notified,
    enums.WorkFlowStatusCodeEnum.Rejected,
  ];
  if (validation.isAgricultureDept()) {
    filter.push(enums.WorkFlowStatusCodeEnum.WaitingLabTest);
  }
  if (validation.isMinistryUser()) {
    filter.push(enums.WorkFlowStatusCodeEnum.Deleted);
  }
  return filter;
};
export const getEmirateIndex = (emirate) => {
  const EmirateList = ["Dubai", "Abu Dhabi", "Sharjah", "Ajman", "Ras al Khaimah", "Fujairah", "Emirate of Umm Al Quwain"];
  return EmirateList.findIndex((item) => emirate.toLowerCase() === item.toLowerCase());
};
export const getRouteValue = (pathname) => {
  const user = getUserPermission();
  var value = pathname?.split("/")[2];
  try {
    var nv = encrypt.decrypt(value);
    return !isNaN(nv) && parseInt(nv) > 0 && user.isLoggedIn;
  } catch {
    return false;
  }
};

export const swalOKCallBack = (title, text, icon, callBack) => {
  swal({
    title: title,
    text: text,
    icon: icon,
  }).then(function () {
    callBack();
  });
};

export const DbUtil = {
  getLabelList: (list) => {
    const resData = [];
    list.map((x) => {
      var name = x.Name.length > 20 ? x.Name.substr(0, 25) + ".." : x.Name;
      return resData.push(name);
    });
    return resData;
  },
  getDataEmirateCount: (list, accountType, labels) => {
    const dataList = [];
    labels.map((emirate) => {
      const res = list.filter((x) => x.Name === emirate && x.Catagory === accountType)[0]?.Count ?? 0;
      return dataList.push(res);
    });
    return dataList;
  },
  getDatasetEmirateCount: (list, label_lang) => {
    const resData = [];
    const color = Obj2Array(enums.Colors);
    const emiratesCode = Obj2ArrayKey(enums.EmiratesCode);
    function getList(label) {
      var ret = [];
      for (let i = 0; i < emiratesCode.length; i++) {
        ret.push(list[emiratesCode[i]]?.[label]);
      }
      return ret;
    }

    labelName.map((label, i) => {
      return resData.push({
        label: label_lang[i],
        data: getList(label),
        backgroundColor: color[i],
      });
    });

    //console.log("resData", resData);
    return resData;
  },
  getDatasetRiskCount: (list, props) => {
    const resData = [];
    const color = Obj2Array(enums.Colors);
    const accountTypeArr = [enums.UserAccountTypeEnum_DB.CentralPoint, enums.UserAccountTypeEnum.MinistryUser];
    const innerLabel = DbUtil.getLabelList(list);
    accountTypeArr.map((type, i) => {
      return resData.push({
        label: props.t(type),
        data: DbUtil.getDataEmirateCount(list, type, innerLabel),
        backgroundColor: color[i],
      });
    });
    return resData;
  },
  getIncidentPerCount: (list) => {
    const resData = [];
    const accountTypeArr = Obj2Array(enums.UserAccountTypeEnum_DB);
    accountTypeArr.map((type, i) => {
      return resData.push(list.filter((x) => x.Name === type)[0]?.Count ?? 0);
    });
    return resData;
  },

  getDatasetPerIncidentCount: (list, totalLbl) => {
    const resData = [];
    const color = Obj2Array(enums.Colors);
    resData.push({
      label: totalLbl,
      data: [list[labelName[0]], list[labelName[1]], list[labelName[2]], list[labelName[3]], list[labelName[4]]],
      backgroundColor: color,
    });
    return resData;
  },

  getCountryPerCount: (list, countryListName) => {
    const resData = [];
    list.map((x, i) => {
      return resData.push(list.filter((x) => x.Name === countryListName[i])[0]?.Count ?? 0);
    });
    return resData;
  },

  getDatasetPerCountryCount: (list, incidentLbl) => {
    const resData = [];
    const color = Obj2Array(enums.Colors);
    resData.push({
      label: incidentLbl,
      data: DbUtil.getCountryPerCount(list, DbUtil.getLabelList(list)),
      backgroundColor: color,
    });
    return resData;
  },

  getDatasetPerProductCount: (list, props) => {
    const resData = [];
    const color = Obj2Array(enums.Colors);
    resData.push({
      label: props.t("Products"),
      data: DbUtil.getCountryPerCount(list, DbUtil.getLabelList(list)),
      backgroundColor: color.reverse(),
    });
    return resData;
  },
};
