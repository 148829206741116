import React from "react";
import ModalView from "../../Common/UI/ModalView";

const ShowFiles = (props) => {
  return (
    <>
      <ModalView
        modalStyle={props.modalStyle}
        isOpen={props.showModal}
        handleShowModal={props.handleShowModal}
        title="attachments"
        body={
          <div>
            <iframe src={props.fileURL} width="100%" height="600px" style={{ border: "none" }} title="file">
              Your browser does not support iframes.
            </iframe>
          </div>
        }
        footer={<div></div>}
      />
    </>
  );
};

export default ShowFiles;
