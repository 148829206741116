import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import RedirectionList from "./RedirectionList";

const AnimalIncidentRedirection = (props) => {
  const [isSearchChanged, setIsSearchChanged] = useState(false);
  const initialData = {
    IncidentTypeCode: "",
    UserAccountType: "",
    UserAccountTypeId:"",
    EmirateId: "",
    HoldingOwnerShipTypeId: "",
    ALVIncidentTypeId: "",
    SourceGovtEntityId: "",
    TargetGovtEntityId: "",
  };
  const [data, setData] = useState(initialData);

  const onChangeSearch = (localVal) => {
    setData(localVal);
    setIsSearchChanged(!isSearchChanged);
  };

  useEffect(() => {
    setData(initialData);
  }, []);
  return (
    <div>
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("AnimalIncidentRedirection")}</h3>
      </div>
      <Filter {...props} searchData={data} isSearchChanged={isSearchChanged} onChangeSearch={onChangeSearch} />
      <RedirectionList {...props} searchData={data} isSearchChanged={isSearchChanged} />
    </div>
  );
};

export default AnimalIncidentRedirection;
