import axios from "axios";
import { Eservices } from "../../Constants/Common";

export const getAgriculturaltenure = async (data) => {
  return await axios.post(Eservices.url.GetAgriculturaltenure + data, null, {
    headers: {
      Authorization: Eservices.authorization,
      withCredentials: false,
    },
  });
};
