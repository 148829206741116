import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { encrypt, enums, foodSafetyFollowUpAction, iFoodSafety, iIncident, transAction, validation, incidentAction, swal, util } from "../../../Imports";
import ActionTaken from "./ActionTaken";
import QuestionWithYesNo from "../../AnimalHealth/InvestigationDetail/QuestionWithYesNo";
import FormInput from "../../../Common/Validation/FormInput";
import Reporter from "../../Common/Reporter";
import { SharedDTO } from "../../../../Interface/IIncidentTrans";

const FoodSafetyFollowUpReport = (props) => {
  let { mainId, id } = useParams();
  mainId = parseInt(encrypt.decrypt(mainId));
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const transaction = useSelector((state) => state.transaction);
  const FSConst = iFoodSafety.FS_Constants.FSFU;
  const transConst = iIncident.SharedDTO.IT;
  const dispatch = useDispatch();
  const [isActionTaken, setIsActionTaken] = useState(true);

  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };
  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (mainId > 0) {
      getMainIncidentTransaction(mainId);
    }
    if (id > 0) {
      getIncidentTransaction(id);
    }
  }, [id, dispatch]);

  const getMainIncidentTransaction = async (mainId) => {
    props.setLoading(true);
    const response = await incidentAction.getIncidentsTransactionById(mainId);
    if (response.data) {
      const res = response.data.ResponseData;
      res.IncidentsTransaction = res;
      await iIncident.getIncidentTransactionData(res, 0, handleChangeDTO, handleChange);
      handleChange("StatusCode", "New", SharedDTO.IT);
      handleChangeDTO(false, "isUpdate");

      props.setLoading(false);
    }
  };
  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await foodSafetyFollowUpAction.getTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;
      handleChangeDTO(res, iFoodSafety.FS_Constants.FSFU);
      handleChangeDTO(res.FoodSafetyFollowUpActionTaken, iFoodSafety.FS_Constants.FSAT);

      await iIncident.getIncidentTransactionData(res, 0, handleChangeDTO, handleChange);

      validation.enableDisableForm("mainForm", true);
      validation.enableDisableForm("mainForm2", true);

      props.setLoading(false);
    }
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iFoodSafety.FoodSafetyFollowUpDTO();
    data = transaction.FoodSafetyFollowUpDTO;
    data.FoodSafetyFollowUpActionTaken = transaction.FoodSafetyActionTakenDTO;
    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.FoodSafetyIncident, 1);

    //make the action to confirm if the action is taken.
    data.IncidentsTransaction.WfActionCode = isActionTaken ? enums.WorkFlowActionCodeEnum.Final : enums.WorkFlowActionCodeEnum.FollowUp;

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }

    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);

    res = await foodSafetyFollowUpAction.create(data);

    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };

  const formValidation = (e) => {
    const foodsafetyForm = isActionTaken ? validation.validateForm("foodsafetyForm", "foodActionTakenForm") : validation.validateForm("foodsafetyForm");
    if (
      transaction.FoodSafetyFollowUpDTO.FoodSafetyFollowUpActionTaken.length === 0 &&
      transaction.FoodSafetyFollowUpDTO.IsActionTaken === enums.YesNoEnum.Yes
    ) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (!foodsafetyForm) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    return true;
  };
  const handleCancelBtn = () => {
    navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
  };
  const handleChange_IsActionTaken = (e) => {
    handleChange(e.target.name, e.target.value, FSConst);

    setIsActionTaken(e.target.value === enums.YesNoEnum.Yes);
  };
  return (
    <div className="tab-wrapper ">
      <div className="container-fluid foodsafetyForm">
        <div className="row">
          <div className="container page-title p-3 pl-4 mb-3">
            <h3>
              {props.t("newFoodSafetyIncident")} {" ("}
              {props.t("FollowupReport")} {")"}
            </h3>
          </div>
          <div className=" ">
            <div className="container-bg row  my-2 mb-4">
              <div className="col-md-3">
                <strong>{props.t("IncidentNumber")}</strong> : <small className="golden">{transaction.IncidentsMainTransactionDTO.RefNo}</small>
              </div>
              <div className="col-md-3 ">
                <strong className=" ">{props.t("IncidentDate")}</strong> :{" "}
                <small className="golden">
                  {transaction.isUpdate ? new Date(transaction.IncidentsMainTransactionDTO.CreatedDate).toLocaleString() : new Date().toLocaleString()}
                </small>
              </div>
              {id > 0 && (
                <>
                  <div className="col-md-3">
                    <strong>{props.t("Name")}</strong> : <span className="golden">{util.getTextFromObj(transaction[transConst].Users?.UsersProfile)}</span>
                  </div>
                  <div className="col-md-3">
                    <strong>{props.t("govEntity")}</strong> : <span className="golden"> {util.getTextFromObj(transaction[transConst].GovtAgency)}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mainForm">
            <div style={{ display: "none" }}>
              <Reporter {...props} handleChange={handleChange} />
            </div>
            <QuestionWithYesNo
              controlName="IsActionTaken"
              label="IsTakenAction"
              list={[enums.YesNoEnum.Yes, enums.YesNoEnum.No]}
              onchangeRadio={handleChange_IsActionTaken}
              value={transaction[FSConst].IsActionTaken}
              {...props}
            />{" "}
          </div>
          <div className="row mainForm2">
            {isActionTaken && (
              <div className="my-2">
                <ActionTaken {...props} handleChangeDTO={handleChangeDTO} />
              </div>
            )}

            <div className="col-md-12">
              <FormInput
                label="Comments"
                name="Comments"
                type="textarea"
                required={false}
                value={transaction[FSConst].Comments}
                onChange={(e) => handleChange(e.target.name, e.target.value, FSConst)}
              />
            </div>
          </div>
          {!transaction.isUpdate && (
            <div className="col-md-3 my-3">
              <button className="btn btn-success mx-3" type="button" onClick={handleSubmitForm}>
                {props.t("Submit")}
              </button>
            </div>
          )}
          <div className="col-md-3 my-3">
            <button className="btn btn-success mx-3" type="button" onClick={handleCancelBtn}>
              {props.t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodSafetyFollowUpReport;
