import React from "react";

import * as Control from "../CommonControl";

function ContactDetail(props) {
  const Previous = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!isEmailExist) {
    console.log("form submitted");
    props.nextStep();
    // }
  };

  return (
    <div>
      <form id="regStep2Form" className="current " onSubmit={submitHandler}>
        <div className="row">
          <div className="col-6">
            <Control.UserEmail
              value={props.values["email"]}
              handleChange={props.handleChange}
              props={props}
            />
          </div>
          <div className="col-6">
            <Control.UserMobileNumber
              handleChange={props.handleChange}
              value={props.values["mobileNo"]}
              props={props}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Control.UserFaxNumber
              handleChange={props.handleChange}
              value={props.values["fax"]}
              props={props}
            />
          </div>
        </div>

        <div className="d-flex mt-5">
          <button
            type="submit"
            className="btn flex-fill p-2 mr-3 col prev"
            onClick={Previous}
          >
            {props.t("previous")}
          </button>

          <button type="submit" className="btn flex-fill p-2 ml-1 col next">
            {props.t("next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactDetail;
