import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iAnimalHealth, validation, enums } from "../../../Imports";
import { AddButton, DeleteButton } from "../../Common/IncidentCommonImport";
import QuestionWithYesNo from "./QuestionWithYesNo";

const LabAnalysis = (props) => {
  const testTypeList = [enums.TestTypeEnum.Quick, enums.TestTypeEnum.Confirmed];
  const initialState = {
    SampleType: "",
    NumberOfSamples: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const transaction = useSelector((state) => state.transaction);
  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "LabAnalysisForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.ALA].concat(
        new iAnimalHealth.AnimalLabAnalysisDTO(localVal.SampleType, localVal.NumberOfSamples, new Date().getTime())
      ),
      iAnimalHealth.AH_Constants.ALA
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = (e) => {
    console.log(e);
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.ALA].filter((x) => x.Uid !== e.Uid),
      iAnimalHealth.AH_Constants.ALA
    );
  };
  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[iAnimalHealth.AH_Constants.AHI].AnimalLabAnalysis.forEach((e, i) => {
      tempInf = tempInf.concat(new iAnimalHealth.AnimalLabAnalysisDTO(e.SampleType, e.NumberOfSamples, new Date().getTime() + i));
    });
    props.handleChangeDTO(tempInf, iAnimalHealth.AH_Constants.ALA);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 my-3  container-bg ">
            <div className="col-md-8 row">
              <FormInput
                label="LabName"
                name="LabName"
                type="text"
                required={true}
                value={transaction[iAnimalHealth.AH_Constants.AHI].LabName}
                onChange={(e) => props.handleChange(e.target.name, e.target.value, iAnimalHealth.AH_Constants.AHI)}
                data-investigate="1"
              />
            </div>
            <div className="row LabAnalysisForm">
              <div className="col-md-4">
                <FormInput label="SampleType" name="SampleType" type="text" required={true} value={localVal.SampleType} onChange={changeControl} />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="NumberOfSamples"
                  name="NumberOfSamples"
                  type="number"
                  min="1"
                  required={true}
                  value={localVal.NumberOfSamples}
                  onChange={changeControl}
                />
              </div>
              <div className="col-md-3 mt-4">
                <AddButton {...props} submitHandler={submitHandler} />
              </div>

              <div className="col-md-8  ">
                {transaction[iAnimalHealth.AH_Constants.ALA].map((e, i) => (
                  <div className="card" key={e.Uid}>
                    <div className="card-body row">
                      <div className="col-md-5">
                        <small className="golden">{props.t("SampleType")}</small> :<small> {e.SampleType}</small>
                      </div>
                      <div className="col-md-3">
                        <small className="golden">{props.t("NumberOfSamples")}</small>:<small> {e.NumberOfSamples}</small>
                      </div>
                    </div>
                    <DeleteButton
                      {...props}
                      deleteHandler={() => {
                        deleteItem(e);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-12 my-3 row">
              <div className="col-md-6">
                <FormInput
                  label="TestDescription"
                  name="TestDescription"
                  type="text"
                  required={true}
                  value={transaction[iAnimalHealth.AH_Constants.AHI].TestDescription}
                  onChange={(e) => props.handleChange(e.target.name, e.target.value, iAnimalHealth.AH_Constants.AHI)}
                  data-investigate="1"
                />
              </div>
              <div className="col-md-6 row">
                <QuestionWithYesNo
                  list={testTypeList}
                  {...props}
                  label="TestType"
                  controlName="TestType"
                  value={transaction[iAnimalHealth.AH_Constants.AHI].TestType}
                  onchangeRadio={(e) => {
                    props.handleChange(e.target.name, e.target.value, iAnimalHealth.AH_Constants.AHI);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LabAnalysis;
