import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectInput } from "../../../Common/Validation/FormInput";
import { iIncident, master, enums, toast, auth, validation, util } from "../../../Imports";

const EmirateGovEntity = ({ handleOnChange, t, emirateId, value, department, unitType, isDisabled }) => {
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const [disableEmirate, setDisableEmirate] = useState(false);
  const [disableGovEntity, setDisableGovEntity] = useState(false);
  const EmirateData = useSelector((state) => state.masterData.emirateData);
  const [govEntityData, setGovEntityData] = useState([]);
  let govEntityLabel = department && department === enums.DepartmentEnum.FoodSafetyDepartment ? "ContactFocalPoint" : "nearestGovEntity";
  const googleMapLocation = useSelector((state) => state.common.googleMapLocation);

  const transaction = useSelector((state) => state.transaction);
  const handleChange = (name, value) => {
    handleOnChange({ target: { name: name, value: value } });
  };
  const onchangeEmirate = async (e) => {
    handleOnChange(e);
    await getGovtEntity(e.target.value);
    await setGovEntityDropDown(transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId);
  };

  const setEmirateForBorderGate = useCallback(async () => {
    if (!transaction.isUpdate && (user.UnitType === enums.UnitTypeEnum.BorderGate.toString() || user.UnitType === enums.UnitTypeEnum.FocalPoint.toString())) {
      console.log(user.UnitType);
      await getGovtEntity(user.EmirateId.toString());
      await setGovEntityDropDown(user.GovEntityId);
      await setEmirateDropDown(user.EmirateId.toString());
      setDisableEmirate(true);
      setDisableGovEntity(true);
    }
  }, []);

  const getGovtEntity = async (emirateId) => {
    setGovEntityData(await master.getGovtEntity(emirateId, unitType, department));
  };
  const setEmirateDropDown = async (value) => {
    document.getElementById("EmirateId").value = value;
    handleChange("EmirateId", value);
  };
  const setGovEntityDropDown = async (value) => {
    document.getElementById("GovernmetnalEntityId").value = value;
    handleChange("GovernmetnalEntityId", value);
  };
  const loadCompLkp = useCallback(async () => {
    dispatch(master.getEmirates());
    await setEmirateForBorderGate();
    await setEmirateForGovtUser();
  }, [dispatch]);

  // useEffect(() => {
  //   setEmirateForBorderGate();
  // }, [EmirateData]);

  const setEmirateForGovtUser = async () => {
    if (!transaction.isUpdate && validation.isGovtUser()) {
      await setEmirateDropDown(user.EmirateId.toString());
      setDisableEmirate(true);
    }
  };
  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);

  useEffect(() => {
    if (googleMapLocation) {
      //const id = enums.EmirateList.indexOf(googleMapLocation);
      const id = util.getEmirateIndex(googleMapLocation);
      console.log("emirate index :" + id);
      if (id === -1) {
        clearLocationTextBox("locationInsideUAEText");
        return;
      } else if ((validation.isGovtUser() || validation.isBorderGateUser()) && parseInt(user.EmirateId) !== id + 1 && !validation.isMinistryGovtUser()) {
        //
        clearLocationTextBox("outOfCoverageArea");
        return;
      }
      if (!transaction.isUpdate && (unitType !== enums.UnitTypeEnum.BorderGate || unitType !== enums.UnitTypeEnum.FocalPoint)) {
        setEmirateDropDown(id + 1);
        getGovtEntity(id + 1);
        setDisableEmirate(true);
      }
    }
  }, [googleMapLocation]);

  const clearLocationTextBox = (msg) => {
    handleChange("Latitude", "");
    handleChange("Longitude", "");
    toast(t(msg), {
      type: "warning",
    });
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      const emirateId = transaction[iIncident.SharedDTO.IT].EmirateId;
      setEmirateDropDown(emirateId);
      getGovtEntity(emirateId);
      setDisableEmirate(transaction.isUpdate);
      setDisableGovEntity(transaction.isUpdate);
    }
  }, [transaction.isUpdate]);
  return (
    <>
      <div className="col-md-4">
        <SelectInput
          name="EmirateId"
          label="emirate"
          required={true}
          disabled={disableEmirate}
          value={emirateId}
          onChange={onchangeEmirate}
          data={EmirateData}
        />
      </div>
      <div className="col-md-4">
        <SelectInput
          name="GovernmetnalEntityId"
          label={govEntityLabel}
          required={true}
          disabled={disableGovEntity}
          value={value}
          onChange={handleOnChange}
          data={govEntityData}
        />
      </div>
    </>
  );
};

export default EmirateGovEntity;
