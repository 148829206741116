import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iAnimalLV } from "../../../Imports";

const ALVSurgeonDetail = (props) => {
  const transaction = useSelector((state) => state.transaction);
  return (
    <div className="col-md-4">
      <FormInput
        name="VeterinarySurgeonName"
        type="text"
        label="VetName"
        required={true}
        disabled={props.isDisabled}
        value={transaction[iAnimalLV.ALV_Constants.ALVD].VeterinarySurgeonName}
        onChange={(e) => {
          props.handleChange(
            e.target.name,
            e.target.value,
            iAnimalLV.ALV_Constants.ALVD
          );
        }}
      />
    </div>
  );
};

export default ALVSurgeonDetail;
