import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import { iAgrcultureBG } from "../../Imports";
import { ImporterName } from "../FoodSafety/SharedControl";
import { constants } from "../../../Constants/Common";

const ImporterDetail = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const ABGID = iAgrcultureBG.ABG_Constants.ABGID;
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, ABGID);
  };
  return (
    <div className="row">
      <ImporterName transaction={transaction[ABGID]} changeControl={changeControl} />

      <div className="col-md-4">
        <FormInput
          name="ImporterPhone"
          type="text"
          label="ImporterPhone"
          required={true}
          disabled={false}
          value={transaction[ABGID].ImporterPhone}
          onChange={changeControl}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="ImporterEmail"
          type="email"
          label="ImporterEmail"
          required={false}
          disabled={false}
          errorMessage={props.t("email") + props.t("incorrect")}
          // pattern={constants.email_regx}
          value={transaction[ABGID].ImporterEmail}
          onChange={changeControl}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="ImporterFax"
          type="text"
          label="ImporterFax"
          required={false}
          disabled={false}
          value={transaction[ABGID].ImporterFax}
          onChange={changeControl}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="ImporterAddress"
          type="text"
          label="ImporterAddress"
          required={false}
          disabled={false}
          value={transaction[ABGID].ImporterAddress}
          onChange={changeControl}
        />
      </div>
    </div>
  );
};

export default ImporterDetail;
