import axios from "axios";
import { URLs } from "../../Constants/Common";

export const create = async (data) => {
  return await axios.post(URLs.CreateFoodSafety, data);
};
export const update = async (data) => {
  return await axios.post(URLs.UpdateFoodSafety, data);
};

export const getTransactionById = async (id) => {
  return await axios.get(
    URLs.getFoodSafetyIncidentsTransactionById + "?id=" + id
  );
};
export const getFoodSafetyReport = async (data) => {
  return await axios.post(URLs.GetFoodSafetyReport, data);
};
