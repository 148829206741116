import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormInput from "../../Common/Validation/FormInput";
import QuestionWithYesNo from "./InvestigationDetail/QuestionWithYesNo";
import ImmunizedAnimals from "./InvestigationDetail/ImmunizedAnimals";
import LabAnalysis from "./InvestigationDetail/LabAnalysis";
import NewlyAdded from "./InvestigationDetail/NewlyAdded";
import { enums, iAnimalHealth, validation, master, util } from "../../Imports";

const InvestigationDetail = (props) => {
  const dispatch = useDispatch();
  const answerYesNo = [enums.YesNoEnum.Yes, enums.YesNoEnum.No];

  const [vaccineValid, setVaccineValid] = useState(false);
  const [isNewAnimalYes, setIsNewAnimalYes] = useState(false);
  const [isAnimalImmunized, setIsAnimalImmunized] = useState(false);
  const [isLabSampledCollected, setIsLabSampledCollected] = useState(false);

  const controlProcedureData = useSelector((state) => state.masterData.controlProceduresData);
  const transaction = useSelector((state) => state.transaction);

  useEffect(() => {
    if (transaction.isUpdate) {
      const data = transaction[iAnimalHealth.AH_Constants.AHI];
      setIsAnimalImmunized(data.IsAnimalsImmunized === enums.YesNoEnum.Yes);
      setIsLabSampledCollected(data.LabSamplesCollected === enums.YesNoEnum.Yes);
      setIsNewAnimalYes(data.IsNewlyAnimalAdded === enums.YesNoEnum.Yes);
      let tempInf = [];
      transaction[iAnimalHealth.AH_Constants.AHI].AnimalControlProceduers.forEach((e) => {
        if (e.ControlProceduersLkp.IsDepended === true && e.ControlProceduersLkp.IsDepended !== null) {
          setVaccineValid(true);
        }
        tempInf = tempInf.concat(new iAnimalHealth.AnimalControlProceduersDTO(e.ControlProceduersLkpId, e.ControlProceduersLkp.IsDepended));
      });
      props.handleChangeDTO(tempInf, iAnimalHealth.AH_Constants.ACP);
    }
    setTimeout(() => {
      validation.isShowInfectionForm(transaction, "investigationDetailForm");
    }, 0);
  }, [transaction.isUpdate]);
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, iAnimalHealth.AH_Constants.AHI);
  };

  const OnChangeControlProcedure = (value, e) => {
    if (e.target.checked) {
      props.handleChangeDTO(
        transaction[iAnimalHealth.AH_Constants.ACP].concat(new iAnimalHealth.AnimalControlProceduersDTO(value.Id, value.Code)),
        iAnimalHealth.AH_Constants.ACP
      );
      if (value.Code !== null && value.Code) {
        setVaccineValid(true);
      }
    } else {
      props.handleChangeDTO(
        transaction[iAnimalHealth.AH_Constants.ACP].filter((x) => x.ControlProceduersLkpId !== value.Id),
        iAnimalHealth.AH_Constants.ACP
      );
      if (value.Code !== null && value.Code) {
        let checkCode = transaction[iAnimalHealth.AH_Constants.ACP].filter((x) => x.Code === true);
        if (checkCode.length === 1) setVaccineValid(false);
      }
    }
  };
  const loadInitialsLkp = useCallback(async () => {
    dispatch(master.getControlProcedures());
  }, [dispatch]);

  useEffect(() => {
    loadInitialsLkp();
  }, [loadInitialsLkp]);

  return (
    <div className="container  my-4 ">
      <div className="row investigationDetailForm">
        <div className="col-md-12 row">
          <QuestionWithYesNo
            list={answerYesNo}
            {...props}
            label="isNewlyAnimalAdded"
            controlName="IsNewlyAnimalAdded"
            value={transaction[iAnimalHealth.AH_Constants.AHI].IsNewlyAnimalAdded}
            onchangeRadio={(e) => {
              changeControl(e);
              setIsNewAnimalYes(e.target.value === enums.YesNoEnum.Yes);
            }}
          />
        </div>
        {isNewAnimalYes && (
          <div className="col-md-12 my-3 row">
            <NewlyAdded {...props} />
          </div>
        )}
        <div className="col-md-12 my-3 row">
          <label className="mb-3">
            <strong>{props.t("controlProcedures")}</strong> <span className="red">*</span>
          </label>
          {controlProcedureData.map((x, i) => (
            <div className="col-md-3" key={x.Id}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`${x.Id}`}
                  required={true}
                  checked={transaction[iAnimalHealth.AH_Constants.ACP].some((t) => t.ControlProceduersLkpId === x.Id)}
                  onChange={(e) => OnChangeControlProcedure(x, e)}
                />
                <label className="form-check-label" htmlFor={`${x.Id}`}>
                  {props.currentLanguage === "ar" ? x.NameAr : x.NameEn}
                </label>
              </div>
            </div>
          ))}
        </div>

        <div className="col-md-12 row my-3">
          <QuestionWithYesNo
            {...props}
            list={answerYesNo}
            label="IsAnimalsImmunized"
            controlName="IsAnimalsImmunized"
            value={transaction[iAnimalHealth.AH_Constants.AHI].IsAnimalsImmunized}
            onchangeRadio={(e) => {
              changeControl(e);
              setIsAnimalImmunized(e.target.value === enums.YesNoEnum.Yes);
            }}
          />

          {isAnimalImmunized && (
            <>
              <div className="col-md-5">
                <FormInput
                  label="lastImmunizationDate"
                  type="date"
                  selectedDate={util.SelectedDate(transaction[iAnimalHealth.AH_Constants.AHI].LastImmunizationDate)}
                  required={true}
                  onChange={(date) => {
                    props.handleChange("LastImmunizationDate", util.SetDate(date), iAnimalHealth.AH_Constants.AHI);
                  }}
                  data-investigate="1"
                  maxDate={new Date()}
                />
              </div>

              <ImmunizedAnimals vaccineValid={vaccineValid} {...props} />
            </>
          )}
        </div>
        <div className="col-md-12 my-3 row">
          <QuestionWithYesNo
            {...props}
            list={answerYesNo}
            label="DetectedNearby"
            controlName="DetectedNearby"
            value={transaction[iAnimalHealth.AH_Constants.AHI].DetectedNearby}
            onchangeRadio={changeControl}
          />
        </div>
        <div className="col-md-12 my-3 row">
          <QuestionWithYesNo
            list={answerYesNo}
            {...props}
            label="LabSamplesCollected"
            controlName="LabSamplesCollected"
            value={transaction[iAnimalHealth.AH_Constants.AHI].LabSamplesCollected}
            onchangeRadio={(e) => {
              changeControl(e);
              setIsLabSampledCollected(e.target.value === enums.YesNoEnum.Yes);
            }}
          />
        </div>
        {isLabSampledCollected && <LabAnalysis {...props} />}
      </div>
    </div>
  );
};

export default InvestigationDetail;
