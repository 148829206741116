import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./Theme/css/app-style.css";
import "./Theme/css/icomoon.css";
import "./Theme/css/font-awesome.min.css";
import "./Theme/css/responsive.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Provider } from "react-redux";
import { store } from "./State/store";
import LoaderTemplate from "./Components/Common/Loader/LoaderTemplate";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["ar", "en"],
    fallbackLng: "ar",
    backend: {
      loadPath: "assets/locale/{{lng}}/translation.json",
    },
    detection: {
      order: ["cookie", "htmlTag", "querystring", "localStorage", "sessionStorage", "navigator", "path", "subdomain"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    interpolation: {
      escapeValue: false,
    },
  });
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<LoaderTemplate isLoading={true} />}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
