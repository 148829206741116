import { DispatchTypes } from "../DispatchType";
const InitialState = {
  userTypeData: [],
  emirateData: [],
  departmentData: [],
  govEntityData: [],
  animalCatagoryData: [],
  animalTypeData: [],
  clinicTypeSymptomsData: [],
  scientificName: "",
  workFlowStatus: [],
  ClinicSymptomLkp: [],
  UserAccountType: [],
  GetRiskDegreeLkp: [],
  controlProceduresData: [],
};

const MasterDataReducer = (state = InitialState, action) => {
  switch (action.type) {
    case DispatchTypes.CONTROL_PROCEDURES:
      return { ...state, controlProceduresData: action.controlProceduresData };
    case DispatchTypes.GET_USERTYPE:
      return { ...state, userTypeData: action.userTypeData };
    case DispatchTypes.GET_EMIRATES:
      return {
        ...state,
        emirateData: action.emirateData,
      };
    case DispatchTypes.GET_DEPARTMENT:
      return {
        ...state,
        departmentData: action.departmentData,
      };
    case DispatchTypes.GET_GOVENTITY:
      return { ...state, govEntityData: action.govEntityData };

    case DispatchTypes.GET_ANIMAL_CATAGORY:
      return {
        ...state,
        animalCatagoryData: action.animalCatagoryData,
      };
    case DispatchTypes.GET_ANIMAL_TYPE:
      return {
        ...state,
        animalTypeData: action.animalTypeData,
      };
    case DispatchTypes.GET_CLINICTYPE_SYMPTOMS:
      return {
        ...state,
        clinicTypeSymptomsData: action.clinicTypeSymptomsData,
      };
    case DispatchTypes.GET_CLINICTYPE_SYMPTOMS_LKP:
      return {
        ...state,
        ClinicSymptomLkp: action.ClinicSymptomLkp,
      };
    case DispatchTypes.GET_SCIENTIFIC_NAME:
      return {
        ...state,
        scientificName: action.scientificName,
      };
    case DispatchTypes.WORKFLOW_STATUS:
      return {
        ...state,
        workFlowStatus: action.workFlowStatus,
      };
    case DispatchTypes.USER_ACCOUNT_TYPE:
      return {
        ...state,
        UserAccountType: action.UserAccountType,
      };
    case DispatchTypes.RISK_DEGREE_LKP:
      return {
        ...state,
        GetRiskDegreeLkp: action.GetRiskDegreeLkp,
      };

    default:
      return state;
  }
};

export default MasterDataReducer;
