import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { accountAction, auth, swal, toast, validation } from "../../Imports";
import { UserConfirmPassword, UserPassword } from "../CommonControl";
import FormInput from "../../Common/Validation/FormInput";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const user = auth.getUserPermission();
  const initialState = {
    Email: user.Email,
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  };

  const [localVal, setLocalVal] = useState(initialState);
  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const form = validation.validateForm("changePasswordForm");
    if (!form) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }

    const res = await accountAction.changePassword(localVal);
    if (res) {
      swal(props.t("success"), props.t("updatedSuccessFully"), "success");
      cancelHandler();
    } else {
      toast(props.t("passwordDoesNotMatch"), { type: "warning" });
    }
    console.log("form submitted");
  };
  const cancelHandler = () => {
    navigate(`/`);
  };
  useEffect(() => {
    document.body.click();

    return {};
  }, []);
  useEffect(() => {
    console.log(localVal);

    return {};
  }, [localVal]);
  return (
    <form onSubmit={submitHandler}>
      <div className="page-title p-3 pl-4">
        <h3>{props.t("ChangePassword")}</h3>
      </div>
      <div className="container my-3  ">
        <div className="row container-bg changePasswordForm">
          <div className="col-md-12">
            <div className="col-md-4">
              <FormInput
                name="CurrentPassword"
                type="password"
                placeholder=""
                errorMessage={props.t("passwordValidation")}
                label={props.t("currentPassword")}
                required={true}
                value={localVal.CurrentPassword}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-4">
              <UserPassword name="NewPassword" value={localVal.NewPassword} label="newPassword" handleChange={handleChange} props={props} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-4">
              <UserConfirmPassword name="confirmPassword" label="password" currentPassField={localVal.NewPassword} handleChange={handleChange} props={props} />
            </div>
          </div>
          <div className="col-md-12 m-4">
            <button className="btn btn-success ml-2 mr-2 " type="submit">
              {props.t("ChangePassword")}
            </button>
            <button className="btn btn-success" type="button" onClick={cancelHandler}>
              {props.t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
