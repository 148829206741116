import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { encrypt } from "../../../Constants/Crypto";
import PopupOver from "../../Common/UI/PopupOver";
import { auth, enums } from "../../Imports";
import { URLRoutes } from "../../Routes/Routes";

const NewIncidentTemplate = (props) => {
  const activeShow = "active show";
  const [deptId, setDeptId] = useState(0);
  const user = auth.getUserPermission();

  const [tabactive, setTabActive] = useState("v-pills-first");
  const [isOperationManager, setIsOperationManager] = useState(false);
  const [isOperationCenter, setIsOperationCenter] = useState(false);
  const openRoute = (e, url) => {
    e.preventDefault();
    document.body.click();
    // navigate(url);
    window.location.href = url;
  };
  useEffect(() => {
    const nonGovUser = user.UserAccountType === enums.UserAccountTypeEnum.NonGovernmentUser;
    setIsOperationManager(user.UnitType === enums.UnitTypeEnum.MinistryUser && user.UserAccountType === enums.UserAccountTypeEnum.DisasterOperation);
    setIsOperationCenter(user.UnitType === enums.UnitTypeEnum.OperationCenterUser && user.UserAccountType === enums.UserAccountTypeEnum.DisasterOperation);
    if (user.isLoggedIn && !nonGovUser) {
      setDeptId(parseInt(user.Department));
    } else setDeptId(0);
  }, [deptId, user]);
  return (
    <>
      {!isOperationManager && (
        <div className="create-request align-self-start p-2 pl-3 pr-3 ml-3">
          <PopupOver
            button={
              <Link to="#">
                <i className="ico icon-Group-252 mr-2"></i>
                {props.t("reportAnIncident")}
                <i className="fa fa-angle-down ml-2"></i>
              </Link>
            }
            body={
              <div>
                <div className="popover-body">
                  <div className="title p-2 text-center">
                    <strong>{props.t("reportAnIncident")}</strong>
                  </div>
                  <div className="request-menu row m-0 d-flex">
                    <div className="col-4 p-0">
                      <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {(deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment || isOperationCenter || deptId === 0) && (
                          <Link
                            id="lnk_animalTab"
                            className={`nav-link d-flex justify-content-between align-items-center ${tabactive === "v-pills-first" ? activeShow : " "}`}
                            data-toggle="pill"
                            role="tab"
                            aria-controls="v-pills-first"
                            aria-selected="false"
                            to="#"
                            onClick={() => setTabActive("v-pills-first")}
                          >
                            <span>{props.t("Animal")}</span>
                            <i className="fa fa-angle-right ml-2"></i>
                          </Link>
                        )}
                        {(deptId === enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment || isOperationCenter || deptId === 0) && (
                          <Link
                            id="lnk_agricultureTab"
                            className={`nav-link d-flex justify-content-between align-items-center ${tabactive === "v-pills-second" ? activeShow : " "}`}
                            data-toggle="pill"
                            onClick={() => setTabActive("v-pills-second")}
                            role="tab"
                            aria-controls="v-pills-second"
                            aria-selected="false"
                            to="#"
                          >
                            <span>{props.t("Agriculture")}</span>
                            <i className="fa fa-angle-right ml-2"></i>
                          </Link>
                        )}
                        {(deptId === enums.DepartmentEnum.FoodSafetyDepartment || isOperationCenter || deptId === 0) && (
                          <Link
                            id="lnk_foodsafteyTab"
                            className={`nav-link d-flex justify-content-between align-items-center ${tabactive === "v-pills-third" ? activeShow : " "}`}
                            data-toggle="pill"
                            role="tab"
                            to="#"
                            aria-controls="v-pills-third"
                            aria-selected="true"
                            onClick={() => setTabActive("v-pills-third")}
                          >
                            <span>{props.t("FoodSafty")}</span>
                            <i className="fa fa-angle-right ml-2"></i>
                          </Link>
                        )}
                        <Link
                          id="lnk_envTab"
                          className={`nav-link d-flex justify-content-between align-items-center ${tabactive === "v-pills-fourth" ? activeShow : " "}`}
                          data-toggle="pill"
                          role="tab"
                          to="#"
                          aria-controls="v-pills-fourth"
                          aria-selected="true"
                          onClick={() => setTabActive("v-pills-fourth")}
                        >
                          <span>{props.t("Environment")}</span>
                          <i className="fa fa-angle-right ml-2"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="col-8 p-0 border-left">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className={`tab-pane fade ${tabactive === "v-pills-first" ? activeShow : " "}`}
                          id="v-pills-first"
                          role="tabpanel"
                          aria-labelledby="v-pills-first-tab"
                        >
                          {((deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment &&
                            user.UnitType !== enums.UnitTypeEnum.HealthFacility &&
                            user.UnitType !== enums.UnitTypeEnum.BorderGate) ||
                            isOperationCenter ||
                            deptId === 0) && (
                            <>
                              <Link to="#" className="p-2" onClick={(e) => openRoute(e, `/animalHealthincident/${encrypt(0)}/${encrypt(0)}`)}>
                                {props.t("reportAnimalHealthIncident")}
                              </Link>
                              {!isOperationCenter && (
                                <Link to="#" onClick={(e) => openRoute(e, `/animallegalviolation/${encrypt(0)}/${encrypt(0)}`)} className="p-2">
                                  {props.t("reportAnimalLegislationsViolationsIncident")}
                                </Link>
                              )}
                            </>
                          )}
                          {deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment && user.UnitType === enums.UnitTypeEnum.HealthFacility && (
                            <Link to="#" onClick={(e) => openRoute(e, `/animalsharedisease/${encrypt(0)}/${encrypt(0)}`)} className="p-2">
                              {props.t("sharedDiseaseReport")}
                            </Link>
                          )}
                          {deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment && user.UnitType === enums.UnitTypeEnum.BorderGate && (
                            <Link to="#" onClick={(e) => openRoute(e, URLRoutes.getAnimalBorderGate(encrypt(0), encrypt(0)))} className="p-2">
                              {props.t("AnimalBorderGate")}
                            </Link>
                          )}
                        </div>

                        {(deptId === enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment || isOperationCenter || deptId === 0) && (
                          <>
                            <div
                              className={`tab-pane fade ${tabactive === "v-pills-second" ? activeShow : " "}`}
                              id="v-pills-second"
                              role="tabpanel"
                              aria-labelledby="v-pills-second-tab"
                            >
                              {user.UnitType !== enums.UnitTypeEnum.BorderGate && (
                                <Link to="#" className="p-2" onClick={(e) => openRoute(e, `/agriculturepest/${encrypt(0)}/${encrypt(0)}`)}>
                                  {props.t("reportAgriculturalPestsIncident")}
                                </Link>
                              )}
                              {user.UnitType === enums.UnitTypeEnum.BorderGate && (
                                <Link to="#" onClick={(e) => openRoute(e, URLRoutes.getAgricultureBorderGate(encrypt(0), encrypt(0)))} className="p-2">
                                  {props.t("agriculturalBorderGateIncident")}
                                </Link>
                              )}
                            </div>
                          </>
                        )}
                        {(deptId === enums.DepartmentEnum.FoodSafetyDepartment || isOperationCenter || deptId === 0) && (
                          <>
                            <div
                              className={`tab-pane fade ${tabactive === "v-pills-third" ? activeShow : " "}`}
                              id="v-pills-third"
                              role="tabpanel"
                              aria-labelledby="v-pills-third-tab"
                            >
                              <Link to="#" className="p-2" onClick={(e) => openRoute(e, `/foodsafety/${encrypt(0)}/${encrypt(0)}`)}>
                                {props.t("reportFoodSafetyIncident")}
                              </Link>

                              <Link to="#" className="p-2" onClick={(e) => openRoute(e, `/foodsafety-border-gate/${encrypt(0)}/${encrypt(0)}`)}>
                                {props.t("FoodSafetyBorderGate")}
                              </Link>
                            </div>
                          </>
                        )}

                        <div
                          className={`tab-pane fade ${tabactive === "v-pills-fourth" ? activeShow : " "}`}
                          id="v-pills-fourth"
                          role="tabpanel"
                          aria-labelledby="v-pills-fourth-tab"
                        >
                          <Link to="#" className="p-2" onClick={(e) => openRoute(e, `environmental-incident/MA==/MA==`)}>
                            {props.t("environmentalIncidentNotifications")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default NewIncidentTemplate;
