import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iFoodSafetyBG } from "../../../Imports";
import * as Control from "../SharedControl";

const ProductInfo = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const FSBGPI = iFoodSafetyBG.FSBD_Constants.FSBGPI;

  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, FSBGPI);
  };
  const handleChange = (name, value) => {
    props.handleChange(name, value, FSBGPI);
  };

  return (
    <div className="container my-2">
      <div className="row container-bg">
        {/* <div className="col-md-4">
          <FormInput
            name="CustomsNumber"
            type="text"
            label="CustomsNumber"
            required={true}
            disabled={false}
            value={transaction[FSBGPI].CustomsNumber}
            onChange={changeControl}
          />
        </div> */}
        <Control.ProductGroup
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductMainCategory
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductSubCategory1
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductSubCategory2
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductSubCategory3
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductName
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductTradeName
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.Volume
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.PackageType
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.BatchNumber
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.ProductProductionDate
          transaction={transaction[FSBGPI]}
          changeControl={handleChange}
        />
        <Control.ProductExpiryDate
          transaction={transaction[FSBGPI]}
          changeControl={handleChange}
        />
        <Control.OtherInfo
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.FactoryName
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.FactoryAddress
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
        <Control.FactoryOrigin
          transaction={transaction[FSBGPI]}
          changeControl={changeControl}
        />
      </div>
    </div>
  );
};

export default ProductInfo;
