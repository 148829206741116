import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { iIncident, enums, util } from "../../Imports";

const AuditLogs = (props) => {
  const transaction = useSelector((state) => state.transaction);

  const [actionHistory, setActionHistory] = useState([]);
  useEffect(() => {
    const actionHistoryConst = transaction[iIncident.SharedDTO.IT].WorkFlowActionHistorys;
    let newActionHistory = actionHistoryConst ? actionHistoryConst.map((obj) => ({ ...obj })) : [];
    console.log("newActionHistory", newActionHistory);

    setActionHistory(newActionHistory);
  }, [transaction.isUpdate]);

  return (
    <>
      {actionHistory && actionHistory.length > 0 && transaction[iIncident.SharedDTO.IT].FollowUp !== 1 && (
        <div className="container ">
          <div className="row container-out-bg commentBox my-3 ">
            <label>
              <strong>{props.t("ActionLogs")}</strong>
            </label>
            <table className="table table-responsive my-1">
              <tbody>
                {actionHistory
                  .sort((a, b) => parseFloat(b.Id) - parseFloat(a.Id))
                  .map((e, i) => {
                    return (
                      (e.WorkFlowState.StatusCode !== enums.WorkFlowStatusCodeEnum.New || e.Comments !== null) && (
                        <tr key={i}>
                          <td>
                            <small className="golden">
                              <strong> {e.CreatedBy} </strong>
                            </small>
                          </td>
                          <td>{new Date(e.CreatedDate).toLocaleString()} </td>
                          <td style={{ width: "15%" }}>
                            <span
                              className="status-box mx-2"
                              style={{
                                backgroundColor: `${e.WorkFlowState.WorkFlowStateLkp.Color}`,
                              }}
                            >
                              {util.getTextFromObj(e.WorkFlowState.WorkFlowStateLkp)}
                            </span>
                          </td>
                          <td style={{ width: "50%" }}>{e.Comments}</td>
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default AuditLogs;
