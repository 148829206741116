import {
  FoodSafetyBaseDTO,
  FoodSafetyProductInfoBaseDTO,
  FoodSafetyRiskInfoBaseDTO,
} from "./IFoodSafetyBase";

export const FSBD_Constants = {
  FSBGT: "FoodSafetyBorderGateTransactionDTO",
  FSBGPI: "FoodSafetyBorderGateProductInfoDTO",
  FSBGRI: "FoodSafetyBorderGateRiskInfoDTO",
};

export class FoodSafetyBorderGateTransactionDTO extends FoodSafetyBaseDTO {
  constructor(
    IncidentsTransactionId,
    IncidentsTransaction,
    ShipmentCertificateNo,
    ImporterPhone,
    RejectionDate,
    RejectionReason,
    CustomsNumber,
    CustomsDate,
    CountryLkp,
    TransportMediaLkp
  ) {
    super();
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.IncidentsTransaction = IncidentsTransaction;
    this.ShipmentCertificateNo = ShipmentCertificateNo;
    this.ImporterPhone = ImporterPhone;
    this.RejectionDate = RejectionDate;
    this.RejectionReason = RejectionReason;
    this.CustomsNumber = CustomsNumber;
    this.CustomsDate = CustomsDate;
    this.CountryLkp = CountryLkp;
    this.TransportMediaLkp = TransportMediaLkp;
  }
}
export class FoodSafetyBorderGateProductInfoDTO extends FoodSafetyProductInfoBaseDTO {
  constructor(
    FoodSafetyBorderGateTransactionId,
    FoodSafetyBorderGateTransaction,
    ProductGroupLkp
  ) {
    super();
    this.FoodSafetyBorderGateTransactionId = FoodSafetyBorderGateTransactionId;
    this.FoodSafetyBorderGateTransaction = FoodSafetyBorderGateTransaction;
    this.ProductGroupLkp = ProductGroupLkp;
  }
}

export class FoodSafetyBorderGateRiskInfoDTO extends FoodSafetyRiskInfoBaseDTO {}

export const IncidentTransactionState = {
  FoodSafetyBorderGateTransactionDTO: new FoodSafetyBorderGateTransactionDTO(),
  FoodSafetyBorderGateProductInfoDTO: new FoodSafetyBorderGateProductInfoDTO(),
  FoodSafetyBorderGateRiskInfoDTO: new FoodSafetyBorderGateRiskInfoDTO(),
};
