import React, { useContext, useEffect, useState } from "react";
import logoar from "../../Theme/img/logo-ar.png";
import logoen from "../../Theme/img/logo-en.png";
import { Link, useLocation, UNSAFE_NavigationContext } from "react-router-dom";
import MobileHeaderTemplate from "../Layout/MobileHeaderTemplate";
import { checkLogin } from "../../Constants/AuthToken";
import NotificationUI from "./HeaderComponent/NotificationUI";
import NewIncidentTemplate from "./HeaderComponent/NewIncidentTemplate";
import UaePassLogo from "./HeaderComponent/UaePassLogo";
import UserSettingUI from "./HeaderComponent/UserSettingUI";
import { util } from "../Imports";

const Header = (props) => {
  const logo = props.currentLanguage === "ar" ? logoar : logoen;
  let location = useLocation();
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  const isLoggedIn = checkLogin();
  const [isHeader, setIsHeader] = useState("block");

  useEffect(() => {
    const loginRoute = util.pagesWOLogin;
    const { pathname } = location;

    const handleListener = (location, action) => {
      console.log("listener", location);
      if (action === "POP") {
        if (loginRoute.indexOf(location.pathname.toLocaleLowerCase()) < 0) {
          setIsHeader("block");
        }
      }
    };

    setIsHeader("block");
    if (loginRoute.indexOf(pathname.toLocaleLowerCase()) > -1 || util.getRouteValue(pathname)) {
      console.log(pathname);
      setIsHeader("none");
    }

    return () => {
      handleListener(navigator.location, navigator.action);
    };
  }, [location, setIsHeader, navigator]);

  return (
    <div id="header" style={{ display: isHeader }}>
      <div className=" d-lg-block web-header">
        <div className="d-flex align-items-center p-4">
          <div className="d-flex flex-fill align-items-center app-links">
            <div className="lang-switcher mr-4">
              <Link to="/" onClick={props.changeLangGlobal} id="lnk_languageSwitcher" className="langBtn header-controls-item icon small">
                {props.t("langText")}
              </Link>
            </div>

            {/* {!isLoggedIn && (
              <span className="mx-3  d-flex justify-content-center">
                <Link id="signIn" className="border-right mr-3 pr-3" to="/Login">
                  {props.t("login")}
                </Link>
                <Link id="lnk_register" to="/Registration">
                  {props.t("register")}
                </Link>
              </span>
            )} */}

            {isLoggedIn && <NotificationUI {...props} />}
            {isLoggedIn && <UserSettingUI {...props} />}

            {!isLoggedIn && <UaePassLogo {...props} isWeb={true} />}

            {isLoggedIn && <NewIncidentTemplate {...props} />}
          </div>
          <div className="d-flex align-items-center app-logo">
            <div className="site-title   text-center pr-3 mr-3">
              <h1> {props.t("app_name")}</h1>
              <h2> {props.t("app_name2")}</h2>
            </div>

            <Link to="/" className="site-logo">
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <MobileHeaderTemplate t={props.t} lang={props.lang} logo={logo} changeLangGlobal={props.changeLangGlobal} />
    </div>
  );
};

export default Header;
