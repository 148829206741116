import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from "../Common/ContactUs";
import Copyright from "../Common/Copyright";
import PrivacyPolicy from "../Common/PrivacyPolicy";
import Home from "../Home";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import ProtectedRoute, { AgricultureDataAccess, AnimalDataAccess, FoodSecurityDataAccess, MinistryUserDataAccess } from "../Account/ProtectedRoute";
import AnimalHealthIncident from "../Incidents/AnimalHealth/AnimalHealthIncident";
import AuthTemplate from "../Account/AuthTemplate";
import Tasks from "../Incidents/Inbox/Tasks";
import Test from "../Incidents/Inbox/Test";
import SideBar from "../Layout/SideBar";
import { checkLogin } from "../../Constants/AuthToken";
import Notification from "../Incidents/Common/Notification/Notification";
import AnimalLegalViolation from "../Incidents/AnimalLeagalViolation/AnimalLegalViolation";
import AgriculturePest from "../Incidents/AgriculturePest/AgriculturePest";
import AnimalShareDisease from "../Incidents/AnimalShareDisease/AnimalShareDisease";
import FoodSafety from "../Incidents/FoodSafety/FoodSafetyIncident/FoodSafety";
import { URLRoutes } from "./Routes";
import AnimalBorderGate from "../Incidents/AnimalBorderGate/AnimalBorderGate";
import FoodSafetyBorderGate from "../Incidents/FoodSafety/FoodSafetyBorderGate/FoodSafetyBorderGate";
import FoodSafetyFollowUpReport from "../Incidents/FoodSafety/FoodSafetyIncident/FoodSafetyFollowUpReport";
import NotFound from "../Layout/NotFound";
import Dashboard from "../Dashboard/Dashboard";
import AgricultureBorderGate from "../Incidents/AgricultureBorderGate/AgricultureBorderGate";
import EnvironmentalIncident from "../Incidents/EnvironmentalIncident/EnvironmentalIncident";
import DocumentLibrary from "../DocumentLibrary/DocumentLibrary";
import ViewDocumentLibrary from "../DocumentLibrary/ViewDocumentLibrary";
import AgricultureReport from "../Incidents/Inbox/Reports/Agriculture/AgricultureReport";
import AnimalHealthReport from "../Incidents/Inbox/Reports/Animal/AnimalHealthReport";
import FoodSafetyReport from "../Incidents/Inbox/Reports/FoodSafety/FoodSafetyReport";
import FoodSafetyByEmirateCount from "../Incidents/Inbox/Reports/FoodSafety/CountByEmirate/FoodSafetyByEmirateCount";
import ManageAccount from "../Account/ManageAccount/ManageAccount";
import AddNewAccount from "../Account/ManageAccount/AddNewAccount";
import ChangePassword from "../Account/Personal/ChangePassword";
import Profile from "../Account/Personal/Profile";
import IncidentDetail from "../Dashboard/IncidentDetails/IncidentDetail";
import SystemConfiguration from "../Configuration/SystemConfiguration";
import OverallReport from "../Incidents/Inbox/Reports/EnvionmentalDisaster/OverallReport";
import AnimalIncidentRedirection from "../IncidentRedirection/Animal/AnimalIncidentRedirection";
import WHOReport from "../Incidents/Inbox/Reports/WorldHealthOrganization/WHOReport";
import AnimalDiseaseConfig from "../Configuration/AnimalDiseaseConfig";
import UAEPass from "../Account/UAEPass/UAEPass";
import LoginFromMobileApp from "../Account/UAEPass/LoginFromMobileApp";
import MultipleAccounts from "../Account/UAEPass/MultipleAccounts";
import RoleSetting from "../Configuration/Admin/RoleSetting";
import WorkFlowSetting from "../Configuration/Admin/WorkFlowSetting";

function AppRouters(props) {
  return (
    <div>
      {/* <BrowserRouter basename={"/biosecapp"}> */}
      <BrowserRouter>
        <Header {...props} />
        <section className="d-flex h-100">
          <div className="d-flex">{checkLogin() ? <SideBar {...props} /> : <div></div>}</div>
          <div className="main-container d-flex flex-column flex-fill" id="content">
            <Routes>
              <Route path={URLRoutes.LoginLinked} element={<AuthTemplate {...props} path="loginLinked" />} />
              <Route path={URLRoutes.ProfileLinking} element={<AuthTemplate {...props} path="profileLinking" />} />
              <Route path={URLRoutes.login} element={<AuthTemplate {...props} path="login" />} />
              <Route path={URLRoutes.registration} element={<AuthTemplate {...props} path="registration" />} />
              <Route path={URLRoutes.forgetPassword} element={<AuthTemplate {...props} path="forgetPassword" />} />
              <Route path={URLRoutes.MultipleAccounts} element={<AuthTemplate {...props} path="multipleAccounts" />} />
              <Route path="/" element={<Home {...props} />} />
              <Route path="contactus" element={<ContactUs {...props} />} />
              <Route path="copyright" element={<Copyright {...props} />} />
              <Route path="privacypolicy" element={<PrivacyPolicy {...props} />} />
              <Route element={<ProtectedRoute {...props} />}>
                <Route element={<AnimalDataAccess {...props} />}>
                  <Route path={URLRoutes.animalSharedDisease} element={<AnimalShareDisease {...props} />} />
                  <Route path={URLRoutes.animalBorderGate} element={<AnimalBorderGate {...props} />} />
                  <Route path={URLRoutes.animalHealthReport} element={<AnimalHealthReport {...props} />} />
                  <Route path={URLRoutes.worldHealthOrganizationReport} element={<WHOReport {...props} />} />
                  <Route path={URLRoutes.AnimalDiseaseConfig} element={<AnimalDiseaseConfig {...props} />} />
                </Route>

                <Route element={<AgricultureDataAccess {...props} />}>
                  <Route path={URLRoutes.agricultureBorderGate} element={<AgricultureBorderGate {...props} />} />
                  <Route path={URLRoutes.agriculturePestReport} element={<AgricultureReport {...props} />} />
                </Route>

                <Route element={<FoodSecurityDataAccess {...props} />}>
                  <Route path={URLRoutes.foodSafetyBorderGate} element={<FoodSafetyBorderGate {...props} />} />
                  <Route path={URLRoutes.foodSafetyFollowUp} element={<FoodSafetyFollowUpReport {...props} />} />
                  <Route path={URLRoutes.foodSafetyReport} element={<FoodSafetyReport {...props} />} />
                  <Route path={URLRoutes.foodSafetyCountByEmirateReport} element={<FoodSafetyByEmirateCount {...props} />} />
                </Route>
                <Route path={URLRoutes.OverAllReport} element={<OverallReport {...props} />} />
                <Route path={URLRoutes.manageIncident} element={<Tasks {...props} />} />
                <Route path={URLRoutes.notification} element={<Notification {...props} />} />

                <Route path={URLRoutes.dashboard} element={<Dashboard {...props} />} />
                <Route path={URLRoutes.incidentDetail} element={<IncidentDetail {...props} />} />
                <Route path={URLRoutes.documentLib} element={<DocumentLibrary {...props} />} />
                <Route path={URLRoutes.viewDocumentLib} element={<ViewDocumentLibrary {...props} />} />
                <Route element={<MinistryUserDataAccess {...props} />}>
                  <Route path={URLRoutes.AnimalIncidentRedirection} element={<AnimalIncidentRedirection {...props} />} />
                  <Route path={URLRoutes.manageAccount} element={<ManageAccount {...props} />} />
                  <Route path={URLRoutes.addNewAccount} element={<AddNewAccount {...props} />} />
                  <Route path={URLRoutes.systemConfiguration} element={<SystemConfiguration {...props} />} />
                  <Route path={URLRoutes.RoleSetting} element={<RoleSetting {...props} />} />
                  <Route path={URLRoutes.WorkFlowSetting} element={<WorkFlowSetting {...props} />} />
                </Route>
                <Route path={URLRoutes.changepassword} element={<ChangePassword {...props} />} />
                <Route path={URLRoutes.profile} element={<Profile {...props} />} />
                <Route path={URLRoutes.EnvironmentalIncident} element={<EnvironmentalIncident {...props} />} />

                <Route path={URLRoutes.animalHealthIncident} element={<AnimalHealthIncident {...props} />} />
                <Route path={URLRoutes.animalHealthIncidentWithSharedDisease} element={<AnimalHealthIncident {...props} />} />
                <Route path={URLRoutes.animalLegalViolation} element={<AnimalLegalViolation {...props} />} />

                <Route path={URLRoutes.agriculturePest} element={<AgriculturePest {...props} />} />
                <Route path={URLRoutes.foodSafety} element={<FoodSafety {...props} />} />
              </Route>

              <Route path={URLRoutes.uaepass} element={<UAEPass {...props} />} />
              <Route path={URLRoutes.LoginFromMobileApp} element={<LoginFromMobileApp {...props} />} />

              <Route path="test" element={<Test {...props} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer {...props} />
          </div>
        </section>
      </BrowserRouter>
    </div>
  );
}

export default AppRouters;
