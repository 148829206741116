export const ABG_Constants = {
  ABGT: "AgricultureBorderGateTransactionDTO",
  ABGSD: "AgricultureBorderGateShipmentDetailDTO",
  ABGID: "AgricultureBorderGateImporterDetailDTO",
  ABDCD: "AgricultureBorderGateCropsDetailDTO",
};

export class AgricultureBorderGateTransactionDTO {
  constructor(
    IncidentsTransactionId,
    ShipmentNo,
    ShipmentCertificateNo,
    ContainerNo,
    BorderPortId,
    GovernmentalEntityLkp,
    AgricultureBorderGateImporterDetail,
    AgricultureBorderGateShipmentDetail,
    AgricultureBorderGateCropsDetail
  ) {
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.ShipmentNo = ShipmentNo;
    this.ShipmentCertificateNo = ShipmentCertificateNo;
    this.ContainerNo = ContainerNo;
    this.BorderPortId = BorderPortId;
    this.GovernmentalEntityLkp = GovernmentalEntityLkp;
    this.AgricultureBorderGateImporterDetail =
      AgricultureBorderGateImporterDetail;
    this.AgricultureBorderGateShipmentDetail =
      AgricultureBorderGateShipmentDetail;
    this.AgricultureBorderGateCropsDetail = AgricultureBorderGateCropsDetail;
  }
}

export class AgricultureBorderGateShipmentDetailDTO {
  constructor(
    AgricultureBorderGateTransactionId,
    AgricultureBorderGateTransaction,
    ExportCountryId,
    CountryLkp,
    TransportMediaId,
    TransportMediaLkp,
    LadingNoBill,
    Transporter,
    ShipmentArrivalDate,
    RejectionDate,
    RejectionReason
  ) {
    this.AgricultureBorderGateTransactionId =
      AgricultureBorderGateTransactionId;
    this.AgricultureBorderGateTransaction = AgricultureBorderGateTransaction;
    this.ExportCountryId = ExportCountryId;
    this.CountryLkp = CountryLkp;
    this.TransportMediaId = TransportMediaId;
    this.TransportMediaLkp = TransportMediaLkp;
    this.LadingNoBill = LadingNoBill;
    this.Transporter = Transporter;
    this.ShipmentArrivalDate = ShipmentArrivalDate;
    this.RejectionDate = RejectionDate;
    this.RejectionReason = RejectionReason;
  }
}
export class AgricultureBorderGateImporterDetailDTO {
  constructor(
    AgricultureBorderGateTransactionId,
    AgricultureBorderGateTransaction,
    ImporterName,
    ImporterPhone,
    ImporterEmail,
    ImporterFax
  ) {
    this.AgricultureBorderGateTransactionId =
      AgricultureBorderGateTransactionId;
    this.AgricultureBorderGateTransaction = AgricultureBorderGateTransaction;
    this.ImporterName = ImporterName;
    this.ImporterPhone = ImporterPhone;
    this.ImporterEmail = ImporterEmail;
    this.ImporterFax = ImporterFax;
  }
}
export class AgricultureBorderGateCropsDetailDTO {
  constructor(
    OrignCountryId,
    CountryName,
    EserviceAgricultureLkpId,
    CropName,
    Amount,
    Unit,
    Commnets,
    Uid
  ) {
    this.OrignCountryId = OrignCountryId;
    this.CountryName = CountryName;
    this.EserviceAgricultureLkpId = EserviceAgricultureLkpId;
    this.CropName = CropName;
    this.Amount = Amount;
    this.Unit = Unit;
    this.Commnets = Commnets;
    this.Uid = Uid;
  }
}
export const IncidentTransactionState = {
  AgricultureBorderGateTransactionDTO:
    new AgricultureBorderGateTransactionDTO(),
  AgricultureBorderGateShipmentDetailDTO:
    new AgricultureBorderGateShipmentDetailDTO(),
  AgricultureBorderGateImporterDetailDTO:
    new AgricultureBorderGateImporterDetailDTO(),
  AgricultureBorderGateCropsDetailDTO: [],
};
