import React, { useEffect } from "react";
import FormInput from "../../Common/Validation/FormInput";
import ReCAPTCHA from "react-google-recaptcha";

const Test = () => {
  function handleChange(e) {}
  function handleUserProfile(e) {}

  function onChangeCaptcha(val) {
    console.log(val);
  }
  useEffect(() => {}, []);
  const submitForm = () => {};
  return (
    <div className="container">
      <div className="row">
        <FormInput
          name="Email"
          type="text"
          label="Email"
          required={false}
          value={""}
          onChange={handleChange}
        />
        <FormInput
          name="NameAr"
          type="text"
          label="NameAr"
          required={false}
          value={""}
          onChange={handleUserProfile}
        />
        <FormInput
          name="MobileNo"
          type="text"
          label="MobileNo3"
          required={false}
          value={""}
          onChange={handleUserProfile}
        />
        <ReCAPTCHA
          sitekey="6LceXWonAAAAAHunbf6qFudGQhBNpGx6ACUxDMkQ"
          onChange={onChangeCaptcha}
        />
        <button className="btn btn-success" onSubmit={submitForm}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Test;
