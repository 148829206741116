export const FoodSafetyCountByEmirate = (data) => {
  return data.map((x) => ({
    Emirate: x.Name,
    "Incident Count": x.Count,
  }));
};

export const FoodSafetyMapper = (data) => {
  return data.map((x) => ({
    "Incident Number": x.IncidentsTransaction.RefNo,
    "Incident Status": x.IncidentsTransaction.StatusCode,
    "Incident Date": x.IncidentsTransaction.CreatedDate,
    "Follow Up": x.IncidentsTransaction.FollowUp > 0 ? "متابعة" : "",
    "Focal Point": x.IncidentsTransaction.GovtAgency?.NameAr,
    "Notification Type": x.IncidentsTransaction.NotificationTypeLkp?.NameAr,
    "Risk Degree": x.IncidentsTransaction.RiskDegreeLkp?.NameAr,
    "Incident Type": x.IncidentsTransaction.IncidentType?.NameAr,
    "Food Facility Name": x.FoodSafetyCompanyDetail?.FacilityName,
    "Food Facility Activity": x.FoodSafetyCompanyDetail?.ActivityTypeLkp?.NameAr,
    "Food Facility Country": x.FoodSafetyCompanyDetail?.CountryLkp?.NameAr,
    "Incident Source": x.FoodSafetyCompanyDetail?.IncidentSource,
    "Distribution Area": x.FoodSafetyCompanyDetail?.DistributionArea,
    "Facility Incident Description": x.FoodSafetyCompanyDetail?.FacilityIncidentDescription,
    "Product Description Main Group": x.FoodSafetyProductInfo?.ProductGroupLkp?.NameAr ?? x.FoodSafetyBorderGateProductInfo?.ProductGroupLkp?.NameAr,
    "Product Description Main Category": x.FoodSafetyProductInfo?.ProductMainCategory ?? x.FoodSafetyBorderGateProductInfo?.ProductMainCategory,
    "Product Description Sub Category 1": x.FoodSafetyProductInfo?.ProductSubCategory1 ?? x.FoodSafetyBorderGateProductInfo?.ProductSubCategory1,
    "Product Description Sub Category 2": x.FoodSafetyProductInfo?.ProductSubCategory2 ?? x.FoodSafetyBorderGateProductInfo?.ProductSubCategory2,
    "Product Description Sub Category 3": x.FoodSafetyProductInfo?.ProductSubCategory3 ?? x.FoodSafetyBorderGateProductInfo?.ProductSubCategory3,
    "Product Name": x.FoodSafetyProductInfo?.ProductName ?? x.FoodSafetyBorderGateProductInfo?.ProductName,
    "Product Trade Name": x.FoodSafetyProductInfo?.ProductTradeName ?? x.FoodSafetyBorderGateProductInfo?.ProductTradeName,
    "Product Volume": x.FoodSafetyProductInfo?.Volume ?? x.FoodSafetyBorderGateProductInfo?.Volume,
    "Product Unit": x.FoodSafetyProductInfo?.UnitLkp?.NameAr ?? x.FoodSafetyBorderGateProductInfo?.UnitLkp?.NameAr,
    "Product Package Type": x.FoodSafetyProductInfo?.PackageType ?? x.FoodSafetyBorderGateProductInfo?.PackageType,
    "Product Batch Number": x.FoodSafetyProductInfo?.BatchNumber ?? x.FoodSafetyBorderGateProductInfo?.BatchNumber,
    "Product Production Date": x.FoodSafetyProductInfo?.ProductProductionDate ?? x.FoodSafetyBorderGateProductInfo?.ProductProductionDate,
    "Product Expiry Date": x.FoodSafetyProductInfo?.ProductExpiryDate ?? x.FoodSafetyBorderGateProductInfo?.ProductExpiryDate,
    "Product Other Info": x.FoodSafetyProductInfo?.OtherInfo ?? x.FoodSafetyBorderGateProductInfo?.OtherInfo,
    "Product Factory Name": x.FoodSafetyProductInfo?.FactoryName ?? x.FoodSafetyBorderGateProductInfo?.FactoryName,
    "Product Factory Address": x.FoodSafetyProductInfo?.FactoryAddress ?? x.FoodSafetyBorderGateProductInfo?.FactoryAddress,
    "Factory Origin": x.FoodSafetyProductInfo?.CountryLkp?.NameAr ?? x.FoodSafetyBorderGateProductInfo?.CountryLkp?.NameAr,
    "Border Port": x.GovernmentalEntityLkp?.NameAr,
    "Arrival Date": x.FoodSafetyProductInfo?.ArrivalDate ?? x.FoodSafetyBorderGateProductInfo?.ArrivalDate,
    "Export Country": x.CountryLkp?.NameAr,
    "Transport Media": x.TransportMediaLkp?.NameAr,
    "Items Count": x.ItemsCount,
    "Total Amount": x.TotalAmount,
    "Shipment Number": x.ShipmentNo,
    "Container Number": x.ContainerNo,
    "Exporter Name": x.ExporterName,
    "Exporter Address": x.ExporterAddress,
    "Importer Name": x.ImporterName,
    "Importer Address": x.ImporterAddress,
    "Risk Source": x.FoodSafetyRiskInfo?.RiskSourceLkp?.NameAr ?? x.FoodSafetyBorderGateRiskInfo?.RiskSourceLkp?.NameAr,
    "Risk Source Group": x.FoodSafetyRiskInfo?.RiskSourceGroupLkp?.NameAr ?? x.FoodSafetyBorderGateRiskInfo?.RiskSourceGroupLkp?.NameAr,
    "Hazard Main": x.FoodSafetyRiskInfo?.HazardMain ?? x.FoodSafetyBorderGateRiskInfo?.HazardMain,
    "Hazard Sub": x.FoodSafetyRiskInfo?.HazardSub ?? x.FoodSafetyBorderGateRiskInfo?.HazardSub,
    "Hazard Description": x.FoodSafetyRiskInfo?.HazardDescription ?? x.FoodSafetyBorderGateRiskInfo?.HazardDescription,
    "Lab Name": x.FoodSafetyRiskInfo?.LabName ?? x.FoodSafetyBorderGateRiskInfo?.LabName,
    "Lab Address": x.FoodSafetyRiskInfo?.LabAddress ?? x.FoodSafetyBorderGateRiskInfo?.LabAddress,
    "Lab Test Type": x.FoodSafetyRiskInfo?.LabTestType ?? x.FoodSafetyBorderGateRiskInfo?.LabTestType,
    "Lab Test Date": x.FoodSafetyRiskInfo?.LabTestDate ?? x.FoodSafetyBorderGateRiskInfo?.LabTestDate,
    "Sample Count": x.FoodSafetyRiskInfo?.SampleCount ?? x.FoodSafetyBorderGateRiskInfo?.SampleCount,
    "Sample Location": x.FoodSafetyRiskInfo?.SampleLocation ?? x.FoodSafetyBorderGateRiskInfo?.SampleLocation,
    "Lab Test Result": x.FoodSafetyRiskInfo?.LabTestResult ?? x.FoodSafetyBorderGateRiskInfo?.LabTestResult,
    "Max Allowed Result": x.FoodSafetyRiskInfo?.MaxAllowedResult ?? x.FoodSafetyBorderGateRiskInfo?.MaxAllowedResult,
    "Legal Reference": x.FoodSafetyRiskInfo?.LegalReference ?? x.FoodSafetyBorderGateRiskInfo?.LegalReference,
    "Extra Legal Reference": x.FoodSafetyRiskInfo?.ExtraLegalReference ?? x.FoodSafetyBorderGateRiskInfo?.ExtraLegalReference,
    "Reporter Name": x.IncidentsTransaction?.Users?.UsersProfile?.NameAr,
    "Reporter Phone": x.IncidentsTransaction?.Users?.UsersProfile?.MobileNo,
    "Reporter Email": x.IncidentsTransaction?.Users?.Email,
    ActionLog: x.IncidentsTransaction?.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};
