import { enums } from "../Components/Imports";
import { getUserPermission } from "../Constants/AuthToken";
import { getLang } from "../Constants/Utils";
import { IncidentTypeEnum, WorkFlowActionCodeEnum, WorkFlowStatusCodeEnum } from "./enums";

const userPermission = getUserPermission();
export const SharedDTO = {
  IT: "IncidentsMainTransactionDTO",
  UP: "UserProfileDTO",
  FA: "FileAttachmentDTO",
  IFA: "IntegrationAttachmentDTO",
  WFA: "WFActions",
  ICH: "IncidentCirculationHistoryDTO",
};

export class IncidentsMainTransactionDTO {
  constructor(
    StatusCode,
    WfActionCode,
    LoggedInUserId,
    UnitTypeCode,
    Latitude,
    Longitude,
    IncidentTypeCode,
    Users,
    GovernmetnalEntityId,
    GovtAgency,
    IncidentAttachment,
    CreatedDate,
    UpdateDate,
    RefNo,
    WFComment,
    FollowUp,
    Id,
    IncidentCirculationHistory,
    EmirateId,
    SharedDiseaseIncident,
    ParentId,
    Redirect_EmirateId,
    Redirect_GovtEntityId,
    RiskDegreeCode,
    Redirect_DepartmentId
  ) {
    this.Id = Id ?? 0;
    this.StatusCode = StatusCode ?? WorkFlowStatusCodeEnum.New;
    this.WfActionCode = WfActionCode ?? null;
    this.LoggedInUserId = LoggedInUserId ?? "";
    this.UnitTypeCode = UnitTypeCode ?? "";
    this.Latitude = Latitude ?? "";
    this.Longitude = Longitude ?? "";
    this.IncidentTypeCode = IncidentTypeCode ?? "";
    this.Users = Users ?? new UsersDTO();
    this.GovernmetnalEntityId = GovernmetnalEntityId ?? "";
    this.EmirateId = EmirateId ?? "";
    this.GovtAgency = GovtAgency ?? null;
    this.IncidentAttachment = IncidentAttachment ?? [];
    this.CreatedDate = CreatedDate ?? "";
    this.UpdateDate = UpdateDate ?? "";
    this.RefNo = RefNo ?? "";
    this.WFComment = WFComment ?? "";
    this.FollowUp = FollowUp ?? "";
    this.IncidentCirculationHistory = IncidentCirculationHistory;
    this.SharedDiseaseIncident = SharedDiseaseIncident ?? false;
    this.ParentId = ParentId ?? 0;
    this.Redirect_EmirateId = Redirect_EmirateId;
    this.Redirect_GovtEntityId = Redirect_GovtEntityId;
    this.Redirect_DepartmentId = Redirect_DepartmentId;
    this.RiskDegreeCode = RiskDegreeCode ?? null;
  }
  // setIncidentAttachment(attachmentList) {
  //   if (attachmentList) {
  //     this.IncidentAttachment = attachmentList.map((x) => {
  //       return new IncidentAttachment(x.Id);
  //     });
  //   }
  // }
  setUser(userProfile, transaction) {
    const userDto = new UsersDTO();
    userDto.Email = userProfile.Email;
    userDto.Password = "test";
    userDto.UsersProfile = userProfile;
    userDto.UsersProfile.OrganizationUnitId = userPermission.OrganizationId;
    //userDto.UsersProfile.NameEn = userProfile.NameEn ?? "Guest User";
    userDto.UsersProfile.NameAr = userProfile.NameAr ?? userProfile.NameEn ?? "Guest User";
    userDto.UsersProfile.PrefLanguage = getLang();
    this.Users = userDto;
  }
}

export class FileAttachments {
  constructor(FileId, FilePath, FileName, FileExtension, FileSize, FileMimeType, IsThumb, ThumbPath, IsActive) {
    this.FileId = FileId;
    this.FilePath = FilePath;
    this.FileName = FileName;
    this.FileExtension = FileExtension;
    this.FileSize = FileSize;
    this.FileMimeType = FileMimeType;
    this.IsThumb = IsThumb;
    this.ThumbPath = ThumbPath;
    this.IsActive = IsActive;
  }
}
export class UsersDTO {
  constructor(Email, Password, UserProfile, UserId) {
    this.Email = Email ?? "";
    this.Password = Password ?? "";
    this.UsersProfile = UserProfile ?? new UserProfileDTO();
    this.UserId = UserId;
  }
}
export class UserProfileDTO {
  constructor(NameAr, NameEn, Email, ReporterEID, PassportNo, MobileNo, Fax, PrefLanguage, OrganizationUnitId, OrganizationUnitsLkp, lastNameEn, lastNameAr) {
    this.NameAr = NameAr ?? "";
    this.NameEn = NameEn ?? "";
    this.Email = Email ?? "";
    this.ReporterEID = ReporterEID ?? "";
    this.PassportNo = PassportNo ?? "";
    this.MobileNo = MobileNo ?? "";
    this.PrefLanguage = PrefLanguage ?? "ar";
    this.OrganizationUnitId = OrganizationUnitId ?? "";
    this.OrganizationUnitsLkp = OrganizationUnitsLkp;
    this.Fax = Fax;
    this.lastNameEn = lastNameEn;
    this.lastNameAr = lastNameAr;
  }
}
export class OrganizationUnitsLkpDTO {
  constructor(GovEntityId, DepartmentUnitType) {
    this.GovEntityId = GovEntityId;
    this.DepartmentUnitType = DepartmentUnitType;
  }
}
export class DepartmentUnitTypeDTO {
  constructor(DepartmentId, UnitTypeCode) {
    this.DepartmentId = DepartmentId;
    this.UnitTypeCode = UnitTypeCode;
  }
}

export class IncidentAttachment {
  constructor(FileAttachmentId, Code, FileAttachment) {
    this.FileAttachmentId = FileAttachmentId;
    this.Code = Code ?? enums.AttachmentEnum.Other;
    this.FileAttachment = FileAttachment ?? new FileAttachments();
  }
}
export class IncidentIntegrationAttachment {
  constructor(FileName, FileURL) {
    this.FileName = FileName ?? "";
    this.FileURL = FileURL ?? "";
  }
}
export class IncidentCirculationHistoryDTO {
  constructor(GovernmentalEntityId, GovEntityName, IsCircularNotification, Uid) {
    this.GovernmentalEntityId = GovernmentalEntityId;
    this.GovEntityName = GovEntityName;
    this.IsCircularNotification = IsCircularNotification;
    this.Uid = Uid ?? Date.now();
  }
}
const getActionCode = (transaction, incidentTypeEnum) => {
  // const user = auth.getUserPermission();
  if (transaction.isUpdate) {
    if (transaction.IncidentsMainTransactionDTO.WfActionCode == null) {
      return WorkFlowActionCodeEnum.Save;
    }
    return transaction.IncidentsMainTransactionDTO.WfActionCode;
  } else if (userPermission.isLoggedIn && userPermission.OrganizationId !== "") {
    if (incidentTypeEnum === enums.IncidentTypeEnum.AgriculturePestIncident) {
      return WorkFlowActionCodeEnum.LabTestRequired;
    } else if (incidentTypeEnum === enums.IncidentTypeEnum.AnimalBorderGate) {
      return WorkFlowActionCodeEnum.Circulate;
    } else if (userPermission.NonGovTypeCode === enums.NonGovUserTypeEnum.HealthFacility) {
      return WorkFlowActionCodeEnum.Submit;
    } else {
      return WorkFlowActionCodeEnum.Confirm;
    }
  } else {
    return WorkFlowActionCodeEnum.Submit;
  }
};
export const setIncidentTransaction = (transaction, incidentTypeEnum, followup, sharedDiseaseId) => {
  sharedDiseaseId = sharedDiseaseId || 0;
  let actionCode = "";
  console.log(transaction);
  const statusCode = !userPermission.isLoggedIn ? WorkFlowStatusCodeEnum.New : transaction.IncidentsMainTransactionDTO.StatusCode;
  if (followup > 0) {
    actionCode = transaction.IncidentsMainTransactionDTO.WfActionCode ?? WorkFlowActionCodeEnum.FollowUp;
  } else actionCode = getActionCode(transaction, incidentTypeEnum);

  const trans = transaction.IncidentsMainTransactionDTO;
  let data = new IncidentsMainTransactionDTO(
    statusCode,
    actionCode,
    null,
    getUnitTypeCode(incidentTypeEnum),
    trans.Latitude,
    trans.Longitude,
    incidentTypeEnum,
    null,
    trans.GovernmetnalEntityId,
    null,
    null,
    null,
    null,
    null,
    trans.WFComment,
    0,
    sharedDiseaseId > 0 ? sharedDiseaseId : trans.Id,
    transaction.IncidentCirculationHistoryDTO,
    trans.EmirateId,
    incidentTypeEnum === IncidentTypeEnum.AnimalSharedDisease ? true : trans.SharedDiseaseIncident,
    0,
    trans.Redirect_EmirateId,
    trans.Redirect_GovtEntityId,
    trans.RiskDegreeCode,
    trans.Redirect_DepartmentId
  );

  data.IncidentAttachment = transaction.FileAttachmentDTO;
  data.setUser(transaction.UserProfileDTO, transaction);

  data.WorkFlowActionHistorys = [];
  return data;
};
export const IncidentTransactionState = {
  IncidentsMainTransactionDTO: new IncidentsMainTransactionDTO(),
  UserProfileDTO: new UserProfileDTO(),
  IncidentCirculationHistoryDTO: [],
  FileAttachmentDTO: [],
  IntegrationAttachmentDTO: [],
  WFActions: {},
  isUpdate: false,
  StatusCode: WorkFlowStatusCodeEnum.New,
  WfActionCode: "",
};

export const getIncidentTransactionData = async (res, followup, handleChangeDTO, handleChange) => {
  const statusCode = followup > 0 ? WorkFlowStatusCodeEnum.New : res.IncidentsTransaction.StatusCode;

  handleChangeDTO(res.IncidentsTransaction, SharedDTO.IT);
  handleChangeDTO(res.IncidentsTransaction.IncidentCirculationHistory ?? [], SharedDTO.ICH);
  handleChangeDTO(res.IncidentsTransaction.Users?.UsersProfile ?? {}, SharedDTO.UP);

  handleChange("FollowUp", followup, SharedDTO.IT);
  handleChange("StatusCode", statusCode, SharedDTO.IT);
  handleChange("ParentId", res.IncidentsTransaction?.ParentId, SharedDTO.IT);
  handleChange("EmirateId", res.IncidentsTransaction?.EmirateId);
  handleChangeDTO(statusCode, "StatusCode");
  handleChange("Email", res.IncidentsTransaction?.Users?.Email, SharedDTO.UP);
  const attachment = res.IncidentsTransaction?.IncidentAttachment.map((file) => {
    return new IncidentAttachment(file.FileAttachmentId, file.Code, file.FileAttach);
  });
  handleChangeDTO(attachment, SharedDTO.FA);

  const integrationAttach = res.IncidentsTransaction?.IncidentIntegrationAttachments?.map((file) => {
    return new IncidentIntegrationAttachment(file.FileName, file.FileURL);
  });
  handleChangeDTO(integrationAttach, SharedDTO.IFA);
  handleChangeDTO(true, "isUpdate");
};

const getUnitTypeCode = (incidentTypeEnum) => {
  if (incidentTypeEnum === enums.IncidentTypeEnum.FoodSafetyIncident || incidentTypeEnum === enums.IncidentTypeEnum.FoodSafetyBorderGate) {
    return !userPermission.isLoggedIn ? enums.UnitTypeEnum.FocalPoint : userPermission.UnitType;
  } else if (userPermission.isLoggedIn && userPermission.OrganizationId === "") {
    return enums.UnitTypeEnum.GovernmentEntity;
  } else {
    return !userPermission.isLoggedIn ? enums.UnitTypeEnum.GovernmentEntity : userPermission.UnitType;
  }
};
