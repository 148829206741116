import React, { useEffect, useState } from "react";
import { auth, encrypt, toast, validation, util, accountAction, swal } from "../../Imports";
import { useNavigate, useParams } from "react-router-dom";
import * as Control from "../CommonControl";
import { isSystemAdministrator } from "../../Incidents/Common/Utils/Validation";

const AddNewAccount = (props) => {
  const navigate = useNavigate();
  const user = auth.getUserPermission();
  let { id } = useParams();
  id = parseInt(encrypt.decrypt(id));
  console.log(id);

  const initialState = {
    prefLanguage: util.getLang(),
    Id: 0,
    nameEn: "",
    lastNameEn: "",
    nameAr: "",
    lastNameAr: "",
    reporterEID: "",
    passportNo: "",
    email: "",
    mobileNo: "",
    fax: "",
    DepartmentId: isSystemAdministrator() ? 0 : user.Department,
    UnitTypeCode: "",
    GovtEntityId: "",
  };

  const [localVal, setLocalVal] = useState(initialState);

  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const getUserById = async (id) => {
    const data = await accountAction.getUserById(id);
    console.log(data);
    if (data.UserId > 0) {
      setLocalVal((p) => ({
        ...p,
        Id: data.UserId,
        nameEn: data.UsersProfile?.NameEn ?? "",
        lastNameEn: data.UsersProfile?.LastNameEn ?? "",
        nameAr: data.UsersProfile?.NameAr ?? "",
        lastNameAr: data.UsersProfile?.LastNameAr ?? "",
        reporterEID: data.UsersProfile?.ReporterEID ?? "",
        passportNo: data.UsersProfile?.PassportNo ?? "",
        email: data.Email ?? "",
        mobileNo: data.UsersProfile?.MobileNo ?? "",
        fax: data.UsersProfile?.Fax ?? "",
        DepartmentId: data.UsersProfile?.OrganizationUnitsLkp?.DepartmentUnitType?.DepartmentId,
        UnitTypeCode: data.UsersProfile?.OrganizationUnitsLkp?.DepartmentUnitType?.UnitTypeCode,
        GovtEntityId: data.UsersProfile?.OrganizationUnitsLkp?.GovEntityId,
      }));
    }
  };

  useEffect(() => {
    if (id > 0) {
      setTimeout(() => {
        getUserById(id);
      }, 30);
    }
    return {};
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = validation.validateForm("employeeForm");
    if (!form) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }

    const res = await accountAction.addUserByAdmin(localVal);
    if (res.UserId > 0) {
      swal(props.t("success"), props.t("submitSuccessFully"), "success");
      cancelHandler();
    }

    console.log("form submitted");
  };

  const cancelHandler = () => {
    navigate(`/manageaccount`);
  };

  return (
    <div>
      <div className="page-title p-3 pl-4">
        <h3>{props.t("AddNewAccount")}</h3>
      </div>
      <div className="container my-3  ">
        <div className="row container-bg employeeForm">
          <div className="col-md-4">
            <Control.FirstNameEnglish props={props} handleChange={handleChange} value={localVal.nameEn} />
          </div>
          <div className="col-md-4">
            <Control.LastNameEnglish props={props} handleChange={handleChange} value={localVal.lastNameEn} />
          </div>
          <div className="col-md-4">
            <Control.FirstNameArabic props={props} handleChange={handleChange} value={localVal.nameAr} />
          </div>
          <div className="col-md-4">
            <Control.LastNameArabic props={props} handleChange={handleChange} value={localVal.lastNameAr} />
          </div>
          <div className="col-md-4">
            <Control.UserEmail value={localVal.email} handleChange={handleChange} props={props} isDisable={id > 0} />
          </div>
          <div className="col-md-4">
            <Control.UserMobileNumber handleChange={handleChange} value={localVal.mobileNo} props={props} />
          </div>
          <div className="col-md-4">
            <Control.UserFaxNumber handleChange={handleChange} value={localVal.fax} props={props} />
          </div>
          <div className="col-md-4">
            <Control.UserEmiratesID
              props={props}
              handleChange={handleChange}
              value={localVal.reporterEID}
              isDisable={id > 0}
              isRequired={!localVal.passportNo}
            />
          </div>
          <div className="col-md-4">
            <Control.UserPassportNo
              props={props}
              handleChange={handleChange}
              value={localVal.passportNo}
              isDisable={id > 0}
              isRequired={!localVal.reporterEID}
            />
          </div>
          <div className="col-md-4">
            <Control.DepartmentInput initialState={localVal} changeControl={handleChange} isDisable={isSystemAdministrator() ? 0 : user.Department} />
          </div>
          <div className="col-md-4">
            <Control.OrgUnitTypeLkpControl props={props} changeControl={handleChange} localVal={localVal.UnitTypeCode} departmentId={localVal.DepartmentId} />
          </div>
          <div className="col-md-4">
            <Control.GovtEntityLkpControl
              unityType={localVal.UnitTypeCode}
              departmentId={localVal.DepartmentId}
              changeControl={handleChange}
              localVal={localVal.GovtEntityId}
            />
          </div>
          <div className="col-md-4 m-4">
            <button className="btn btn-success ml-2 mr-2 " type="button" onClick={submitHandler}>
              {id > 0 ? props.t("update") : props.t("add")}
            </button>
            <button className="btn btn-success" type="button" onClick={cancelHandler}>
              {props.t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAccount;
