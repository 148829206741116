import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";
import { auth, enums, iAgrculturePest, master, validation, util } from "../../Imports";
import { AddButton, DeleteButton } from "../Common/IncidentCommonImport";

const PlantTypeDetail = (props) => {
  const initialState = {
    PlantTypeId: "",
    PlantTypeName: "",
    PlantTypeOther: "",
    BlightDescriptionId: "",
    BlightDescriptionName: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const transaction = useSelector((state) => state.transaction);
  const [plantType, setPlantType] = useState([]);
  const [blightDescription, setBlightDescription] = useState([]);
  const agrConst = iAgrculturePest.AP_Constants.APTD;
  const user = auth.getUserPermission();
  const loadLkp = async () => {
    setPlantType(await master.getPlantTypesLkp(0));
    setBlightDescription(await master.getBlightDescriptionLkp(0));
  };
  useEffect(() => {
    loadLkp();
  }, []);
  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const onChangePlantType = (e) => {
    const text = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
    setLocalVal((p) => ({
      ...p,
      PlantTypeId: e.target.value,
      PlantTypeName: text,
    }));
  };
  const onChangeBlightDescription = (e) => {
    const text = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
    setLocalVal((p) => ({
      ...p,
      BlightDescriptionId: e.target.value,
      BlightDescriptionName: text,
    }));
  };
  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[agrConst].forEach((e, i) => {
      tempInf = tempInf.concat(
        new iAgrculturePest.AgriculturePestDetailDTO(
          e.PlantTypeId,
          util.getProperText(e.PlantTypeLkp.NameAr, e.PlantTypeLkp.NameEn),
          e.BlightDescriptionName == null ? util.getProperText(e.BlightDescriptionLkp.NameAr, e.BlightDescriptionLkp.NameEn) : e.BlightDescriptionName,
          e.BlightDescriptionId,
          e.PlantTypeOther
        )
      );
    });
    props.handleChangeDTO(tempInf, agrConst);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "TypesDetail";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[agrConst].concat(
        new iAgrculturePest.AgriculturePestDetailDTO(
          localVal.PlantTypeId,
          localVal.PlantTypeName,
          localVal.BlightDescriptionName,
          localVal.BlightDescriptionId,
          localVal.PlantTypeOther
        )
      ),
      agrConst
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[agrConst].filter((x) => x.Uid !== e.Uid),
      agrConst
    );
  };
  return (
    <div>
      <div className="row my-1 container-bg TypesDetail">
        <label className="control-title my-2 mb-4">
          <strong>{props.t("TypesDetail")}</strong>
        </label>
        <div className="col-md-4">
          <SelectInput
            name="PlantTypeId"
            label="PlantType"
            required={true}
            disabled={false}
            value={localVal.PlantTypeId}
            onChange={onChangePlantType}
            data={plantType}
          />
        </div>
        {localVal.PlantTypeId === enums.PlantTypeEnum.Others && (
          <div className="col-md-4">
            <FormInput
              name="PlantTypeOther"
              type="text"
              label="PlantType"
              required={true}
              disabled={false}
              value={localVal.PlantTypeOther}
              onChange={handleChange}
            />
          </div>
        )}
        {user?.UserAccountType === enums.UserAccountTypeEnum.GovernmentalUser && (
          <div className="col-md-4">
            <SelectInput
              name="BlightDescriptionId"
              label="BlightDescription"
              required={true}
              disabled={false}
              value={localVal.BlightDescriptionId}
              onChange={onChangeBlightDescription}
              data={blightDescription}
            />
          </div>
        )}
        {user?.UserAccountType !== enums.UserAccountTypeEnum.GovernmentalUser && (
          <div className="col-md-4">
            <FormInput
              name="BlightDescriptionName"
              type="text"
              label="BlightDescription"
              required={true}
              disabled={false}
              value={localVal.BlightDescriptionName}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-md-4 mt-4">
          <AddButton {...props} submitHandler={submitHandler} />
        </div>

        <div className="col-md-12 row my-2">
          {transaction[agrConst]?.map((e, i) => (
            <div className="card" key={i}>
              <div className="card-body row">
                <div className="col-md-4">
                  <small className="golden">{props.t("PlantType")}</small> :<small> {e.PlantTypeName}</small>
                </div>
                {e?.PlantTypeOther && (
                  <div className="col-md-4">
                    <small className="golden">{props.t("PlantType")}</small> :<small> {e.PlantTypeOther}</small>
                  </div>
                )}
                <div className="col-md-4">
                  <small className="golden">{props.t("BlightDescription")}</small>:<small> {e.BlightDescriptionName}</small>
                </div>
              </div>
              <DeleteButton
                {...props}
                deleteHandler={() => {
                  deleteItem(e);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlantTypeDetail;
