import React from "react";
import FormInput from "../../../Common/Validation/FormInput";

const QuestionWithYesNo = ({
  t,
  onchangeRadio,
  label,
  list,
  controlName,
  value,
}) => {
  const onchangeEvent = (e) => {
    onchangeRadio({
      target: { name: e.target.name, value: e.target.value },
    });
  };
  return (
    <>
      <label className="mb-2">
        <strong>{t(label)}</strong>
        <span className="red">*</span>
      </label>
      {list.map((x, i) => (
        <div className="col-md-2" key={i}>
          <div className="form-check">
            <FormInput
              type="radio"
              required={true}
              id={x}
              name={controlName}
              onChange={onchangeEvent}
              htmlFor={x}
              checked={x === value}
              value={value}
              data-investigate="1"
            />
            {/* <label className="form-check-label" htmlFor={`${x}`}>
              {props.t(`${x}`)}
            </label> */}
          </div>
        </div>
      ))}
    </>
  );
};

export default QuestionWithYesNo;
