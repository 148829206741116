import React, { useState } from "react";
import ReportFilter from "./ReportFilter";
import OverallReportGrid from "./OverallReportGrid";
import { paginationDto } from "../../ReportControl";

const OverallReport = (props) => {
  const initialState = {
    fromDate: "",
    toDate: "",
    EmirateId: 0,
    RefNo: "",
    DepartmentId: 0,
    ReporterName: "",
    ReporterMobileNo: "",
    StatusCode: "",
    RiskDegree: "",
    IncidentTypeCode: "",
    ...paginationDto,
  };
  const [data, setData] = useState(initialState);
  const [isSearchChanged, setIsSearchChanged] = useState(false);

  const onChangeSearch = (localVal) => {
    setData(localVal);
    setIsSearchChanged(!isSearchChanged);
  };
  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("report")}</h3>
      </div>
      <div className="d-flex flex-column justify-content-center search-page mt-1 container-fluid d-flex  flex-shrink-0">
        <ReportFilter {...props} onChangeSearch={onChangeSearch} data={data} />
        <OverallReportGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />
      </div>
    </div>
  );
};

export default OverallReport;
