import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import { iAnimalBG } from "../../Imports";

const ShipmentInitialData = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const abgt = iAnimalBG.ABD_Constants.ABGT;
  return (
    <>
      <div className="row container-bg my-2">
        <div className="col-md-4">
          <FormInput
            name="ShipmentNo"
            type="text"
            label="ShipmentNo"
            required={true}
            disabled={false}
            value={transaction[abgt].ShipmentNo}
            onChange={(e) => {
              props.handleChange(e.target.name, e.target.value, abgt);
            }}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="ContainerNo"
            type="text"
            label="ContainerNo"
            required={true}
            disabled={false}
            value={transaction[abgt].ContainerNo}
            onChange={(e) => {
              props.handleChange(e.target.name, e.target.value, abgt);
            }}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="ShipmentCertificateNo"
            type="text"
            label="ShipmentCertificateNo"
            required={true}
            disabled={false}
            value={transaction[abgt].ShipmentCertificateNo}
            onChange={(e) => {
              props.handleChange(e.target.name, e.target.value, abgt);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ShipmentInitialData;
