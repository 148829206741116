import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PopupOver from "../Common/UI/PopupOver";
import { auth, documentLibAction, encrypt, enums, toast, util, validation } from "../Imports";

const ViewDocumentLibrary = (props) => {
  const user = auth.getUserPermission();
  const [documentList, setDocumentList] = useState([]);

  const loadComLkp = useCallback(async () => {
    props.setLoading(true);
    let data = null;
    if (user.UnitType !== enums.UnitTypeEnum.MinistryUser) {
      data = (await documentLibAction.GetDocumentLibrary(user.Department, 0)).data.ResponseData;
      data = data.filter((x) => x.IsEnabled === true);
    } else {
      data = (await documentLibAction.GetDocumentLibrary(user.Department, 0)).data.ResponseData;
    }
    setDocumentList(data);
    props.setLoading(false);
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  const switchIsActive = async (e) => {
    const id = e.target.dataset.id;
    var preCheckedStatus = e.target.checked;
    var row = document.getElementById("row_" + id);
    var checkbox = document.getElementById("checkbox_" + id);
    const data = await documentLibAction.EnableDisabledDocumentLib(id);
    if (data.data.IsSuccess) {
      checkbox.checked = preCheckedStatus;
      if (preCheckedStatus) row.classList.remove("disabled-row");
      else row.classList.add("disabled-row");
      toast(props.t("updatedSuccessFully"), { type: "success" });
    }
  };
  return (
    <div>
      <div className="page-title p-3 pl-4">
        <h3>{props.t("ViewDocumentLibrary")}</h3>
      </div>
      {validation.isAccessDocumentLib(user) && (
        <Link to={`/documentLib/${encrypt.encrypt(0)}/${encrypt.encrypt("add")}`} className="btn new-document m-2">
          {props.t("NewDocument")}
        </Link>
      )}

      <div className="container-fluid d-flex flex-grow-1 flex-shrink-0 ">
        <div className="table-container col flex-fill">
          <div className="table-wrapper p-2 mt-3">
            <table id="export-table" className="table table-striped text-center m-0">
              <thead>
                <tr>
                  <th scope="col">{props.t("DocumentNumber")}</th>
                  <th scope="col">{props.t("DocumentDescription")}</th>
                  <th scope="col">{props.t("DocumentType")}</th>
                  <th scope="col">{props.t("Department")}</th>
                  <th scope="col">{props.t("CreatedDate")}</th>
                  <th scope="col">{props.t("attachments")}</th>
                  {user.UnitType === enums.UnitTypeEnum.MinistryUser && <th scope="col">{props.t("IsActive")}</th>}
                  <th scope="col">{props.t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {documentList.map((data, index) => {
                  return (
                    <tr key={index} id={"row_" + data.Id} className={!data.IsEnabled ? "disabled-row" : ""}>
                      <td>{index + 1}</td>
                      <td>{data.Description}</td>
                      <td>{util.getProperText(data.DocumentTypeLkp.NameAr, data.DocumentTypeLkp.NameEn)}</td>
                      <td>{util.getProperText(data.DepartmentsLkp.NameAr, data.DepartmentsLkp.NameEn)}</td>
                      <td>{data.CreatedDate?.split("T")[0] + " " + data.CreatedDate?.split("T")[1].substr(0, 8)}</td>
                      <td>
                        <a href={data.FileAttachmentsTransaction.FilePath} target="_blank" rel="noreferrer">
                          <img alt="" src={data.FileAttachmentsTransaction.ThumbPath} width="30" />
                          {/* <div style={{ fontSize: "9px" }}>
                            {data.FileAttachmentsTransaction.FileName}
                          </div> */}
                        </a>
                      </td>
                      {user.UnitType === enums.UnitTypeEnum.MinistryUser && (
                        <td>
                          <input
                            type="checkbox"
                            data-id={data.Id}
                            id={"checkbox_" + data.Id}
                            checked={data.IsEnabled}
                            onChange={(e) => switchIsActive(e)}
                          ></input>
                        </td>
                      )}
                      <td>
                        <PopupOver
                          button={
                            <Link to="#" className="tools-box-toggle">
                              <i className="fa fa-ellipsis-v"></i>
                            </Link>
                          }
                          body={
                            <div className="d-flex flex-column tools-box">
                              <Link className="view p-2 text-left border-bottom" to={`/documentLib/${encrypt.encrypt(data.Id)}/${encrypt.encrypt("view")}`}>
                                <i className="ico icon-Group-392 mr-2"></i>
                                <span>{props.t("View")}</span>
                              </Link>
                              {validation.isAccessDocumentLib(user) && (
                                <Link className="edit p-2 text-left" to={`/documentLib/${encrypt.encrypt(data.Id)}/${encrypt.encrypt("edit")}`}>
                                  <i className="ico icon-Group-388 mr-2"></i>
                                  <span>{props.t("Edit")}</span>
                                </Link>
                              )}
                            </div>
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDocumentLibrary;
