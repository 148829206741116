import React, { useState } from "react";
import {
  ColumnCirculationHistoryIcon,
  ColumnCreateDate,
  ColumnEmiratesName,
  ColumnIncidentType,
  ColumnNotificationTypeName,
  ColumnProductName,
  ColumnStatus,
  DynamicLink,
  IsShowNotificationType,
  IsShowProductName,
} from "./SearchGrid";
import CirculationUI from "../../Common/CirculationUI";
import FollowUpReportIncident from "../../AnimalHealth/UIComponent/FollowUpReportIncident";

const MyIncidnetGrid = (props) => {
  const [incidentId, setIncidentId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalFollowUp, setShowModalFollowUp] = useState(false);

  const handleShowModal = (incidentId) => {
    setShowModal(!showModal);
    setIncidentId(incidentId);
  };
  const handleShowModalFollowUp = (incidentId) => {
    setIncidentId(incidentId);
    setShowModalFollowUp(!showModalFollowUp);
  };
  return (
    <div>
      <table className="table table-striped table-hover text-center m-0">
        <thead>
          <tr>
            <th>{props.t("IncidentNumber")}</th>
            <th>{props.t("IncidentDate")}</th>
            <th>{props.t("incidnetType")} </th>
            <th>{props.t("IncidentStatus")} </th>
            <IsShowProductName props={props} />
            <IsShowNotificationType props={props} />
            <th>{props.t("emirate")} </th>
            <th>{props.t("Circulation")} </th>
          </tr>
        </thead>
        <tbody>
          {props.data.Incidents.map((incident, index) => {
            return (
              <tr key={index}>
                <td>
                  <DynamicLink incident={incident} />
                </td>
                <td>
                  <ColumnCreateDate incident={incident} />
                </td>
                <td>
                  <ColumnIncidentType incident={incident} props={props} />
                </td>
                <td>
                  <ColumnStatus incident={incident} />
                </td>
                <ColumnProductName incident={incident} />
                <ColumnNotificationTypeName incident={incident} />
                <td>
                  <ColumnEmiratesName incident={incident} props={props} />
                  {/* <ColumnGPSLocation trans={incident} /> */}
                </td>
                <td>
                  <ColumnCirculationHistoryIcon
                    incident={incident}
                    props={props}
                    handleShowModal={handleShowModal}
                    showModalFollowUp={showModalFollowUp}
                    handleShowModalFollowUp={handleShowModalFollowUp}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showModal && <CirculationUI {...props} showModal={showModal} handleShowModal={handleShowModal} data={incidentId} />}
      {showModalFollowUp && (
        <FollowUpReportIncident
          {...props}
          data={{ Id: incidentId }}
          showModal={true}
          handleShowModal={() => {
            setShowModalFollowUp(!showModalFollowUp);
          }}
        />
      )}
    </div>
  );
};

export default MyIncidnetGrid;
