import React from "react";
import { useState } from "react";
import ReportFilter from "./ReportFilter";
import FoodSafetyGrid from "./FoodSafetyGrid";
import { useEffect } from "react";
import { useCallback } from "react";
import { auth, validation } from "../../../../Imports";

const FoodSafetyReport = (props) => {
  const user = auth.getUserPermission();
  const initialState = {
    fromDate: "",
    toDate: "",
    EmirateId: validation.getUserEmirateId(),
    RefNo: "",
    DepartmentId: user.Department,
    ReporterName: "",
    ReporterMobileNo: "",
    StatusCode: "",
    RiskDegree: "",
    IncidentTypeCode: "",
    UnitTypeCode: "",
    BorderPortId: 0,
    paginationDto: {
      pageNo: 1,
      pageSize: 10,
    },
  };
  const [isSearchChanged, setIsSearchChanged] = useState(false);
  const [data, setData] = useState(initialState);
  const onChangeSearch = (localVal) => {
    setData(localVal);
    setIsSearchChanged(!isSearchChanged);
  };
  const getData = useCallback(async () => {
    setIsSearchChanged(!isSearchChanged);
  }, []);
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("FoodSafetyIncidentDetailsReport")}</h3>
      </div>
      <div className="d-flex flex-column justify-content-center search-page mt-1 container-fluid d-flex flex-shrink-0">
        <ReportFilter {...props} type="Pest" data={data} onChangeSearch={onChangeSearch} />

        <FoodSafetyGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />
      </div>
    </div>
  );
};

export default FoodSafetyReport;
