import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iIncident } from "../../../Imports";

const ConfirmAction = (props) => {
  const transaction = useSelector((state) => state.transaction);
  return (
    <div className="row col-md-12 mt-3 actionbuttonForm">
      <FormInput
        label="Recommendations"
        name="WFComment"
        type="textarea"
        rows="3"
        cols="20"
        required={true}
        value={transaction[iIncident.SharedDTO.IT].WFComment}
        onChange={(e) => {
          props.handleChange(
            e.target.name,
            e.target.value,
            iIncident.SharedDTO.IT
          );
        }}
        data-investigate="1"
      />
    </div>
  );
};

export default ConfirmAction;
