import React from "react";
import ExtraDataPlantAnimalFarm from "./ExtraDataPlantAnimalFarm";
import ExtraDataFoodFacility from "./ExtraDataFoodFacility";
import ExtraDataHealthFacility from "./ExtraDataHealthFacility";
import { RegistrationType } from "../../../Interface/Registration";

function ExtraData(props) {
  console.log("ExtraData", props);
  const Previous = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form submitted");
    props.submitHandlerRegistration();
  };
  return (
    <>
      <form id="regStep4Form" className="current" onSubmit={submitHandler}>
        {props.values.NonGovTypeCode === RegistrationType.HealthFacility && (
          <ExtraDataHealthFacility {...props} />
        )}
        {props.values.NonGovTypeCode === RegistrationType.AnimalPlant && (
          <ExtraDataPlantAnimalFarm {...props} />
        )}
        {props.values.NonGovTypeCode === RegistrationType.FoodFacility && (
          <ExtraDataFoodFacility {...props} />
        )}

        <div className="d-flex mt-5">
          <button
            type="button"
            className="btn flex-fill p-2 mr-1 col prev"
            onClick={Previous}
          >
            {props.t("previous")}
          </button>
          <button type="submit" className="btn flex-fill p-2 ml-3 col next">
            {props.t("createAccount")}
          </button>
        </div>
      </form>
    </>
  );
}

export default ExtraData;
