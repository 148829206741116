import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectInput } from "../../../Common/Validation/FormInput";
import { iIncident, master, toast, validation, auth, util } from "../../../Imports";

const EmirateDistrict = (props) => {
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const [disable, setDisable] = useState(false);
  const EmirateData = useSelector((state) => state.masterData.emirateData);
  const googleMapLocation = useSelector((state) => state.common.googleMapLocation);
  const transaction = useSelector((state) => state.transaction);
  const handleChange = (name, value) => {
    props.handleOnChange({ target: { name: name, value: value } });
  };
  const onchangeEmirate = async (e) => {
    props.handleOnChange(e);
  };

  const setEmirateDropDown = (value) => {
    document.getElementById("EmirateId").value = value;
    handleChange("EmirateId", value);
  };

  const loadCompLkp = useCallback(async () => {
    dispatch(master.getEmirates());
  }, [dispatch]);

  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);

  useEffect(() => {
    if (googleMapLocation) {
      console.log(googleMapLocation);
      const id = util.getEmirateIndex(googleMapLocation);

      console.log(id);
      if (id === -1) {
        clearLocationTextBox("locationInsideUAEText");
        return;
      } else if ((validation.isGovtUser() || validation.isBorderGateUser()) && parseInt(user.EmirateId) !== id + 1 && parseInt(user.EmirateId) !== 8) {
        clearLocationTextBox("outOfCoverageArea");
        return;
      }
      setEmirateDropDown(id + 1);
      setDisable(true);
      // getGovtEntity(id + 1);
    }
  }, [googleMapLocation]);

  const clearLocationTextBox = (msg) => {
    handleChange("Latitude", "");
    handleChange("Longitude", "");
    toast(props.t(msg), {
      type: "warning",
    });
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      const emirateId = transaction[iIncident.SharedDTO.IT].EmirateId;
      setEmirateDropDown(emirateId);

      setDisable(transaction.isUpdate);
    }
  }, [transaction.isUpdate]);
  return (
    <>
      <div className="col-md-4">
        <SelectInput
          name="EmirateId"
          label="emirate"
          required={true}
          disabled={disable}
          value={props.emirateId}
          onChange={onchangeEmirate}
          data={EmirateData}
        />
      </div>
      {/* <div className="col-md-4">
        <SelectInput
          name="GovernmetnalEntityId"
          label="nearestGovEntity"
          required={true}
          disabled={disable}
          value={props.value}
          onChange={props.handleOnChange}
          data={govEntityData}
        />
      </div> */}
    </>
  );
};

export default EmirateDistrict;
