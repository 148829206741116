import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { master, iAnimalLV } from "../../../Imports";
import ALVSurgeonDetail from "./ALVSurgeonDetail";

const ALVViolationDetail = (props) => {
  const [veterinaryFacility, setVeterinaryFacility] = useState([]);
  const transaction = useSelector((state) => state.transaction);
  const changeControl = (e) => {
    props.handleChange(
      e.target.name,
      e.target.value,
      iAnimalLV.ALV_Constants.ALVD
    );
  };
  const loadLkp = async () => {
    setVeterinaryFacility(await master.getVetEstablishmentLkp(0));
  };
  useEffect(() => {
    loadLkp();
  }, []);
  useEffect(() => {}, []);

  return (
    <>
      <div className="col-md-4">
        <FormInput
          name="ManufacturingCompany"
          type="text"
          label="ManufacturerCompany"
          required={true}
          disabled={props.isDisabled}
          value={transaction[iAnimalLV.ALV_Constants.ALVD].ManufacturingCompany}
          onChange={changeControl}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="ProductName"
          type="text"
          label="ProductName"
          required={true}
          disabled={props.isDisabled}
          value={transaction[iAnimalLV.ALV_Constants.ALVD].ProductName}
          onChange={changeControl}
        />
      </div>

      <ALVSurgeonDetail {...props} />

      <div className="col-md-4">
        <SelectInput
          name="VeterinaryEstablishmentId"
          label="VeterinaryFacility"
          required={true}
          disabled={props.isDisabled}
          value={
            transaction[iAnimalLV.ALV_Constants.ALVD].VeterinaryEstablishmentId
          }
          onChange={changeControl}
          data={veterinaryFacility}
        />
      </div>
    </>
  );
};

export default ALVViolationDetail;
