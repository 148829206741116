import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { iAnimalHealth, util, iIncident, enums, validation } from "../../Imports";
import FormInput from "../../Common/Validation/FormInput";
import Reporter from "./Reporter";
import Holdings from "./Holdings.jsx";
import GPSLocation from "./LocationDetail/GPSLocation";
import EmirateGovEntity from "./LocationDetail/EmirateGovEntity";
import BeforeConfirmDetails from "../AnimalHealth/MainDetail.jsx/BeforeConfirmDetails";

function IncidentMainDetail(props) {
  const [disable, setDisable] = useState(false);
  const [disableGovEntity, setDisableGovEntity] = useState(false);
  const [showBeforeConfirm, setShowBeforeConfirm] = useState(false);
  const transaction = useSelector((state) => state.transaction);

  const handleChange = (e) => {
    props.handleChange(e.target.name, e.target.value, iAnimalHealth.AH_Constants.AHT);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      document.getElementById("HoldingTypeId").value = transaction[iAnimalHealth.AH_Constants.IH].HoldingTypeId;
      setDisable(transaction.isUpdate && transaction[iIncident.SharedDTO.IT].FollowUp !== 1);
      setDisableGovEntity(transaction.isUpdate);
      setShowBeforeConfirm(validation.isUserLoggedIn());
    } else {
      setShowBeforeConfirm(validation.isGovtUser());
    }
  }, [transaction.isUpdate]);

  return (
    <div className="container my-2">
      <div className="row container-bg">
        <GPSLocation {...props} isDisabled={disableGovEntity} />

        <EmirateGovEntity
          {...props}
          unitType={enums.UnitTypeEnum.GovernmentEntity}
          department={enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment}
          isDisabled={disableGovEntity}
          value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
          emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
          handleOnChange={(e) => props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
        />

        {showBeforeConfirm && (
          <div className="col-md-4">
            <FormInput
              name="Area"
              type="text"
              label="Area"
              required={false}
              disabled={disable}
              value={transaction[iAnimalHealth.AH_Constants.AHT].Area}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-md-4">
          <FormInput
            name="InfectionStartDate"
            label="infectionStartDate"
            disabled={disable}
            type="date"
            selectedDate={util.SelectedDate(transaction[iAnimalHealth.AH_Constants.AHT].InfectionStartDate)}
            required={true}
            onChange={(date) => props.handleChange("InfectionStartDate", util.SetDate(date), iAnimalHealth.AH_Constants.AHT)}
            maxDate={new Date()}
          />
        </div>
        {showBeforeConfirm && <BeforeConfirmDetails {...props} />}
      </div>

      <Holdings {...props} disabled={false} />

      <Reporter {...props} />
    </div>
  );
}

export default IncidentMainDetail;
