import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import AccountDetail from "./reg-steps-comp/AccountDetail";
import ContactDetail from "./reg-steps-comp/ContactDetail";
import SecurityData from "./reg-steps-comp/SecurityData";
import ExtraData from "./reg-steps-comp/ExtraData";
import { RegistrationType } from "../../Interface/Registration";
import { util, accountAction } from "../Imports";
import { useNavigate } from "react-router-dom";

function Registration(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    step: 1,
    prefLanguage: util.getLang(),
  });
  const registerUserResponse = useSelector((state) => state.account.registerUser);

  const [completedText, setCompletedText] = useState("completed");
  const [activeText, setActiveText] = useState("active");
  const [hideText, setHideText] = useState("d-none");

  const [active, setActive] = useState({
    step1_active: activeText,
    step1_completed: "",
    step2_active: "",
    step2_completed: "",
    step3_active: "",
    step3_completed: "",
    step4_active: "",
    step4_completed: "",
  });

  const prevStep = () => {
    setFormData({ ...formData, step: formData.step - 1 });
  };

  const nextStep = () => {
    setFormData({ ...formData, step: formData.step + 1 });
  };
  const handleChange = (e) => {
    //console.log(e);
    if (e.target.name === "NonGovTypeCode") {
      const userTypeList = [RegistrationType.AnimalPlant, RegistrationType.FoodFacility, RegistrationType.HealthFacility];
      if (userTypeList.indexOf(e.target.value) > -1) setHideText("d-block");
      else setHideText("d-none");
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const changeStepStyle = () => {
    // console.log(formData.step);
    if (formData.step === 1) {
      setActive({
        step1_active: activeText,
      });
    } else if (formData.step === 2) {
      setActive({
        step1_active: activeText,
        step2_active: activeText,
        step1_completed: completedText,
      });
    } else if (formData.step === 3) {
      setActive({
        step1_active: activeText,
        step2_active: activeText,
        step3_active: activeText,
        step1_completed: completedText,
        step2_completed: completedText,
      });
    } else if (formData.step === 4) {
      setActive({
        step1_active: activeText,
        step2_active: activeText,
        step3_active: activeText,
        step4_active: activeText,
        step1_completed: completedText,
        step2_completed: completedText,
        step3_completed: completedText,
      });
    }
  };
  const submitHandlerRegistration = async () => {
    const res = await accountAction.registerUser(formData);
    if (res.data.IsSuccess) {
      swal(props.t("registrationDone"), props.t("approvalMsg"), "success");
      navigate("/login");
    } else {
      swal("Error!", res.data.ResponseData, "error");
    }
  };
  // useEffect(() => {
  //   if (registerUserResponse) {
  //     if (registerUserResponse.IsSuccess) {
  //     } else {
  //     }
  //   }
  // }, [registerUserResponse]);
  useEffect(() => {
    changeStepStyle();
    console.log(formData);
  }, [formData]);

  return (
    <div>
      <div className="reg-progress-bar mt-5">
        <ul className="d-flex justify-content-center p-0">
          <li className={`d-flex align-items-center ${active.step1_active} ${active.step1_completed}`}>
            <div className="align-items-center d-flex flex-column">
              <i className="ico icon-Group-100 d-flex justify-content-center align-items-center"></i>
              <span className="mt-1">{props.t("accountDetails")}</span>
            </div>
          </li>
          <li className={`d-flex align-items-center ${active.step2_active} ${active.step2_completed}`}>
            <div className="align-items-center d-flex flex-column">
              <i className="ico icon-Group-141 d-flex justify-content-center align-items-center"></i>
              <span className="mt-1">{props.t("contactDetails")}</span>
            </div>
          </li>
          <li className={`d-flex align-items-center ${active.step3_active} ${active.step3_completed}`}>
            <div className="align-items-center d-flex flex-column">
              <i className="ico icon-Group-501 d-flex justify-content-center align-items-center"></i>
              <span className="mt-1">{props.t("securityData")}</span>
            </div>
          </li>
          <li className={`d-flex align-items-center ${hideText} ${active.step4_active} ${active.step4_completed}`}>
            <div className="align-items-center d-flex flex-column">
              <i className="ico icon-Path-1216 d-flex justify-content-center align-items-center"></i>
              <span className="mt-1">{props.t("extraData")}</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="registration-form  mt-5">
        {formData.step === 1 && <AccountDetail nextStep={nextStep} handleChange={handleChange} values={formData} {...props} />}
        {formData.step === 2 && <ContactDetail nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} values={formData} {...props} />}
        {formData.step === 3 && (
          <SecurityData
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formData}
            submitHandlerRegistration={submitHandlerRegistration}
            {...props}
          />
        )}
        {formData.step === 4 && (
          <ExtraData
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formData}
            submitHandlerRegistration={submitHandlerRegistration}
            {...props}
          />
        )}
      </div>
    </div>
  );
}

export default Registration;
