export const SC_Constants = {
  SGC: "SystemGeneralConfigurationDTO",
  SEC: "SystemEmailConfigurationDTO",
};

export class SystemConfigurationDTO {
  constructor(SystemGeneralConfigurationDTO, SystemEmailConfigurationDTO) {
    this.SystemGeneralConfigurationDTO = SystemGeneralConfigurationDTO;
    this.SystemEmailConfigurationDTO = SystemEmailConfigurationDTO ?? [];
  }
}
export class SystemGeneralConfigurationDTO {
  constructor(
    DepartmentId,
    DepartmentsLkp,
    TimeToConfirmIncident,
    RiskDegreeCode
  ) {
    this.DepartmentId = DepartmentId;
    this.DepartmentsLkp = DepartmentsLkp;
    this.TimeToConfirmIncident = TimeToConfirmIncident;
    this.RiskDegreeCode = RiskDegreeCode;
  }
}

export class SystemEmailConfigurationDTO {
  constructor(
    DepartmentId,
    ReporterEmail,
    GovtEntityId,
    GovtEntityName,
    SystemEmailCode,
    Uid
  ) {
    this.DepartmentId = DepartmentId;
    this.ReporterEmail = ReporterEmail;
    this.GovtEntityId = GovtEntityId;
    this.GovtEntityName = GovtEntityName;
    this.SystemEmailCode = SystemEmailCode;
    this.Uid = Uid;
  }
}
