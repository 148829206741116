import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, enums, iIncident, master, toast, util, validation } from "../../../Imports";
import { SelectInput } from "../../../Common/Validation/FormInput";
// import QuestionWithYesNo from "./QuestionWithYesNo";

const CirculateDetail = (props) => {
  const initialState = {
    GovernmentalEntityId: "",
    GovEntityName: "",
    EmirateId: "",
    EmirateName: "",
    IsCircularNotification: props.IsCircularNotification ?? false,
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [selectAllActive, setSelectAllActive] = useState(false);
  // const answerYesNo = [enums.YesNoEnum.Yes, enums.YesNoEnum.No];
  // const [riskDegreeData, setRiskDegreeData] = useState([]);
  const EmirateData = useSelector((state) => state.masterData.emirateData);
  const transaction = useSelector((state) => state.transaction);
  const [govEntityData, setGovEntityData] = useState([]);
  const [disableControl, setDisableControl] = useState(false);
  const user = auth.getUserPermission();
  let govEntityLabel = "nearestGovEntity";
  const dispatch = useDispatch();

  const onchangeEmirate = async (e) => {
    setLocalVal((p) => ({
      ...p,
      EmirateId: e.target.value,
      EmirateName: e.target.name,
    }));
    getGovtEntity(e.target.value);
  };

  const getGovtEntityType = () => {
    const borderGate = [enums.IncidentTypeEnum.AgricultureBorderGate, enums.IncidentTypeEnum.AnimalBorderGate, enums.IncidentTypeEnum.FoodSafetyBorderGate];
    const focalPoint = [enums.IncidentTypeEnum.FoodSafetyIncident];

    if (borderGate.indexOf(transaction.IncidentsMainTransactionDTO.IncidentTypeCode) > -1) {
      return enums.UnitTypeEnum.BorderGate;
    } else if (focalPoint.indexOf(transaction.IncidentsMainTransactionDTO.IncidentTypeCode) > -1) {
      return enums.UnitTypeEnum.FocalPoint;
    } else {
      return enums.UnitTypeEnum.GovernmentEntity;
    }
    // if (user.Department.toString() === enums.DepartmentEnum.FoodSafetyDepartment.toString()) {
    //   return enums.UnitTypeEnum.FocalPoint;
    // } else if (user.Department.toString() === enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment.toString()) {
    //   return enums.UnitTypeEnum.BorderGate;
  };
  const getGovtEntity = async (emirateId) => {
    let data = "";
    data = await master.getGovtEntity(emirateId, getGovtEntityType(), user.Department);
    setGovEntityData(data);
  };
  const changeGovEntity = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      GovernmentalEntityId: e.target.value,
      GovEntityName: e.nativeEvent.target[index].text,
    }));
  };
  const loadCompLkp = useCallback(async () => {
    dispatch(master.getEmirates());
  }, [dispatch]);
  useEffect(() => {
    loadCompLkp();
    if (transaction.isUpdate) {
      setDisableControl(
        !validation.isMinistryUser() && transaction[iIncident.SharedDTO.ICH].filter((x) => x.IsCircularNotification === props.IsCircularNotification).length > 0
      );
    }
  }, [loadCompLkp]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const trans = transaction[iIncident.SharedDTO.ICH];
    const formName = props.title;
    if (!validation.validateForm(formName)) {
      return;
    }
    if (trans.find((x) => x.GovernmentalEntityId.toString() === localVal.GovernmentalEntityId)) {
      toast(props.t("alreadyExist"), { type: "warning" });
      return;
    }
    props.handleChangeDTO(
      trans.concat(new iIncident.IncidentCirculationHistoryDTO(localVal.GovernmentalEntityId, localVal.GovEntityName, props.IsCircularNotification)),
      iIncident.SharedDTO.ICH
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const selectAllGovEntity = async () => {
    const data = await master.getGovtEntity(0, getGovtEntityType(), user.Department);
    const trans = transaction[iIncident.SharedDTO.ICH];
    data.forEach((x, i) => {
      if (trans.filter((t) => t.GovernmentalEntityId === x.Id.toString()).length === 0) {
        trans.push(new iIncident.IncidentCirculationHistoryDTO(x.Id, util.getTextFromObj(x), props.IsCircularNotification, Date.now() + i));
      }
    });
    props.handleChangeDTO(trans, iIncident.SharedDTO.ICH);
    setSelectAllActive(true);
  };
  const clearAllGovEntity = () => {
    const trans = transaction[iIncident.SharedDTO.ICH].filter((x) => x.IsCircularNotification !== props.IsCircularNotification);
    props.handleChangeDTO(trans, iIncident.SharedDTO.ICH);
    setSelectAllActive(false);
  };
  const deleteHandler = (e) => {
    console.log(e);
    props.handleChangeDTO(
      transaction[iIncident.SharedDTO.ICH].filter((x) => x.Uid !== e.Uid),
      iIncident.SharedDTO.ICH
    );
  };

  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[iIncident.SharedDTO.ICH].forEach((e, i) => {
      tempInf = tempInf.concat(
        new iIncident.IncidentCirculationHistoryDTO(
          e.GovernmentalEntityId,
          props.currentLanguage === "ar" ? e.GovernmentalEntityLkp.NameAr : e.GovernmentalEntityLkp.NameEn,
          e.IsCircularNotification
        )
      );
    });
    props.handleChangeDTO(tempInf, iIncident.SharedDTO.ICH);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }

    if (transaction.isUpdate && transaction.IncidentCirculationHistoryDTO.length === 0) {
      setTimeout(() => {
        validation.enableDisableForm("circulateForm", false);
        validation.enableDisableForm(props.title, false);
      }, 1000);
    }
  }, [transaction.isUpdate]);

  return (
    <div>
      <div className="col-md-12 my-2 row circulateForm">
        {/* <div className="col-md-6 my-3 row">
          <QuestionWithYesNo
            list={answerYesNo}
            {...props}
            label="Circulate"
            controlName="Circulate"
            data-circulate="1"
            value={transaction[props.CirculateConst].Circulate}
            onchangeRadio={(e) => {
              props.handleChange(
                e.target.name,
                e.target.value,
                props.CirculateConst
              );
            }}
          />
        </div> */}
        {/* {transaction[props.CirculateConst].Circulate ===
          enums.YesNoEnum.Yes && (
          <> */}
        <div className={`${props.title} container-bg row`}>
          <h6>
            <strong>{props.t(props.title)}</strong>
            {!disableControl && (
              <small className="mx-3 golden">
                {!selectAllActive && <a onClick={selectAllGovEntity}>{props.t("SelectAll")}</a>}
                {selectAllActive && <a onClick={clearAllGovEntity}>{props.t("ClearAll")}</a>}
              </small>
            )}
          </h6>

          {!disableControl && (
            <>
              <div className="col-md-4">
                <SelectInput
                  name="EmirateId"
                  label="emirate"
                  required={true}
                  disabled={disableControl}
                  value={localVal.EmirateId}
                  onChange={onchangeEmirate}
                  data={EmirateData}
                  data-circulate="1"
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  name="GovernmetnalEntityId"
                  label={govEntityLabel}
                  required={true}
                  disabled={disableControl}
                  value={localVal.GovernmentalEntityId}
                  onChange={changeGovEntity}
                  data={govEntityData}
                  data-circulate="1"
                />
              </div>
            </>
          )}
          {!disableControl && (
            <div className="col-md-4 mt-4">
              <button className="btn btn-success" type="button" onClick={submitHandler}>
                {props.t("add")}
              </button>
            </div>
          )}

          <div className="col-md-12 row my-2">
            {transaction[iIncident.SharedDTO.ICH] &&
              transaction[iIncident.SharedDTO.ICH]
                .filter((x) => x.IsCircularNotification === props.IsCircularNotification)
                .map((e, i) => (
                  <div className="card" key={i}>
                    <div className="card-body row">
                      <div className="col-md-8">
                        <small className="golden">{props.t("govEntity")}</small> :
                        <small>
                          <strong> {e.GovEntityName}</strong>
                        </small>
                      </div>
                    </div>
                    {!disableControl && (
                      <div className="closebtn">
                        <i style={{ cursor: "pointer", color: "red" }} className="fa fa-close" onClick={() => deleteHandler(e)}></i>
                      </div>
                    )}
                  </div>
                ))}
          </div>
        </div>
        {/* </>
        )} */}
      </div>
    </div>
  );
};

export default CirculateDetail;
