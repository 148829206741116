export const AgriculturePestMapper = (data) => {
  return data.map((x) => ({
    RefNo: x?.IncidentsTransaction?.RefNo,
    IncidentType: x?.IncidentsTransaction?.IncidentType,
    LocationGPS: `North :${x?.IncidentsTransaction?.Latitude} East :${x?.IncidentsTransaction?.Longitude}`,
    Status: x?.IncidentsTransaction?.StatusCode,
    IncidentDate: x?.IncidentsTransaction?.CreatedDate,
    Emirate: x?.IncidentsTransaction.GovtAgency?.EmirateLkp?.NameAr,
    Importance: x?.Importance,
    VisitRequired: x?.VisitRequired,
    ReporterName: x?.IncidentsTransaction?.Users?.UsersProfile?.NameAr,
    ReporterPhone: x?.IncidentsTransaction?.Users?.UsersProfile.MobileNo,
    ReporterEmail: x.IncidentsTransaction?.Users?.UsersProfile.Email,
    ReporterEmirateId: x.IncidentsTransaction?.Users?.UsersProfile.ReporterEID,
    ReporterPassport: x.IncidentsTransaction?.Users?.UsersProfile.PassportNo,
    ReporterAddress: x.IncidentsTransaction?.Users?.UsersProfile.Address,
    FarmOwnerName: x.IncidentHoldings?.Name,
    FarmOwnerPhone: x.IncidentHoldings?.MobileNo,
    FarmOwnerEmail: x.IncidentHoldings?.Email,
    FarmNumber: x.IncidentHoldings?.Number,
    PlantType: x.AgriculturePestDetail.map((x) => {
      return x.PlantTypeLkp.NameAr + " ";
    }).toLocaleString(),
    PestName: x.AgriculturePestDetail.map((x) => {
      return x.BlightDescriptionLkp.NameAr + " ";
    }).toLocaleString(),
    ActionLog: x.IncidentsTransaction?.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};

export const AgricultureBorderGateMapper = (data) => {
  return data.map((x) => ({
    RefNo: x?.IncidentsTransaction?.RefNo,
    IncidentType: x?.IncidentsTransaction?.IncidentType,
    LocationGPS: `North :${x?.IncidentsTransaction?.Latitude} East :${x?.IncidentsTransaction?.Longitude}`,
    BorderPort: x.GovernmentalEntityLkp.NameAr,
    ShipmentNo: x.ShipmentNo,
    ContainerNo: x.ContainerNo,
    ShipmentCertificateNo: x.ShipmentCertificateNo,
    ShipmentArrivalDate: x.AgricultureBorderGateShipmentDetail?.ShipmentArrivalDate?.split("T")[0],
    Status: x?.IncidentsTransaction?.StatusCode,
    IncidentDate: x?.IncidentsTransaction?.CreatedDate,
    Emirate: x?.IncidentsTransaction.GovtAgency?.EmirateLkp?.NameAr,
    ImporterName: x?.AgricultureBorderGateImporterDetail?.ImporterName,
    ImporterPhone: x?.AgricultureBorderGateImporterDetail?.ImporterPhone,
    ImporterEmail: x?.AgricultureBorderGateImporterDetail?.ImporterEmail,
    ImporterAddress: x?.AgricultureBorderGateImporterDetail?.ImporterAddress,
    ExportCountry: x?.AgricultureBorderGateShipmentDetail?.CountryLkp?.NameAr,
    RejectionDate: x?.AgricultureBorderGateShipmentDetail?.RejectionDate,
    RejectionReason: x?.AgricultureBorderGateShipmentDetail?.RejectionReason,
    TransportMedia: x?.AgricultureBorderGateShipmentDetail?.TransportMediaLkp?.NameAr,
    Crops_OrginCountry: x.AgricultureBorderGateCropsDetail.map((x) => {
      return x.CountryLkp.NameAr + ", ";
    }).toLocaleString(),
    CropsType: x.AgricultureBorderGateCropsDetail.map((x) => {
      return x.EserviceAgricultureLkp.NameAr + ", ";
    }).toLocaleString(),
    CropsAmount: x.AgricultureBorderGateCropsDetail.map((x) => {
      return x.Amount + " ";
    }).toLocaleString(),
    CropsUnit: x.AgricultureBorderGateCropsDetail.map((x) => {
      return x.Unit + " ";
    }).toLocaleString(),
    ActionLog: x.IncidentsTransaction?.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};
