import axios from "axios";
import { URLs } from "../../Constants/Common";

export const create = async (data) => {
  return await axios.post(URLs.CreateAgricultureBorderGate, data);
};
export const update = async (data) => {
  return await axios.post(URLs.UpdateAgricultureBorderGate, data);
};
export const createFollowUp = async (data) => {
  return await axios.post(URLs.CreateFollowUpAgricultureBorderGate, data);
};

export const getTransactionById = async (id) => {
  return await axios.get(
    URLs.getAgricultureBorderGateIncidentsTransactionById + "?id=" + id
  );
};
export const getAgricultureBorderGateReport = async (data) => {
  return await axios.post(URLs.GetAgricultureBorderGateReport, data);
};
