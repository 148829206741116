import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AH_Constants } from "../../../Interface/IAnimalHealth";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";
import * as eserviceDataAction from "../../../State/Actions/Eservices";
import { iAnimalHealth, master, enums, toast, validation } from "../../Imports";

const Holdings = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [ownerShipData, setOwnerShipData] = useState([]);
  const transaction = useSelector((state) => state.transaction);

  const reqField = validation.isUserLoggedIn() && (validation.isGovtUser() || validation.isMinistryUser());
  const loadCompLkp = useCallback(async () => {
    setOwnerShipData(await master.getHoldingOwnershipType());
  }, [dispatch]);

  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);

  useEffect(() => {
    if (!props.OwnerShipType) document.getElementById("HoldingTypeId").value = transaction[iAnimalHealth.AH_Constants.IH].HoldingTypeId;

    validation.isShowInfectionForm(transaction, "holdingForm");
    //enableControl(transaction[iAnimalHealth.AH_Constants.IH], true);
  }, [transaction.isUpdate]);
  useEffect(() => {
    // if (!transaction[AH_Constants.IH].Number) {
    //   enableControl("", true);
    // }
  }, [transaction[AH_Constants.IH].Number]);

  const RetreiveDataEservice = async (e) => {
    setIsLoading(true);
    try {
      const { data } = await eserviceDataAction.getAgriculturaltenure(transaction[AH_Constants.IH].Number);

      if (data != null) {
        console.log(data);
        enableControl(data, false);
        setIsLoading(false);
      } else {
        toast(props.t("dataNotFound"), { type: "error" });
        enableControl("", true);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const enableControl = (data, isEnabled) => {
    props.handleChange("Name", isEnabled ? "" : data.ParticipantName, AH_Constants.IH);
    props.handleChange("MobileNo", isEnabled ? "" : data.ParticipantMobile, AH_Constants.IH);
    props.handleChange("Email", isEnabled ? "" : data.ParticipantEmail, AH_Constants.IH);
    props.handleChange("IsFarm", props.OwnerShipType === enums.OwnerShipTypeEnum.Farm.toString(), AH_Constants.IH);
    document.getElementById("Name_holding").disabled = !isEnabled;
    document.getElementById("MobileNo_holding").disabled = !isEnabled;
    document.getElementById("Email_holding").disabled = !isEnabled;
  };
  const handleChange = (e) => {
    props.handleChange(e.target.name, e.target.value, AH_Constants.IH);
  };
  return (
    <div className=" ">
      <div className="row my-1 container-bg holdingForm">
        <label className="control-title my-2 mb-4">
          <strong>{props.t("propertyDetail")}</strong>
        </label>
        {!props.OwnerShipType && (
          <div className="col-md-4">
            <SelectInput
              name="HoldingTypeId"
              label="propertyType"
              required={true}
              disabled={props.disabled}
              value={transaction[AH_Constants.IH].HoldingTypeId}
              onChange={handleChange}
              data={ownerShipData}
            />
          </div>
        )}

        {transaction[AH_Constants.IH].HoldingTypeId === enums.OwnerShipTypeEnum.Others.toString() && (
          <div className="col-md-4">
            <FormInput
              name="HoldingTypeOther"
              type="text"
              label="propertyType"
              required={true}
              disabled={props.disabled}
              value={transaction[AH_Constants.IH].HoldingTypeOther}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-md-4">
          <FormInput
            name="Number"
            type="text"
            label="propertyNumber"
            // required={false}
            disabled={props.disabled}
            required={false}
            value={transaction[AH_Constants.IH].Number}
            onChange={handleChange}
          />
        </div>
        {(transaction[AH_Constants.IH].HoldingTypeId === enums.OwnerShipTypeEnum.Farm.toString() ||
          (props.OwnerShipType && props.OwnerShipType === enums.OwnerShipTypeEnum.Farm.toString())) && (
          <div className="col-md-4 mt-4">
            <button
              disabled={isLoading || !transaction[AH_Constants.IH].Number || transaction.isUpdate}
              className="btn btn-success"
              type="button"
              onClick={RetreiveDataEservice}
            >
              {isLoading && <span className="spinner-border spinner-border-sm"></span>}
              {props.t("retreiveFarmData")}
            </button>
          </div>
        )}
        <div className="col-md-4">
          <FormInput
            id="Name_holding"
            name="Name"
            type="text"
            label="propertyName"
            disabled={props.disabled}
            required={reqField}
            value={transaction[AH_Constants.IH].Name}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            id="MobileNo_holding"
            name="MobileNo"
            type="text"
            label="propertyPhoneNo"
            pattern="\d*"
            maxLength={16}
            minLength={10}
            required={reqField}
            disabled={props.disabled}
            value={transaction[AH_Constants.IH].MobileNo}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            id="Email_holding"
            name="Email"
            type="email"
            errorMessage={props.t("email") + props.t("incorrect")}
            // pattern={constants.email_regx}
            label="propertyEmail"
            required={false}
            disabled={props.disabled}
            value={transaction[AH_Constants.IH].Email}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="Address"
            type="text"
            label="propertyAddress"
            required={reqField}
            disabled={props.disabled}
            value={transaction[AH_Constants.IH].Address}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Holdings;
