import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WorkFlowStatusCodeEnum } from "../../../Interface/enums";
import { encrypt, enums, iIncident } from "../../Imports";
import RelatedIncident from "../AnimalHealth/UIComponent/RelatedIncident";
import FollowUpReportIncident from "../AnimalHealth/UIComponent/FollowUpReportIncident";

const IncidentStatusUpdate = (props) => {
  const [wfActionHistory, setwfActionHistory] = useState([]);
  const [wfArrayLength, setWfArrayLength] = useState(0);
  const [showParentReport, setShowParentReport] = useState(false);
  const [showModalFollowupReport, setShowModalFollowupReport] = useState(false);
  const [showModalRelatedIncident, setShowModalRelatedIncident] = useState(false);
  const [showFollowUpBtn, setShowFollowUpBtn] = useState(false);
  const transaction = useSelector((state) => state.transaction);

  useEffect(() => {
    if (transaction.isUpdate) {
      setwfActionHistory(transaction.IncidentsMainTransactionDTO.WorkFlowActionHistorys);
      setWfArrayLength(transaction.IncidentsMainTransactionDTO.FollowUp === 1 ? 0 : transaction.IncidentsMainTransactionDTO.WorkFlowActionHistorys.length - 1);
      setShowFollowUpBtn(
        transaction.IncidentsMainTransactionDTO.StatusCode !== WorkFlowStatusCodeEnum.New &&
          (transaction.IncidentsMainTransactionDTO.IncidentTypeCode === enums.IncidentTypeEnum.AnimalHealth ||
            transaction.IncidentsMainTransactionDTO.IncidentTypeCode === enums.IncidentTypeEnum.FoodSafetyIncident)
      );
      setShowParentReport(transaction[iIncident.SharedDTO.IT].ParentId > 0);
    }
  }, [transaction.isUpdate]);

  const handleShowModalFollowupReport = () => {
    setShowModalFollowupReport(!showModalFollowupReport);
  };
  const handleShowModalRelatedIncident = () => {
    setShowModalRelatedIncident(!showModalRelatedIncident);
  };
  return (
    <div className="container ">
      <div className="container-bg row  my-2">
        <div className="col-md-4 ">
          <strong className=" ">{props.t("IncidentDate")}</strong> :{" "}
          <small className="golden">{new Date(transaction.IncidentsMainTransactionDTO.CreatedDate).toLocaleString()}</small>
        </div>
        <div className="col-md-4">
          <strong>{props.t("IncidentNumber")}</strong> :<small className="golden"> {transaction.IncidentsMainTransactionDTO.RefNo}</small>
        </div>
        {wfActionHistory.length > 0 && transaction.StatusCode !== enums.WorkFlowStatusCodeEnum.New && (
          <div className="col-md-4">
            <strong>{props.t("IncidentStatus")}</strong>
            <span
              className="status-box mx-2"
              style={{
                backgroundColor: `${wfActionHistory[wfArrayLength].WorkFlowState.WorkFlowStateLkp.Color}`,
              }}
            >
              {props.currentLanguage === "ar"
                ? wfActionHistory[wfArrayLength].WorkFlowState.WorkFlowStateLkp.NameAr
                : wfActionHistory[wfArrayLength].WorkFlowState.WorkFlowStateLkp.NameEn}
            </span>
          </div>
        )}
        {/* show New status for followup reprot  */}
        {transaction.StatusCode === enums.WorkFlowStatusCodeEnum.New && (
          <div className="col-md-4">
            <strong>{props.t("IncidentStatus")}</strong>
            <span
              className="status-box mx-2"
              style={{
                backgroundColor: "#5a9eef",
              }}
            >
              {props.currentLanguage === "ar" ? "جديد" : "New"}
            </span>
          </div>
        )}
        {showFollowUpBtn && (
          <>
            {transaction.IncidentsMainTransactionDTO.IncidentTypeCode === enums.IncidentTypeEnum.AnimalHealth && (
              <div className="col-md-4 mt-3">
                <button type="button" className="btn btn-success" id="RelatedIncident" onClick={handleShowModalRelatedIncident}>
                  {props.t("RelatedIncident")}
                </button>
              </div>
            )}
            {!showParentReport && (
              <div className="col-md-4 mt-3">
                <button type="button" className="btn btn-success" id="FollowupReport" onClick={handleShowModalFollowupReport}>
                  {props.t("FollowupReport")}
                </button>
              </div>
            )}
          </>
        )}
        {showParentReport && (
          <div className="col-md-4 mt-4">
            <a
              rel="noreferrer"
              className="pro-link"
              id="ParentReport"
              target="_blank"
              href={` ${transaction[iIncident.SharedDTO.IT].IncidentType.IncidentURL}${encrypt.encrypt(
                transaction[iIncident.SharedDTO.IT].ParentId
              )}/${encrypt.encrypt(0)}`}
            >
              {props.t("ParentReport")}
            </a>
          </div>
        )}
        {showModalFollowupReport && (
          <FollowUpReportIncident
            showModal={showModalFollowupReport}
            handleShowModal={handleShowModalFollowupReport}
            data={transaction.IncidentsMainTransactionDTO}
            {...props}
          />
        )}
        {showModalRelatedIncident && (
          <RelatedIncident
            showModal={showModalRelatedIncident}
            handleShowModal={handleShowModalRelatedIncident}
            data={transaction.IncidentsMainTransactionDTO}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

export default IncidentStatusUpdate;
