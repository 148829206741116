import React, { useCallback, useEffect, useMemo, useState } from "react";
import { auth, dashboardAction, enums, validation } from "../../Imports";
import { useParams } from "react-router-dom";
import * as Report from "../../Incidents/Inbox/ReportControl";
import * as Grid from "../../Incidents/Inbox/Grids/SearchGrid";
import Pagination from "../../Common/UI/Pagination/Pagination";

const IncidentDetail = (props) => {
  let { type, subType } = useParams();
  const emirateId = validation.getUserEmirateId();

  const initialState = {
    fromDate: "",
    toDate: "",
    EmirateId: emirateId,
    EmirateName: "",
  };
  let PageSize = 10;
  const [localVal, setLocalVal] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(0);
  const [incidentData, setIncidentData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
    const text = e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text;
    setLocalVal((p) => ({
      ...p,
      EmirateName: text,
    }));
    onSearchEmirateHandle(text);
  };
  const changeControlDate = (name, value) => {
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
    console.log(name);
    if (name === "fromDate") {
      onSearchDatesHandle(value, localVal.toDate);
    } else if (name === "toDate") {
      onSearchDatesHandle(localVal.fromDate, value);
    }
  };
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return incidentData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, incidentData]);

  const onSearchDatesHandle = (fromDate, toDate) => {
    console.log(fromDate, toDate, localVal.EmirateName);

    if (fromDate && toDate) {
      const searchResult = searchData.filter(
        (x) =>
          new Date(fromDate).toLocaleDateString() <= new Date(x.CreatedDate).toLocaleDateString() &&
          new Date(toDate).toLocaleDateString() >= new Date(x.CreatedDate).toLocaleDateString()
      );
      setIncidentData(searchResult);
    }
  };
  const onSearchEmirateHandle = (search) => {
    //console.log(searchData);
    if (search.length > 0) {
      const searchResult = searchData.filter((x) => x.EmirateLkp?.NameEn.indexOf(search) > -1 || x.EmirateLkp?.NameAr.indexOf(search) > -1);
      setIncidentData(searchResult);
    }
  };
  const loadComLkp = useCallback(async () => {
    let res = null;
    if (type === enums.IncidentTypeEnum.AnimalHealth) res = (await dashboardAction.getAnimalStateCount_IncidentDetail(subType, emirateId)).data.ResponseData;
    else if (type === enums.IncidentTypeEnum.AnimalLegalViolation) {
      res = (await dashboardAction.getLeagalViolationStatCount_IncidentDetail(subType, emirateId)).data.ResponseData;
    } else if (type === enums.IncidentTypeEnum.FoodSafetyIncident) {
      res = (await dashboardAction.getCountByStatus_IncidentDetail(subType, emirateId)).data.ResponseData;
    } else if (type === enums.IncidentTypeEnum.AgriculturePestIncident) {
      res = (await dashboardAction.getAgricultureStateCount_IncidentDetail(subType, emirateId)).data.ResponseData;
    }

    setIncidentData(res);
    setSearchData(res);
    setCurrentPage(1);
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4">
        <h3>{props.t("Dashboard")}</h3>
      </div>

      <div className="container my-3">
        <div className="row flex-fill container-bg">
          <div className="col-md-3">
            <Report.DateControl label="from" localVal={localVal.fromDate} changeControlDate={changeControlDate} />
          </div>
          <div className="col-md-3">
            <Report.DateControl label="to" localVal={localVal.toDate} changeControlDate={changeControlDate} />
          </div>
          <div className="col-md-3">
            <Report.EmirateLkpControl localVal={localVal.EmirateId} changeControl={changeControl} />
          </div>
        </div>

        <div className="">
          <table className="table table-wrapper container-bg table-responsive">
            <thead>
              <tr>
                <th>{props.t("IncidentNumber")}</th>
                <th>{props.t("IncidentDate")}</th>
                <th>{props.t("reporterName")} </th>
                <th>{props.t("IncidentStatus")} </th>
                <th>{props.t("emirate")} </th>
                <th>{props.t("incidentLocation")} </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((incident, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Grid.DynamicLink incident={incident} />
                    </td>
                    <td>
                      <Grid.ColumnCreateDate incident={incident} />
                    </td>
                    <td>
                      <Grid.ColumnUserName incident={incident} />
                    </td>
                    <td>{incident.StatusCode}</td>
                    <td>
                      <Grid.ColumnEmiratesName incident={incident} props={props} />
                    </td>
                    <td>
                      <Grid.ColumnGPSLocation trans={incident} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {incidentData?.length > 0 && (
            <Pagination
              className="pagination-bar my-3"
              currentPage={currentPage}
              totalCount={incidentData.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
              {...props}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IncidentDetail;
