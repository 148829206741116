import { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import cookies from "js-cookie";
import { util } from "../../Imports";

const FormInput = (props) => {
  //console.log(props);
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  let {
    label,
    errorMessage,
    onChange,
    id,
    fontIcon,
    type,
    selectedDate,
    ...inputProps
  } = props;
  if (type === "number") {
    errorMessage = t("EnterNumberGreaterThanZero");
  }

  //const lang = cookies.get("i18next") || "ar";
  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className="form-group">
      {t(label) && (
        <label>
          {t(label)}
          {props.required && <span className="red"> *</span>}
        </label>
      )}
      {type === "radio" && (
        <>
          <input
            {...inputProps}
            id={`${props.htmlFor}_${props.name}`}
            type="radio"
            onChange={onChange}
            onBlur={handleFocus}
            className="form-check-input"
            onFocus={() => {}}
            focused={focused.toString()}
            value={id}
          />
          <label
            className="form-check-label"
            htmlFor={`${props.htmlFor}_${props.name}`}
          >
            {t(`${props.htmlFor}`)}
          </label>
        </>
      )}
      {type === "textarea" && (
        <textarea
          {...inputProps}
          id={id ?? inputProps.name}
          type={type}
          onChange={onChange}
          onBlur={handleFocus}
          className="form-control"
          onFocus={() => {}}
          focused={focused.toString()}
          rows={4}
          cols={20}
        ></textarea>
      )}

      {type === "date" && (
        <>
          {/* <input
            id={id ?? inputProps.name}
            type={type}
            // required={true}
            onChange={onChange}
            focused={focused.toString()}
            onBlur={handleFocus}
            className="form-control"
            // dateFormat="dd/MM/yyyy"
          /> */}
          <DatePicker
            showIcon={true}
            id={id ?? inputProps.name}
            type={type}
            selected={util.DateFormat(selectedDate)}
            required={true}
            onChange={onChange}
            onFocus={() => {}}
            focused={focused.toString()}
            onBlur={handleFocus}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            {...inputProps}
          />
          {/* <span>📅</span> */}
        </>
      )}

      {type !== "textarea" && type !== "date" && type !== "radio" && (
        <input
          {...inputProps}
          id={id ?? inputProps.name}
          type={type}
          onChange={onChange}
          onBlur={handleFocus}
          className={
            fontIcon ? "form-control  loginInput" : "form-control pl-2 pb-2"
          }
          onFocus={
            () => {}
            // inputProps.name === "confirmPassword" && setFocused(true)
          }
          focused={focused.toString()}
        />
      )}
      {fontIcon != null && <i className={fontIcon}></i>}
      <span
        className="error"
        dangerouslySetInnerHTML={{
          __html: !errorMessage ? t("mandaotryField") : errorMessage,
        }}
      ></span>
    </div>
  );
};
export default FormInput;

export const SelectInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  const lang = cookies.get("i18next") || "ar";
  const { label, errorMessage, onChange, data, name, required } = props;

  const handleFocus = (e) => {
    // var val = document.getElementById(name).value;
    // setSelectedVal(val);
    setFocused(true);
  };

  return (
    <div>
      <div className="form-group">
        {t(label) && (
          <label>
            {t(label)}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        )}
        <select
          name={name}
          id={name}
          onChange={onChange}
          onBlur={handleFocus}
          focused={focused.toString()}
          required={required}
          {...props}
        >
          <option value="">
            {t("select")} {t(label)}
          </option>
          {data.map((e) => (
            <option key={e.Id} value={e.Id} data-code={e.Code}>
              {lang === "ar" ? e.NameAr : e.NameEn}
            </option>
          ))}
        </select>

        <span
          className="error  "
          dangerouslySetInnerHTML={{
            __html: !errorMessage ? t("mandaotryField") : errorMessage,
          }}
        ></span>
      </div>
    </div>
  );
};
