import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import userImg from "../../Theme/img/avatar_empty.png";
import dashboard_Img from "../../Theme/img/dashboard.svg";
import search_img from "../../Theme/img/search.svg";
import report_img from "../../Theme/img/report.svg";
import arciv_img from "../../Theme/img/arciv.svg";
import setting_img from "../../Theme/img/settings.svg";
import notification_img from "../../Theme/img/font-icons/bell.svg";
import * as token from "../../Constants/AuthToken";
import { encrypt, enums, validation, util } from "../Imports";
import { URLRoutes } from "../Routes/Routes";
import NotificationUI from "./HeaderComponent/NotificationUI";
import UserSettingUI from "./HeaderComponent/UserSettingUI";

const SideBar = (props) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [show, setShow] = useState({
    dashboard: true,
    myIncident: true,
    myTask: true,
    incidentSearch: true,
    documentLibrary: true,
    reports: true,
    circulatedIncident: false,
  });
  const user = token.getUserPermission();
  let location = useLocation();
  const navigate = useNavigate();
  const isHealthFacility = user.UnitType === enums.UnitTypeEnum.HealthFacility;
  const isBorderGate = user.UnitType === enums.UnitTypeEnum.BorderGate;
  const nonGovUser = user.UserAccountType === enums.UserAccountTypeEnum.NonGovernmentUser;
  const isOperationManager = user.UnitType === enums.UnitTypeEnum.MinistryUser && validation.isDisasterOperationDept();
  const isOperationCenter = user.UnitType === enums.UnitTypeEnum.OperationCenterUser && validation.isDisasterOperationDept();
  const isCallCenterUser = user.OrganizationId === "133";

  const [isShrink, setIsShrink] = useState(false);

  const shrinkSideBar = () => {
    setIsShrink(!isShrink);
  };

  const activeLink = (url) => {
    return location.pathname.indexOf(url) > -1 ? "active" : "";
  };
  useEffect(() => {
    const { pathname } = location;
    const handleListener = (location, action) => {
      if (action === "POP") {
      }
    };
    setShowSideBar(!util.getRouteValue(pathname));
    return () => {
      handleListener(navigator.location, navigator.action);
    };
  }, [location, navigator]);
  useEffect(() => {
    setShow((pre) => ({
      ...pre,
      myTask: !isHealthFacility && !isBorderGate && !nonGovUser && !isOperationManager && !isOperationCenter && !isCallCenterUser,
      dashboard: !isHealthFacility && !nonGovUser && !isCallCenterUser,
      documentLibrary: !isHealthFacility && !nonGovUser && !isOperationManager && !isOperationCenter && !isCallCenterUser,
      reports: !isHealthFacility && !isBorderGate && !nonGovUser && !isCallCenterUser,
      incidentSearch: !nonGovUser && !isOperationCenter,
      myIncident: !isOperationCenter,
      circulatedIncident: isBorderGate && validation.isAnimalDept(),
    }));
  }, []);

  const closeSideBar = () => {
    var element = document.getElementById("sidebar");
    element.classList.remove("slide");
  };
  return (
    <>
      {showSideBar && user.isLoggedIn && (
        <div className={`side-menu d-flex flex-column  ${isShrink ? "new-shrink" : " "}`} id="sidebar">
          <div className="d-lg-none">
            <div className="mobile-menu-tools d-flex">
              <div className="close-btn app-close-js ml-auto d-flex p-4">
                <a className="close" aria-label="Close" onClick={closeSideBar}>
                  <span aria-hidden="true">×</span>
                </a>
              </div>
              <div className="close-btn app-close-js ml-auto d-flex p-4">{user.isLoggedIn && <NotificationUI {...props} />}</div>
              <div className="close-btn app-close-js ml-auto d-flex p-4">{user.isLoggedIn && <UserSettingUI {...props} />}</div>
            </div>
          </div>
          <div className="loggedin-user d-flex flex-shrink-0  mx-2 pt-3 pb-2">
            <div className="user-img mr-3">
              <a>
                <img alt="" src={userImg} />
              </a>
            </div>
            <div className="loggedin-user-info d-flex flex-column">
              <span>
                <a>{user.Name}</a>
              </span>
              <strong className="mb-1">
                {user.UnitTypeName != null ? util.getProperText(user.UnitTypeName.split("-")[0], user.UnitTypeName.split("-")[1]) : ""}
              </strong>
              <p className="m-0">{user.GovEntity != null ? util.getProperText(user.GovEntity.split("-")[0], user.GovEntity.split("-")[1]) : ""}</p>
            </div>
            <div className="side-menu-toggle" onClick={shrinkSideBar}>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </div>

          <div className="flex-fill overflow-y">
            <ul className="nav nav-tabs flex-column">
              {show.dashboard && (
                <li className="nav-item  ">
                  <Link className="p-2 " to={`/dashboard/${encrypt.encrypt(enums.DashboardType.General)}`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={dashboard_Img} />
                      <span>{props.t("dashboard")}</span>
                    </span>
                  </Link>
                </li>
              )}
              {show.incidentSearch && (
                <li className={`nav-item ${activeLink(enums.SearchCriteriaEnum.IncidentSearch)}`}>
                  <Link className="p-2 " to={`/manageincident/${enums.SearchCriteriaEnum.IncidentSearch}`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={search_img} />
                      <span>{props.t("IncidentSearch")}</span>
                    </span>
                  </Link>
                </li>
              )}
              {show.documentLibrary && (
                <li className="nav-item">
                  <Link className="p-2" to={`/viewdocumentLib`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={arciv_img} />
                      <span>{props.t("documentLibrary")}</span>
                    </span>
                  </Link>
                </li>
              )}
              {show.myIncident && (
                <li className={`nav-item ${activeLink(enums.SearchCriteriaEnum.MyIncidents)}`}>
                  <Link className="p-2" to={`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={dashboard_Img} />
                      <span>{props.t("MyIncidents")}</span>
                    </span>
                  </Link>
                </li>
              )}
              {show.circulatedIncident && (
                <li className={`nav-item ${activeLink(enums.SearchCriteriaEnum.CirculatedIncident)}`}>
                  <Link className="p-2" to={`/manageincident/${enums.SearchCriteriaEnum.CirculatedIncident}`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={dashboard_Img} />
                      <span>{props.t("CirculatedIncident")}</span>
                    </span>
                  </Link>
                </li>
              )}

              {show.myTask && (
                <li className={`nav-item ${activeLink(enums.SearchCriteriaEnum.MyTask)}`}>
                  <Link className="p-2" to={`/manageincident/${enums.SearchCriteriaEnum.MyTask}`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={dashboard_Img} />
                      <span>{props.t("MyTask")}</span>
                    </span>
                  </Link>
                </li>
              )}
              <li className={`nav-item ${activeLink("/" + URLRoutes.notification)}`}>
                <Link className="p-2" to={"/" + URLRoutes.notification}>
                  <span className="nav-icon align-items-center d-flex">
                    {/* <img alt="" src={notification_img} /> */}
                    <i className="ico icon-Group-184" style={{ fontSize: "33px", color: "#b3b3b3" }}></i>
                    <span>{props.t("Notification")}</span>
                  </span>
                </Link>
              </li>
              {show.reports && (
                <li className="hide-on-mobile nav-item">
                  <a className="p-2" href="#">
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={report_img} />
                      <span>{props.t("report")}</span>
                      <i className="fa fa-angle-down ml-auto mr-auto"></i>
                    </span>
                  </a>
                  <ul className="sub-menu">
                    {validation.isAgricultureDept() && (
                      <>
                        <li className=" p-2">
                          <Link className="" to={`/agriculture-pest-report/${enums.IncidentTypeEnum.AgriculturePestIncident}`}>
                            <span>{props.t("agriculturalPestsReport")}</span>
                          </Link>
                        </li>

                        <li className=" p-2">
                          <Link className="" to={`/agriculture-pest-report/${enums.IncidentTypeEnum.AgricultureBorderGate}`}>
                            <span>{props.t("agriculturalBorderGateReport")}</span>
                          </Link>
                        </li>
                      </>
                    )}
                    {validation.isAnimalDept() && (
                      <>
                        <li className="p-2">
                          <Link className="" to={`/animal-health-report/${enums.IncidentTypeEnum.AnimalHealth}`}>
                            <span>{props.t("animalHealthDetailReport")}</span>
                          </Link>
                        </li>
                        <li className="p-2">
                          <Link className="" to={`/animal-health-report/${enums.IncidentTypeEnum.AnimalLegalViolation}`}>
                            <span>{props.t("leagalViolationDetailReport")}</span>
                          </Link>
                        </li>

                        {isBorderGate ||
                          (validation.isMinistryUser() && (
                            <li className="p-2">
                              <Link className="" to={`/animal-health-report/${enums.IncidentTypeEnum.AnimalBorderGate}`}>
                                <span>{props.t("borderGateDetailReport")}</span>
                              </Link>
                            </li>
                          ))}
                      </>
                    )}

                    {validation.isFoodSafetyDept() && (
                      <>
                        <li className="p-2">
                          <Link className="" to={`/${URLRoutes.foodSafetyReport}`}>
                            <span>{props.t("reportFoodSafetyIncident")}</span>
                          </Link>
                        </li>
                        <li className="p-2">
                          <Link className="" to={`/${URLRoutes.foodSafetyCountByEmirateReport}`}>
                            <span>{props.t("foodSafetyCountByEmirate")}</span>
                          </Link>
                        </li>
                      </>
                    )}
                    {(isOperationCenter || isOperationManager) && (
                      <>
                        {/* <li className="p-2">
                          <Link className="" to={`/${URLRoutes.OverAllReport}`}>
                            <span>{props.t("report")}</span>
                          </Link>
                        </li> */}
                      </>
                    )}
                  </ul>
                </li>
              )}
              {validation.isAccessDocumentLib() && !isCallCenterUser && (
                <li className={`nav-item ${activeLink("manageaccount")}`}>
                  <Link className="p-2" to={`/manageaccount`}>
                    <span className="nav-icon align-items-center d-flex">
                      <img alt="" src={setting_img} />
                      <span>{props.t("ManageAccount")}</span>
                    </span>
                  </Link>
                </li>
              )}
              {validation.isAccessSystemSetting() && !isCallCenterUser && (
                <>
                  <li className={`nav-item ${activeLink("system-configuration")}`}>
                    <Link className="p-2" to={`/system-configuration/${user.Department}`}>
                      <span className="nav-icon align-items-center d-flex">
                        <img alt="" src={setting_img} />
                        <span>{props.t("systemSetting")}</span>
                      </span>
                    </Link>
                  </li>

                  <li className={`nav-item ${activeLink("animal-incident-redirection")}`}>
                    <Link className="p-2" to={`/animal-incident-redirection`}>
                      <span className="nav-icon align-items-center d-flex">
                        <img alt="" src={setting_img} />
                        <span>{props.t("IncidentRedirection")}</span>
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>

          {/* <div className=" log-out p-2 text-center">
            <Link to="#" rel="noreferrer" onClick={logoutSession}>
              <span>{props.t("SignOut")}</span>
              <i className="ico icon-Group-246 mx-2"></i>
            </Link>
          </div> */}
        </div>
      )}
    </>
  );
};

export default SideBar;
