import React, { useState, useCallback, useEffect } from "react";
import { auth, dashboardAction, util, validation } from "../../Imports";
import DateUI from "../Shared/DateUI";
import ChartUI, { ChartType } from "../../Common/UI/ChartUI";

const Charts = (props) => {
  const DBType = {
    incidentByEmirateCount: "incidentByEmirateCount",
    incidentByIncidentSource: "incidentByIncidentSource",
    incidentByOriginCountry: "incidentByOriginCountry",
    incidentByProductCatagory: "incidentByProductCatagory",
    incidentByRiskSourceGroup: "incidentByRiskSourceGroup",
    incidentByActionTaken: "incidentByActionTaken",
  };
  const emirateId = validation.getUserEmirateId();
  const labels = util.getEmirateList();
  const labelName = [props.t("Companies"), props.t("Consumer"), props.t("FocalPoint"), props.t("MinistryUser"), props.t("BorderGate")];

  const user = auth.getUserPermission();
  const [incidentByEmirateCount, setIncidentByEmirateCount] = useState({});
  const [incidentByIncidentSource, setIncidentByIncidentSource] = useState([]);
  const [incidentByOriginCountry, setIncidentByOriginCountry] = useState([]);
  const [incidentByActionTaken, setIncidentByActionTaken] = useState([]);
  const [incidentByProductCatagory, setIncidentByProductCatagory] = useState([]);
  const [incidentByRiskSourceGroup, setIncidentByRiskSourceGroup] = useState([]);

  const loadComLkp = useCallback(async () => {
    setIncidentByEmirateCount((await dashboardAction.getIncidentByEmirate(user.Department, emirateId))?.data?.ResponseData);
    setIncidentByIncidentSource((await dashboardAction.getIncidentByIncidentSource(user.Department, emirateId)).data.ResponseData);
    setIncidentByOriginCountry((await dashboardAction.getIncidentByOriginCountry(emirateId)).data.ResponseData);
    setIncidentByProductCatagory((await dashboardAction.getIncidentByProductCatagory(emirateId)).data.ResponseData);
    setIncidentByRiskSourceGroup((await dashboardAction.getIncidentByRiskSourceGroup(emirateId)).data.ResponseData);
    setIncidentByActionTaken((await dashboardAction.getIncidentByActionTaken(emirateId)).data.ResponseData);
  }, []);

  useEffect(() => {
    loadComLkp();
  }, []);

  // useEffect(() => {
  //   console.log(incidentByEmirateCount);
  // }, [incidentByEmirateCount]);

  const handleClearBtn = async (type) => {
    handleApplyBtn(null, null, type);
  };

  const handleApplyBtn = async (fromDate, toDate, type) => {
    switch (type) {
      case DBType.incidentByRiskSourceGroup:
        setIncidentByRiskSourceGroup((await dashboardAction.getIncidentByRiskSourceGroup(emirateId, fromDate, toDate)).data.ResponseData);
        break;
      case DBType.incidentByEmirateCount:
        setIncidentByEmirateCount((await dashboardAction.getIncidentByEmirate(user.Department, emirateId, fromDate, toDate)).data.ResponseData);
        break;
      case DBType.incidentByIncidentSource:
        setIncidentByIncidentSource((await dashboardAction.getIncidentByIncidentSource(user.Department, emirateId, fromDate, toDate)).data.ResponseData);
        break;
      case DBType.incidentByOriginCountry:
        setIncidentByOriginCountry((await dashboardAction.getIncidentByOriginCountry(emirateId, fromDate, toDate)).data.ResponseData);
        break;
      case DBType.incidentByProductCatagory:
        setIncidentByProductCatagory((await dashboardAction.getIncidentByProductCatagory(emirateId, fromDate, toDate)).data.ResponseData);
        break;
      case DBType.incidentByActionTaken:
        setIncidentByActionTaken((await dashboardAction.getIncidentByActionTaken(emirateId, fromDate, toDate)).data.ResponseData);
        break;
      default: {
        break;
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        <span className="d-flex m-4">{props.t("IncidentsGraphicalSummary")}</span>
        <div className="app-charts-grid">
          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentsPerEmirate")}</span>
                <DateUI {...props} type={DBType.incidentByEmirateCount} handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <ChartUI
                // title={props.t("IncidentsPerEmirate")}
                legendDisplay={true}
                type={ChartType.barStacked}
                labels={labels}
                datasets={util.DbUtil.getDatasetEmirateCount(incidentByEmirateCount, labelName)}
              />
            </div>
          </div>

          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column  ">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentsPerIncidentSource")}</span>
                <DateUI {...props} type={DBType.incidentByIncidentSource} handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <ChartUI
                legendDisplay={false}
                type={ChartType.bar}
                labels={labelName}
                datasets={util.DbUtil.getDatasetPerIncidentCount(incidentByIncidentSource, props.t("Total"))}
              />
            </div>
          </div>

          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column  ">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentsPerCountryOrigin")}</span>
                <DateUI {...props} type={DBType.incidentByOriginCountry} handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <ChartUI
                legendDisplay={true}
                type={ChartType.pie}
                labels={util.DbUtil.getLabelList(incidentByOriginCountry)}
                datasets={util.DbUtil.getDatasetPerCountryCount(incidentByOriginCountry, props.t("Incident"))}
              />
            </div>
          </div>
          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column  ">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentPerActionTaken")}</span>
                <DateUI {...props} type={DBType.incidentByActionTaken} handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <ChartUI
                legendDisplay={true}
                type={ChartType.pie}
                labels={util.DbUtil.getLabelList(incidentByActionTaken)}
                datasets={util.DbUtil.getDatasetPerCountryCount(incidentByActionTaken)}
              />
            </div>
          </div>
          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column  ">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentsPerSubstanceCategory")}</span>
                <DateUI {...props} type={DBType.incidentByProductCatagory} handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <ChartUI
                legendDisplay={true}
                type={ChartType.pie}
                labels={util.DbUtil.getLabelList(incidentByProductCatagory)}
                datasets={util.DbUtil.getDatasetPerProductCount(incidentByProductCatagory, props)}
              />
            </div>
          </div>

          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column  ">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentsPerDangers")}</span>
                <DateUI {...props} type={DBType.incidentByRiskSourceGroup} handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <div className="align-items-center d-flex m-3">
                <ChartUI
                  legendDisplay={true}
                  type={ChartType.barStacked}
                  labels={util.DbUtil.getLabelList(incidentByRiskSourceGroup)}
                  datasets={util.DbUtil.getDatasetRiskCount(incidentByRiskSourceGroup, props)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
