import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Control from "../CommonControl";
import { accountAction, auth, enums, swal, toast, util, validation } from "../../Imports";

const Profile = (props) => {
  const navigate = useNavigate();
  const user = auth.getUserPermission();
  const initialState = {
    prefLanguage: util.getLang(),
    Id: 0,
    nameEn: "",
    lastNameEn: "",
    nameAr: "",
    lastNameAr: "",
    reporterEID: "",
    passportNo: "",
    email: "",
    mobileNo: "",
    fax: "",
    DepartmentId: user.Department,
    UnitTypeCode: "",
    GovtEntityId: "",
  };

  const [localVal, setLocalVal] = useState(initialState);

  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const getUserById = async (id) => {
    const data = await accountAction.getUserById(id);
    console.log(data);
    if (data.UserId > 0) {
      setLocalVal((p) => ({
        ...p,
        Id: data.UserId,
        nameEn: data.UsersProfile?.NameEn ?? "",
        lastNameEn: data.UsersProfile?.LastNameEn ?? "",
        nameAr: data.UsersProfile?.NameAr ?? "",
        lastNameAr: data.UsersProfile?.LastNameAr ?? "",
        reporterEID: data.UsersProfile?.ReporterEID ?? "",
        passportNo: data.UsersProfile?.PassportNo ?? "",
        email: data.Email ?? "",
        mobileNo: data.UsersProfile?.MobileNo ?? "",
        fax: data.UsersProfile?.Fax ?? "",
        DepartmentId: data.UsersProfile?.OrganizationUnitsLkp?.DepartmentUnitType?.DepartmentId,
        UnitTypeCode: data.UsersProfile?.OrganizationUnitsLkp?.DepartmentUnitType?.UnitTypeCode,
        GovtEntityId: data.UsersProfile?.OrganizationUnitsLkp?.GovEntityId,
      }));
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const form = validation.validateForm("employeeForm");
    if (!form) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    const res = await accountAction.addUserByAdmin(localVal);
    if (res.UserId > 0) {
      swal(props.t("success"), props.t("submitSuccessFully"), "success");
      cancelHandler();
    }
    console.log("form submitted");
  };
  const cancelHandler = () => {
    navigate(`/`);
  };
  useEffect(() => {
    document.body.click();
    getUserById(user.UserId);
    return {};
  }, [user.UserId]);
  useEffect(() => {
    console.log(localVal);
  }, [localVal]);
  return (
    <div>
      <div className="page-title p-3 pl-4">
        <h3>{props.t("Profile")}</h3>
      </div>
      <div className="container my-3  ">
        <div className="row container-bg employeeForm">
          <div className="col-md-4">
            <Control.FirstNameEnglish props={props} handleChange={handleChange} value={localVal.nameEn} isDisable={!validation.isMinistryUser()} />
          </div>
          <div className="col-md-4">
            <Control.LastNameEnglish props={props} handleChange={handleChange} value={localVal.lastNameEn} isDisable={!validation.isMinistryUser()} />
          </div>
          <div className="col-md-4">
            <Control.FirstNameArabic props={props} handleChange={handleChange} value={localVal.nameAr} isDisable={!validation.isMinistryUser()} />
          </div>
          <div className="col-md-4">
            <Control.LastNameArabic props={props} handleChange={handleChange} value={localVal.lastNameAr} isDisable={!validation.isMinistryUser()} />
          </div>
          <div className="col-md-4">
            <Control.UserEmail value={localVal.email} handleChange={handleChange} props={props} isDisable={true} />
          </div>
          <div className="col-md-4">
            <Control.UserMobileNumber handleChange={handleChange} value={localVal.mobileNo} props={props} isDisable={!validation.isMinistryUser()} />
          </div>
          <div className="col-md-4">
            <Control.UserFaxNumber handleChange={handleChange} value={localVal.fax} props={props} isDisable={!validation.isMinistryUser()} />
          </div>
          <div className="col-md-4">
            <Control.UserEmiratesID props={props} handleChange={handleChange} value={localVal.reporterEID} isDisable={true} />
          </div>
          <div className="col-md-4">
            <Control.UserPassportNo props={props} handleChange={handleChange} value={localVal.passportNo} isDisable={true} />
          </div>
          {user.UserAccountType !== enums.UserAccountTypeEnum.NonGovernmentUser && (
            <>
              <div className="col-md-4">
                <Control.DepartmentInput initialState={localVal} changeControl={handleChange} isDisable={user.Department} />
              </div>
              <div className="col-md-4">
                <Control.OrgUnitTypeLkpControl
                  props={props}
                  changeControl={handleChange}
                  localVal={localVal.UnitTypeCode}
                  departmentId={localVal.DepartmentId}
                  isDisable={true}
                />
              </div>
              <div className="col-md-4">
                <Control.GovtEntityLkpControl
                  unityType={localVal.UnitTypeCode}
                  departmentId={localVal.DepartmentId}
                  changeControl={handleChange}
                  localVal={localVal.GovtEntityId}
                  isDisable={true}
                />
              </div>
            </>
          )}

          {validation.isMinistryUser() && (
            <div className="col-md-4 m-4">
              <button className="btn btn-success ml-2 mr-2 " type="button" onClick={submitHandler}>
                {props.t("update")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
