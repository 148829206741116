import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { auth, dashboardAction, validation } from "../../Imports";
import BoxUI from "../Shared/BoxUI";

const StatusCount = (props) => {
  const emirateId = validation.getUserEmirateId();
  const [statusCount, setStatusCount] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const user = auth.getUserPermission();
  const loadComLkp = useCallback(async () => {
    setStatusCount((await dashboardAction.getCountByStatus(user.Department, emirateId)).data.ResponseData);
  }, []);

  useEffect(() => {
    setTotalCount(
      (statusCount?.New ?? 0) +
        (statusCount?.Closed ?? 0) +
        (statusCount?.Confirmed ?? 0) +
        (statusCount?.FollowUp ?? 0) +
        (statusCount?.FinallyClosed ?? 0) +
        (statusCount?.Rejected ?? 0) +
        (statusCount?.Circulated ?? 0)
    );
  }, [statusCount]);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);

  return (
    <>
      <BoxUI title={`${props.t("NewIncident")}`} link="/IncidentDetail/FoodSafetyIncident/New" count={statusCount?.New ?? 0} />
      <BoxUI title={`${props.t("ClosedIncident")}`} link="/IncidentDetail/FoodSafetyIncident/Closed" count={statusCount?.Closed ?? 0} />
      <BoxUI title={`${props.t("ConfirmedIncident")}`} link="/IncidentDetail/FoodSafetyIncident/Confirmed" count={statusCount?.Confirmed ?? 0} />
      <BoxUI title={`${props.t("FollowUpIncident")}`} link="/IncidentDetail/FoodSafetyIncident/FollowUp" count={statusCount?.FollowUp ?? 0} />
      <BoxUI title={`${props.t("FinallyClosedIncident")}`} link="/IncidentDetail/FoodSafetyIncident/FinallyClosed" count={statusCount?.FinallyClosed ?? 0} />
      <BoxUI title={`${props.t("RejectedIncident")}`} link="/IncidentDetail/FoodSafetyIncident/Rejected" count={statusCount?.Rejected ?? 0} />
      <BoxUI title={`${props.t("CirculatedIncident")}`} link="/IncidentDetail/FoodSafetyIncident/Circulated" count={statusCount?.Circulated ?? 0} />
      <BoxUI title={`${props.t("TotalIncidents")}`} link="/IncidentDetail/FoodSafetyIncident/Circulated" count={totalCount ?? 0} />
    </>
  );
};

export default StatusCount;
