import React from "react";
import { useSelector } from "react-redux";
import { enums, iAgrculturePest, validation } from "../../Imports";
import QuestionWithYesNo from "../AnimalHealth/InvestigationDetail/QuestionWithYesNo";

const OtherDetail = (props) => {
  const transaction = useSelector((state) => state.transaction);
  return (
    <>
      {validation.isUserLoggedIn() && (
        <div className="container   my-2 otherDetailForm">
          <div className="row container-bg">
            <div className="col row">
              <QuestionWithYesNo
                controlName="Importance"
                label="Importance"
                list={[enums.ImportanceEnum.High, enums.ImportanceEnum.Low]}
                onchangeRadio={(e) =>
                  props.handleChange(
                    e.target.name,
                    e.target.value,
                    iAgrculturePest.AP_Constants.APT
                  )
                }
                value={transaction[iAgrculturePest.AP_Constants.APT].Importance}
                {...props}
              />
            </div>
            <div className="col row">
              <QuestionWithYesNo
                controlName="VisitRequired"
                label="VisitRequired"
                list={[enums.YesNoEnum.Yes, enums.YesNoEnum.No]}
                onchangeRadio={(e) =>
                  props.handleChange(
                    e.target.name,
                    e.target.value,
                    iAgrculturePest.AP_Constants.APT
                  )
                }
                value={
                  transaction[iAgrculturePest.AP_Constants.APT].VisitRequired
                }
                {...props}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OtherDetail;
