import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { iIncident, enums, validation } from "../../Imports";

const AddButton = (props) => {
  const [status, setStatus] = useState(true);
  const transaction = useSelector((state) => state.transaction);
  useEffect(() => {
    setStatus(
      transaction[iIncident.SharedDTO.IT].StatusCode ===
        enums.WorkFlowStatusCodeEnum.New.toString() || validation.isEmployee()
    );
  }, [transaction.isUpdate]);
  return (
    <>
      {status && (
        <button
          className="btn btn-success"
          type="button"
          onClick={props.submitHandler}
        >
          {props.t("add")}
        </button>
      )}
    </>
  );
};

export default AddButton;
