import { DispatchTypes } from "../DispatchType";

const InitialState = {
  registerUser: null,
  isEmirateIDExist: null,
  isPassportExist: null,
  isEmailExist: null,
  error: null,
  userProfileData: {},
};

const AccountReducer = (state = InitialState, action) => {
  switch (action.type) {
    case DispatchTypes.REGISTER:
      return {
        ...state,
        registerUser: action.registerUser,
      };
    case DispatchTypes.IS_EMAIL_EXSIT:
      return { ...state, isEmailExist: action.isEmailExist };
    case DispatchTypes.IS_EMIRATEID_EXSIT:
      return { ...state, isEmirateIDExist: action.isEmirateIDExist };
    case DispatchTypes.IS_PASSPORT_EXSIT:
      return { ...state, isPassportExist: action.isPassportExist };
    case DispatchTypes.USER_PROFILE:
      return {
        ...state,
        userProfileData: action.userProfileData,
      };
    default:
      return state;
  }
};

export default AccountReducer;
