import React from "react";
import GISMap from "./GIS/GISMap";
import General from "./Dashboard/General";
import MonthlyDashboard from "./Dashboard/MonthlyDashboard";

const DisasterManagement = (props) => {
  return (
    <div>
      <div className="row d-flex flex-wrap">
        {/* <GISMap {...props} /> */}

        <div>
          <General {...props} />
          <MonthlyDashboard {...props} />
        </div>
      </div>
    </div>
  );
};

export default DisasterManagement;
