export const EnIncident_Constants = {
  EIT: "EnvironmentalIncidentTransactionDTO",
};

export class EnvironmentalIncidentTransactionDTO {
  constructor(IncidentsTransactionId, IncidentsTransaction, Details) {
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.IncidentsTransaction = IncidentsTransaction;
    this.Details = Details;
  }
}
export const IncidentTransactionState = {
  EnvironmentalIncidentTransactionDTO: new EnvironmentalIncidentTransactionDTO(),
};
