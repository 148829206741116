export class FoodSafetyBaseDTO {
  constructor(
    BorderPortId,
    GovernmentalEntityLkp,
    ShipmentNo,
    ExporterName,
    ExporterAddress,
    ContainerNo,
    ItemsCount,
    TotalAmount,
    ImporterName,
    ImporterAddress,
    ExportCountryId,
    TransportMediaId
  ) {
    this.BorderPortId = BorderPortId;
    this.GovernmentalEntityLkp = GovernmentalEntityLkp;
    this.ShipmentNo = ShipmentNo;
    this.ExporterName = ExporterName;
    this.ExporterAddress = ExporterAddress;
    this.ContainerNo = ContainerNo;
    this.ItemsCount = ItemsCount;
    this.TotalAmount = TotalAmount;
    this.ImporterName = ImporterName;
    this.ImporterAddress = ImporterAddress;
    this.ExportCountryId = ExportCountryId;
    this.TransportMediaId = TransportMediaId;
  }
}
export class FoodSafetyProductInfoBaseDTO {
  constructor(
    ProductName,
    ProductGroupId,
    ProductMainCategory,
    ProductSubCategory1,
    ProductSubCategory2,
    ProductSubCategory3,
    ProductTradeName,
    Volume,
    PackageType,
    BatchNumber,
    ProductProductionDate,
    ProductExpiryDate,
    OtherInfo,
    FactoryName,
    FactoryAddress,
    FactoryOrginId,
    CountryLkp
  ) {
    this.ProductName = ProductName;
    this.ProductGroupId = ProductGroupId;
    this.ProductMainCategory = ProductMainCategory;
    this.ProductSubCategory1 = ProductSubCategory1;
    this.ProductSubCategory2 = ProductSubCategory2;
    this.ProductSubCategory3 = ProductSubCategory3;
    this.ProductTradeName = ProductTradeName;
    this.Volume = Volume;
    this.PackageType = PackageType;
    this.BatchNumber = BatchNumber;
    this.ProductProductionDate = ProductProductionDate;
    this.ProductExpiryDate = ProductExpiryDate;
    this.OtherInfo = OtherInfo;
    this.FactoryName = FactoryName;
    this.FactoryAddress = FactoryAddress;
    this.FactoryOrginId = FactoryOrginId;
    this.CountryLkp = CountryLkp;
  }
}
export class FoodSafetyRiskInfoBaseDTO {
  constructor(
    RiskSourceId,
    RiskSourceLkp,
    RiskSourceGroupId,
    RiskSourceGroupLkp,
    HazardMain,
    HazardSub,
    HazardDescription,
    LabName,
    LabAddress,
    LabTestType,
    LabTestDate,
    SampleCount,
    SampleLocation,
    LabTestResult,
    LegalReference,
    ExtraLegalReference
  ) {
    this.RiskSourceId = RiskSourceId;
    this.RiskSourceLkp = RiskSourceLkp;
    this.RiskSourceGroupId = RiskSourceGroupId;
    this.RiskSourceGroupLkp = RiskSourceGroupLkp;
    this.HazardMain = HazardMain;
    this.HazardSub = HazardSub;
    this.HazardDescription = HazardDescription;
    this.LabName = LabName;
    this.LabAddress = LabAddress;
    this.LabTestType = LabTestType;
    this.LabTestDate = LabTestDate;
    this.SampleCount = SampleCount;
    this.SampleLocation = SampleLocation;
    this.LabTestResult = LabTestResult;
    this.LegalReference = LegalReference;
    this.ExtraLegalReference = ExtraLegalReference;
  }
}
