import React from "react";
import BoxUI from "../../Shared/BoxUI";
import { useCallback } from "react";
import { useState } from "react";
import { envDisasterAction, util } from "../../../Imports";
import { useEffect } from "react";

const General = (props) => {
  const [statusCount, setStatusCount] = useState([]);
  const loadComLkp = useCallback(async () => {
    setStatusCount((await envDisasterAction.GetGeneralInfo()).data.ResponseData);
  }, []);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div>
      <div className="row d-flex flex-wrap">
        <BoxUI title={`${props.t("TotalCasesSinceStartOfSystem")}`} link="#" count={statusCount?.totalIncidentCount ?? 0} />
        <BoxUI
          title={`${props.t("NewCasesForTheMonth")} - ${util.CurrentMonthYearDisplay(new Date().getMonth() - 1)}`}
          link="#"
          count={statusCount?.totalCurrentMonth ?? 0}
        />
        <BoxUI title={`${props.t("OpenIncidents")}`} link="#" count={statusCount?.openIncidents ?? 0} />
        <BoxUI title={`${props.t("ClosedIncidents")}`} link="#" count={statusCount?.closeIncidents ?? 0} />
        <BoxUI title={`${props.t("HighRiskIncidents")}`} link="#" count={statusCount?.dangerRisk ?? 0} />
      </div>
    </div>
  );
};

export default General;
