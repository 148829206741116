export class AttachmentDTO {
  constructor(id, fileObject) {
    this.id = id;
    this.fileObject = fileObject;
  }
}
export const SystemEmailEnum = {
  AdministrationManager: "AdministrationManager",
  RegionalSpecial: "RegionalSpecial",
  EnvironmentalCompliance: "EnvironmentalCompliance",
};

export const DashboardType = {
  FoodSecurity: "FoodSecurity",
  Animal: "Animal",
  General: "General",
  Agriculture: "Agriculture",
  DisasterManagement: "DisasterManagement",
};
export const BrowserStoreKey = {
  InboxType: "biosec_search_type",
};

export const ALVIncidentTypeEnum = {
  AW: "AW",
  PoDA: "PoDA",
  VP: "VP",
  VF: "VF",
};
export const SearchCriteriaEnum = {
  IncidentSearch: "IncidentSearch",
  MyIncidents: "MyIncidents",
  MyTask: "MyTask",
  CirculatedIncident: "CirculatedIncident",
};

export const InfectionDetailGroupEnum = {
  Default: "Default",
  InfectedClinicallyAnimalsDetails: "InfectedClinicallyAnimalsDetails",
  DetailsOfAnimalsSusceptibleToInfection: "DetailsOfAnimalsSusceptibleToInfection",
  DetailsOfAnimalsWithSuspectedInfection: "DetailsOfAnimalsWithSuspectedInfection",
  DetailsOfTotalAnimalsInPossession: "DetailsOfTotalAnimalsInPossession",
};
export const InfectionTypeEnum = {
  Suspected: "Suspected",
  Confirmed: "Confirmed",
};
export const YesNoEnum = {
  Yes: "Yes",
  No: "No",
};
export const ImportanceEnum = {
  High: "High",
  Low: "Low",
};
export const VaccinationEnum = {
  Fully: "FullyVaccination",
  Ring: "RingVaccination",
};
export const TestTypeEnum = {
  Quick: "Quick",
  Confirmed: "Confirmed",
};
export const GenderEnum = {
  Male: "Male",
  Female: "Female",
  Other: "Other",
  MaleAndFemale: "MaleAndFemale",
};
export const WorkFlowStatusCodeEnum = {
  New: "New",
  Rejected: "Rejected",
  Confirmed: "Confirmed",
  Circulated: "Circulated",
  Closed: "Closed",
  FinallyClosed: "FinallyClosed",
  Commented: "Commented",
  Notified: "Notified",
  Announced: "Announced",
  CirculatedGlobally: "CirculatedGlobally",
  CirculatedLocally: "CirculatedLocally",
  CirculatedLocallyAndGlobally: "CirculatedLocallyAndGlobally",
  Draft: "Draft",
  Final: "Final",
  Following: "Following",
  FollowUp: "FollowUp",
  Initial: "Initial",
  Inspected: "Inspected",
  NoResponse: "NoResponse",
  Resolved: "Resolved",
  Unchecked: "Unchecked",
  WaitingLabTest: "WaitingLabTest",
  Deleted: "Deleted",
};
export const WorkFlowActionCodeEnum = {
  Accept: "Accept",
  Approve: "Approve",
  Confirm: "Confirm",
  RejectIt: "RejectIt",
  Save: "Save",
  Submit: "Submit",
  Redirect: "Redirect",
  FollowUp: "FollowUp",
  Close: "Close",
  ReOpen: "ReOpen",
  Final: "Final",
  FinallyClosed: "FinallyClosed",
  LabTestRequired: "LabTestRequired",
  Circulate: "Circulate",
  Delete: "Delete",
  Positive: "Positive",
  Negative: "Negative",
  CirculateInternal: "CirculateInternal",
  WriteCommentOnly: "WriteCommentOnly",
  Transfer: "Transfer",
};
export const AnimalCatagoryEnum = {
  DomesticAnimals: "1",
  WildAnimals: "2",
};
export const AnimalTypeEnum = {
  Other: "Others",
};

export const OwnerShipTypeEnum = {
  Farm: 2,
  Establishment: 3,
  AnimalPark: 4,
  AnimalKennelAndCattery: 5,
  NaturalHeritage: 6,
  Slaughterhouse: 7,
  BreedingCenter: 8,
  AnimalShop: 9,
  AnimalMarket: 10,
  Others: 11,
};
export const FoodActionTakenEnum = {
  Others: "16",
};
export const ClinicalSymptomsEnum = {
  Others: "26",
};
export const AnimalDiseaseEnum = {
  Others: "1",
};
export const PlantTypeEnum = {
  Others: "38",
};
export const AttachmentEnum = {
  DocumentLibrary: "DocumentLibrary",
  EpidemiologicalReport: "EpidemiologicalReport",
  LabTestReport: "LabTestReport",
  ProductPhoto: "ProductPhoto",
  HealthCertificate: "HealthCertificate",
  DistributionList: "DistributionList",
  Other: "Other",
};

export const DepartmentEnum = {
  AnimalDevelopmentAndHealthDepartment: 1,
  AgriculturalDevelopmentAndHealthDepartment: 2,
  FoodSafetyDepartment: 3,
  EnvironmentalDisasterManagement: 4,
  AbuDhabiAgricultureFoodSafetyAuthority: 5,
  DubaiMunicipality: 6,
  SharjahMunicipality: 7,
  FujairahMunicipality: 8,
  RasAlKhaimaMunicipality: 9,
  SystemAdmin: 10,
  CallCenterDepartment: 11,
  WasteManagementDepartment: 12,
};
export const GovtEntityEnum = {
  MOCCAE: 71,
  CallCenter: 78,
};
export const IncidentTypeEnum = {
  AnimalHealth: "AnimalHealth",
  AnimalLegalViolation: "AnimalLegalViolation",
  AnimalSharedDisease: "AnimalSharedDisease",
  AnimalBorderGate: "AnimalBorderGate",
  AgriculturePestIncident: "AgriculturePestIncident",
  AgricultureBorderGate: "AgricultureBorderGate",
  FoodSafetyIncident: "FoodSafetyIncident",
  FoodSafetyBorderGate: "FoodSafetyBorderGate",
  EnvironmentalIncident: "EnvironmentalIncident",
};
export const UserAccountTypeEnum = {
  GovernmentalUser: "GovernmentalUser",
  NonGovernmentUser: "NonGovernmentUser",
  MinistryUser: "MinistryUser",
  BorderUser: "BorderUser",
  DisasterOperation: "DisasterOperation",
  Consumer: "Consumer",
  FoodStuff: "FoodStuff",
  CentralPoint: "CentralPoint",
  SubPoint: "SubPoint",
};
export const UserAccountTypeEnum_DB = {
  GovernmentalUser: "GovernmentalUser",
  MinistryUser: "MinistryUser",
  BorderUser: "BorderUser",
  CentralPoint: "CentralPoint",
  SubPoint: "SubPoint",
};
export const NonGovUserTypeEnum = {
  Public: "Public",
  PlantAnimalFarm: "PlantAnimalFarm",
  FoodFacility: "FoodFacility",
  HealthFacility: "HealthFacility",
  AgrcultureCompany: "AgrcultureCompany",
  NotRegister: "NotRegister",
};
export const UnitTypeEnum = {
  FocalPoint: "FocalPoint",
  SubUnit: "SubUnit",
  MinistryUser: "MinistryUser",
  OperationCenterUser: "OperationCenterUser",
  GovernmentEntity: "GovernmentEntity",
  HealthFacility: "HealthFacility",
  BorderGate: "BorderGate",
  AgricultureDepartment: "AgricultureDepartment",
  SystemAdministrator: "SystemAdministrator",
};
export const FSNotificationTypeEnum = {
  ToBeInformed: 1,
  Notification: 2,
  FollowUp: 3,
};
export const EmiratesCode = {
  DXB: 1,
  AUD: 2,
  SHJ: 3,
  AJM: 4,
  RAK: 5,
  FUJ: 6,
  UAQ: 7,
};
export const Colors = {
  blue: "#36a2eb",
  red: "#ff6384",
  green: "#4bc0c0",
  orange: "#ff9f40",
  purple: "#9966ff",
  gray: "#c9cbcf",
  yellow: "#f4e052",
};
