import React, { useState } from "react";
import { enums, validation, transAction, iIncident } from "../../Imports";
import { useDispatch, useSelector } from "react-redux";
import EvnAttachment from "./Evn_Attachment";
import EnvGPSLocation from "./Env_GPSLocation";
import ServiceDetail from "./ServiceDetail";
import EmirateGovEntity from "../Common/LocationDetail/EmirateGovEntity";

const Detail = (props) => {
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const [showCommentBox, setShowCommentBox] = useState(true);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };
  const emailHandleChange = (name, value, state) => {
    handleChange(name, value, state);
    handleChange("NameEn", "Guest User", state);
    handleChange("NameAr", "Guest User", state);
  };
  const passFileInfoToComponent = (file) => {
    console.log("file:" + file);
    if (file.get("files").type.match("image.*")) {
      setShowCommentBox(true);
    }
  };
  return (
    <>
      <table className="table  ">
        <tr>
          <td className="font-gray">
            {props.t("attachments")} <span className="red">*</span>
          </td>
          <td>
            <EvnAttachment
              required={false}
              topic=" "
              handleChangeDTO={handleChangeDTO}
              code={enums.AttachmentEnum.Other}
              canUploadFile={validation.isGovtUser()}
              passFileInfoToComponent={passFileInfoToComponent}
              {...props}
            />
          </td>
        </tr>
        {showCommentBox && (
          <tr>
            <td className="font-gray">{props.t("Comments")}</td>
            <td>
              <ServiceDetail {...props} handleChange={handleChange} />
            </td>
          </tr>
        )}
        <tr>
          <td className="font-gray">
            {props.t("incidentLocation")}
            <span className="red">*</span>
          </td>
          <td>
            <EnvGPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} hideLatLngTextBox={true} />
            <div style={{ display: "none" }}>
              <EmirateGovEntity
                {...props}
                unitType={enums.UnitTypeEnum.GovernmentEntity}
                department={enums.DepartmentEnum.EnvironmentalDisasterManagement}
                isDisabled={true}
                value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
              />
            </div>
          </td>
        </tr>
        {/* <tr>
          <td className="font-gray">{props.t("email")}</td>
          <td>
            <FormInput
              name="Email"
              type="email"
              label=" "
              disabled={false}
              errorMessage={props.t("email") + props.t("incorrect")}
              required={false}
              value={transaction[iIncident.SharedDTO.UP].Email}
              onChange={(e) => emailHandleChange(e.target.name, e.target.value, iIncident.SharedDTO.UP)}
            />
          </td>
        </tr> */}
      </table>
    </>
  );
};

export default Detail;
