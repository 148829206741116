import React from "react";
import FormInput from "../../Common/Validation/FormInput";

function ExtraDataPlantAnimalFarm(props) {
  const inputs = [
    {
      id: 0,
      name: "ownershipNumber",
      type: "text",
      placeholder: "",
      errorMessage: props.t("enter") + props.t("ownershipNumber"),
      label: props.t("ownershipNumber"),
      fontIcon: "",
    },
    {
      id: 1,
      name: "ownershipType",
      type: "text",
      placeholder: "",
      errorMessage: props.t("enter") + props.t("ownershipType"),
      label: props.t("ownershipType"),
      fontIcon: "",
    },
    {
      id: 2,
      name: "farmOwnerName",
      type: "text",
      placeholder: "",
      errorMessage: props.t("enter") + props.t("farmOwnerName"),
      label: props.t("farmOwnerName"),
      fontIcon: "",
    },
    {
      id: 3,
      name: "farmOwnerEmail",
      type: "email",
      placeholder: "",
      errorMessage: props.t("enter") + props.t("farmOwnerEmailAddress"),
      label: props.t("farmOwnerEmailAddress"),
      fontIcon: "",
    },
  ];

  return (
    <div>
      <div className="row farmdata">
        <div className="col-6">
          <FormInput
            key={inputs[0].id}
            {...inputs[0]}
            value={props.values[inputs[0].name]}
            onChange={props.handleChange}
          />
        </div>
        <div className="col-6">
          <FormInput
            key={inputs[1].id}
            {...inputs[1]}
            value={props.values[inputs[1].name]}
            onChange={props.handleChange}
          />
        </div>
      </div>
      <div className="row farmdata">
        <div className="col-6">
          <FormInput
            key={inputs[2].id}
            {...inputs[2]}
            value={props.values[inputs[2].name]}
            onChange={props.handleChange}
          />
        </div>
        <div className="col-6">
          <FormInput
            key={inputs[3].id}
            {...inputs[3]}
            value={props.values[inputs[3].name]}
            onChange={props.handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ExtraDataPlantAnimalFarm;
