import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import AccountReducer from "./Reducers/Account";
import AnimalHealthReducer from "./Reducers/AnimalHealth";
import AuthReducer from "./Reducers/Auth";
import CommonReducer from "./Reducers/Common";
import ErrorReducer from "./Reducers/Error";
import EservicesDataReducer from "./Reducers/Eservices";
import MasterDataReducer from "./Reducers/MasterData";
import NotificationReducer from "./Reducers/Notification";
import TransactionReducer from "./Reducers/Transaction";

const rootReducer = combineReducers({
  auth: AuthReducer,
  errors: ErrorReducer,
  masterData: MasterDataReducer,
  account: AccountReducer,
  common: CommonReducer,
  eservices: EservicesDataReducer,
  // animalHealth: AnimalHealthReducer,
  notification: NotificationReducer,
  transaction: TransactionReducer,
});

export const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(thunk))
);
