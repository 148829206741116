import axios from "axios";
import { URLs } from "../../Constants/Common";

export const getWorkFlowActions = async (incidentTypeCode, statusCode, condition) => {
  return await axios.get(URLs.getWorkFlowActions + `?incidentTypeCode=${incidentTypeCode}&statusCode=${statusCode}&condition=${condition}`);
};
export const addWorkFlowActions = async (data) => {
  const res = await axios.post(URLs.AddWorkFlowActions, data);
  if (res.data.IsSuccess) {
    return res.data.ResponseData;
  }
};
export const deleteWorkFlowActions = async (id) => {
  const res = await axios.delete(URLs.DeleteWorkFlowActions + "?id=" + id);
  if (res.data.IsSuccess) {
    return res.data.ResponseData;
  }
};
