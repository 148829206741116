import axios from "axios";
import { URLs } from "../../Constants/Common";

export const createAnimalSharedDisease = async (data) => {
  return await axios.post(URLs.CreateAnimalSharedDisease, data);
};
export const updateAnimalSharedDisease = async (data) => {
  return await axios.post(URLs.UpdateAnimalSharedDisease, data);
};
export const createFollowUpAnimalSharedDisease = async (data) => {
  return await axios.post(URLs.CreateFollowUpAnimalSharedDisease, data);
};

export const getAnimalSharedDiseaseTransactionById = async (id) => {
  return await axios.get(
    URLs.getAnimalSharedDiseaseTransactionById + "?id=" + id
  );
};
export const animalSharedDiseaseUploadFiles = async (
  data,
  animalSharedDiseaseId
) => {
  return await axios.post(
    URLs.AnimalSharedDiseaseUploadFiles + animalSharedDiseaseId,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const deleteAnimalSharedDiseaseFiles = async (
  animalSharedDiseaseId,
  fileId
) => {
  return await axios.delete(
    `${URLs.DeleteAnimalSharedDiseaseFiles}?FileId=${fileId}&animalSharedDiseaseId=${animalSharedDiseaseId}`
  );
};
