import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Report from "../../Incidents/Inbox/ReportControl";
import { enums, incidentRedirectionAction, master, swal, toast, validation } from "../../Imports";

const Filter = (props) => {
  const dispatch = useDispatch();
  const [localVal, setLocalVal] = useState(props.searchData);
  const UserTypeAccountData = useSelector((state) => state.masterData.UserAccountType);
  const changeControl = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const name = e.target.name;
    let value = e.target.value;
    if (name === "UserAccountType") {
      setLocalVal((p) => ({
        ...p,
        [name]: value,
        UserAccountTypeId: e.nativeEvent.target[index].getAttribute("data-code"),
      }));
    }
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const getDropdownData = useCallback(async () => {
    dispatch(master.getUserAccountType());
  }, [dispatch]);

  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);

  useEffect(() => {
    setLocalVal(props.searchData);
  }, [props.isSearchChanged]);

  const SearchHandler = async (e) => {
    e.preventDefault();
    console.log(localVal);
    props.onChangeSearch(localVal);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = validation.validateForm("incidentRedirectionForm");
    if (!form) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    const res = await incidentRedirectionAction.createIncidentRedirection(localVal);
    if (res) {
      swal(props.t("success"), props.t("submitSuccessFully"), "success");
      props.onChangeSearch(props.searchData);
      setLocalVal(props.searchData);
    }
  };
  return (
    <div className="container my-2 incidentRedirectionForm">
      <div className="row container-bg">
        <div className="col-md-3 mt-1">
          <Report.IncidentTypeLkpControl localVal={localVal.IncidentTypeCode} changeControl={changeControl} isRequired={true} />
        </div>
        <div className="col-md-3 mt-1">
          <Report.UserAccountTypeLkpControl localVal={localVal.UserAccountType} changeControl={changeControl} data={UserTypeAccountData} isRequired={true} />
        </div>

        <div className="col-md-3 mt-1">
          <Report.EmirateLkpControl localVal={localVal.EmirateId} changeControl={changeControl} isRequired={true} />
        </div>

        {localVal.IncidentTypeCode === enums.IncidentTypeEnum.AnimalHealth && (
          <div className="col-md-3 mt-1">
            <Report.HoldingOwnerShipTypeLkpControl
              localVal={localVal.HoldingOwnerShipTypeId}
              changeControl={changeControl}
              isRequired={true}
              name={"HoldingOwnerShipTypeId"}
            />
          </div>
        )}
        {localVal.IncidentTypeCode === enums.IncidentTypeEnum.AnimalLegalViolation && (
          <div className="col-md-3 mt-1">
            <Report.LegalViolationTypeLkpControl
              localVal={localVal.ALVIncidentTypeId}
              name="ALVIncidentTypeId"
              changeControl={changeControl}
              isRequired={true}
            />
          </div>
        )}
        <div className="col-md-3 mt-1">
          <Report.GovtEntityLkpControl
            departmentEnum={enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment}
            localVal={localVal.SourceGovtEntityId}
            name={"SourceGovtEntityId"}
            emirateId={localVal.EmirateId}
            label={"sourceGovEntity"}
            changeControl={changeControl}
            isRequired={true}
          />
        </div>
        <div className="col-md-3 mt-1">
          <Report.GovtEntityLkpControl
            departmentEnum={enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment}
            localVal={localVal.TargetGovtEntityId}
            name={"TargetGovtEntityId"}
            emirateId={0}
            label={"targetGovEntity"}
            changeControl={changeControl}
            isRequired={true}
          />
        </div>

        <div className="col-md-3 mt-4">
          <button className="btn btn-success ml-2 mr-2 " type="button" onClick={submitHandler}>
            {props.t("add")}
          </button>
          <button className="btn btn-warning" type="button" onClick={SearchHandler}>
            {props.t("search")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
