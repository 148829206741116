import React from "react";
import {
  ColumnCreateDate,
  ColumnEmiratesName,
  ColumnGPSLocation,
  ColumnIncidentType,
  ColumnNotificationTypeName,
  ColumnProductName,
  ColumnStatus,
  DynamicLink,
  IsShowNotificationType,
  IsShowProductName,
} from "./SearchGrid";

const MyTaskGrid = (props) => {
  return (
    <div>
      <div>
        <table className="table table-striped table-hover text-center m-0">
          <thead>
            <tr>
              <th>{props.t("IncidentNumber")}</th>
              <th>{props.t("IncidentDate")}</th>
              <th>{props.t("incidnetType")} </th>
              <th>{props.t("IncidentStatus")} </th>
              <IsShowProductName props={props} />
              <IsShowNotificationType props={props} />
              <th>{props.t("emirate")} </th>
              <th>{props.t("incidentLocation")} </th>
            </tr>
          </thead>
          <tbody>
            {props.data.Incidents.map((incident, index) => {
              return (
                <tr key={index}>
                  <td>
                    <DynamicLink incident={incident} />
                  </td>
                  <td>
                    <ColumnCreateDate incident={incident} />
                  </td>
                  <td>
                    <ColumnIncidentType incident={incident} props={props} />
                  </td>
                  <td>
                    <ColumnStatus incident={incident} />
                  </td>
                  <ColumnProductName incident={incident} />
                  <ColumnNotificationTypeName incident={incident} />
                  <td>
                    <ColumnEmiratesName incident={incident} props={props} />
                  </td>
                  <td>
                    <ColumnGPSLocation trans={incident} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyTaskGrid;
