import React, { useCallback, useEffect, useState } from "react";
import FormInput, { SelectInput } from "../Common/Validation/FormInput";
import { auth, master, validation, enums, iSysConfig, util, swal } from "../Imports";
import { useNavigate, useParams } from "react-router-dom";
import { GovtEntityLkpControl } from "../Account/CommonControl";
import AddButton from "../Incidents/Common/AddButton";
import DeleteButton from "../Incidents/Common/DeleteButton";
import QuestionWithYesNo from "../Incidents/AnimalHealth/InvestigationDetail/QuestionWithYesNo";

const SystemConfiguration = (props) => {
  let { type } = useParams();
  console.log(type);
  const navigate = useNavigate();
  const user = auth.getUserPermission();
  const initialState = {
    TimeToConfirmIncident: "",
    TimeToReOpenIncident: "",
    RiskDegreeCode: "",
    ReporterEmail_Administrator: "",
    ReporterEmail_AreasSector: "",
    ReporterEmail_EnvCompliance: "",
    GovtEntityId: "",
    SystemEmailCode: "",
    GovtEntityName: "",
  };

  const [localVal, setLocalVal] = useState(initialState);
  const [riskDegreeData, setRiskDegreeData] = useState([]);
  const [adminEmailList, setAdminEmailList] = useState([]);
  const [validACL, setValidACL] = useState({
    Administrator: false,
    AreasSector: false,
    EnvCompliance: false,
    TimeToReOpenIncident: false,
  });

  const getSystemConfiguration = async (deptId) => {
    const getSysConfig = await master.getSystemConfiguration(deptId);
    if (getSysConfig) {
      console.log(getSysConfig.ResponseData.SystemEmailConfigurationDTO);
      console.log(getSysConfig.ResponseData.SystemGeneralConfigurationDTO);
      var gc = getSysConfig.ResponseData.SystemGeneralConfigurationDTO;
      var ec = getSysConfig.ResponseData.SystemEmailConfigurationDTO;

      if (gc) {
        setLocalVal((p) => ({
          ...p,
          TimeToConfirmIncident: gc.TimeToConfirmIncident,
          RiskDegreeCode: gc.RiskDegreeCode,
        }));
      }

      setAdminEmailList(
        ec.map(
          (x) =>
            new iSysConfig.SystemEmailConfigurationDTO(
              x.DepartmentId,
              x.ReporterEmail,
              x.GovtEntityId,
              x.GovernmentalEntityLkp ? util.getTextFromObj(x.GovernmentalEntityLkp) : "",
              x.SystemEmailCode,
              new Date().getTime() + x.Id
            )
        )
      );
    }
  };
  const loadComLkp = useCallback(async () => {
    const deptId = parseInt(user.Department);

    setValidACL((p) => ({
      ...p,
      Administrator: deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment,
      EnvCompliance: deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment,
      AreasSector: deptId !== enums.DepartmentEnum.EnvironmentalDisasterManagement,
      TimeToReOpenIncident: deptId === enums.DepartmentEnum.FoodSafetyDepartment,
    }));
    setRiskDegreeData(await master.getRiskDegreeLkp());
    await getSystemConfiguration(deptId);
  }, []);

  useEffect(() => {
    console.log(validACL);
  }, [validACL]);

  useEffect(() => {
    if (!validation.isAccessDocumentLib(user)) {
      navigate(`/error`);
    }
    loadComLkp();
  }, [loadComLkp]);

  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeGovtEntity = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      GovtEntityId: e.target.value,
      GovtEntityName: e.nativeEvent.target[index].text,
    }));
  };
  const AddAdministrationEmail = (e) => {
    e.preventDefault();
    const formName = "AdministratorEmailForm";
    if (!validation.validateForm(formName)) return;
    setAdminEmailList(
      adminEmailList.concat(
        new iSysConfig.SystemEmailConfigurationDTO(
          user.Department,
          localVal.ReporterEmail_Administrator,
          localVal.GovtEntityId,
          localVal.GovtEntityName,
          enums.SystemEmailEnum.AdministrationManager,
          new Date().getTime()
        )
      )
    );
    validation.resetFormByClassName(formName);
  };

  const AddEmailOfAreasSector = (e) => {
    e.preventDefault();
    const formName = "EmailOfAreasSectorSpecialistsForm";
    if (!validation.validateForm(formName)) return;
    setAdminEmailList(
      adminEmailList.concat(
        new iSysConfig.SystemEmailConfigurationDTO(
          user.Department,
          localVal.ReporterEmail_AreasSector,
          null,
          null,
          enums.SystemEmailEnum.RegionalSpecial,
          new Date().getTime()
        )
      )
    );
    validation.resetFormByClassName(formName);
  };
  const AddEnvironmentalCompliance = (e) => {
    e.preventDefault();
    const formName = "AddEnvironmentalComplianceForm";
    if (!validation.validateForm(formName)) return;
    setAdminEmailList(
      adminEmailList.concat(
        new iSysConfig.SystemEmailConfigurationDTO(
          user.Department,
          localVal.ReporterEmail_EnvCompliance,
          null,
          null,
          enums.SystemEmailEnum.EnvironmentalCompliance,
          new Date().getTime()
        )
      )
    );
    validation.resetFormByClassName(formName);
  };
  const deleteItem = (e) => {
    setAdminEmailList(adminEmailList.filter((x) => x.Uid !== e.Uid));
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (adminEmailList.length === 0) {
      return false;
    }

    const data = new iSysConfig.SystemConfigurationDTO(
      new iSysConfig.SystemGeneralConfigurationDTO(user.Department, null, localVal.TimeToConfirmIncident, localVal.RiskDegreeCode),
      adminEmailList
    );
    console.log(data);
    const resp = await master.createSystemConfiguration(data);
    if (resp.IsSuccess) {
      swal(props.t("success"), props.t("updatedSuccessFully"), "success");
    }
  };
  useEffect(() => {
    console.log(adminEmailList);
    console.log(localVal);
  }, [adminEmailList, localVal]);
  return (
    <div>
      <div className="container page-title p-3 pl-4 mb-3">
        <h3>{props.t("updateSystemSetting")}</h3>
      </div>
      <div className="container ">
        <div className="row container-bg">
          {validACL.TimeToReOpenIncident && (
            <div className="col-md-4">
              <FormInput
                name="TimeToReOpenIncident"
                type="number"
                label="TimeToReOpenIncident"
                required={true}
                disabled={false}
                value={localVal.TimeToReOpenIncident}
                onChange={changeControl}
              />
              <span className="label">{props.t("hour")}</span>
            </div>
          )}

          <div className="col-md-4">
            <FormInput
              name="TimeToConfirmIncident"
              type="number"
              label="TimeAllowedToConfirmIncident"
              required={true}
              disabled={false}
              value={localVal.TimeToConfirmIncident}
              onChange={changeControl}
            />
            <span className="label">{props.t("hour")}</span>
          </div>
          <div className="col-md-4">
            <SelectInput
              name="RiskDegreeCode"
              label="RiskDegree"
              required={true}
              disabled={false}
              value={localVal.RiskDegreeCode}
              onChange={changeControl}
              data={riskDegreeData}
            />
          </div>
        </div>
        {validACL.Administrator && (
          <div className="row container-bg AdministratorEmailForm">
            <strong className="mt-1 mb-3">{props.t("AdministratorEmail")}</strong>
            <div className="col-md-4">
              <FormInput
                name="ReporterEmail_Administrator"
                type="email"
                label="email"
                required={true}
                disabled={false}
                value={localVal.ReporterEmail_Administrator}
                onChange={changeControl}
              />
            </div>
            <div className="col-md-4">
              <GovtEntityLkpControl
                departmentId={user.Department}
                changeControl={changeGovtEntity}
                localVal={localVal.GovtEntityId}
                unityType={enums.UnitTypeEnum.GovernmentEntity}
                isDisable={false}
              />
            </div>
            <div className="col-md-3 mt-4">
              <AddButton {...props} submitHandler={AddAdministrationEmail} />
            </div>
            <div className="col-md-12 row">
              {adminEmailList
                .filter((x) => x.SystemEmailCode === enums.SystemEmailEnum.AdministrationManager)
                .map((e, i) => (
                  <div className="card" key={e.Uid + i}>
                    <div className="card-body row">
                      <div className="col-md-6">
                        <small className="golden">{props.t("email")}</small> :<small> {e.ReporterEmail}</small>
                      </div>
                      <div className="col-md-6">
                        <small className="golden">{props.t("govEntity")}</small>:<small> {e.GovtEntityName}</small>
                      </div>
                    </div>
                    <DeleteButton
                      {...props}
                      deleteHandler={() => {
                        deleteItem(e);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        {/* <div className="row container-bg EmailOfAreasSectorSpecialistsForm">
          <strong className="mt-1 mb-3">{props.t("EmailOfAreasSectorSpecialists")}</strong>
          <div className="col-md-8">
            <FormInput
              name="ReporterEmail_AreasSector"
              type="email"
              label="email"
              required={true}
              disabled={false}
              value={localVal.ReporterEmail_AreasSector}
              onChange={changeControl}
            />
          </div>

          <div className="col-md-3 mt-4">
            <AddButton {...props} submitHandler={AddEmailOfAreasSector} />
          </div>
          <div className="col-md-12 row">
            {adminEmailList
              .filter((x) => x.SystemEmailCode === enums.SystemEmailEnum.RegionalSpecial)
              .map((e, i) => (
                <div className="card" key={e.Uid + i}>
                  <div className="card-body row">
                    <div className="col-md-12">
                      <small className="golden">{props.t("email")}</small> :<small> {e.ReporterEmail}</small>
                    </div>
                  </div>
                  <DeleteButton
                    {...props}
                    deleteHandler={() => {
                      deleteItem(e);
                    }}
                  />
                </div>
              ))}
          </div>
        </div> */}
        {validACL.EnvCompliance && (
          <div className="row container-bg AddEnvironmentalComplianceForm">
            <strong className="mt-1 mb-3">{props.t("EmailOfEnvironmentalComplianceDepartment")}</strong>
            <div className="col-md-8">
              <FormInput
                name="ReporterEmail_EnvCompliance"
                type="email"
                label="email"
                required={true}
                disabled={false}
                value={localVal.ReporterEmail_EnvCompliance}
                onChange={changeControl}
              />
            </div>

            <div className="col-md-3 mt-4">
              <AddButton {...props} submitHandler={AddEnvironmentalCompliance} />
            </div>
            <div className="col-md-12 row">
              {adminEmailList
                .filter((x) => x.SystemEmailCode === enums.SystemEmailEnum.EnvironmentalCompliance)
                .map((e, i) => (
                  <div className="card" key={e.Uid + i}>
                    <div className="card-body row">
                      <div className="col-md-12">
                        <small className="golden">{props.t("email")}</small> :<small> {e.ReporterEmail}</small>
                      </div>
                    </div>
                    <DeleteButton
                      {...props}
                      deleteHandler={() => {
                        deleteItem(e);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-3 mt-4 mb-5">
            <button className="btn btn-success" onClick={SubmitForm}>
              {props.t("Submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemConfiguration;
