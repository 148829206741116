import React, { useCallback, useEffect, useState } from "react";
import { swal, master, iWorkFlow, workflowAction } from "../../Imports";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";

const WorkFlowSetting = (props) => {
  const initialState = {
    WorkFlowId: 0,
    PrevStateId: 0,
    NextStateId: 0,
    wfActionId: 0,
    Condition: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [workFlowData, setWorkFlowData] = useState([]);
  const [prevStateData, setPrevStateData] = useState([]);
  const [wfActionData, setWfActionData] = useState([]);
  const [workFlowActionsListData, setGetWorkFlowActionsListData] = useState([]);

  const loadComLkp = useCallback(async () => {
    setWorkFlowData(await master.getWorkFlowLkp());
    setPrevStateData(await master.getWorkFlowState());
    setWfActionData(await master.getWorkFlowActionLkp());
    setGetWorkFlowActionsListData(await master.getWorkFlowActionsList(1, 0, ""));
  }, []);

  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    loadComLkp();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await workflowAction.addWorkFlowActions(
      new iWorkFlow.WorkFlowActionsDTO(localVal.WorkFlowId, localVal.PrevStateId, localVal.wfActionId, localVal.NextStateId, localVal.Condition)
    );
    if (data) {
      swal(props.t("success"), props.t("submitSuccessFully"), "success");
    } else {
      swal("Warning", "Already exist", "warning");
    }
  };

  const onDelete = async (id) => {
    swal({
      title: props.t("AreYouSure"),
      text: props.t("DoYouWantToDelete"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const isDeleted = await workflowAction.deleteWorkFlowActions(id);
        if (isDeleted) {
          swal(props.t("success"), props.t("deletedSuccessFully"), "success");
        }
      }
    });
  };
  const handleSearch = async () => {
    setGetWorkFlowActionsListData(await master.getWorkFlowActionsList(localVal.WorkFlowId, localVal.PrevStateId, localVal.Condition));
  };
  return (
    <form id="regStep2Form" name="form1" className="current " onSubmit={handleSubmit}>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-2">
            <SelectInput
              name="WorkFlowId"
              label="WorkFlow"
              required={true}
              disabled={false}
              value={localVal.WorkFlowId}
              onChange={handleChange}
              data={workFlowData}
            />
          </div>
          <div className="col-md-2">
            <SelectInput
              name="PrevStateId"
              label="Previous State "
              required={true}
              disabled={false}
              value={localVal.PrevStateId}
              onChange={handleChange}
              data={prevStateData}
            />
          </div>
          <div className="col-md-2">
            <SelectInput
              name="wfActionId"
              label="WorkFlow Action"
              required={true}
              disabled={false}
              value={localVal.wfActionId}
              onChange={handleChange}
              data={wfActionData}
            />
          </div>
          <div className="col-md-2">
            <SelectInput
              name="NextStateId"
              label="Next State "
              required={true}
              disabled={false}
              value={localVal.NextStateId}
              onChange={handleChange}
              data={prevStateData}
            />
          </div>
          <div className="col-md-2">
            <FormInput name="Condition" label="Condition " required={true} value={localVal.Condition} onChange={handleChange} />
          </div>

          <div className="col-md-3 mt-4">
            <button className="btn btn-success ml-2 mr-2 " type="submit">
              {props.t("add")}
            </button>
            <button className="btn btn-success ml-2 mr-2 " type="button" onClick={handleSearch}>
              {props.t("search")}
            </button>
          </div>

          <div className="col-md-12 mt-5">
            <table className="table table-responsive table-bordered">
              <thead>
                <tr>
                  <th>WorkFlow</th>
                  <th>Previous Step</th>
                  <th>Action</th>
                  <th>Next Step</th>
                  <th> Condition</th>
                  <th> Action</th>
                </tr>
              </thead>
              <tbody>
                {workFlowActionsListData.length > 0 &&
                  workFlowActionsListData.map((x) => (
                    <tr key={x.Id}>
                      <td>{x.WorkFlow.NameEn}</td>
                      <td>{x.PreviousState.StatusCode}</td>
                      <td>{x.WFActionCode}</td>
                      <td>{x.NextState.StatusCode}</td>
                      <td>{x.Condition}</td>
                      <td>
                        <a
                          rel="noreferrer"
                          className="edit p-2 text-left"
                          onClick={() => {
                            onDelete(x.Id);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  );
};

export default WorkFlowSetting;
