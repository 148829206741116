import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { enums, encrypt, validation, auth, iIncident, transAction, swal, iAgrcultureBG, agrBGAction } from "../../Imports";
import { useDispatch, useSelector } from "react-redux";
import Attachment from "../Common/Attachment";
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import Reporter from "../Common/Reporter";
import GPSLocation from "../Common/LocationDetail/GPSLocation";
import EmirateDistrict from "../Common/LocationDetail/EmirateDistrict";
import ShipmentInitialData from "../Common/ShipmentInitialData";
import ImporterDetail from "./ImporterDetail";
import ShipmentDetail from "./ShipmentDetail";
import CropsDetail from "./CropsDetail";
import { isGovtUser } from "../Common/Utils/Validation";

const AgricultureBorderGate = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  followup = parseInt(encrypt.decrypt(followup));
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  useEffect(() => {
    // console.log(transaction);
  }, [transaction]);

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    try {
      const response = await agrBGAction.getTransactionById(id);
      if (response.data) {
        const res = response.data.ResponseData;
        handleChangeDTO(res, iAgrcultureBG.ABG_Constants.ABGT);
        handleChangeDTO(res.AgricultureBorderGateShipmentDetail, iAgrcultureBG.ABG_Constants.ABGSD);
        handleChangeDTO(res.AgricultureBorderGateImporterDetail, iAgrcultureBG.ABG_Constants.ABGID);
        handleChangeDTO(res.AgricultureBorderGateCropsDetail, iAgrcultureBG.ABG_Constants.ABDCD);
        await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);

        validation.enableDisableForm("gpsLocationFrom", true);
        validation.enableDisableForm("shipmentInitialDataFrom", true);
        validation.enableDisableForm("ImporterDetailForm", true);
        validation.enableDisableForm("ShipmentDetailsForm", true);
        validation.enableDisableForm("CropsDetailForm", true);

        props.setLoading(false);
      }
    } catch (ex) {
      console.error("ERROR:", ex);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iAgrcultureBG.AgricultureBorderGateTransactionDTO();
    data = transaction.AgricultureBorderGateTransactionDTO;
    data.AgricultureBorderGateShipmentDetail = transaction.AgricultureBorderGateShipmentDetailDTO;
    data.AgricultureBorderGateImporterDetail = transaction.AgricultureBorderGateImporterDetailDTO;
    data.AgricultureBorderGateCropsDetail = transaction.AgricultureBorderGateCropsDetailDTO;

    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.AgricultureBorderGate);

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }
    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await agrBGAction.create(data);
    else if (followup > 0) {
      res = await agrBGAction.createFollowUp(data);
    } else {
      res = await agrBGAction.update(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };

  const validateDetailForm_user = (e) => {
    const form1 = validation.validateForm("gpsLocationFrom", "CropsDetailForm");
    const form2 = validation.validateForm("shipmentInitialDataFrom", "CropsDetailForm");
    const form3 = validation.validateForm("ImporterDetailForm", "CropsDetailForm");
    const form4 = validation.validateForm("ShipmentDetailsForm", "CropsDetailForm");

    if (!form1 || !form2 || !form3 || !form4) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.AgricultureBorderGateCropsDetailDTO.length === 0) {
      toast(props.t("CropDetailsAreRequired"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (!validation.commonValidation(transaction, props)) {
      return false;
    }

    return true;
  };
  const formValidation = () => {
    return validateDetailForm_user();
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("agriculturalBorderGateIncident")}</h3>
            </div>
            <div className="col-sm-12  ">
              <Tabs defaultActiveKey="IncidentDetail">
                <Tab eventKey="IncidentDetail" title={props.t("IncidentDetail")}>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}

                    <div className="container ">
                      <div className=" row container-bg my-2 gpsLocationFrom">
                        <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                        <EmirateDistrict
                          emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                          isDisabled={false}
                          handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                          {...props}
                        />
                      </div>
                      <div className="shipmentInitialDataFrom">
                        <ShipmentInitialData
                          fsConst={iAgrcultureBG.ABG_Constants.ABGT}
                          handleChange={handleChange}
                          handleChangeDTO={handleChangeDTO}
                          {...props}
                        />
                      </div>
                      <div style={{ display: "display" }}>
                        <Reporter handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="ImporterDetail" title={props.t("ImporterDetail")}>
                  <div className="tab-item-wrapper">
                    <div className="container  ImporterDetailForm">
                      <div className="row container-bg my-2  ">
                        <ImporterDetail handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="ShipmentDetails" title={props.t("ShipmentDetails")}>
                  <div className="tab-item-wrapper">
                    <div className="container ShipmentDetailsForm">
                      <ShipmentDetail handleChange={handleChange} {...props} />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="TypesDetail" title={props.t("TypesDetail")}>
                  <div className="tab-item-wrapper">
                    <div className="CropsDetailForm">
                      <CropsDetail handleChangeDTO={handleChangeDTO} {...props} />
                    </div>
                    <Attachment required={true} handleChangeDTO={handleChangeDTO} code={enums.AttachmentEnum.Other} canUploadFile={isGovtUser()} {...props} />

                    {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                    <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgricultureBorderGate;
