import React, { useCallback, useEffect, useState } from "react";
import { getProperText } from "../../../Constants/Utils";
import ModalView from "../../Common/UI/ModalView";
import { incidentAction } from "../../Imports";

const CirculationUI = (props) => {
  const [circulationData, setCirculationData] = useState([]);
  const [circulationNotificationData, setCirculationNotificationData] = useState([]);
  const getCirculation = useCallback(async () => {
    const res = await incidentAction.getCirculationHistory(props.data);
    if (res.data && res.data.IsSuccess) {
      console.log("res.data.ResponseData", res.data.ResponseData);
      const result = res.data.ResponseData;
      setCirculationData(result.filter((x) => x.IsCircularNotification === false));
      setCirculationNotificationData(result.filter((x) => x.IsCircularNotification === true));
    }
  }, [props.data]);

  useEffect(() => {
    getCirculation();
  }, [props.data, getCirculation]);
  return (
    <div>
      <ModalView
        isOpen={props.showModal}
        handleShowModal={props.handleShowModal}
        title="Circulation"
        body={
          <div>
            <div className="container">
              <table className="table table-striped text-center m-0 my-incident app-log-table">
                <thead>
                  <tr>
                    <th className="table-header">{props.t("CirculationHistory")}</th>
                  </tr>
                </thead>
                <tbody>
                  {circulationData.filter((x) => x.IsCircularNotification === false).length > 0 &&
                    circulationData.map((e) => {
                      return (
                        <tr key={e.Id}>
                          <td colSpan={3}>{getProperText(e.GovernmentalEntityLkp.NameAr, e.GovernmentalEntityLkp.NameEn)}</td>
                        </tr>
                      );
                    })}
                  {circulationData.length === 0 && (
                    <tr>
                      <td colSpan={3}>{props.t("dataNotFound")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {circulationNotificationData.length !== 0 && (
                <table className="table table-striped text-center m-0 my-incident app-log-table">
                  <thead>
                    <tr>
                      <th className="table-header">{props.t("CirculationNotification")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {circulationNotificationData.length > 0 &&
                      circulationNotificationData.map((e) => {
                        return (
                          <tr key={e.Id}>
                            <td colSpan={3}>{getProperText(e.GovernmentalEntityLkp.NameAr, e.GovernmentalEntityLkp.NameEn)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        }
        footer={<div></div>}
      />
    </div>
  );
};

export default CirculationUI;
