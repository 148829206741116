export const RegistrationType = {
  public: "Public",
  AnimalPlant: "PlantAnimalFarm",
  FoodFacility: "FoodFacility",
  HealthFacility: "HealthFacility",
  AgrcultureCompany: "AgrcultureCompany",
  NotRegister: "NotRegister",
};

export default class MasterDataClass {
  constructor(Id, NameAr, NameEn, Code) {
    this.Id = Id;
    this.NameAr = NameAr;
    this.NameEn = NameEn;
    this.Code = Code;
  }
}

export class UserClass {
  constructor(email, password, UsersProfileDTO, PasswordUI, UUId, UAEPassEmail, UAEPassUserType) {
    this.Email = email;
    this.Password = password;
    this.PasswordUI = PasswordUI;
    this.UsersProfile = UsersProfileDTO ?? new UserRegistrationDTO();
    this.UUId = UUId;
    this.UAEPassEmail = UAEPassEmail;
    this.UAEPassUserType = UAEPassUserType;
  }
}
export class nonGovUserTypesLkpDTO {
  constructor(code, nameEn, nameAr) {
    this.Code = code;
    this.NameEn = "test";
    this.NameAr = "test";
  }
}
export class UserPlantAnimal {
  constructor(ownershipNumber, ownershipType, farmOwnerName, farmOwnerEmail) {
    this.OwnershipNumber = ownershipNumber;
    this.OwnershipType = ownershipType;
    this.FarmOwnerName = farmOwnerName;
    this.FarmOwnerEmail = farmOwnerEmail;
  }
}
export class UserVetClinic {
  constructor(healthFacilityName, healthFacilityAddress) {
    this.HealthFacilityName = healthFacilityName;
    this.HealthFacilityAddress = healthFacilityAddress;
  }
}
export class UserFoodFacility {
  constructor(facilityName, facilityPhoneNo, facilityEmail) {
    this.FacilityName = facilityName;
    this.FacilityPhoneNo = facilityPhoneNo;
    this.FacilityEmail = facilityEmail;
  }
}
export class UserRegistrationDTO {
  constructor(
    nameAr,
    nameEn,
    lastNameEn,
    lastNameAr,
    NonGovTypeCode,
    reporterEID,
    passportNo,
    mobileNo,
    email,
    fax,
    prefLanguage,
    userPlantAnimalDTO,
    UserVetClinicDTO,
    UserFoodFacilityDTO,
    fileAttachmentsDTO,
    ImageId
  ) {
    this.NameAr = nameAr ?? nameEn;
    this.NameEn = nameEn;
    this.LastNameEn = lastNameEn;
    this.LastNameAr = lastNameAr;
    this.NonGovTypeCode = NonGovTypeCode;
    this.ReporterEID = reporterEID;
    this.PassportNo = passportNo;
    this.MobileNo = mobileNo;
    this.Email = email;
    this.ImageId = ImageId;
    this.Fax = fax;
    this.PrefLanguage = prefLanguage;
    this.UserPlantAnimal = userPlantAnimalDTO;
    this.UserVetClinic = UserVetClinicDTO;
    this.UserFoodFacility = UserFoodFacilityDTO;
    this.FileAttachments = fileAttachmentsDTO;
  }
}
