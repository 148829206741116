import React, { useContext, useEffect, useState } from "react";
import { Link, UNSAFE_NavigationContext, useLocation } from "react-router-dom";
import { auth,util } from "../Imports";

const Footer = (props) => {
  const curr_date = new Date().getFullYear();
  const curr_appversion = auth.checkAppVersion();

  let location = useLocation();
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  const [isHeader, setIsHeader] = useState("block");
  useEffect(() => {
    const loginRoute = util.pagesWOLogin;
    const handleListener = (location, action) => {
      //console.log("listener", location);
      if (action === "POP") {
        if (loginRoute.indexOf(location.pathname.toLocaleLowerCase()) < 0) {
          setIsHeader("block");
        }
      }
    };

    const { pathname } = location;
    setIsHeader("block");
    if (loginRoute.indexOf(pathname.toLocaleLowerCase()) > -1) {
      setIsHeader("none");
    }
    return () => {
      handleListener(navigator.location, navigator.action);
    };
  }, [location, setIsHeader, navigator]);
  return (
    <div className="website-footer" style={{ display: isHeader }}>
      <div className="footer-container d-flex justify-content-between align-items-center m-4">
        <div className="footer-links d-flex justify-content-center">
          <Link to="/copyright" className="border-right mr-3 pr-3">
            {props.t("Copyright")}
          </Link>
          <Link to="/privacypolicy" className="border-right mr-3 pr-3">
            {props.t("PrivacyPolicy")}
          </Link>
          <Link
            to="/contactus"
            id="ctl00_ctl83_ctl00_lnk_contactUs"
            className="border-right mr-3 pr-3"
          >
            {props.t("ContactUs")}
          </Link>
        </div>
        <div className="footer-disclaimer text-center mt-2">
          {props.t("app_version", { curr_appversion })}
        </div>
        <div className="footer-disclaimer text-center mt-2 moccae_right_reserved">
          {props.t("moccae_right_reserved", { curr_date })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
