import {
  FoodSafetyBaseDTO,
  FoodSafetyProductInfoBaseDTO,
  FoodSafetyRiskInfoBaseDTO,
} from "./IFoodSafetyBase";

export const FS_Constants = {
  FST: "FoodSafetyTransactionDTO",
  FSPI: "FoodSafetyProductInfoDTO",
  FSRI: "FoodSafetyRiskInfoDTO",
  FSCD: "FoodSafetyCompanyDetailDTO",
  FSAT: "FoodSafetyActionTakenDTO",
  FSFU: "FoodSafetyFollowUpDTO",
};

export class FoodSafetyTransactionDTO extends FoodSafetyBaseDTO {
  constructor(
    IncidentsTransactionId,
    NotificationTypeId,
    IncidentDescription,
    FoodSafetyCompanyDetail,
    FoodSafetyProductInfo,
    FoodSafetyRiskInfo,
    FoodSafetyActionTaken,
    Circulate,
    CirculationToTakeActions
  ) {
    super();
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.NotificationTypeId = NotificationTypeId ?? null;
    this.IncidentDescription = IncidentDescription ?? "";
    this.FoodSafetyCompanyDetail = FoodSafetyCompanyDetail;
    this.FoodSafetyProductInfo = FoodSafetyProductInfo;
    this.FoodSafetyRiskInfo = FoodSafetyRiskInfo;
    this.FoodSafetyActionTaken = FoodSafetyActionTaken;
    this.Circulate = Circulate ?? "";
    this.CirculationToTakeActions = CirculationToTakeActions;
  }
}
export class FoodSafetyProductInfoDTO extends FoodSafetyProductInfoBaseDTO {
  constructor(
    FoodSafetyTransactionId,
    FoodSafetyTransaction,
    UnitId,
    UnitLkp,
    ArrivalDate,
    ProductDescription
  ) {
    super();
    this.FoodSafetyTransactionId = FoodSafetyTransactionId;
    this.FoodSafetyTransaction = FoodSafetyTransaction;
    this.UnitId = UnitId;
    this.UnitLkp = UnitLkp;
    this.ArrivalDate = ArrivalDate;
    this.ProductDescription = ProductDescription ?? "";
  }
}
export class FoodSafetyRiskInfoDTO extends FoodSafetyRiskInfoBaseDTO {
  constructor(MaxAllowedResult) {
    super();
    this.MaxAllowedResult = MaxAllowedResult;
  }
}
export class FoodSafetyCompanyDetailDTO {
  constructor(
    Id,
    FoodSafetyTransactionId,
    PhoneNo,
    FacilityName,
    FacilityPhoneNo,
    IncidentSource,
    FacilityResponsible,
    ActivityTypeId,
    ActivityTypeLkp,
    CountryId,
    Address,
    IncidentDate,
    DistributionArea,
    FacilityIncidentDescription
  ) {
    this.Id = Id ?? 0;
    this.FoodSafetyTransactionId = FoodSafetyTransactionId ?? 0;
    this.PhoneNo = PhoneNo ?? "";
    this.FacilityName = FacilityName ?? "";
    this.FacilityPhoneNo = FacilityPhoneNo ?? "";
    this.IncidentSource = IncidentSource ?? "";
    this.FacilityResponsible = FacilityResponsible ?? "";
    this.ActivityTypeId = ActivityTypeId ?? "";
    this.ActivityTypeLkp = ActivityTypeLkp ?? "";
    this.CountryId = CountryId ?? "";
    this.Address = Address ?? "";
    this.IncidentDate = IncidentDate;
    this.DistributionArea = DistributionArea ?? "";
    this.FacilityIncidentDescription = FacilityIncidentDescription ?? "";
  }
}
export class FoodSafetyActionTakenDTO {
  constructor(FoodActionTakenId, FoodActionTakenName, Others, Uid) {
    this.FoodActionTakenId = FoodActionTakenId;
    this.FoodActionTakenName = FoodActionTakenName;
    this.Others = Others;
    this.Uid = Uid ?? Date.now();
  }
}

export class FoodSafetyFollowUpDTO {
  constructor(
    IncidentsTransactionId,
    FoodSafetyTransactionId,
    Comments,
    IsActionTaken,
    FoodSafetyFollowUpActionTaken
  ) {
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.FoodSafetyTransactionId = FoodSafetyTransactionId;
    this.IsActionTaken = IsActionTaken;
    this.FoodSafetyFollowUpActionTaken = FoodSafetyFollowUpActionTaken;
    this.Comments = Comments;
  }
}

export const IncidentTransactionState = {
  FoodSafetyTransactionDTO: new FoodSafetyTransactionDTO(),
  FoodSafetyCompanyDetailDTO: new FoodSafetyCompanyDetailDTO(),
  FoodSafetyProductInfoDTO: new FoodSafetyProductInfoDTO(),
  FoodSafetyRiskInfoDTO: new FoodSafetyRiskInfoDTO(),
  FoodSafetyActionTakenDTO: [],
  FoodSafetyFollowUpDTO: new FoodSafetyFollowUpDTO(),
};
