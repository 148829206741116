import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import MapPicker from "react-google-map-picker";
import { toast } from "react-toastify";
import { constants } from "../../../../Constants/Common";
import ModalView from "../../../Common/UI/ModalView";

function MapModal(props) {
  const DefaultLocation = { lat: 25.276987, lng: 55.296249 };
  const DefaultZoom = 10;
  const [location, setLocation] = useState(DefaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);

  const openMapAndShowCurrPosition = useCallback(() => {
    setTimeout(() => {
      position();
    }, 1000);
  }, []);

  const handleChangeLocation = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
  };

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };
  const position = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        console.log(err);
        //toast("Internet issue, proxy connection failed", { type: "error" });
      }
    );
  };
  const handleUpdateLatLngTextbox = () => {
    props.updateLatLngTextbox(location);
  };
  useEffect(() => {
    openMapAndShowCurrPosition();
  }, [openMapAndShowCurrPosition]);

  return (
    <div>
      <ModalView
        title="chooseLocation"
        isOpen={props.isOpen}
        handleShowModal={props.handleShowMapModal}
        footer={
          <Button variant="" className="action btn pl-4 pr-4" onClick={handleUpdateLatLngTextbox}>
            {props.t("ChooseThisLocation")}
          </Button>
        }
        body={
          <MapPicker
            defaultLocation={location}
            zoom={zoom}
            mapTypeId="roadmap"
            style={{ height: "400px" }}
            onChangeLocation={handleChangeLocation}
            onChangeZoom={handleChangeZoom}
            apiKey={constants.googleMap_API}
          ></MapPicker>
        }
      ></ModalView>
    </div>
  );
}

export default MapModal;
