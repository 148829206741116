import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import MapModal from "../Common/LocationDetail/MapModal";
import { iIncident, commonAction, toast, enums, auth, validation, incidentAction, util } from "../../Imports";

const EnvGPSLocation = (props) => {
  const hideLatLngTextBox = props.hideLatLngTextBox ?? false;
  const [showMapModal, setShowMapModal] = useState(false);
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);
  const googleMapAreaName = useSelector((state) => state.common.googleMapAreaName);
  const updateLatLngTextbox = async (location) => {
    await getCurrentLocationOption(location.lat, location.lng);
    handleShowMapModal();
  };
  const getCurrentLocationOption = async (lat, lng) => {
    dispatch(commonAction.getGoogleMapLocation(lat + "," + lng));

    props.handleChange("Latitude", lat, iIncident.SharedDTO.IT);
    props.handleChange("Longitude", lng, iIncident.SharedDTO.IT);
  };

  const handleShowMapModal = () => {
    setShowMapModal(!showMapModal);
  };
  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successLocation, errorLocation);
    } else {
      toast(props.t("Geolocation not supported"), { type: "warning" });
    }
  };
  const successLocation = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    getCurrentLocationOption(latitude, longitude);

    var text = `${latitude},  ${longitude}`;
    if (hideLatLngTextBox) document.getElementById("latLngText").innerHTML = googleMapAreaName;
    console.log(text);
  };
  useEffect(() => {
    if (googleMapAreaName) {
      document.getElementById("latLngText").innerHTML = googleMapAreaName;
    }
  }, [googleMapAreaName]);
  const errorLocation = () => {
    toast(props.t("Unable to retrieve your location"), { type: "warning" });
  };
  const validateAndUpdateGPSCoordinates = async () => {
    if (validation.isGovtUser()) {
      const transactionId = transaction[iIncident.SharedDTO.IT].Id;
      const lat = transaction[iIncident.SharedDTO.IT].Latitude;
      const lng = transaction[iIncident.SharedDTO.IT].Longitude;

      const emirate = await commonAction.googleMapLocation(lat + "," + lng);

      if (emirate !== "" && emirate !== null) {
        const id = util.getEmirateIndex(emirate);

        if (id === -1) {
          toast(props.t("locationInsideUAEText"), { type: "warning" });
        } else if (parseInt(user.EmirateId) !== id + 1 && !validation.isMinistryGovtUser()) {
          toast(props.t("outOfCoverageArea"), { type: "warning" });
        } else {
          props.setLoading(true);
          var res = await incidentAction.updateLocationCoordinate(transactionId, lat, lng);
          res = res.data.ResponseData;
          if (res) {
            toast(props.t("updatedSuccessFully"), { type: "success" });
          } else {
            toast(props.t("SomethingWentWrong"), { type: "error" });
          }
        }
      }
      props.setLoading(false);
    }
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      setTimeout(() => {
        if (validation.isGovtUser()) setDisable(false);
      }, 2000);
    }
  }, [transaction.isUpdate]);
  return (
    <>
      {/* <label className="control-title my-2 mb-4">
        <strong>{props.t("incidentLocation")}</strong>
      </label> */}
      {hideLatLngTextBox && <div></div>}
      {!hideLatLngTextBox && (
        <>
          <div className="col-md-4 col-sm-5">
            <FormInput
              name="Latitude"
              type="text"
              label="latitude"
              pattern="^-?([0-8]?[0-9]|90)(\.[0-9]{1,20})$"
              required={true}
              disabled={disable}
              value={transaction[iIncident.SharedDTO.IT].Latitude}
              errorMessage={props.t("EnterValidValue_Lat")}
              onChange={(e) => {
                props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT);
              }}
            />
          </div>
          <div className=" col-md-4 col-sm-5">
            <FormInput
              name="Longitude"
              type="text"
              label="longtitude"
              pattern="^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,20})$"
              disabled={disable}
              required={true}
              value={transaction[iIncident.SharedDTO.IT].Longitude}
              errorMessage={props.t("EnterValidValue_Lng")}
              onChange={(e) => {
                props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT);
              }}
            />
          </div>
        </>
      )}
      <div className="col-md-4 mx-3 pick-location-row  d-flex flex-row flex-grow-1">
        {!transaction.isUpdate ? (
          <span className=" upload-icon-bg">
            <i className="fa fa-map-marker  upload-icon-fa" aria-hidden="true" onClick={handleLocationClick}></i> <span id="latLngText"></span>
            {/* <button type="button" className="nf-CaptureButton mt-2 " id="mapsPicker" onClick={handleLocationClick}></button> */}
          </span>
        ) : (
          <>
            {validation.isGovtUser() && (
              <a target="_blank" rel="noreferrer" onClick={validateAndUpdateGPSCoordinates} className="btn btn-danger mt-4 mx-1">
                {props.t("updateLocation")}
              </a>
            )}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://www.google.com/maps/search/?api=1&query=${transaction[iIncident.SharedDTO.IT].Latitude},${
                transaction[iIncident.SharedDTO.IT].Longitude
              }`}
              className="btn btn-success mt-4"
            >
              {props.t("ViewMap")}
            </a>
          </>
        )}
      </div>

      <MapModal updateLatLngTextbox={updateLatLngTextbox} isOpen={showMapModal} handleShowMapModal={handleShowMapModal} {...props} />
    </>
  );
};

export default EnvGPSLocation;
