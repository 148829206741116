export const ALV_Constants = {
  ALVT: "AnimalLegalViolationTransactionDTO",
  ALVD: "AnimalLegalViolationDetailDTO",
};

export class AnimalLegalViolationTransactionDTO {
  constructor(
    IncidentsTransactionId,
    IncidentsTransaction,
    Area,
    ALVIncidentTypeId,
    ALVIncidentTypeLkp,
    Description,
    GovernmetnalEntityId,
    AnimalLegalViolationDetail
  ) {
    this.IncidentsTransactionId = IncidentsTransactionId ?? 0;
    this.IncidentsTransaction = IncidentsTransaction ?? "";
    this.Area = Area ?? "";
    this.ALVIncidentTypeId = ALVIncidentTypeId ?? 0;
    this.ALVIncidentTypeLkp = ALVIncidentTypeLkp ?? "";
    this.Description = Description ?? "";
    this.GovernmetnalEntityId = GovernmetnalEntityId ?? null;
    this.AnimalLegalViolationDetail =
      AnimalLegalViolationDetail ?? new AnimalLegalViolationDetailDTO();
  }
}

export class AnimalLegalViolationDetailDTO {
  constructor(
    AnimalLegalViolationTransactionId,
    VeterinarySurgeonName,
    ManufacturingCompany,
    ProductName,
    VeterinaryEstablishmentId,
    VeterinaryEstablishmentLkp
  ) {
    this.AnimalLegalViolationTransactionId =
      AnimalLegalViolationTransactionId ?? 0;
    this.VeterinarySurgeonName = VeterinarySurgeonName ?? "";
    this.ManufacturingCompany = ManufacturingCompany ?? "";
    this.ProductName = ProductName ?? "";
    this.VeterinaryEstablishmentId = VeterinaryEstablishmentId ?? null;
    this.VeterinaryEstablishmentLkp = VeterinaryEstablishmentLkp ?? "";
  }
}
export const IncidentTransactionState = {
  AnimalLegalViolationDetailDTO: new AnimalLegalViolationDetailDTO(),
  AnimalLegalViolationTransactionDTO: new AnimalLegalViolationTransactionDTO(),
};
