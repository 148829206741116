import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { master, iAnimalHealth, validation } from "../../../Imports";
import { AddButton, DeleteButton } from "../../Common/IncidentCommonImport";

const Slaughtered = (props) => {
  const initialState = {
    AnimalTypeScientificName: "",
    AnimalCatagoryId: "",
    AnimalTypeName: "",
    AnimalTypeId: "",
    AnimalCatagoryName: "",
    NoOfAnimals: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [animalTypeData, setAnimalTypeData] = useState([]);
  const animalCatagryData = useSelector((state) => state.masterData.animalCatagoryData);
  const transaction = useSelector((state) => state.transaction);
  const changeControl = (e) => {
    console.log(e);
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeAnimalCatagory = async (e) => {
    if (e.target.value) {
      var index = e.nativeEvent.target.selectedIndex;
      setLocalVal((p) => ({
        ...p,
        AnimalCatagoryId: e.target.value,
        AnimalCatagoryName: e.nativeEvent.target[index].text,
        AnimalTypeScientificName: "",
      }));
      setAnimalTypeData(await master.getAnimalType(e.target.value));
    }
  };
  const changeAnimalType = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      AnimalTypeId: e.target.value,
      AnimalTypeName: e.nativeEvent.target[index].text,
      AnimalTypeScientificName: e.nativeEvent.target[index].getAttribute("data-code"),
    }));
  };
  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[iAnimalHealth.AH_Constants.AHI].AnimalSlaughtered.forEach((e, i) => {
      tempInf = tempInf.concat(
        new iAnimalHealth.AnimalSlaughteredDTO(
          e.AnimalTypeId,
          e.NoOfAnimals,
          new Date().getTime() + i,
          props.currentLanguage === "ar" ? e.AnimalType.NameAr : e.AnimalType.NameEn
        )
      );
    });
    props.handleChangeDTO(tempInf, iAnimalHealth.AH_Constants.ASlaughter);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  const SubmitHandler = async (e) => {
    e.preventDefault();
    const formName = "slaughteredAnimalForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.ASlaughter].concat(
        new iAnimalHealth.AnimalSlaughteredDTO(localVal.AnimalTypeId, localVal.NoOfAnimals, new Date().getTime(), localVal.AnimalTypeName)
      ),
      iAnimalHealth.AH_Constants.ASlaughter
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.ASlaughter].filter((x) => x.Uid !== e.Uid),
      iAnimalHealth.AH_Constants.ASlaughter
    );
  };
  return (
    <div className="container container-bg my-2">
      <label className="mb-2">
        <strong>{props.t("DataOfSlaughteredAnimals")}</strong>
      </label>
      <div className="row slaughteredAnimalForm">
        <div className="col-md-3">
          <SelectInput
            name="AnimalCatagoryId"
            label="animalCatagory"
            required={true}
            value={localVal.AnimalCatagoryId}
            onChange={changeAnimalCatagory}
            data={animalCatagryData}
          />
        </div>
        <div className="col-md-3">
          <SelectInput name="AnimalTypeId" label="animalType" required={true} value={localVal.AnimalTypeId} onChange={changeAnimalType} data={animalTypeData} />
          {localVal.AnimalTypeScientificName && (
            <div>
              <small>
                <strong>{props.t("scientificName")}: </strong>
                {localVal.AnimalTypeScientificName}
              </small>
            </div>
          )}
        </div>
        <div className="col-md-3">
          <FormInput
            name="NoOfAnimals"
            type="number"
            label="numberofAnimalsSlaughter"
            value={localVal.NoOfAnimals}
            onChange={changeControl}
            required={true}
            min="1"
          />
        </div>

        <div className="col-md-3 mt-4">
          <AddButton {...props} submitHandler={SubmitHandler} />
        </div>
      </div>

      <div className="col-md-12 row">
        {transaction[iAnimalHealth.AH_Constants.ASlaughter].map((e, i) => (
          <div className="card" key={i}>
            <div className="card-body row">
              <div className="col-md-3">
                <small className="golden">{props.t("animalType")}</small> :<small> {e.AnimalTypeName}</small>
              </div>
              <div className="col-md-3">
                <small className="golden">{props.t("numberofAnimals")}</small>:<small> {e.NoOfAnimals}</small>
              </div>
            </div>
            <DeleteButton
              {...props}
              deleteHandler={() => {
                deleteItem(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slaughtered;
