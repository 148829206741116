export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error: error,
  };
};

export const hideError = () => {
  return {
    type: HIDE_ERROR,
  };
};
export const execute = (callback) => {
  try {
    const res = callback;
    return [res, null];
  } catch (err) {
    console.error(Error(err.message ?? err));
    throw new Error("Something went wrong.Please try again.");

    // return [null, err];
  }
};
export const executeAsync = async (callback) => {
  try {
    const res = await callback;
    return [res, null];
  } catch (err) {
    console.error(Error(err.message ?? err));
    throw new Error("Something went wrong.Please try again.");

    // return [null, err];
  }
};
