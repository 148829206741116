import React from "react";
import DiseaseIncidentGIS from "./DiseaseIncidentGIS";
import SpreadIncidentGIS from "./SpreadIncidentGIS";

const GISMap = (props) => {
  const fileType = props.currentLanguage === "ar" ? "mapAr.html" : "map.html";
  return (
    <>
      <DiseaseIncidentGIS fileType={fileType} {...props} />

      <SpreadIncidentGIS fileType={fileType} {...props} />
    </>
  );
};

export default GISMap;
