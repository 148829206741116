import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import { iAnimalBG, enums, validation } from "../../Imports";
import Attachment from "../Common/Attachment";

const LabTestDetails = (props) => {
  const labConst = iAnimalBG.ABD_Constants.ABGT;
  const transaction = useSelector((state) => state.transaction);
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, labConst);
  };
  return (
    <div className="container animalDetailForm my-2">
      <div className="row container-bg">
        <div className="col-md-12">
          <FormInput
            name="ViolationReason"
            type="textarea"
            label="ViolationReason"
            required={true}
            disabled={false}
            value={transaction[labConst].ViolationReason}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-12">
          <Attachment
            required={true}
            topic="attachments"
            handleChangeDTO={props.handleChangeDTO}
            code={enums.AttachmentEnum.LabTestReport}
            canUploadFile={validation.isBorderGateUser()}
            {...props}
          />
        </div>
        {/* <div className="col-md-12">
          <FormInput
            name="Comments"
            type="textarea"
            label="Comments"
            required={false}
            disabled={false}
            value={transaction[labConst].Comments}
            onChange={changeControl}
          />
        </div> */}
      </div>
    </div>
  );
};

export default LabTestDetails;
