import { DispatchTypes } from "../DispatchType";

export const setTransactionDto = (dto, state) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DispatchTypes.SET_TRANSACTION_DTO,
      transactionDto: dto,
      transactionState: state,
    });
  };
};
export const setTransactionbyName = (name, value, state) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DispatchTypes.SET_TRANSACTION_NAME,
      transactionName: name,
      transactionDto: value,
      transactionState: state,
    });
  };
};
export const newTransaction = () => {
  return async (dispatch, getState) => {
    console.log("newTransaction");
    window.scroll(0, 0);
    dispatch({
      type: DispatchTypes.SET_NEW_TRANSACTION,
      data: {},
    });
  };
};
