import axios from "axios";
import { URLs } from "../../Constants/Common";

export const GetGeneralInfo = async () => {
  return await axios.get(`${URLs.GetGeneralInfo}`);
};
export const getIncidentStatus = async () => {
  return await axios.get(`${URLs.GetIncidentStatus}`);
};
export const getIncidentPerEmirate = async () => {
  return await axios.get(`${URLs.GetIncidentPerEmirate}`);
};
export const getIncidentDangerRisk = async () => {
  return await axios.get(`${URLs.GetIncidentDangerRisk}`);
};
export const getComparisonOpenCloseYearlyIncidents = async () => {
  return await axios.get(`${URLs.GetComparisonOpenCloseYearlyIncidents}`);
};
export const getComparisonOpenCloseMonthlyIncidents = async () => {
  return await axios.get(`${URLs.GetComparisonOpenCloseMonthlyIncidents}`);
};
export const getIncidentDetailDangerRisk = async () => {
  return await axios.get(`${URLs.GetIncidentDetailDangerRisk}`);
};
export const getNumberOfIncident = async () => {
  return await axios.get(`${URLs.GetNumberOfIncident}`);
};
export const getIncidentTypes = async () => {
  return await axios.get(`${URLs.GetIncidentTypes}`);
};
export const getIncidentsBySource = async () => {
  return await axios.get(`${URLs.GetIncidentsBySource}`);
};
export const getIncidentsByGovEntity = async () => {
  return await axios.get(`${URLs.GetIncidentsByGovEntity}`);
};
