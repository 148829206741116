import React from "react";
import { enums } from "../../Imports";
import Attachment from "../Common/Attachment";
import { isGovtUser } from "../Common/Utils/Validation";

const AllAttachment = (props) => {
  return (
    <div className="container my-2">
      <div className="row">
        <div className="col-md-6">
          <Attachment
            required={true}
            topic="LabReport"
            colWidth={4}
            handleChangeDTO={props.handleChangeDTO}
            code={enums.AttachmentEnum.LabTestReport}
            canUploadFile={isGovtUser()}
            {...props}
          />
        </div>
        <div className="col-md-6">
          <Attachment
            required={true}
            topic="ProductPhoto"
            colWidth={4}
            handleChangeDTO={props.handleChangeDTO}
            code={enums.AttachmentEnum.ProductPhoto}
            canUploadFile={isGovtUser()}
            {...props}
          />
        </div>
        <div className="col-md-6">
          <Attachment
            required={true}
            topic="HealthCertificate"
            colWidth={4}
            handleChangeDTO={props.handleChangeDTO}
            code={enums.AttachmentEnum.HealthCertificate}
            canUploadFile={isGovtUser()}
            {...props}
          />
        </div>

        <div className="col-md-6">
          <Attachment
            required={true}
            topic="DistributionList"
            colWidth={4}
            handleChangeDTO={props.handleChangeDTO}
            code={enums.AttachmentEnum.DistributionList}
            canUploadFile={isGovtUser()}
            {...props}
          />
        </div>
        <div className="col-md-12">
          <Attachment required={true} handleChangeDTO={props.handleChangeDTO} code={enums.AttachmentEnum.Other} canUploadFile={isGovtUser()} {...props} />
        </div>
      </div>
    </div>
  );
};

export default AllAttachment;
