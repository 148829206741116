import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import InfectionCard from "./InfectionCard";
import { AddButton } from "../../Common/IncidentCommonImport";
import { iIncident, enums, iAnimalHealth, master, auth, validation } from "../../../Imports";

const InfectionDefault = (props) => {
  const initialState = {
    AnimalCatagoryId: "",
    AnimalCatagoryName: "",
    AnimalTypeId: "",
    AnimalTypeName: "",
    AnimalTypeOther: "",
    AnimalTypeScientificName: "",
    NoOfInfectedAnimals: "",
    NoOfPossibleInfectedAnimals: "",
    NoOfDeadAnimals: "",
    OtherSymptoms: "",
    OtherDisease: "",
  };
  const [updateModeDisease, setUpdateModeDisease] = useState(false);
  const [updateSection, setUpdateSection] = useState({});
  const [tempClinicSymptom, setTempClinicSymptom] = useState([]);
  const [tempDisease, setTempDisease] = useState([]);
  const [isShowOtherTextbox, setIsShowOtherTextbox] = useState(false);
  const [isShowOtherDiseaseTxt, setIsShowOtherDiseaseTxt] = useState(false);
  const [errorClinicSymp, setErrorClinicSymp] = useState("");
  const [errorDisease, setErrorDisease] = useState("");
  const [diseasesData, setDiseasesData] = useState([]);
  const [clinicalSymptomsData, setClinicalSymptomsData] = useState([]);
  const [localVal, setLocalVal] = useState(initialState);
  const [animalTypeData, setAnimalTypeData] = useState([]);

  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const animalCategoryData = useSelector((state) => state.masterData.animalCatagoryData);

  const user = auth.getUserPermission();
  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    //console.log("tempClinicSymptom", tempClinicSymptom);
    //console.log("tempDisease", tempDisease);
  }, [tempClinicSymptom, tempDisease]);

  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[iAnimalHealth.AH_Constants.IID].forEach((e, i) => {
      tempInf = tempInf.concat(
        new iAnimalHealth.IncidentInfectionDetailsDTO(
          new Date().getTime() + i,
          e.AnimalType.AnimalCatagoryId,
          "", //e.AnimalCatagoryName,
          e.AnimalTypeId,
          props.currentLanguage === "ar" ? e.AnimalType.NameAr : e.AnimalType.NameEn,
          e.NoOfInfectedAnimals,
          e.NoOfPossibleInfectedAnimals,
          e.NoOfDeadAnimals,
          e.OtherSymptoms,
          e.Comments,
          e.DiseaseAppearFirst,
          e.InfectionSymptomDetails.map((v) => {
            return new iAnimalHealth.InfectionSymptomDetailDTO(v.ClinicalSymptoms.Id, v.ClinicalSymptoms.NameAr, v.ClinicalSymptoms.NameEn);
          }),
          e.InfectionDiseaseDetails.map((v) => {
            return new iAnimalHealth.InfectionDiseaseDetailDTO(
              v.AnimalDisease.Id,
              v.AnimalDisease.NameAr,
              v.AnimalDisease.NameEn,
              v.AnimalDisease.IsSharedDisease
            );
          }),
          e.AnimalAge,
          e.AnimalBreed,
          e.AnimalGender,
          e.Anatomy,
          e.OtherDisease,
          e.InfectionDetailGroupType,
          e.NoOfAnimals,
          e.AnimalTypeOther
        )
      );
    });
    props.handleChangeDTO(tempInf, iAnimalHealth.AH_Constants.IID);

    // if (user.UnitType === UnitTypeEnum.GovernmentEntity) {
    //   const res = await masterDataAction.getAnimalTypeDiseases(e.target.value);
    //   setDiseasesData(res);
    // }
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
    setTimeout(() => {
      validation.isShowInfectionForm(transaction, "infectionsForm");
    }, 2000);
  }, [transaction.isUpdate]);

  const changeAnimalCatagory = async (e) => {
    if (e.target.value) {
      var index = e.nativeEvent.target.selectedIndex;
      setLocalVal((p) => ({
        ...p,
        AnimalCatagoryId: e.target.value,
        AnimalCatagoryName: e.nativeEvent.target[index].text,
        AnimalTypeScientificName: "",
      }));
      setAnimalTypeData(await master.getAnimalType(e.target.value));
    }
  };
  const changeAnimalType = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      AnimalTypeId: e.target.value,
      AnimalTypeName: e.nativeEvent.target[index].text,
      AnimalTypeScientificName: e.nativeEvent.target[index].getAttribute("data-code"),
    }));
    loadSymptomDiseaseData(localVal.AnimalCatagoryId, e.target.value);
  };
  const loadSymptomDiseaseData = async (animalCatagoryId, animalId) => {
    setClinicalSymptomsData(await master.getClinicTypeSymptoms(animalId));

    if (user.UnitType === enums.UnitTypeEnum.GovernmentEntity || user.NonGovTypeCode === enums.NonGovUserTypeEnum.HealthFacility) {
      const res = await master.getAnimalTypeDiseases(animalId, animalCatagoryId);

      setDiseasesData(res);
    }
  };
  const loadCompLkp = useCallback(async () => {
    if (animalCategoryData.length === 0) dispatch(master.getAnimalCatagry());
  }, [dispatch, animalCategoryData]);

  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);

  const handleAddRow = () => {
    if (clinicalSymptomsData.length > 0 && tempClinicSymptom.length === 0) {
      setErrorClinicSymp(props.t("mandaotryField"));
      return;
    }
    if (diseasesData.length > 0 && tempDisease.length === 0) {
      setErrorDisease(props.t("mandaotryField"));
      return;
    }
    var detail = new Array(
      new iAnimalHealth.IncidentInfectionDetailsDTO(
        new Date().getTime(),
        localVal.AnimalCatagoryId,
        localVal.AnimalCatagoryName,
        localVal.AnimalTypeId,
        localVal.AnimalTypeName,
        localVal.NoOfInfectedAnimals,
        localVal.NoOfPossibleInfectedAnimals,
        0,
        localVal.OtherSymptoms,
        "",
        "",
        tempClinicSymptom,
        tempDisease,
        0,
        "",
        "",
        "",
        localVal.OtherDisease,
        enums.InfectionDetailGroupEnum.Default,
        0
      )
    );
    const newData = transaction[iAnimalHealth.AH_Constants.IID].concat(detail);
    props.handleChangeDTO(newData, iAnimalHealth.AH_Constants.IID);
    UpdateSharedDiseaseValue(newData);
    restForm();
  };
  const restForm = () => {
    setTempClinicSymptom([]);
    setTempDisease([]);
    setClinicalSymptomsData([]);
    setDiseasesData([]);
    setIsShowOtherTextbox(false);
    setIsShowOtherDiseaseTxt(false);
    document.getElementById("AnimalCatagoryId").value = "";
    document.getElementById("AnimalCatagoryId").setAttribute("focused", "false");
    document.getElementById("AnimalTypeId").value = "";
    document.getElementById("AnimalTypeId").setAttribute("focused", "false");
    setLocalVal(initialState);
  };
  const OnChangeDiseases = (value, e) => {
    console.log(value);
    var tempList = [];
    if (e.target.checked) {
      tempList = tempDisease.concat({
        AnimalDiseaseId: value.Id,
        NameAr: value.NameAr,
        NameEn: value.NameEn,
        IsSharedDisease: value.Code,
      });

      if (value.Id.toString() === enums.AnimalDiseaseEnum.Others) {
        setIsShowOtherDiseaseTxt(true);
      }
    } else {
      tempList = tempDisease.filter((x) => x.AnimalDiseaseId !== value.Id);
      if (value.Id.toString() === enums.AnimalDiseaseEnum.Others) {
        setIsShowOtherDiseaseTxt(false);
      }
    }
    setTempDisease(tempList);

    // props.handleChange(
    //   "SharedDiseaseIncident",
    //   tempList.some((x) => x.IsSharedDisease === true),
    //   iIncident.SharedDTO.IT
    // );
  };
  const OnChangeClinicSymptom = (value, e) => {
    var tempList = [];
    if (e.target.checked) {
      tempList = tempClinicSymptom.concat({
        ClinicalSymptomId: value.Id,
        NameAr: value.NameAr,
        NameEn: value.NameEn,
      });
      if (value.Id.toString() === enums.ClinicalSymptomsEnum.Others) {
        setIsShowOtherTextbox(true);
      }
    } else {
      tempList = tempClinicSymptom.filter((x) => x.ClinicalSymptomId !== value.Id);
      if (value.Id.toString() === enums.ClinicalSymptomsEnum.Others) {
        setIsShowOtherTextbox(false);
      }
    }
    setTempClinicSymptom(tempList);
  };
  const updateDisease = async (e) => {
    console.log(e);
    setUpdateSection(e);
    setUpdateModeDisease(true);
    setLocalVal((p) => ({
      ...p,
      AnimalCatagoryId: e.AnimalCatagory,
      AnimalCatagoryName: "",
      AnimalTypeId: e.AnimalTypeId,
      AnimalTypeName: "",
      NoOfInfectedAnimals: e.NoOfInfectedAnimals,
      NoOfPossibleInfectedAnimals: e.NoOfPossibleInfectedAnimals,
      OtherSymptoms: e.OtherSymptoms,
      OtherDisease: e.OtherDisease,
    }));
    setAnimalTypeData(await master.getAnimalType(e.AnimalCatagory));
    loadSymptomDiseaseData(e.AnimalCatagory, e.AnimalTypeId);
    setTempClinicSymptom(e.InfectionSymptomDetails);
    setTempDisease(e.InfectionDiseaseDetails);
  };
  const updateDiseaseSection = () => {
    const data = transaction[iAnimalHealth.AH_Constants.IID].find((x) => x.Uid === updateSection.Uid);
    data.InfectionDiseaseDetails = tempDisease;
    data.OtherDisease = localVal.OtherDisease;
    data.InfectionSymptomDetails = tempClinicSymptom;
    data.AnimalTypeId = localVal.AnimalTypeId;
    data.NoOfInfectedAnimals = localVal.NoOfInfectedAnimals;
    data.NoOfPossibleInfectedAnimals = localVal.NoOfPossibleInfectedAnimals;
    data.OtherSymptoms = localVal.OtherSymptoms;
    //data.NoOfDeadAnimals = localVal.NoOfDeadAnimals;

    props.handleChangeDTO(transaction[iAnimalHealth.AH_Constants.IID], iAnimalHealth.AH_Constants.IID);
    setUpdateModeDisease(false);
    UpdateSharedDiseaseValue(transaction[iAnimalHealth.AH_Constants.IID]);
    restForm();
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    const formName = "animalForm1";
    if (!validation.validateForm(formName)) return;

    if (updateModeDisease) {
      updateDiseaseSection();
    } else {
      handleAddRow();
    }

    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const UpdateSharedDiseaseValue = (data) => {
    const checkSD = data.filter((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.Default);
    console.log(checkSD);
    let IsSharedDisease = false;
    checkSD.forEach((s) => {
      if (s.InfectionDiseaseDetails?.some((x) => x.IsSharedDisease === true)) {
        IsSharedDisease = true;
      }
    });
    props.handleChange("SharedDiseaseIncident", IsSharedDisease, iIncident.SharedDTO.IT);
  };
  const cancelBtn = () => {
    setUpdateModeDisease(false);
    restForm();
  };
  return (
    <div>
      <div className="container  my-2 container-bg">
        <div id="animalForm1" className="animalForm1">
          <div className="row">
            <div className="col-md-3">
              <SelectInput
                name="AnimalCatagoryId"
                label="animalCatagory"
                required={true}
                value={localVal.AnimalCatagoryId}
                onChange={changeAnimalCatagory}
                data={animalCategoryData}
              />
            </div>
            <div className="col-md-3">
              <SelectInput
                name="AnimalTypeId"
                label="animalType"
                required={true}
                value={localVal.AnimalTypeId}
                onChange={changeAnimalType}
                data={animalTypeData}
              />
              {localVal.AnimalTypeScientificName && (
                <div>
                  <small>
                    <strong>{props.t("scientificName")}: </strong>
                    {localVal.AnimalTypeScientificName}
                  </small>
                </div>
              )}
            </div>
            {localVal.AnimalTypeScientificName === enums.AnimalTypeEnum.Other && (
              <div className="col-md-3">
                <FormInput name="AnimalTypeOther" type="text" label="animalType" value={localVal.AnimalTypeOther} onChange={changeControl} />
              </div>
            )}
            <div className="col-md-3">
              <FormInput
                name="NoOfInfectedAnimals"
                type="number"
                required={true}
                label="numberOfInfectedAnimalsInPossession"
                value={localVal.NoOfInfectedAnimals}
                onChange={changeControl}
                min="1"
              />
            </div>
            <div className="col-md-3">
              <FormInput
                name="NoOfPossibleInfectedAnimals"
                type="number"
                required={true}
                label="numberOfPossibleInfectedAnimals"
                value={localVal.NoOfPossibleInfectedAnimals}
                onChange={changeControl}
                min="1"
              />
            </div>
            {/* <div className="col-md-3">
              <FormInput
                name="NoOfDeadAnimals"
                type="number"
                label="numberOfDeadAnimals"
                value={localVal.NoOfDeadAnimals}
                onChange={changeControl}
                min="1"
              />
            </div> */}

            {diseasesData.length > 0 && (
              <div className="col-md-12">
                <div className="row mt-3">
                  <label className="mb-2">
                    <strong>{props.t("diseaseName")}</strong>
                    <span className="red">*</span>
                  </label>
                  {diseasesData.map((x, i) => (
                    <div className="col-md-3" key={i}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={tempDisease.some((temp) => temp.AnimalDiseaseId === x.Id)}
                          id={`disease_${x.Id}`}
                          onChange={(e) => OnChangeDiseases(x, e)}
                          data-shared={x.Code}
                        />
                        <label className="form-check-label" htmlFor={`disease_${x.Id}`}>
                          {props.currentLanguage === "ar" ? x.NameAr : x.NameEn}
                        </label>
                      </div>
                    </div>
                  ))}
                  {errorDisease && tempDisease.length === 0 && <small className="red">{props.t("mandaotryField")}</small>}
                </div>
              </div>
            )}
            {isShowOtherDiseaseTxt && (
              <div className="col-md-12 ">
                <FormInput name="OtherDisease" type="textarea" label="otherDisease" required={true} value={localVal.OtherDisease} onChange={changeControl} />
              </div>
            )}

            {clinicalSymptomsData.length > 0 && (
              <div className="col-md-12 my-3">
                <div className="row">
                  <label className="mb-2">
                    <strong>{props.t("clinicalSymptoms")}</strong>
                    <span className="red">*</span>
                  </label>
                  {clinicalSymptomsData.map((x, i) => (
                    <div className="col-md-3" key={i}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={tempClinicSymptom.some((temp) => temp.ClinicalSymptomId === x.Id)}
                          id={`symptom_${x.Id}`}
                          onChange={(e) => OnChangeClinicSymptom(x, e)}
                        />
                        <label className="form-check-label" htmlFor={`symptom_${x.Id}`}>
                          {props.currentLanguage === "ar" ? x.NameAr : x.NameEn}
                        </label>
                      </div>
                    </div>
                  ))}
                  {errorClinicSymp && tempClinicSymptom.length === 0 && <small className="red">{props.t("mandaotryField")}</small>}
                </div>
              </div>
            )}
            {isShowOtherTextbox && (
              <div className="col-md-12">
                <FormInput name="OtherSymptoms" type="textarea" label="otherSymptoms" required={true} value={localVal.OtherSymptoms} onChange={changeControl} />
              </div>
            )}

            <div className="col-md-12 my-3">
              {updateModeDisease ? (
                <>
                  <button className="btn btn-success" type="button" onClick={SubmitHandler}>
                    {props.t("update")}
                  </button>
                  <button className="btn btn-success mx-3" type="button" onClick={cancelBtn}>
                    {props.t("cancel")}
                  </button>
                </>
              ) : (
                <AddButton submitHandler={SubmitHandler} {...props} />
              )}
            </div>
          </div>

          <div className="row">
            <InfectionCard
              updateDisease={updateDisease}
              type={enums.InfectionDetailGroupEnum.Default}
              UpdateSharedDiseaseValue={UpdateSharedDiseaseValue}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfectionDefault;
