import React from "react";
import PhoneInput from "react-phone-input-2";
import FormInput from "../../Common/Validation/FormInput";

function ExtraDataFoodFacility(props) {
  const inputs = [
    {
      id: 0,
      name: "facilityName",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: props.t("foodFacilityName"),
      required: true,
      fontIcon: "",
    },
    {
      id: 1,
      name: "facilityEmail",
      type: "email",
      placeholder: "",
      errorMessage: "",
      label: props.t("foodFacilityEmail"),
      required: false,
      fontIcon: "",
    },
  ];
  const onChangePhone = (value) => {
    props.handleChange({
      target: { name: "facilityPhoneNo", value: value },
    });
  };
  return (
    <div>
      <div className="row foodfacilitydata ">
        <div className="col-6">
          <FormInput key={inputs[0].id} {...inputs[0]} value={props.values[inputs[0].name]} onChange={props.handleChange} />
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>{props.t("phoneNumber")}</label>
            <PhoneInput
              country={"ae"}
              preferredCountries={["ae", "us", "gb"]}
              value={props.values["facilityPhoneNo"]}
              enableSearch={false}
              countryCodeEditable={false}
              enableAreaCodes={false}
              autoFormat={false}
              onChange={onChangePhone}
              inputProps={{
                name: "foodFacilityPhoneNo",
                required: true,
                pattern: ".{10,}",
              }}
            />
          </div>
        </div>
      </div>
      <div className="row foodfacilitydata ">
        <div className="col-6">
          <FormInput key={inputs[1].id} {...inputs[1]} value={props.values[inputs[1].name]} onChange={props.handleChange} />
        </div>
      </div>
    </div>
  );
}

export default ExtraDataFoodFacility;
