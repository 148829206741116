import React, { useCallback, useEffect, useState } from "react";
import * as authAction from "../../State/Actions/Auth";
import FormInput from "../Common/Validation/FormInput";
import { Link, useNavigate } from "react-router-dom";
import { checkLogin, removeUser, setUserPermission } from "../../Constants/AuthToken";
import UAEPassButton from "./UAEPass/UAEPassButton";

const Login = (props) => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Invalid Email address",
      label: "",
      required: true,
      fontIcon: "ico icon-Group-116",
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage: "Password is required",
      label: "",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      fontIcon: "ico icon-Path-393",
    },
  ];
  const removeUserBeforeLogin = useCallback(() => {
    removeUser();
  }, [removeUser]);

  useEffect(() => {
    console.log("removeUserBeforeLogin");
    if (!checkLogin()) {
      removeUserBeforeLogin();
    }
    // if (process.env.REACT_APP_ENV === "prod") {
    //   navigate("/");
    // }
  }, []);

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    console.log("form submitted");
    props.setLoading(true);

    try {
      const res = await authAction.signin(formValues.email, formValues.password);
      console.log(res);
      if (res.data.IsSuccess) {
        setUserPermission(res.data.ResponseData.AccessToken, res.data.ResponseData.RefreshToken);
      } else {
        setError(props.t("emailOrPasswordIsWrong"));
      }
      props.setLoading(false);
      window.location.reload();
    } catch (e) {
      console.log(e);
      setError(props.t("emailOrPasswordIsWrong"));
    }
  };

  return (
    <div style={{ direction: "ltr" }} className="">
      <div className="login-form mt-5 col-md-11 mx-4 ">
        <form onSubmit={SubmitHandler}>
          {inputs.map((input, i) => {
            return (
              <div className="mb-3" key={i}>
                <FormInput key={input.id} {...input} value={formValues[input.name]} onChange={onChange} />
              </div>
            );
          })}

          <div className="form-check d-flex justify-content-between">
            <div>
              <input name="cbxrememberme" type="checkbox" id="cbxrememberme" className="form-check-input  " />
              <label htmlFor="cbxrememberme" className="form-check-label d-flex align-items-center">
                {props.t("rememberMe")}
              </label>
            </div>
            <div>
              <Link className="form-text forgot-pass" to="/forgetPassword">
                {props.t("forgetPassword")}
              </Link>
            </div>
          </div>
          <button
            //disabled={!formValid}
            id="btn_login"
            className="btn btn-warning  p-2 mt-4"
          >
            <i className="fa fa-angle-left"></i>
            {props.t("login")}
          </button>
          <div className="form-error text-center mt-3">
            <div className="has-error">{error}</div>
          </div>
        </form>
      </div>

      {/* <UAEPassButton {...props} /> */}
    </div>
  );
};

export default Login;
