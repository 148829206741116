import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";
import { iAnimalBG, master, validation } from "../../Imports";
import { AddButton, DeleteButton } from "../Common/IncidentCommonImport";

const AnimalDetail = (props) => {
  const initialState = {
    DestinationCountryId: "",
    DestinationCountryName: "",
    SourceCountryId: "",
    SourceCountryName: "",
    EserviceAnimalCatagoryId: "",
    EserviceAnimalCatagoryName: "",
    EserviceAnimalId: "",
    EserviceAnimalName: "",
    AnimalNo: "",
    Unit: "",
    Comments: "",
  };
  const lang = props.currentLanguage;
  const [localVal, setLocalVal] = useState(initialState);
  const [countryData, setCountryData] = useState([]);
  const [eserviceAnimalData, setEserviceAnimalData] = useState([]);
  const [eserviceAnimalCatagoryData, setEserviceAnimalCatagoryData] = useState([]);
  const [hideControls, setHideControls] = useState(false);
  const transaction = useSelector((state) => state.transaction);
  const adConst = iAnimalBG.ABD_Constants.ABGAD;

  const loadComLkp = useCallback(async () => {
    setCountryData(await master.getCountryLkp(0));
    setEserviceAnimalCatagoryData(await master.getEserviceAnimalCatagoryLkp(0));
  }, []);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);

  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[adConst].forEach((e, i) => {
      tempInf = tempInf.concat(
        new iAnimalBG.AnimalBorderGateAnimalDetailDTO(
          e.DestinationCountryId,
          lang ? e.DestinationCountryLkp.NameAr : e.DestinationCountryLkp.NameEn,
          e.SourceCountryId,
          lang ? e.SourceCountryLkp.NameAr : e.SourceCountryLkp.NameEn,
          e.EserviceAnimalId,
          lang ? e.EserviceAnimalLkp.NameAr : e.EserviceAnimalLkp.NameEn,
          e.EserviceCategoryId,
          lang ? e.EserviceAnimalCategoryLkp.NameAr : e.EserviceAnimalCategoryLkp.NameEn,
          e.AnimalNo,
          e.Unit,
          e.Comments,
          new Date().getTime() + i
        )
      );
    });
    props.handleChangeDTO(tempInf, adConst);

    setHideControls(true);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeDestinationCountry = async (e) => {
    if (e.target.value) {
      setLocalVal((p) => ({
        ...p,
        DestinationCountryId: e.target.value,
        DestinationCountryName: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
    }
  };
  const changeSourceCountry = async (e) => {
    if (e.target.value) {
      setLocalVal((p) => ({
        ...p,
        SourceCountryId: e.target.value,
        SourceCountryName: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
    }
  };
  const changeEserviceAnimalCatagoryType = async (e) => {
    const val = e.target.value;
    if (val) {
      setLocalVal((p) => ({
        ...p,
        EserviceAnimalCatagoryId: val,
        EserviceAnimalCatagoryName: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
      setEserviceAnimalData(await master.getEserviceAnimalLkp(val));
    }
  };
  const changeEserviceAnimalType = async (e) => {
    if (e.target.value) {
      setLocalVal((p) => ({
        ...p,
        EserviceAnimalId: e.target.value,
        EserviceAnimalName: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "animalDetailForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[adConst].concat(
        new iAnimalBG.AnimalBorderGateAnimalDetailDTO(
          localVal.DestinationCountryId,
          localVal.DestinationCountryName,
          localVal.SourceCountryId,
          localVal.SourceCountryName,
          localVal.EserviceAnimalId,
          localVal.EserviceAnimalName,
          localVal.EserviceAnimalCatagoryId,
          localVal.EserviceAnimalCatagoryName,
          localVal.AnimalNo,
          localVal.Unit,
          localVal.Comments
        )
      ),
      adConst
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[adConst].filter((x) => x.Uid !== e.Uid),
      adConst
    );
  };
  return (
    <>
      <div className="container animalDetailForm my-2">
        <div className="row container-bg">
          {!hideControls && (
            <>
              <div className="col-md-4">
                <SelectInput
                  name="DestinationCountryId"
                  label="DestinationCountry"
                  required={true}
                  disabled={false}
                  value={localVal.DestinationCountryId}
                  onChange={changeDestinationCountry}
                  data={countryData}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  name="SourceCountryId"
                  label="SourceCountry"
                  required={true}
                  disabled={false}
                  value={localVal.SourceCountryId}
                  onChange={changeSourceCountry}
                  data={countryData}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  name="EserviceCategoryId"
                  label="animalCatagory"
                  required={true}
                  disabled={false}
                  value={localVal.EserviceAnimalCatagoryId}
                  onChange={changeEserviceAnimalCatagoryType}
                  data={eserviceAnimalCatagoryData}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  name="EserviceAnimalId"
                  label="animalType"
                  required={true}
                  disabled={false}
                  value={localVal.EserviceAnimalId}
                  onChange={changeEserviceAnimalType}
                  data={eserviceAnimalData}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="AnimalNo"
                  type="number"
                  label="numberofAnimals"
                  required={true}
                  disabled={false}
                  value={localVal.AnimalNo}
                  onChange={changeControl}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="Unit"
                  type="number"
                  label="Unit"
                  required={false}
                  disabled={false}
                  value={localVal.Unit}
                  onChange={changeControl}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-12">
                <FormInput
                  name="Comments"
                  type="textarea"
                  label="Comments"
                  required={false}
                  disabled={false}
                  value={localVal.Comments}
                  onChange={changeControl}
                  data-exclude="1"
                />
              </div>
              <div className="col-md-3 mt-4">
                <AddButton {...props} submitHandler={submitHandler} />
              </div>
            </>
          )}
          <div className="col-md-12 row my-3">
            {transaction[adConst].map((e, i) => (
              <div className="card" key={e.Uid + i}>
                <div className="card-body row">
                  <div className="col-md-4">
                    <small className="golden">{props.t("DestinationCountry")}</small>:<small> {e.DestinationCountryName}</small>
                  </div>
                  <div className="col-md-4">
                    <small className="golden">{props.t("SourceCountry")}</small>:<small> {e.SourceCountryName}</small>
                  </div>
                  <div className="col-md-4">
                    <small className="golden">{props.t("animalCatagory")}</small>:<small> {e.EserviceAnimalCatagoryName}</small>
                  </div>
                  <div className="col-md-4">
                    <small className="golden">{props.t("Type")}</small>:<small> {e.EserviceAnimalName}</small>
                  </div>
                  <div className="col-md-4">
                    <small className="golden">{props.t("Quantity")}</small>:<small> {e.AnimalNo}</small>
                  </div>
                  {e.Unit && (
                    <div className="col-md-4">
                      <small className="golden">{props.t("Unit")}</small>:<small> {e.Unit}</small>
                    </div>
                  )}
                  {e.Comments && (
                    <div className="col-md-4">
                      <small className="golden">{props.t("Comments")}</small>:<small> {e.Comments}</small>
                    </div>
                  )}
                </div>
                <DeleteButton
                  {...props}
                  deleteHandler={() => {
                    deleteItem(e);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimalDetail;
