import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SelectInput } from "../../Common/Validation/FormInput";
import { auth, iAgrculturePest, master, validation } from "../../Imports";

const IncidentSource = (props) => {
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);
  const [incidentSource, setIncidentSource] = useState([]);
  const loadLkp = async () => {
    if (validation.isUserLoggedIn())
      setIncidentSource(await master.getIncidentSourceLkp(0));
    else setIncidentSource((await master.getIncidentSourceLkp(0)).slice(0, 3));
  };
  useEffect(() => {
    loadLkp();
  }, []);
  const handleChangeSource = (e) => {
    props.handleChange(
      e.target.name,
      e.target.value,
      iAgrculturePest.AP_Constants.APT
    );
  };
  return (
    <>
      <div className="col-md-4">
        <SelectInput
          name="IncidentSourceId"
          label="IncidentSource"
          required={true}
          disabled={false}
          value={transaction[iAgrculturePest.AP_Constants.APT].IncidentSourceId}
          onChange={handleChangeSource}
          data={incidentSource}
        />
      </div>
    </>
  );
};

export default IncidentSource;
