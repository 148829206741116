import axios from "axios";
import { URLs } from "../../Constants/Common";

export const CREATE_ANIMALHEALTH = "CREATE_ANIMALHEALTH";

export const createAnimalLegalViolation = async (data) => {
  return await axios.post(URLs.CreateAnimalLegalViolation, data);
};
export const updateAnimalLegalViolation = async (data) => {
  return await axios.post(URLs.UpdateAnimalLegalViolation, data);
};
export const createFollowUpAnimalLegalViolation = async (data) => {
  return await axios.post(URLs.CreateFollowUpAnimalLegalViolation, data);
};

export const getAnimalLegalViolationTransactionById = async (id) => {
  return await axios.get(
    URLs.getAnimalLegalViolationTransactionById + "?id=" + id
  );
};
export const getAnimalLegalViolationReport = async (data) => {
  return await axios.post(URLs.GetAnimalLegalViolationReport, data);
};
