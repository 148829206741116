import axios from "axios";
import { URLs } from "../../Constants/Common";

export const create = async (data) => {
  return await axios.post(URLs.CreateAgriculturePest, data);
};
export const update = async (data) => {
  return await axios.post(URLs.UpdateAgriculturePest, data);
};
export const createFollowUp = async (data) => {
  return await axios.post(URLs.CreateFollowUpAgriculturePest, data);
};

export const getTransactionById = async (id) => {
  return await axios.get(
    URLs.getAgriculturePestIncidentsTransactionById + "?id=" + id
  );
};
export const getAgriculturePestReport = async (data) => {
  return await axios.post(URLs.GetAgriculturePestReport, data);
};
