import React, { useCallback, useEffect, useState } from "react";

import { enums, incidentAction } from "../../Imports";
import PaginationUI from "../../Common/UI/PaginationUI";
import MyIncidnetGrid from "./Grids/MyIncidnetGrid";
import MyTaskGrid from "./Grids/MyTaskGrid";
import SearchGrid from "./Grids/SearchGrid";
import { pageSettingDto } from "./ReportControl";
import { GeneralSearchGridMapper } from "./DataMapper";
import CirculatedIncidentGrid from "./Grids/CirculatedIncidentGrid";

const Inbox = (props) => {
  let pageSetting = {
    ...pageSettingDto,
  };
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(pageSetting);

  const onPageChange = async (page) => {
    await getGridData(props.searchData, page);
  };
  const getGridData = async (data, page) => {
    props.setLoading(true);
    data.paginationDto.pageNo = page;

    const resp = await incidentAction.getIncidentSearch(data);
    const result = resp.data.ResponseData;
    setData(result);
    setPagination((pre) => ({
      ...pre,
      totalPages: Math.ceil(result.PaginationDto.TotalItems / result.PaginationDto.PageSize),
      TotalItems: result.PaginationDto.TotalItems,
      currentPage: page,
      showMax: page > pageSetting.showMax ? pageSetting.showMax : 2,
    }));
    props.setLoading(false);
  };
  const getSearchData = useCallback(async () => {
    await getGridData(props.searchData, 1);
  }, [getGridData]);

  const exportToPDF = async () => {
    console.log("create-report");
    let data = props?.searchData;
    data.paginationDto.pageSize = 0;
    const resp = await incidentAction.getIncidentSearch(data);
    const result = resp.data.ResponseData;
    return GeneralSearchGridMapper(result.Incidents);
  };
  useEffect(() => {
    getSearchData();
  }, [props.isSearchChanged]);

  return (
    <div>
      <div className="table-container col flex-fill">
        <div className="table-wrapper p-2 mx-3 container-bg table-responsive">
          {data?.Incidents.length === 0 && (
            <div className="text-center mt-5 search-message" id="nothingFoundMessage">
              <i className="ico icon-Group-420"></i>
              <p className="mt-4">
                {props.t("NoSearchResult")}
                <strong className="text-dark  "></strong>
              </p>
            </div>
          )}
          {data?.Incidents.length > 0 && props.type === enums.SearchCriteriaEnum.MyIncidents && <MyIncidnetGrid data={data} {...props} />}
          {data?.Incidents.length > 0 && props.type === enums.SearchCriteriaEnum.MyTask && <MyTaskGrid data={data} {...props} />}
          {data?.Incidents.length > 0 && props.type === enums.SearchCriteriaEnum.IncidentSearch && <SearchGrid data={data} {...props} />}
          {data?.Incidents.length > 0 && props.type === enums.SearchCriteriaEnum.CirculatedIncident && <CirculatedIncidentGrid data={data} {...props} />}
          {data?.Incidents.length > 0 && (
            <PaginationUI
              {...props}
              TotalItems={pagination.TotalItems}
              totalPages={pagination.totalPages}
              pageSize={props.searchData.paginationDto.pageSize}
              currentPage={pagination.currentPage}
              showMax={pageSetting.showMax}
              onClick={onPageChange}
              getExcelReport={exportToPDF}
              ExcelFileName={"Biosec_Report"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Inbox;
