import { OwnerShipTypeEnum } from "./enums";
import { IncidentsMainTransactionDTO } from "./IIncidentTrans";

export const AH_Constants = {
  IID: "IncidentInfectionDetailsDTO",
  ISD: "InfectionSymptomDetailDTO",
  IH: "IncidentsHoldingsDTO",
  AHT: "AnimalHealthTransactionDTO",
  AHI: "AnimalHealthInvestigationDTO",
  ACP: "AnimalControlProceduersDTO",
  ALA: "AnimalLabAnalysisDTO",
  AID: "AnimalImmunizedDetailsDTO",
  ADiscard: "AnimalDiscardedDTO",
  ASlaughter: "AnimalSlaughteredDTO",
  ADead: "AnimalDeadDTO",
  ANewly: "AnimalNewlyAddedDTO",
};
export class AnimalHealthTransactionDTO {
  constructor(
    IncidentsTransaction,
    IncidentHoldingId,
    IncidentHoldings,
    InfectionStartDate,
    InfectionConfirmDate,
    InfectionTypeCode,
    Circulate,
    IncidentInfectionDetail,
    AnimalHealthInvestigation,
    Id,
    Area
  ) {
    this.Id = Id;
    this.InfectionConfirmDate = InfectionConfirmDate;
    this.InfectionTypeCode = InfectionTypeCode;
    this.Circulate = Circulate;
    this.IncidentsTransaction = IncidentsTransaction ?? new IncidentsMainTransactionDTO();
    this.IncidentHoldingId = IncidentHoldingId;
    this.IncidentHoldings = IncidentHoldings ?? new IncidentsHoldingsDTO();
    this.InfectionStartDate = InfectionStartDate;

    this.IncidentInfectionDetail = IncidentInfectionDetail ?? new IncidentInfectionDetailsDTO();
    this.AnimalHealthInvestigation = AnimalHealthInvestigation;
    this.Area = Area;
  }
  setHoldingType(holdingTypeId) {
    this.IncidentHoldings.IsFarm = holdingTypeId === OwnerShipTypeEnum.Farm.toString();
  }
}
export class IncidentsHoldingsDTO {
  constructor(HoldingTypeId, Number, Name, MobileNo, Email, Address, IsFarm, HoldingTypeOther) {
    this.HoldingTypeId = HoldingTypeId;
    this.Number = Number ?? "";
    this.Name = Name ?? "";
    this.MobileNo = MobileNo ?? "";
    this.Email = Email ?? "";
    this.Address = Address ?? "";
    this.IsFarm = IsFarm ?? false;
    this.HoldingTypeOther = HoldingTypeOther ?? "";
  }
}
export class IncidentInfectionDetailsDTO {
  constructor(
    Uid,
    AnimalCatagory,
    AnimalCatagoryName,
    AnimalTypeId,
    AnimalTypeName,
    NoOfInfectedAnimals,
    NoOfPossibleInfectedAnimals,
    NoOfDeadAnimals,
    OtherSymptoms,
    Comments,
    DiseaseAppearFirst,
    InfectionSymptomDetails,
    InfectionDiseaseDetails,
    AnimalAge,
    AnimalBreed,
    AnimalGender,
    Anatomy,
    OtherDisease,
    InfectionDetailGroupType,
    NoOfAnimals,
    AnimalTypeOther
  ) {
    this.Uid = Uid;
    this.AnimalCatagory = AnimalCatagory ?? "";
    this.AnimalCatagoryName = AnimalCatagoryName ?? "";
    this.AnimalTypeName = AnimalTypeName ?? "";
    this.AnimalTypeId = AnimalTypeId ?? 0;
    this.NoOfInfectedAnimals = NoOfInfectedAnimals ?? "";
    this.NoOfPossibleInfectedAnimals = NoOfPossibleInfectedAnimals ?? "";
    this.NoOfDeadAnimals = NoOfDeadAnimals ?? "";
    this.OtherSymptoms = OtherSymptoms ?? "";
    this.Comments = Comments ?? "";
    this.DiseaseAppearFirst = DiseaseAppearFirst ?? "";
    this.InfectionSymptomDetails = InfectionSymptomDetails ?? "";
    this.InfectionDiseaseDetails = InfectionDiseaseDetails ?? "";
    this.AnimalAge = AnimalAge ?? "";
    this.AnimalBreed = AnimalBreed ?? "";
    this.AnimalGender = AnimalGender ?? "";
    this.Anatomy = Anatomy ?? "";
    this.OtherDisease = OtherDisease ?? "";
    this.InfectionDetailGroupType = InfectionDetailGroupType ?? "";
    this.NoOfAnimals = NoOfAnimals ?? "";
    this.AnimalTypeOther = AnimalTypeOther ?? "";
  }
}
export class InfectionSymptomDetailDTO {
  constructor(ClinicalSymptomId, NameAr, NameEn) {
    this.ClinicalSymptomId = ClinicalSymptomId;
    this.NameAr = NameAr ?? "";
    this.NameEn = NameEn ?? "";
  }
}
export class InfectionDiseaseDetailDTO {
  constructor(AnimalDiseaseId, NameAr, NameEn, IsSharedDisease) {
    this.AnimalDiseaseId = AnimalDiseaseId;
    this.NameAr = NameAr ?? "";
    this.NameEn = NameEn ?? "";
    this.IsSharedDisease = IsSharedDisease;
  }
}

export class AnimalHealthInvestigationDTO {
  constructor(
    AnimalHealthTransactionId,
    IsNewlyAnimalAdded,
    IsAnimalsImmunized,
    LastImmunizationDate,
    DetectedNearby,
    LabSamplesCollected,
    LabName,
    TestDescription,
    TestType,
    ActionsOnIncident,
    AnimalLabAnalysis,
    ImmunizedAnimalDetails,
    AnimalControlProceduers,
    AnimalNewlyAdded,
    AnimalDead,
    AnimalSlaughtered,
    AnimalDiscarded
  ) {
    this.AnimalHealthTransactionId = AnimalHealthTransactionId;
    this.IsNewlyAnimalAdded = IsNewlyAnimalAdded ?? "";
    this.IsAnimalsImmunized = IsAnimalsImmunized ?? "";
    this.LastImmunizationDate = LastImmunizationDate ?? "";
    this.DetectedNearby = DetectedNearby ?? "";
    this.LabSamplesCollected = LabSamplesCollected ?? "";
    this.LabName = LabName ?? "";
    this.TestDescription = TestDescription ?? "";
    this.TestType = TestType ?? "";
    this.ActionsOnIncident = ActionsOnIncident ?? "";
    this.AnimalLabAnalysis = AnimalLabAnalysis ?? [];
    this.ImmunizedAnimalDetails = ImmunizedAnimalDetails ?? [];
    this.AnimalControlProceduers = AnimalControlProceduers ?? [];
    this.AnimalNewlyAdded = AnimalNewlyAdded ?? [];
    this.AnimalDead = AnimalDead ?? [];
    this.AnimalSlaughtered = AnimalSlaughtered ?? [];
    this.AnimalDiscarded = AnimalDiscarded ?? [];
  }
}

export class AnimalLabAnalysisDTO {
  constructor(SampleType, NumberOfSamples, Uid) {
    this.SampleType = SampleType ?? "";
    this.NumberOfSamples = NumberOfSamples ?? "";
    this.Uid = Uid ?? "";
  }
}

export class AnimalImmunizedDetailsDTO {
  constructor(AnimalTypeId, NoOfImmunizedAnimals, ImmunizationType, ImmunizationDate, AnimalTypeName, Uid, AnimalTypeOther) {
    this.AnimalTypeId = AnimalTypeId;
    this.NoOfImmunizedAnimals = NoOfImmunizedAnimals ?? "";
    this.ImmunizationType = ImmunizationType ?? "";
    this.ImmunizationDate = ImmunizationDate ?? "";
    this.AnimalTypeName = AnimalTypeName ?? "";
    this.Uid = Uid ?? "";
    this.AnimalTypeOther = AnimalTypeOther ?? "";
  }
}
export class AnimalControlProceduersDTO {
  constructor(ControlProceduersLkpId, Code) {
    this.ControlProceduersLkpId = ControlProceduersLkpId;
    this.Code = Code ?? "";
  }
}
export class AnimalDiscardedDTO {
  constructor(AnimalTypeId, NoOfAnimals, Uid, AnimalTypeName, AnimalTypeOther) {
    this.AnimalTypeId = AnimalTypeId;
    this.NoOfAnimals = NoOfAnimals ?? "";
    this.Uid = Uid ?? "";
    this.AnimalTypeName = AnimalTypeName ?? "";
    this.AnimalTypeOther = AnimalTypeOther ?? "";
  }
}
export class AnimalSlaughteredDTO {
  constructor(AnimalTypeId, NoOfAnimals, Uid, AnimalTypeName, AnimalTypeOther) {
    this.AnimalTypeId = AnimalTypeId;
    this.NoOfAnimals = NoOfAnimals ?? "";
    this.Uid = Uid ?? "";
    this.AnimalTypeName = AnimalTypeName ?? "";
    this.AnimalTypeOther = AnimalTypeOther ?? "";
  }
}

export class AnimalDeadDTO {
  constructor(AnimalTypeId, NoOfAnimals, Uid, AnimalTypeName, AnimalTypeOther) {
    this.AnimalTypeId = AnimalTypeId;
    this.NoOfAnimals = NoOfAnimals ?? "";
    this.Uid = Uid ?? "";
    this.AnimalTypeName = AnimalTypeName ?? "";
    this.AnimalTypeOther = AnimalTypeOther ?? "";
  }
}
export class AnimalNewlyAddedDTO {
  constructor(AnimalTypeId, NoOfAnimals, EmirateId, Address, DateAdded, Uid, AnimalTypeName, EmirateName, AnimalTypeOther) {
    this.AnimalTypeId = AnimalTypeId;
    this.NoOfAnimals = NoOfAnimals ?? "";
    this.EmirateId = EmirateId ?? "";
    this.Address = Address ?? "";
    this.DateAdded = DateAdded ?? "";
    this.Uid = Uid ?? "";
    this.AnimalTypeName = AnimalTypeName ?? "";
    this.EmirateName = EmirateName ?? "";
    this.AnimalTypeOther = AnimalTypeOther ?? "";
  }
}
export const IncidentTransactionState = {
  AnimalHealthTransactionDTO: new AnimalHealthTransactionDTO(),
  IncidentsHoldingsDTO: new IncidentsHoldingsDTO(),
  IncidentInfectionDetailsDTO: [],
  AnimalHealthInvestigationDTO: new AnimalHealthInvestigationDTO(),
  AnimalImmunizedDetailsDTO: [],
  AnimalLabAnalysisDTO: [],
  AnimalControlProceduersDTO: [],
  AnimalDiscardedDTO: [],
  AnimalSlaughteredDTO: [],
  AnimalDeadDTO: [],
  AnimalNewlyAddedDTO: [],
};
