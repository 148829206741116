import axios from "axios";
import { URLs } from "../../Constants/Common";

export const getUserInfo = async (code) => {
  var data = {
    Code: code,
    SystemName: "Biosec",
  };
  return await axios.post(URLs.GetUserInfo, data);
};
