import React from "react";
import FormInput from "../../Common/Validation/FormInput";
import { useSelector } from "react-redux";
import { iEnvIncident, validation } from "../../Imports";

const ServiceDetail = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const constIT = iEnvIncident.EnIncident_Constants.EIT;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <FormInput
            name="Details"
            type="textarea"
            label={validation.isMinistryUser() ? "Comments" : " "}
            disabled={validation.isMinistryUser()}
            required={false}
            value={transaction[constIT].Details}
            onChange={(e) => {
              props.handleChange(e.target.name, e.target.value, constIT);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
