import axios from "axios";
import { constants, URLs } from "../../Constants/Common";
import { DispatchTypes } from "../DispatchType";

export const uploadFile = (data) => {
  return async (dispatch) => {
    try {
      await axios
        .post(URLs.FileUpload, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.IsSuccess) {
            const resData = res.data;
            dispatch({
              type: DispatchTypes.FILE_UPLOAD,
              fileUpload: resData.ResponseData,
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const uploadMultipleFiles = async (data) => {
  return await axios.post(URLs.UploadMultipleFiles, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const incidentUploadFiles = async (data, incidentId, code) => {
  return await axios.post(`${URLs.IncidentUploadFiles}/${incidentId}/${code}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const deleteIncidentFiles = async (incidentId, fileId) => {
  return await axios.delete(`${URLs.DeleteIncidentFiles}?FileId=${fileId}&incidentId=${incidentId}`);
};

export const deleteFiles = async (fileId) => {
  return await axios.delete(URLs.DeleteFile + "?FileId=" + fileId);
};
export const googleMapLocationAreaName = async (latlng) => {
  try {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${constants.googleMap_API}`);
    const res = await response.json();
    if (res?.status === "OK") {
      if (res?.results[0]) {
        console.log(res?.results[0]);
        const area = res?.results[res?.results?.length - 2].formatted_address?.split("-");
        return res?.results[0].formatted_address;
      }
    }
    return "";
  } catch {
    return "";
  }
};
export const googleMapLocation = async (latlng) => {
  let city = "";
  try {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${constants.googleMap_API}`);
    const res = await response.json();
    if (res?.status === "OK") {
      if (res?.results[0]) {
        const area = res?.results[res?.results?.length - 2].formatted_address?.split("-");
        city = area[0];
        return city?.trim() + "$$" + res?.results[0].formatted_address;
      }
    }
    return "";
  } catch {
    return "";
  }
};

export const getGoogleMapLocation = (latlng) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DispatchTypes.GOOGLE_MAP_LOCATION,
        googleMapLocation: "",
        googleMapAreaName: "",
      });
      const city = await googleMapLocation(latlng);
       

      dispatch({
        type: DispatchTypes.GOOGLE_MAP_LOCATION,
        googleMapLocation: city.split("$$")[0],
        googleMapAreaName: city.split("$$")[1],
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: DispatchTypes.GOOGLE_MAP_LOCATION,
        googleMapLocation: e,
        googleMapAreaName: e,
      });
    }
  };
};
