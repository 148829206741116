export const ASD_Constants = {
  ASDT: "AnimalSharedDiseaseTransactionDTO",
  ASDA: "AnimalSharedDiseaseAttachmentDTO",
  ASDIP: "AnimalSharedDiseaseInfectedPeopleDTO",
};
export class AnimalSharedDiseaseTransactionDTO {
  constructor(
    Id,
    IncidentsTransactionId,
    IncidentsTransaction,
    AnimalDiseaseLkpId,
    AnimalDiseaseLkp,
    NoOfInfectedPeople,
    IncidentDescription,
    Notes,
    AnimalSharedDiseaseInfectedPeople,
    AnimalSharedDiseaseAttachment
  ) {
    this.Id = Id;
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.IncidentsTransaction = IncidentsTransaction;
    this.AnimalDiseaseLkpId = AnimalDiseaseLkpId;
    this.AnimalDiseaseLkp = AnimalDiseaseLkp;
    this.NoOfInfectedPeople = NoOfInfectedPeople;
    this.IncidentDescription = IncidentDescription;
    this.Notes = Notes;
    this.AnimalSharedDiseaseInfectedPeople = AnimalSharedDiseaseInfectedPeople;
    this.AnimalSharedDiseaseAttachment = AnimalSharedDiseaseAttachment;
  }
}
export class AnimalSharedDiseaseAttachmentDTO {
  constructor(FileAttachmentId) {
    this.FileAttachmentId = FileAttachmentId;
  }
}
export class AnimalSharedDiseaseInfectedPeopleDTO {
  constructor(Name, PhoneNo, EmiratesId, Passport, Email, Uid) {
    this.Name = Name;
    this.PhoneNo = PhoneNo;
    this.EmiratesId = EmiratesId;
    this.Passport = Passport;
    this.Email = Email;
    this.Uid = Uid ?? Date.now();
  }
}
export const IncidentTransactionState = {
  AnimalSharedDiseaseTransactionDTO: new AnimalSharedDiseaseTransactionDTO(),
  AnimalSharedDiseaseAttachmentDTO: [],
  AnimalSharedDiseaseInfectedPeopleDTO: [],
};
