import i18next from "i18next";
import React, { useEffect } from "react";
import logo from "../../Theme/img/Logo_Email.png";
import { Link, useNavigate } from "react-router-dom";
import Login from "./Login";
import Registration from "./Registration";
import ForgetPassword from "./ForgetPassword";
import ProfileLinking from "./UAEPass/ProfileLinking";
import LoginLinked from "./UAEPass/LoginLinked";
import MultipleAccounts from "./UAEPass/MultipleAccounts";
import { checkLogin } from "../../Constants/AuthToken";
import { util } from "../Imports";

function AuthTemplate(props) {
  const navigate = useNavigate();
  const isLoggedIn = checkLogin();
  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);
  return (
    <div>
      <span id="DeltaPlaceHolderMain">
        <div id="upl_login">
          <section className="login-page d-flex h-100">
            <div className="login-container m-auto p-1">
              <Link className="login-logo text-center" to="/">
                <div>
                  <img src={logo} alt="logo" />
                </div>
              </Link>
              <div className="login-title align-items-center d-flex justify-content-around mt-3">
                <div className="text-center">
                  <h1> {props.t("app_name")}</h1>
                  <h2> {props.t("app_name2")}</h2>
                </div>
              </div>
              {util.pagesWOLoginShowLinks.includes(props.path) && (
                <div className="login-links d-flex justify-content-center mt-3">
                  <Link to="/login" className={`border-right mr-3 pr-3 ${props.path === "login" ? "active" : ""}`}>
                    {props.t("login")}
                  </Link>
                  <Link to="/registration" className={`${props.path === "registration" ? "active" : ""}`}>
                    {props.t("register")}
                  </Link>
                </div>
              )}

              {props.path === "login" && <Login {...props} />}
              {props.path === "registration" && <Registration {...props} />}
              {props.path === "forgetPassword" && <ForgetPassword {...props} />}
              {props.path === "loginLinked" && <LoginLinked {...props} />}
              {props.path === "profileLinking" && <ProfileLinking {...props} />}
              {props.path === "multipleAccounts" && <MultipleAccounts {...props} />}

              <div className="footer-links d-flex justify-content-center mt-5">
                <Link to="/copyright" className="border-right mr-3 pr-3">
                  {props.t("Copyright")}
                </Link>
                <Link to="/privacypolicy" className="border-right mr-3 pr-3">
                  {props.t("PrivacyPolicy")}
                </Link>
                <Link to="/contactus" id="ctl10_lnk_contactUs" className="border-right mr-3 pr-3">
                  {props.t("ContactUs")}
                </Link>
                <Link className="lang-switcher-footer" to="#" onClick={props.changeLangGlobal}>
                  {i18next.language === "en" ? "العربية" : "English"}
                </Link>
              </div>
              <div className="footer-disclaimer text-center mt-3">
                <small>{props.t("Moccae_all_right_reserved")}</small>
              </div>
            </div>
          </section>
        </div>
      </span>
    </div>
  );
}

export default AuthTemplate;
