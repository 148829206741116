import React from "react";
import CountByEmirateReportFilter from "./CountByEmirateReportFilter";
import CountByEmirateGrid from "./CountByEmirateGrid";
import { useState } from "react";

const FoodSafetyByEmirateCount = (props) => {
  const [isSearchChanged, setIsSearchChanged] = useState(false);
  const [data, setData] = useState("");
  const onChangeSearch = (localVal) => {
    setData(localVal);
    setIsSearchChanged(!isSearchChanged);
  };

  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("foodSafetyCountByEmirate")}</h3>
      </div>
      <div className="d-flex flex-column justify-content-center search-page mt-1 container-fluid d-flex flex-shrink-0">
        <CountByEmirateReportFilter
          {...props}
          onChangeSearch={onChangeSearch}
        />

        <CountByEmirateGrid
          {...props}
          searchData={data}
          isSearchChanged={isSearchChanged}
        />
      </div>
    </div>
  );
};

export default FoodSafetyByEmirateCount;
