import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, encrypt, enums } from "../Imports";
import AnimalHealth from "./AnimalHealth/AnimalHealth";
import FoodSecurity from "./FoodSecurity/FoodSecurity";
import Agriculture from "./Agriculture/Agriculture";
import DisasterManagement from "./DisasterManagement/DisasterManagement";

const Dashboard = (props) => {
  let { type } = useParams();
  type = encrypt.decrypt(type);
  const user = auth.getUserPermission();
  const [isFoodSecurity, setIsFoodSecurity] = useState(false);
  const [isAnimalHealth, setIsAnimalHealth] = useState(false);
  const [isAgriculture, setIsAgriculture] = useState(false);
  const [isDisasterManagement, setIsDisasterManagement] = useState(false);

  useEffect(() => {
    setIsFoodSecurity(
      type === enums.DashboardType.FoodSecurity ||
        (type === enums.DashboardType.General &&
          user.Department ===
            enums.DepartmentEnum.FoodSafetyDepartment.toString())
    );
    setIsAnimalHealth(
      type === enums.DashboardType.Animal ||
        (type === enums.DashboardType.General &&
          user.Department ===
            enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment.toString())
    );
    setIsAgriculture(
      type === enums.DashboardType.Agriculture ||
        (type === enums.DashboardType.General &&
          user.Department ===
            enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment.toString())
    );
    setIsDisasterManagement(
      type === enums.DashboardType.DisasterManagement ||
        (type === enums.DashboardType.General &&
          user.Department ===
            enums.DepartmentEnum.EnvironmentalDisasterManagement.toString())
    );
    return () => {};
  }, []);

  return (
    <div>
      <div className="page-title p-3 pl-4">
        <h3>{props.t("Dashboard")}</h3>
      </div>
      <div className="container-fluid d-flex flex-column flex-grow-1 flex-shrink-0 my-4">
        <div className="d-flex flex-fill mr-4">
          <div className="ml-4 mr-1 flex-fill">
            {isFoodSecurity && <FoodSecurity {...props} />}
            {isAnimalHealth && <AnimalHealth {...props} />}
            {isAgriculture && <Agriculture {...props} />}
            {isDisasterManagement && <DisasterManagement {...props} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
