import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UnitTypeEnum } from "../../../Interface/enums";
import FormInput from "../../Common/Validation/FormInput";

import { iIncident, accountAction, auth } from "../../Imports";

const Reporter = (props) => {
  const [disableTxt, setDisableTxt] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [isBorderGateUser, setIsBorderGateUser] = useState(false);
  const isLogin = auth.checkLogin();
  const userData = auth.getUserPermission();

  const transaction = useSelector((state) => state.transaction);

  const getUserProfile_NotUpdateMode = async () => {
    if (isLogin && transaction.IncidentsMainTransactionDTO.Users?.Email === "") {
      setProfileData(await accountAction.getUserProfile(userData.Email));
      setIsBorderGateUser(userData.UnitType === UnitTypeEnum.BorderGate);
    }
  };
  const getUserProfile_UpdateMode = () => {
    setProfileData(transaction.IncidentsMainTransactionDTO.Users);
  };

  useEffect(() => {
    setTimeout(() => {
      if (transaction.isUpdate) {
        getUserProfile_UpdateMode();
      } else {
        getUserProfile_NotUpdateMode();
      }
    }, 0);
  }, [transaction.isUpdate]);

  const setProfileData_textBox = () => {
    props.handleChange("NameEn", profileData.UsersProfile.NameEn, iIncident.SharedDTO.UP);
    props.handleChange("NameAr", profileData.UsersProfile.NameAr, iIncident.SharedDTO.UP);
    props.handleChange("Email", profileData.Email, iIncident.SharedDTO.UP);
    props.handleChange("ReporterEID", profileData.UsersProfile.ReporterEID, iIncident.SharedDTO.UP);
    props.handleChange("PassportNo", profileData.UsersProfile.PassportNo, iIncident.SharedDTO.UP);
    props.handleChange("MobileNo", profileData.UsersProfile.MobileNo, iIncident.SharedDTO.UP);
    setDisableTxt(true);
  };
  useEffect(() => {
    if (profileData?.constructor === Object && Object.keys(profileData).length > 0) {
      setProfileData_textBox();
    }
  }, [profileData]);

  return (
    <div className="row my-1 container-bg">
      <label className="control-title my-2 mb-4">
        <strong>{props.t("reporterDetail")}</strong>
      </label>
      <div className="col-md-4">
        <FormInput
          name="NameEn"
          label="reporterName"
          required={true}
          disabled={disableTxt}
          value={transaction[iIncident.SharedDTO.UP].NameEn}
          onChange={(e) => {
            props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.UP);
            props.handleChange("NameAr", e.target.value, iIncident.SharedDTO.UP);
          }}
        />
        {/* <em className="control-description  ">{props.t("required")}</em> */}
      </div>
      <div className="col-md-4">
        <FormInput
          name="MobileNo"
          type="text"
          label="mobileNo"
          // pattern="\d*"
          maxLength={15}
          minLength={10}
          required={true}
          disabled={disableTxt}
          value={transaction[iIncident.SharedDTO.UP].MobileNo}
          onChange={(e) => props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.UP)}
        />
        {/* <em className="control-description">{props.t("required")}</em> */}
      </div>
      {!isBorderGateUser && (
        <>
          <div className="col-md-4">
            <FormInput
              name="ReporterEID"
              type="text"
              dir="ltr"
              disabled={disableTxt}
              placeholder="784-xxxx-xxxxxxx-x"
              errorMessage={props.t("emiratesID") + props.t("incorrect")}
              label="emiratesID"
              required={!transaction[iIncident.SharedDTO.UP].PassportNo}
              maxLength={18}
              pattern="^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$"
              value={transaction[iIncident.SharedDTO.UP].ReporterEID}
              onChange={(e) => props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.UP)}
            />
            <em className="control-description  ">{props.t("emirateIDPassportRequired")}</em>
          </div>
          <div className="col-md-4">
            <FormInput
              name="PassportNo"
              type="text"
              label="passportNumber"
              required={!transaction[iIncident.SharedDTO.UP].ReporterEID}
              disabled={disableTxt}
              value={transaction[iIncident.SharedDTO.UP].PassportNo}
              onChange={(e) => props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.UP)}
            />
            <em className="control-description  ">{props.t("emirateIDPassportRequired")}</em>
          </div>
        </>
      )}
      <div className="col-md-4">
        <FormInput
          name="Email"
          type="email"
          label="email"
          disabled={disableTxt}
          errorMessage={props.t("email") + props.t("incorrect")}
          // pattern={constants.email_regx}
          required={true}
          value={transaction[iIncident.SharedDTO.UP].Email}
          onChange={(e) => props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.UP)}
        />
        {/* <em className="control-description">{props.t("required")}</em> */}
      </div>
    </div>
  );
};

export default Reporter;
