import axios from "axios";
import { URLs } from "../../Constants/Common";

export const create = async (data) => {
  return await axios.post(URLs.CreateEnvironmentalIncident, data);
};
export const update = async (data) => {
  return await axios.post(URLs.UpdateEnvironmentalIncident, data);
};

export const getTransactionById = async (id) => {
  return await axios.get(URLs.getEnvironmentalIncidentTransactionById + "?id=" + id);
};
export const getEnvironmentalIncidentReport = async (data) => {
  return await axios.post(URLs.GetEnvironmentalIncidentReport, data);
};
