import React from "react";

function Copyright(props) {
  return (
    <>
      <div className="page-title p-3 pl-4">
        <h3> {props.t("Copyright")}</h3>
      </div>

      <div
        className="m-4"
        dangerouslySetInnerHTML={{ __html: props.t("CopyRight_Description") }}
      ></div>
    </>
  );
}

export default Copyright;
