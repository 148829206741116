export const GeneralSearchGridMapper = (data) => {
  return data.map((x) => ({
    RefNo: x?.RefNo,
    IncidentType: x?.IncidentType,
    LocationGPS: `North :${x?.Latitude} East :${x?.Longitude}`,
    Status: x?.StatusCode,
    IncidentDate: x?.CreatedDate,
    Emirate: x?.GovtAgency?.EmirateLkp?.NameAr,
    Entity: x?.GovtAgency.NameAr,
    ReporterName: x?.Users?.UsersProfile?.NameAr,
    ReporterPhone: x?.Users?.UsersProfile.MobileNo,
    ReporterEmail: x.Users?.UsersProfile.Email,
    ReporterEmirateId: x.Users?.UsersProfile.ReporterEID,
    ReporterPassport: x.Users?.UsersProfile.PassportNo,
    ReporterAddress: x.Users?.UsersProfile.Address,
    ActionLog: x.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};
export const OverAllReportGridMapper = (data) => {
  return data.map((x) => ({
    RefNo: x?.RefNo,
    IncidentType: x?.IncidentType,
    LocationGPS: `North :${x?.Latitude} East :${x?.Longitude}`,
    Status: x?.StatusCode,
    IncidentDate: x?.CreatedDate,
    Emirate: x?.GovtAgency?.EmirateLkp?.NameAr,
    Entity: x?.GovtAgency.NameAr,
    RiskDegree: x?.RiskDegreeCode,
    Comments: x.WorkFlowActionHistorys[x?.WorkFlowActionHistorys.length - 1].Comments?.substr(0, 32760),
    ReporterName: x?.Users?.UsersProfile?.NameAr,
    ReporterPhone: x?.Users?.UsersProfile.MobileNo,
    ReporterEmail: x.Users?.UsersProfile.Email,
    ReporterEmirateId: x.Users?.UsersProfile.ReporterEID,
    ReporterPassport: x.Users?.UsersProfile.PassportNo,
    ReporterAddress: x.Users?.UsersProfile.Address,
    ActionLog: x.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};
