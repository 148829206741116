import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { dashboardAction, validation } from "../../Imports";
import BoxUI from "../Shared/BoxUI";

const StatusCount = (props) => {
  const [agricultureStateCount, setAgricultureStateCount] = useState({});
  const emirateId = validation.getUserEmirateId();

  const loadComLkp = useCallback(async () => {
    setAgricultureStateCount((await dashboardAction.getAgricultureStateCount(emirateId)).data.ResponseData);
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <>
      <BoxUI
        title={`${props.t("BorderPortsIncidents")}`}
        link="/IncidentDetail/AgriculturePestIncident/BorderPortsIncidents"
        count={agricultureStateCount?.BorderGateCount ?? 0}
      />
      <BoxUI
        title={`${props.t("GovernmentPestIncidents")}`}
        link="/IncidentDetail/AgriculturePestIncident/GovernmentPestIncidents"
        count={agricultureStateCount?.GovtIncidentCount ?? 0}
      />
      <BoxUI
        title={`${props.t("NonGovernmentPestIncidents")}`}
        link="/IncidentDetail/AgriculturePestIncident/NonGovernmentPestIncidents"
        count={agricultureStateCount?.NovGovtIncidentCount ?? 0}
      />
    </>
  );
};

export default StatusCount;
