import React from "react";
// import Pagination from "react-bootstrap/Pagination";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { ExportToExcel } from "../../Incidents/Inbox/Reports/Excel/Export2Excel";
import { paginationDto } from "../../Incidents/Inbox/ReportControl";

const PaginationUI = (props) => {
  const paginate = paginationDto;
  return (
    <div className="d-flex p-3 mt-2">
      <div>
        <label>
          {props.t("TotalRecords")}: {props.TotalItems}
        </label>
      </div>
      <div className="pager col d-flex justify-content-center">
        {props.totalPages > 1 && (
          <PaginationControl
            total={props?.TotalItems}
            page={props?.currentPage}
            between={3}
            limit={props.pageSize ?? paginate?.paginationDto?.pageSize}
            changePage={props.onClick}
            ellipsis={1}
          />
        )}
      </div>
      {props.getExcelReport && (
        <div>
          <ExportToExcel getExcelReport={props.getExcelReport} fileName={props.ExcelFileName} {...props} customHeader={props.CustomHeader} />
        </div>
      )}
    </div>
  );
};

export default PaginationUI;
