import React, { useEffect, useState } from "react";
import { auth, master, swal } from "../Imports";
import FormInput from "./Validation/FormInput";
import { useNavigate } from "react-router-dom";
import { constants } from "../../Constants/Common";

function ContactUs(props) {
  const user = auth.getUserPermission();
  const navigate = useNavigate();
  const initialState = {
    Email: user.Email,
    Name: user.Name,
    PhoneNo: "",
    SubjectTitle: "",
    SubjectDetail: "",
  };
  //

  const [localVal, setLocalVal] = useState(initialState);
  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    const isSent = await master.createUpdate_ContactUs(localVal);
    if (isSent) {
      swal(props.t("success"), props.t("messageSentSuccessfully"), "success");
      cancelHandler();
    } else {
      swal(props.t("Error"), props.t("SomethingWentWrong"), "error");
    }
  };
  const cancelHandler = () => {
    navigate(`/`);
  };
  useEffect(() => {
    console.log(localVal);
    return () => {};
  }, [localVal]);

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className="page-title p-3 pl-4">
          <h3>{props.t("ContactUs")}</h3>
        </div>
        <div className="container my-3 ">
          <div
            className="my-3 alert alert-warning"
            dangerouslySetInnerHTML={{
              __html: props.t("ContactUs_Description"),
            }}
          ></div>
          <div className="row container-bg contactusForm">
            <div className="col-md-4">
              {user.Name ? (
                <>
                  <div>{props.t("Name")}</div>
                  <b className="mt-1">{localVal.Name}</b>
                </>
              ) : (
                <FormInput
                  name="Name"
                  disabled={user.Name ?? false}
                  required={true}
                  type="text"
                  label={props.t("Name")}
                  value={localVal.Name}
                  onChange={handleChange}
                />
              )}
            </div>
            <div className="col-md-4">
              {user.Email ? (
                <>
                  <div>{props.t("email")}</div>
                  <b className="mt-1">{localVal.Email}</b>
                </>
              ) : (
                <FormInput
                  name="Email"
                  disabled={user.Email ?? false}
                  required={true}
                  type="email"
                  label={props.t("email")}
                  errorMessage={props.t("email") + props.t("incorrect")}
                  // pattern={constants.email_regx}
                  value={localVal.Email}
                  onChange={handleChange}
                />
              )}
            </div>
            <div className="col-md-4">
              <FormInput
                name="PhoneNo"
                disabled={false}
                required={true}
                type="text"
                label={props.t("mobileNo")}
                value={localVal.PhoneNo}
                pattern=".{10,}"
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                name="SubjectTitle"
                disabled={false}
                required={true}
                type="text"
                label={props.t("SubjectTitle")}
                value={localVal.SubjectTitle}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-8">
              <FormInput
                name="SubjectDetail"
                disabled={false}
                required={true}
                type="textarea"
                label={props.t("SubjectDetail")}
                value={localVal.SubjectDetail}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-4 d-flex justify-content-around  ">
              <button className="btn btn-success " type="submit">
                {props.t("Send")}
              </button>
              <button className="bt-reset" type="button" onClick={cancelHandler}>
                {props.t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
