import React from "react";

const PestIncidentGIS = (props) => {
  return (
    <div>
      <div className="graph-item">
        <div className="graph-item-inner d-flex flex-column">
          <div className="d-flex justify-content-between mt-1">
            <span className="p-2">{props.t("PestIncidents")}</span>
          </div>
          <div>
            <div style={{ height: 450 }}>
              <iframe
                title="animalDisease"
                id="MapFrame"
                src={`${process.env.REACT_APP_GIS_URL}/agriculture/map/${props.fileType}`}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  overflow: "hidden",
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PestIncidentGIS;
