import { DispatchTypes } from "../DispatchType";

const InitialState = {
  unReadNotificationData: [],
};
const NotificationReducer = (state = InitialState, action) => {
  switch (action.type) {
    case DispatchTypes.UNREAD_NOTIFICATION:
      return {
        ...state,
        unReadNotificationData: action.unReadNotificationData,
      };

    default:
      return state;
  }
};
export default NotificationReducer;
