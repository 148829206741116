import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { enums, encrypt, validation, auth, iIncident, agrPestAction, transAction, swal, iAgrculturePest, iAnimalHealth } from "../../Imports";
import { useDispatch, useSelector } from "react-redux";
import Attachment from "../Common/Attachment";
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import Reporter from "../Common/Reporter";
import GPSLocation from "../Common/LocationDetail/GPSLocation";
import PlantTypeDetail from "./PlantTypeDetail";
import Holdings from "../Common/Holdings";
import EmirateDistrict from "../Common/LocationDetail/EmirateDistrict";
import IncidentSource from "./IncidentSource";
import OtherDetail from "./OtherDetail";

const AgriculturePest = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  followup = parseInt(encrypt.decrypt(followup));
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  useEffect(() => {
    // console.log(transaction);
  }, [transaction]);

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await agrPestAction.getTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;

      handleChangeDTO(res, iAgrculturePest.AP_Constants.APT);
      handleChangeDTO(res.IncidentHoldings, iAnimalHealth.AH_Constants.IH);
      handleChangeDTO(res.AgriculturePestDetail, iAgrculturePest.AP_Constants.APTD);

      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);
      validation.isShowInfectionForm(transaction, "agriculturePestForm");
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iAgrculturePest.AgriculturePestTransactionDTO();
    data = transaction.AgriculturePestTransactionDTO;
    data.AgriculturePestDetail = transaction.AgriculturePestDetailDTO;
    data.IncidentHoldings = transaction.IncidentsHoldingsDTO;

    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.AgriculturePestIncident);

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }

    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await agrPestAction.create(data);
    else if (followup > 0) {
      res = await agrPestAction.createFollowUp(data);
    } else {
      res = await agrPestAction.update(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };
  const validateDetailForm_public = (e) => {
    const gpsLocationFrom = validation.validateForm("gpsLocationFrom");
    if (!gpsLocationFrom) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.AgriculturePestDetailDTO.length === 0) {
      toast(props.t("SpeciesDetailRequired"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (!validation.captchaValidation(transaction, props)) {
      return false;
    }
    return true;
  };
  const validateDetailForm_user = (e) => {
    const actionFrom = validation.validateForm("actionbuttonForm");
    const gpsLocationFrom = validation.validateForm("gpsLocationFrom");
    const otherDetailForm = validation.validateForm("otherDetailForm");

    if (!actionFrom || !validation.validateInvestigationDetailForm() || !gpsLocationFrom || !otherDetailForm) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.AgriculturePestDetailDTO.length === 0) {
      toast(props.t("SpeciesDetailRequired"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (
      (transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.Positive ||
        transaction.IncidentsMainTransactionDTO.WfActionCode === enums.WorkFlowActionCodeEnum.Negative) &&
      transaction.FileAttachmentDTO.findIndex((x) => x.Code === enums.AttachmentEnum.LabTestReport) < 0
    ) {
      toast(props.t("LabResultRequired"), { type: "warning" });
      return false;
    }
    return true;
  };

  const formValidation = () => {
    if (auth.checkLogin()) {
      return validateDetailForm_user();
    } else {
      return validateDetailForm_public();
    }
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("reportAgriculturalPestsIncident")}</h3>
            </div>
            <div className="col-sm-12 ">
              <Tabs defaultActiveKey="mainDetail">
                <Tab eventKey="mainDetail" title={props.t("reporterDetail")}>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}

                    <div className="container ">
                      <Reporter handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="ReportData" title={props.t("ReportData")}>
                  <div className="tab-item-wrapper">
                    <div className="container  ">
                      <div className="row container-bg my-2 gpsLocationFrom">
                        <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />

                        <EmirateDistrict
                          emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                          isDisabled={false}
                          handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                          {...props}
                        />

                        <IncidentSource handleChange={handleChange} {...props} />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="FarmOwnerInformation" title={props.t("FarmOwnerInformation")}>
                  <div className="tab-item-wrapper">
                    <div className="container">
                      <Holdings OwnerShipType={enums.OwnerShipTypeEnum.Farm.toString()} handleChange={handleChange} disabled={false} {...props} />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="TypesDetail" title={props.t("TypesDetail")}>
                  <div className="tab-item-wrapper">
                    <div className="container agriculturePestForm">
                      <PlantTypeDetail handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                    </div>

                    <OtherDetail handleChange={handleChange} handleChangeDTO={handleChangeDTO} {...props} />
                    <Attachment
                      required={true}
                      handleChangeDTO={handleChangeDTO}
                      code={enums.AttachmentEnum.Other}
                      canUploadFile={validation.isGovtUser()}
                      {...props}
                    />
                    {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                    <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgriculturePest;
