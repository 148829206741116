import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { encrypt, enums, incidentAction, util } from "../../../Imports";
import ModalView from "../../../Common/UI/ModalView";
import LoaderUI from "../../../Common/Loader/LoaderUI";

const FollowUpReportIncident = (props) => {
  console.log(props);
  const [incidnetData, setIncidentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getFollowUpData = useCallback(async () => {
    const res = await incidentAction.getFollowUpIncident(props.data.Id);
    if (res.data && res.data.IsSuccess) {
      if (res.data.ResponseData.length > 0) {
        setIncidentData(res.data.ResponseData);
      }
      setIsLoading(false);
    }
  }, [props.data.Id]);

  useEffect(() => {
    getFollowUpData();
  }, []);

  return (
    <>
      <ModalView
        modalStyle="modal-style"
        isOpen={props.showModal}
        handleShowModal={props.handleShowModal}
        title="FollowupReport"
        body={
          <div>
            {isLoading && <LoaderUI />}
            {!isLoading && (
              <div className="container fsFollowUpModal">
                {incidnetData.length > 0 &&
                  incidnetData.map((e) => {
                    return (
                      <div className="row bottom-border" key={e.Id}>
                        <div className="col-md-3">
                          <Link
                            to={
                              e.IncidentTypeCode === enums.IncidentTypeEnum.FoodSafetyIncident
                                ? `/food-safety-followup/MA==/${encrypt.encrypt(e.Id)}`
                                : `/animalHealthincident/${encrypt.encrypt(e.Id)}/MA==`
                            }
                            target="_blank"
                          >
                            <small>
                              <strong>{e.RefNo}</strong>
                            </small>
                          </Link>
                        </div>

                        <div className="col-md-3">
                          <span>{util.getTextFromObj(e.WorkFlowActionHistorys[0]?.OrganizationUnitsLkp?.GovernmentalEntityLkp)}</span>
                        </div>
                        <div className="col-md-3">
                          <span
                            className="status-box mx-2"
                            style={{
                              backgroundColor: `${e.WorkFlowActionHistorys[0]?.WorkFlowState?.WorkFlowStateLkp?.Color}`,
                            }}
                          >
                            {util.getTextFromObj(e.WorkFlowActionHistorys[0]?.WorkFlowState?.WorkFlowStateLkp)}
                          </span>
                        </div>
                        <div className="col-md-3">
                          <small>{new Date(e.CreatedDate).toLocaleString()}</small>
                        </div>
                      </div>
                    );
                  })}
                {incidnetData.length === 0 && <div>{props.t("dataNotFound")}</div>}
              </div>
            )}
          </div>
        }
        footer={<div></div>}
      />
    </>
  );
};

export default FollowUpReportIncident;
