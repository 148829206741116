import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import PaginationUI from "../../../../Common/UI/PaginationUI";
import { foodSafetyAction, foodSafetyBGAction, util } from "../../../../Imports";
import { ColumnCreateDate, ColumnGPSLocation } from "../../Grids/SearchGrid";
import { IncidentTypeEnum } from "../../../../../Interface/enums";
import * as mapper from "./DataMapper";
import { pageSettingDto } from "../../ReportControl";

const FoodSafetyGrid = (props) => {
  let pageSetting = { ...pageSettingDto };
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(pageSetting);

  const onPageChange = async (page) => {
    await getGridData(props.searchData, page);
  };
  const getGridData = async (data, page) => {
    if (!data) return;
    props.setLoading(true);
    data.paginationDto.pageNo = page;
    let resp = null;
    console.log(data);
    if (data.IncidentTypeCode === IncidentTypeEnum.FoodSafetyBorderGate) {
      resp = await foodSafetyBGAction.getFoodSafetyBorderGateReport(data);
    } else {
      resp = await foodSafetyAction.getFoodSafetyReport(data);
    }
    const result = resp.data.ResponseData;
    setData(result);
    setPagination((pre) => ({
      ...pre,
      totalPages: Math.ceil(resp.data.TotalItems / pageSetting.totalPages),
      TotalItems: resp.data.TotalItems,
      currentPage: page,
      showMax: page > pageSetting.showMax ? pageSetting.showMax : 2,
    }));
    props.setLoading(false);
  };
  const getSearchData = useCallback(async () => {
    console.log(props.searchData);
    await getGridData(props?.searchData, 1);
  }, [getGridData]);

  useEffect(() => {
    getSearchData();
  }, [props.isSearchChanged]);

  const exportToPDF = async () => {
    let data = props?.searchData;
    data.paginationDto.pageSize = 0;
    console.log("---excel report---");
    let res = null;
    if (data.IncidentTypeCode === IncidentTypeEnum.FoodSafetyBorderGate) {
      res = await foodSafetyBGAction.getFoodSafetyBorderGateReport(data);
    } else {
      res = await foodSafetyAction.getFoodSafetyReport(data);
    }
    return mapper.FoodSafetyMapper(res.data.ResponseData);
  };
  return (
    <div>
      <div className="table-container col flex-fill">
        <div className="table-wrapper p-2 m-3 container-bg table-responsive">
          {data?.length === 0 && (
            <div className="text-center mt-5 search-message" id="nothingFoundMessage">
              <i className="ico icon-Group-420"></i>
              <p className="mt-4">
                {props.t("NoSearchResult")}
                <strong className="text-dark  "></strong>
              </p>
            </div>
          )}
          {data?.length > 0 && (
            <>
              <table className="table table-striped table-hover text-center m-0">
                <thead>
                  <tr>
                    <th>{props.t("IncidentNumber")}</th>
                    <th>{props.t("IncidentType")}</th>
                    <th>{props.t("incidentLocation")} </th>
                    <th>{props.t("IncidentDate")}</th>
                    <th>{props.t("IncidentStatus")}</th>
                    <th>{props.t("RiskDegree")}</th>
                    <th>{props.t("NotificationType")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((trans, index) => {
                    return (
                      <tr key={index}>
                        <td>{trans.IncidentsTransaction.RefNo}</td>
                        <td>{util.getTextFromObj(trans?.IncidentsTransaction?.IncidentType)}</td>
                        <td>
                          <ColumnGPSLocation trans={trans.IncidentsTransaction} />
                        </td>
                        <td>
                          <ColumnCreateDate incident={trans.IncidentsTransaction} />
                        </td>

                        <td>{trans?.IncidentsTransaction?.StatusCode}</td>
                        <td>{util.getTextFromObj(trans?.IncidentsTransaction?.RiskDegreeLkp)}</td>
                        <td>{trans?.NotificationTypeLkp?.NameAr}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}

          {data?.length > 0 && (
            <PaginationUI
              {...props}
              TotalItems={pagination.TotalItems}
              totalPages={pagination.totalPages}
              currentPage={pagination.currentPage}
              pageSize={pageSetting.pageSize}
              showMax={pageSetting.showMax}
              onClick={onPageChange}
              getExcelReport={exportToPDF}
              ExcelFileName={"FoodSafety"}
              CustomHeader={""}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FoodSafetyGrid;
