import React from "react";
import FormInput from "../../Common/Validation/FormInput";

function ExtraDataHealthFacility(props) {
  const inputs = [
    {
      id: 0,
      name: "healthFacilityName",
      type: "text",
      placeholder: "",
      errorMessage: "",
      label: props.t("healthFacilityName"),
      required: false,
      fontIcon: "",
    },
  ];
  function onChangeAddress(e) {
    props.handleChange({
      target: {
        name: [e.target.name],
        value: e.target.value,
      },
    });
  }
  return (
    <div>
      <div className="row healthfacilitydata  ">
        <div className="col-6">
          <FormInput
            key={inputs[0].id}
            {...inputs[0]}
            value={props.values[inputs[0].name]}
            onChange={props.handleChange}
          />
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>{props.t("healthFacilityAddress")}</label>
            <textarea
              className="form-control"
              name="healthFacilityAddress"
              rows="2"
              cols="20"
              value={props.values["healthFacilityAddress"]}
              onChange={onChangeAddress}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraDataHealthFacility;
