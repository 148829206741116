import React from "react";
import { useSelector } from "react-redux";
import { iFoodSafety } from "../../../Imports";
import * as Control from "../SharedControl";

const ProductInfo = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const FSPI = iFoodSafety.FS_Constants.FSPI;
  const FST = iFoodSafety.FS_Constants.FST;
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, FSPI);
  };
  const changeControl_FST = (e) => {
    props.handleChange(e.target.name, e.target.value, FST);
  };
  const handleChange = (name, value) => {
    props.handleChange(name, value, FSPI);
  };
  return (
    <div className="container my-2">
      <div className="row container-bg">
        <Control.ProductGroup transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.ProductName transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.ProductMainCategory transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.ProductSubCategory1 transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.ProductSubCategory2 transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.ProductSubCategory3 transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.ProductTradeName transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.Volume transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.Unit transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.PackageType transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.BatchNumber transaction={transaction[FSPI]} changeControl={changeControl} />

        <Control.ProductProductionDate transaction={transaction[FSPI]} changeControl={handleChange} />
        <Control.ProductExpiryDate transaction={transaction[FSPI]} changeControl={handleChange} />
        <Control.OtherInfo transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.FactoryName transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.FactoryAddress transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.FactoryOrigin transaction={transaction[FSPI]} changeControl={changeControl} />
        <Control.BorderPort transaction={transaction[FST]} changeControl={changeControl_FST} emirateId={transaction.IncidentsMainTransactionDTO.EmirateId} />
        <Control.ArrivalDate transaction={transaction[FSPI]} changeControl={handleChange} />

        <Control.ExporterCountry transaction={transaction[FST]} changeControl={changeControl_FST} />
        <Control.TransportMedia transaction={transaction[FST]} changeControl={changeControl_FST} />
        <Control.ItemsCount transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.TotalAmount transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.ShipmentNumber transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.ExporterName transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.ExporterAddress transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.ContainerNumber transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.ImporterName transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
        <Control.ImporterAddress transaction={transaction[FST]} changeControl={changeControl_FST} isRequired={false} />
      </div>
    </div>
  );
};

export default ProductInfo;
