import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iFoodSafetyBG, util } from "../../../Imports";
import * as Control from "../SharedControl";

const ImporterExporterInfo = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const FSBGT = iFoodSafetyBG.FSBD_Constants.FSBGT;
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, FSBGT);
  };
  return (
    <div className="container my-2">
      <div className="row container-bg">
        <Control.ImporterName transaction={transaction[FSBGT]} changeControl={changeControl} isRequired={true} />
        <div className="col-md-4">
          <FormInput
            name="ImporterPhone"
            type="text"
            label="ImporterPhone"
            required={true}
            disabled={false}
            value={transaction[FSBGT].ImporterPhone}
            onChange={changeControl}
          />
        </div>

        <Control.ImporterAddress transaction={transaction[FSBGT]} changeControl={changeControl} isRequired={true} />

        <Control.ExporterName transaction={transaction[FSBGT]} changeControl={changeControl} isRequired={true} />
        <Control.ExporterAddress transaction={transaction[FSBGT]} changeControl={changeControl} isRequired={true} />

        <div className="col-md-4">
          <FormInput
            name="RejectionDate"
            type="date"
            label="RejectionDate"
            selectedDate={util.SelectedDate(transaction[FSBGT].RejectionDate)}
            required={true}
            disabled={false}
            onChange={(date) => {
              props.handleChange("RejectionDate", util.SetDate(date), FSBGT);
            }}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="RejectionReason"
            type="text"
            label="RejectionReason"
            required={true}
            disabled={false}
            value={transaction[FSBGT].RejectionReason}
            onChange={changeControl}
          />
        </div>
      </div>
    </div>
  );
};

export default ImporterExporterInfo;
