import React, { useState } from "react";
import { RegistrationType } from "../../../Interface/Registration";
import { UserConfirmPassword, UserPassword } from "../CommonControl";

function SecurityData(props) {
  console.log(props.values.NonGovTypeCode);
  const Previous = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form submitted");
    if (
      props.values.NonGovTypeCode === RegistrationType.public ||
      props.values.NonGovTypeCode === RegistrationType.AgrcultureCompany
    )
      props.submitHandlerRegistration();
    else props.nextStep();
  };
  // const onChangePassword = (e) => {
  //   props.setValues({
  //     ...props.values,
  //     users: { password: e.target.value },
  //   });
  // };
  const onChangePreferredLanguage = (e) => {
    props.handleChange({
      target: { name: "prefLanguage", value: e.target.value },
    });
  };
  const handleChangeConfirmPassword = (e) => {
    props.handleChange({
      target: { name: "confirmPassword", value: e.target.value },
    });
  };
  return (
    <form id="regStep3Form" className="current  " onSubmit={submitHandler}>
      <div className="row">
        <div className="col-6">
          <UserPassword
            name="PasswordUI"
            value={props.values["PasswordUI"]}
            label="password"
            handleChange={props.handleChange}
            props={props}
          />
        </div>
        <div className="col-6">
          <UserConfirmPassword
            name="confirmPassword"
            label="password"
            currentPassField={props.values.PasswordUI}
            handleChange={props.handleChange}
            props={props}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>
              {props.t("preferredLanguage")} <span className="red"> *</span>
            </label>
            <label className="radio">
              <input
                type="radio"
                name="prefLanguage"
                value="en"
                checked={
                  props.values.prefLanguage &&
                  props.values.prefLanguage === "en"
                }
                onChange={onChangePreferredLanguage}
              />
              <span>English</span>
            </label>
            <label className="radio">
              <input
                type="radio"
                name="prefLanguage"
                value="ar"
                checked={
                  props.values.prefLanguage &&
                  props.values.prefLanguage === "ar"
                }
                onChange={onChangePreferredLanguage}
              />
              <span>عربي</span>
            </label>
          </div>
        </div>
      </div>
      <div className="d-flex mt-5">
        <button
          type="submit"
          className="btn flex-fill p-2 mr-3 col prev"
          onClick={Previous}
        >
          {props.t("previous")}
        </button>
        {props.values.NonGovTypeCode !== RegistrationType.public &&
          props.values.NonGovTypeCode !==
            RegistrationType.AgrcultureCompany && (
            <button type="submit" className="btn flex-fill p-2 ml-1 col next">
              {props.t("next")}
            </button>
          )}
        {(props.values.NonGovTypeCode === RegistrationType.public ||
          props.values.NonGovTypeCode ===
            RegistrationType.AgrcultureCompany) && (
          <button type="submit" className="btn flex-fill p-2 ml-1 col next">
            {props.t("createAccount")}
          </button>
        )}
      </div>
    </form>
  );
}

export default SecurityData;
