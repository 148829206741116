import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animalHealthAction, encrypt, enums } from "../../../Imports";
import ModalView from "../../../Common/UI/ModalView";

const RelatedIncident = (props) => {
  const [incidnetData, setIncidentData] = useState([]);
  const getDataRelatedIncident = useCallback(async () => {
    const res = await animalHealthAction.getRelatedIncidents(props.data.Id);

    if (res.data && res.data.IsSuccess) {
      setIncidentData(res.data.ResponseData);
    }
  }, [props.data.Id]);

  useEffect(() => {
    getDataRelatedIncident();
  }, []);
  return (
    <ModalView
      isOpen={props.showModal}
      handleShowModal={props.handleShowModal}
      title="RelatedIncident"
      body={
        <div>
          <div className="container">
            {incidnetData.length > 0 &&
              incidnetData.map((e) => {
                return (
                  <div className="row" key={e.Id}>
                    <div className="col-md-5">
                      <Link
                        to={
                          e.IncidentsTransaction.IncidentTypeCode ===
                          enums.IncidentTypeEnum.FoodSafetyIncident
                            ? `/food-safety-followup/MA==/${encrypt.encrypt(
                                e.IncidentsTransaction.Id
                              )}`
                            : `/animalHealthincident/${encrypt.encrypt(
                                e.IncidentsTransaction.Id
                              )}/MA==`
                        }
                        target="_blank"
                      >
                        <small>
                          <strong>{e.IncidentsTransaction.RefNo}</strong>
                        </small>
                      </Link>
                    </div>
                    <div className="col-md-5">
                      <small>
                        {new Date(
                          e.IncidentsTransaction.CreatedDate
                        ).toLocaleString()}
                      </small>
                    </div>
                  </div>
                );
              })}
            {incidnetData.length === 0 && <div>{props.t("dataNotFound")}</div>}
          </div>
        </div>
      }
      footer={<div></div>}
    />
  );
};

export default RelatedIncident;
