import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { paginationDto } from "../../ReportControl";
import { auth, enums, validation } from "../../../../Imports";
import AnimalHealthGrid from "./AnimalHealthGrid";
import ReportFilter from "./ReportFilter";
import AnimalBGGrid from "./AnimalBGGrid";
import AnimalLVGrid from "./AnimalLVGrid";

const AnimalHealthReport = (props) => {
  let { type } = useParams();
  const [isSearchChanged, setIsSearchChanged] = useState(false);
  const user = auth.getUserPermission();
  const initialState = {
    fromDate: "",
    toDate: "",
    EmirateId: validation.getUserEmirateId(),
    refNo: "",
    DepartmentId: user.Department,
    ReporterName: "",
    ReporterMobileNo: "",
    UserAccountType: "",
    wfActionCode: 0,
    statusCode: "",
    RiskDegree: "",
    LegalViolationTypeId: 0,
    AnimalDiseaseId: 0,
    BorderPortId: 0,
    ...paginationDto,
  };
  const [data, setData] = useState(initialState);
  const onChangeSearch = (localVal) => {
    console.log("onChangeSearch", localVal);
    setIsSearchChanged(!isSearchChanged);
    setData(localVal);
  };
  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        {type === enums.IncidentTypeEnum.AnimalHealth && <h3>{props.t("animalHealthDetailReport")}</h3>}
        {type === enums.IncidentTypeEnum.AnimalBorderGate && <h3>{props.t("borderGateDetailReport")}</h3>}
        {type === enums.IncidentTypeEnum.AnimalLegalViolation && <h3>{props.t("leagalViolationDetailReport")}</h3>}
      </div>
      <div className="d-flex flex-column justify-content-center search-page mt-1 container-fluid d-flex flex-shrink-0">
        <ReportFilter {...props} type={type} onChangeSearch={onChangeSearch} data={data} />
        {type === enums.IncidentTypeEnum.AnimalHealth && <AnimalHealthGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />}
        {type === enums.IncidentTypeEnum.AnimalBorderGate && <AnimalBGGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />}
        {type === enums.IncidentTypeEnum.AnimalLegalViolation && <AnimalLVGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />}
      </div>
    </div>
  );
};

export default AnimalHealthReport;
