import { DispatchTypes } from "../DispatchType";

const InitialState = {
  fileUpload: [],
  deleteFile: "",
  googleMapLocation: "",
  googleMapAreaName: "",
};

const CommonReducer = (state = InitialState, action) => {
  switch (action.type) {
    case DispatchTypes.GOOGLE_MAP_LOCATION:
      return {
        ...state,
        googleMapLocation: action.googleMapLocation,
        googleMapAreaName: action.googleMapAreaName,
      };
    case DispatchTypes.FILE_UPLOAD:
      return {
        ...state,
        fileUpload: action.fileUpload,
      };
    case DispatchTypes.FILE_UPLOAD_MULTIPLE:
      return {
        ...state,
        fileUpload: action.fileUpload,
      };
    case DispatchTypes.DELETE_FILE:
      // if (state.fileUpload.length > 0) {
      //   state.fileUpload = state.fileUpload.filter(
      //     (x) => x.fileId !== action.fileId
      //   );
      // }
      return {
        ...state,
        fileUpload: state.fileUpload,
        fileId: action.fileId,
      };
    default:
      return state;
  }
};

export default CommonReducer;
