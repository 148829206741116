import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import { iAnimalBG, util } from "../../Imports";
import { constants } from "../../../Constants/Common";

const ShipmentDetail = (props) => {
  const shipConst = iAnimalBG.ABD_Constants.ABGSD;
  const transaction = useSelector((state) => state.transaction);
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, shipConst);
  };
  return (
    <div>
      <div className="container animalDetailForm my-2">
        <div className="row container-bg">
          <div className="col-md-4">
            <FormInput
              name="ShipmentDate"
              type="date"
              label="ShipmentDate"
              selectedDate={util.SelectedDate(transaction[shipConst].ShipmentDate)}
              required={true}
              disabled={false}
              onChange={(date) => {
                props.handleChange("ShipmentDate", util.SetDate(date), shipConst);
              }}
              maxDate={new Date()}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="ImporterName"
              type="text"
              label="ImporterName"
              required={true}
              disabled={false}
              value={transaction[shipConst].ImporterName}
              onChange={changeControl}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="ImporterPhone"
              type="text"
              label="ImporterPhone"
              pattern="\d*"
              maxLength={15}
              minLength={10}
              required={true}
              disabled={false}
              value={transaction[shipConst].ImporterPhone}
              onChange={changeControl}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="ImporterEmail"
              type="email"
              label="ImporterEmail"
              errorMessage={props.t("email") + props.t("incorrect")}
              // pattern={constants.email_regx}
              required={true}
              disabled={false}
              value={transaction[shipConst].ImporterEmail}
              onChange={changeControl}
            />
          </div>
          <div className="col-md-4">
            <FormInput
              name="ShipmentRejectionDate"
              type="date"
              label="ShipmentRejectionDate"
              selectedDate={util.SelectedDate(transaction[shipConst].ShipmentRejectionDate)}
              required={true}
              disabled={false}
              onChange={(date) => {
                props.handleChange("ShipmentRejectionDate", util.SetDate(date), shipConst);
              }}
              maxDate={new Date()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetail;
