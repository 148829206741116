export const DispatchTypes = {
  LOGOUT: "LOGOUT",
  AUTHENTICATE: "AUTHENTICATE",
  REGISTER: "REGISTER",
  IS_EMIRATEID_EXSIT: "IS_EMIRATEID_EXSIT",
  IS_PASSPORT_EXSIT: "IS_PASSPORT_EXSIT",
  IS_EMAIL_EXSIT: "IS_EMAIL_EXSIT",
  GET_USERTYPE: "GET_USERTYPE",
  GET_EMIRATES: "GET_EMIRATES",
  GET_DEPARTMENT: "GET_DEPARTMENT",
  GET_GOVENTITY: "GET_GOVENTITY",
  GET_HOLDINGOWNERSHIP_TYPE: "GET_HOLDINGOWNERSHIP_TYPE",
  GET_ANIMAL_CATAGORY: "GET_ANIMAL_CATAGORY",
  GET_ANIMAL_TYPE: "GET_ANIMAL_TYPE",
  GET_SCIENTIFIC_NAME: "GET_SCIENTIFIC_NAME",
  GET_CLINICTYPE_SYMPTOMS: "GET_CLINICTYPE_SYMPTOMS",
  GET_CLINICTYPE_SYMPTOMS_LKP: "GET_CLINICTYPE_SYMPTOMS_LKP",
  WORKFLOW_STATUS: "WORKFLOW_STATUS",
  GETAGRICULTURALTENURE: "GETAGRICULTURALTENURE",
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_UPLOAD_MULTIPLE: "FILE_UPLOAD_MULTIPLE",
  DELETE_FILE: "DELETE_FILE",
  GOOGLE_MAP_LOCATION: "GOOGLE_MAP_LOCATION",
  USER_ACCOUNT_TYPE: "USER_ACCOUNT_TYPE",
  RISK_DEGREE_LKP: "RISK_DEGREE_LKP",
  USER_PROFILE: "USER_PROFILE",
  GET_ANIMAL_DIEASE_LKP: "GET_ANIMAL_DIEASE_LKP",
  CONTROL_PROCEDURES: "CONTROL_PROCEDURES",
  UNREAD_NOTIFICATION: "UNREAD_NOTIFICATION",
  GET_TRANSACTION: "GET_TRANSACTION",
  SET_TRANSACTION_DTO: "SET_TRANSACTION_DTO",
  SET_TRANSACTION_NAME: "SET_TRANSACTION_NAME",
  SET_NEW_TRANSACTION: "SET_NEW_TRANSACTION",
};
