import React, { useCallback, useEffect, useState } from "react";
import { dashboardAction, master, util, validation } from "../../Imports";
import DateUI from "../Shared/DateUI";
import { SelectInput } from "../../Common/Validation/FormInput";
import GISMap from "./GISMap";
import ChartUI, { ChartType } from "../../Common/UI/ChartUI";

const Charts = (props) => {
  const emirateId = validation.getUserEmirateId();
  const [incidentByEmirateCount, setIncidentByEmirateCount] = useState([]);
  const [BlightDescriptionId, setBlightDescriptionId] = useState("");
  const loadComLkp = useCallback(async () => {
    setIncidentByEmirateCount((await dashboardAction.getAgricultureIncidentByEmirate(emirateId)).data.ResponseData);
  }, []);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  const handleClearBtn = async () => {
    handleApplyBtn(null, null);
  };

  const handleApplyBtn = async (fromDate, toDate) => {
    setIncidentByEmirateCount((await dashboardAction.getAgricultureIncidentByEmirate(emirateId, 0, fromDate, toDate)).data.ResponseData);
  };
  const changeAnimalDiseaseControl = async (e) => {
    setBlightDescriptionId(e.target.value);
    setIncidentByEmirateCount((await dashboardAction.getAgricultureIncidentByEmirate(emirateId, e.target.value)).data.ResponseData);
  };
  return (
    <div className="container">
      <div className="row">
        <span className="d-flex m-4">{props.t("IncidentsGraphicalSummary")}</span>
        <div className="app-charts-grid">
          <GISMap {...props} />

          <div className="graph-item">
            <div className="graph-item-inner d-flex flex-column">
              <div className="d-flex justify-content-between mt-1">
                <span className="p-2">{props.t("IncidentsPerEmirate")}</span>
                <BlightDescriptionInput BlightDescriptionId={BlightDescriptionId} changeControl={changeAnimalDiseaseControl} />
                <DateUI {...props} type="incidentByEmirateCount" handleApplyBtn={handleApplyBtn} handleClearBtn={handleClearBtn} />
              </div>
              <ChartUI
                legendDisplay={true}
                type={ChartType.pie}
                labels={util.DbUtil.getLabelList(incidentByEmirateCount)}
                datasets={util.DbUtil.getDatasetPerCountryCount(incidentByEmirateCount)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const BlightDescriptionInput = ({ BlightDescriptionId, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getBlightDescriptionLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-6">
      <SelectInput name="BlightDescriptionId" label="BlightDescription" required={false} value={BlightDescriptionId} onChange={changeControl} data={data} />
    </div>
  );
};
export default Charts;
