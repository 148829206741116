import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { enums, master, iAnimalLV, validation } from "../../../Imports";
import ALVSurgeonDetail from "./ALVSurgeonDetail";
import ViolationDetail from "./ALVViolationDetail";

const ALVServiceDetail = (props) => {
  const [violationType, setViolationType] = useState([]);
  const [showViolationDetail, setShowViolationDetail] = useState(false);
  const [showSurgeonDetail, setShowSurgeonDetail] = useState(false);
  const [controlDisable, setControlDisable] = useState(false);
  const transaction = useSelector((state) => state.transaction);

  const onchangeViolationType = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const code = e.nativeEvent.target[index].getAttribute("data-code");
    changeControl(e);
    setShowViolationDetail(code === enums.ALVIncidentTypeEnum.VP);
    setShowSurgeonDetail(code === enums.ALVIncidentTypeEnum.VF);
  };
  const changeControl = (e) => {
    props.handleChange(
      e.target.name,
      e.target.value,
      iAnimalLV.ALV_Constants.ALVT
    );
  };
  const loadLkp = async () => {
    setViolationType(await master.getALVIncidentTypeLkp(0));
  };
  useEffect(() => {
    loadLkp();
  }, []);
  useEffect(() => {
    if (transaction.isUpdate) {
      // console.log(transaction);
      setShowViolationDetail(
        transaction[iAnimalLV.ALV_Constants.ALVT].ALVIncidentTypeLkp.Code ===
          enums.ALVIncidentTypeEnum.VP
      );
      setShowSurgeonDetail(
        transaction[iAnimalLV.ALV_Constants.ALVT].ALVIncidentTypeLkp.Code ===
          enums.ALVIncidentTypeEnum.VF
      );
      // setControlDisable(validation.isGovtUser() && validation.isMinistryUser());
      validation.isShowInfectionForm(transaction, "ALVServiceDetailForm");
    }
  }, [transaction.isUpdate]);
  return (
    <div className="ALVServiceDetailForm">
      <div className="col-md-4 ">
        <FormInput
          name="Area"
          type="text"
          label="Area"
          required={!showSurgeonDetail && !showViolationDetail}
          disabled={controlDisable}
          value={transaction[iAnimalLV.ALV_Constants.ALVT].Area}
          onChange={changeControl}
        />
        <em className="control-description">{props.t("alv_AreaDesciption")}</em>
      </div>
      <label className="control-title my-2 mb-4">
        <strong>{props.t("IncidentDetail")}</strong>
      </label>
      <div className="col-md-4">
        <SelectInput
          name="ALVIncidentTypeId"
          label="ViolationType"
          required={true}
          disabled={controlDisable}
          value={transaction[iAnimalLV.ALV_Constants.ALVT].ALVIncidentTypeId}
          onChange={onchangeViolationType}
          data={violationType}
        />
      </div>
      <div className="col-md-12">
        <FormInput
          label="AuthorDescription"
          name="Description"
          type="textarea"
          rows="3"
          cols="20"
          required={true}
          value={transaction[iAnimalLV.ALV_Constants.ALVT].Description}
          onChange={changeControl}
          disabled={controlDisable}
          data-investigate="1"
        />
      </div>

      {showViolationDetail && (
        <ViolationDetail {...props} isDisabled={controlDisable} />
      )}

      {showSurgeonDetail && (
        <ALVSurgeonDetail {...props} isDisabled={controlDisable} />
      )}
    </div>
  );
};

export default ALVServiceDetail;
