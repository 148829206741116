import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { master, enums, auth } from "../../../../Imports";
import * as Report from "../../ReportControl";

const ReportFilter = (props) => {
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const [localVal, setLocalVal] = useState(props.data);
  const UserTypeAccountData = useSelector((state) => state.masterData.UserAccountType);

  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeControlDate = (name, value) => {
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const getDropdownData = useCallback(async () => {
    dispatch(master.getUserAccountType());
  }, [dispatch, props.type]);

  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);

  useEffect(() => {
    console.log(localVal);
  }, [localVal]);

  const resetForm = (e) => {};
  const searchFormSubmit = (e) => {
    e.preventDefault();
    props.onChangeSearch(localVal);
  };
  const handleReset = () => {
    setLocalVal(props.data);
  };
  return (
    <div>
      <div className="table-filter">
        <form id="search-filter-form" onReset={resetForm} onSubmit={searchFormSubmit}>
          <div className="pt-3 pl-3 pr-3 m-3 container-bg">
            <div className="row flex-fill">
              <div className="col-md-3">
                <Report.DateControl label="from" localVal={localVal.fromDate} changeControlDate={changeControlDate} />
              </div>
              <div className="col-md-3">
                <Report.DateControl label="to" localVal={localVal.toDate} changeControlDate={changeControlDate} />
              </div>
              {user.UnitType === enums.UnitTypeEnum.MinistryUser && (
                <div className="col-md-3">
                  <Report.EmirateLkpControl localVal={localVal.EmirateId} changeControl={changeControl} />
                </div>
              )}
              {props.type === enums.IncidentTypeEnum.AnimalBorderGate && (
                <div className="col-md-3">
                  <Report.BorderPortLkpControl
                    localVal={localVal.BorderPortId}
                    emirateId={localVal.EmirateId}
                    departmentEnum={enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment}
                    changeControl={changeControl}
                  />
                </div>
              )}
              <div className="col-md-3">
                <Report.WFStatusLkpControl localVal={localVal.StatusCode} changeControl={changeControl} />
              </div>
              {props.type === enums.IncidentTypeEnum.AnimalLegalViolation && (
                <div className="col-md-3">
                  <Report.LegalViolationTypeLkpControl localVal={localVal.LegalViolationTypeId} changeControl={changeControl} name="LegalViolationTypeId" />
                </div>
              )}
              {props.type === enums.IncidentTypeEnum.AnimalHealth && (
                <>
                  <div className="col-md-3">
                    <Report.UserAccountTypeLkpControl
                      localVal={localVal.UserAccountType}
                      changeControl={changeControl}
                      data={UserTypeAccountData.filter((x) => x.Code <= 3)}
                    />
                  </div>
                  <div className="col-md-3">
                    <Report.AnimalDiseaseLkpControl localVal={localVal.AnimalDiseaseId} changeControl={changeControl} />
                  </div>
                  <div className="col-md-3">
                    <Report.RiskDegreeLkpControl localVal={localVal.RiskDegree} changeControl={changeControl} />
                  </div>
                </>
              )}
              <div className="col-md-3">
                <Report.TextBoxControl label="reporterName" name="ReporterName" changeControl={changeControl} localVal={localVal.ReporterName} />
              </div>
              <div className="col-md-3">
                <Report.TextBoxControl label="mobileNo" name="ReporterMobileNo" changeControl={changeControl} localVal={localVal.ReporterMobileNo} />
              </div>
              <div className="col-md-3">
                <Report.TextBoxControl label="IncidentNumber" name="refNo" changeControl={changeControl} localVal={localVal.refNo} />
              </div>

              <div className="col-md-3 my-4" id="searchForm" style={{ marginTop: "15px" }}>
                <button type="submit" id="btnSearch" className="btn btn-success mx-2 pt-2 pb-2">
                  {props.t("search")}
                </button>
                <button type="reset" onClick={handleReset} className="btn bt-reset mx-2  pt-2 pb-2">
                  {props.t("clear")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportFilter;
