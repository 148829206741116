import axios from "axios";
import { URLs } from "../../Constants/Common";

export const createFoodSafetyBorderGate = async (data) => {
  return await axios.post(URLs.CreateFoodSafetyBorderGate, data);
};
export const updateFoodSafetyBorderGate = async (data) => {
  return await axios.post(URLs.UpdateFoodSafetyBorderGate, data);
};
export const createFollowUpFoodSafetyBorderGate = async (data) => {
  return await axios.post(URLs.CreateFollowUpFoodSafetyBorderGate, data);
};

export const getFoodSafetyBorderGateTransactionById = async (id) => {
  return await axios.get(
    URLs.getFoodSafetyBorderGateIncidentsTransactionById + "?id=" + id
  );
};
export const getFoodSafetyBorderGateReport = async (data) => {
  return await axios.post(URLs.GetFoodSafetyBorderGateReport, data);
};
