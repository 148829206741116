import React, { useState, useCallback, useEffect } from "react";
import PaginationUI from "../../../../Common/UI/PaginationUI";
import {
  ColumnCreateDate,
  ColumnEmiratesName,
  ColumnGovEntityName,
  ColumnIncidentType,
  ColumnStatus,
  ColumnUserName,
  DynamicLink,
} from "../../Grids/SearchGrid";
import { incidentAction } from "../../../../Imports";
import { pageSettingDto } from "../../ReportControl";
import { OverAllReportGridMapper } from "../../DataMapper";

const OverallReportGrid = (props) => {
  let pageSetting = { ...pageSettingDto };
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(pageSetting);

  const onPageChange = async (page) => {
    await getGridData(props.searchData, page);
  };
  const getGridData = async (data, page) => {
    props.setLoading(true);
    data.paginationDto.pageNo = page;
    const resp = await incidentAction.getIncidentsTransactionReport(data);
    const result = resp.data.ResponseData;
    setData(result);
    setPagination((pre) => ({
      ...pre,
      totalPages: Math.ceil(result.PaginationDto.TotalItems / result.PaginationDto.PageSize),
      TotalItems: result.PaginationDto.TotalItems,
      currentPage: page,
      showMax: page > pageSetting.showMax ? pageSetting.showMax : 2,
    }));
    props.setLoading(false);
  };
  const getSearchData = useCallback(async () => {
    await getGridData(props.searchData, 1);
  }, [getGridData]);

  useEffect(() => {
    getSearchData();
  }, [props.isSearchChanged]);

  const exportToPDF = async () => {
    let data = props?.searchData;
    data.paginationDto.pageSize = 0;
    const resp = await incidentAction.getIncidentsTransactionReport(data);
    const result = resp.data.ResponseData;
    return OverAllReportGridMapper(result.Incidents);
  };
  return (
    <div>
      <div className="table-container col flex-fill">
        <div className="table-wrapper p-2 m-3 container-bg table-responsive">
          {data?.Incidents.length === 0 && (
            <div className="text-center mt-5 search-message" id="nothingFoundMessage">
              <i className="ico icon-Group-420"></i>
              <p className="mt-4">
                {props.t("NoSearchResult")}
                <strong className="text-dark"></strong>
              </p>
            </div>
          )}
          {data?.Incidents.length > 0 && (
            <>
              <table className="table table-striped table-hover text-center m-0">
                <thead>
                  <tr>
                    <th>{props.t("IncidentNumber")}</th>
                    <th>{props.t("emirate")}</th>
                    <th>{props.t("govEntity")} </th>
                    <th>
                      {props.t("IncidentStatus")}/{props.t("RiskDegree")}{" "}
                    </th>
                    <th>{props.t("reporterName")} </th>
                    <th style={{ width: "25%" }}>{props.t("Comments")} </th>
                  </tr>
                </thead>
                <tbody>
                  {data.Incidents.map((incident, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <DynamicLink incident={incident} />
                          <div>
                            <ColumnCreateDate incident={incident} />
                          </div>
                        </td>
                        <td>
                          <ColumnEmiratesName incident={incident} props={props} />
                        </td>
                        <td>
                          <ColumnGovEntityName incident={incident} props={props} />
                          <div>
                            <ColumnIncidentType incident={incident} props={props} />
                          </div>
                        </td>

                        <td>
                          <ColumnStatus incident={incident} />
                          <div>
                            <small>{incident?.RiskDegreeCode}</small>
                          </div>
                        </td>
                        <td>
                          <ColumnUserName incident={incident} />
                        </td>
                        <td style={{ width: "25%" }}>{incident?.WorkFlowActionHistorys[incident?.WorkFlowActionHistorys.length - 1].Comments}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}

          {data?.Incidents.length > 0 && (
            <PaginationUI
              {...props}
              TotalItems={pagination.TotalItems}
              totalPages={pagination.totalPages}
              currentPage={pagination.currentPage}
              pageSize={pageSetting.pageSize}
              showMax={pageSetting.showMax}
              onClick={onPageChange}
              getExcelReport={exportToPDF}
              ExcelFileName={"Biosec_Overall_Report"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OverallReportGrid;
