import React from "react";
import { UserClass } from "../../../Interface/Registration";
import { UserProfileDTO } from "../../../Interface/IIncidentTrans";
import { useNavigate } from "react-router-dom";
import { authAction, auth, util } from "../../Imports";

const ProfileLinking = (props) => {
  const navigate = useNavigate();

  const registerUserIf_NO = async () => {
    props.setLoading(true);
    const userDetail = JSON.parse(sessionStorage.getItem("uaepass_data"));
    console.log(userDetail);
    const fullNameEn = userDetail.fullnameEN?.replaceAll(",", " ").trim();
    const fullNameAr = userDetail.fullnameAR?.replaceAll(",", " ").trim();
    const userObj = new UserClass(
      userDetail.email,
      "test",
      new UserProfileDTO(
        fullNameAr ?? fullNameEn,
        fullNameEn,
        userDetail.email,
        util.formatEID(userDetail.idn),
        userDetail.passportNumber ?? "NA",
        userDetail.mobile,
        null,
        "ar",
        null,
        null,
        userDetail.lastnameEN,
        userDetail.lastnameAR
      ),
      null,
      userDetail.uuid,
      userDetail.email,
      userDetail.userType
    );
    const register = await authAction.registerUserAndLogin_UAEPass(userObj);
    auth.setUserPermission(register.data.ResponseData.AccessToken, register.data.ResponseData.RefreshToken);
    props.setLoading(false);

    const user = auth.getUserPermission();
    console.log("UAEPass:" + user.OrganizationId);

    setTimeout(() => {
      navigate("/environmental-incident/MA==/MA==");
    }, 1000);
  };
  const handleYesBtn = async () => {
    navigate("/loginLinked");
  };
  const handleNoBtn = () => {
    registerUserIf_NO();
  };
  // const redirectPage = useCallback(() => {
  //   const userDetail = JSON.parse(sessionStorage.getItem("uaepass_data"));
  //   if (userDetail != null) {
  //     navigate("/");
  //   }
  // }, [navigate]);
  // useEffect(() => {
  //   redirectPage();
  // }, []);

  return (
    <div className="text-center mt-4">
      <h4 className="pro-link">{props.t("ProfileLinkingText")}</h4>

      <div className="mt-4">
        <h5>{props.t("DoYouHaveOnlineAccount")}</h5>
      </div>

      <div className="d-flex flex-row justify-content-around mt-5">
        <button className="btn btn-success mx-3" type="button" onClick={handleYesBtn}>
          {props.t("Yes")} {" *"}
        </button>
        <button className="btn btn-success mx-3" type="button" onClick={handleNoBtn}>
          {props.t("No")}
          {" **"}
        </button>
      </div>

      <div
        className="mt-5 mb-4"
        dangerouslySetInnerHTML={{
          __html: props.t("ProfileLinkingNotes"),
        }}
      ></div>
    </div>
  );
};

export default ProfileLinking;
