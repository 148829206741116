import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend, BarElement } from "chart.js";

export const ChartType = {
  pie: "pie",
  barStacked: "bar-stacked",
  bar: "bar",
  horizontalBar: "horizontalBar",
  line: "line",
};
const ChartUI = (props) => {
  const pie_Options = {
    tooltips: {
      enabled: false,
    },
    plugins: {
      dataLabels: {
        formatter: (value, ctx) => {
          var percentage = 0;
          let datasets = ctx.chart.data.datasets;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            let percentage = Math.round((value / sum) * 100) + "%";
            return percentage;
          } else {
            return percentage;
          }
        },
        color: "#fff",
      },
    },
  };
  const options = {
    // maintainAspectRatio: true,
    // responsive: true,
    scales: {
      x: {
        ticks: {
          font: {
            size: 10,
            family: "UniversNextArabic",
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 10,
            family: "UniversNextArabic",
          },
        },
      },
    },
    plugins: {
      legend: {
        display: props.legendDisplay ? true : false,
        labels: {
          font: {
            size: 12,
            family: "UniversNextArabic",
          },
        },
      },
      title: {
        display: props.title ? true : false,
        text: props.title,
        color: "#ba9041",
        font: {
          family: "UniversNextArabic",
          size: 20,
          weight: "bold",
          lineHeight: 1.2,
        },
      },
    },
    responsive: true,
  };
  return (
    <>
      <>
        {props.type === ChartType.line && (
          <Line
            datasetIdKey="id"
            options={{
              ...options,
            }}
            data={{
              labels: props.labels,
              datasets: props.datasets,
            }}
          />
        )}
        {props.type === ChartType.pie && (
          <Pie
            datasetIdKey="id"
            options={{
              ...options,
              ...pie_Options,
            }}
            data={{
              labels: props.labels,
              datasets: props.datasets,
            }}
          />
        )}
        {props.type === ChartType.barStacked && (
          <Bar
            datasetIdKey="id"
            options={{
              ...options,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={{
              labels: props.labels,
              datasets: props.datasets,
            }}
          />
        )}
        {props.type === ChartType.bar && (
          <Bar
            datasetIdKey="id"
            options={{
              ...options,
            }}
            data={{
              labels: props.labels,
              datasets: props.datasets,
            }}
          />
        )}
        {props.type === ChartType.horizontalBar && (
          <Bar
            datasetIdKey="id"
            options={{
              ...options,
              indexAxis: "y",
            }}
            data={{
              labels: props.labels,
              datasets: props.datasets,
            }}
          />
        )}
      </>
    </>
  );
};
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);
export default ChartUI;
