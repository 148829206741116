import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { enums, iAnimalHealth, master, util } from "../../../Imports";

const BeforeConfirmDetails = (props) => {
  const [disable, setDisable] = useState(false);
  const [infectionType, setInfectionType] = useState([]);
  const transaction = useSelector((state) => state.transaction);

  const loadLkp = useCallback(async () => {
    setInfectionType(await master.getInfectionTypeLkp());
  }, []);
  useEffect(() => {
    loadLkp();
  }, [loadLkp]);

  useEffect(() => {
    // if (!validation.isGovtUser()) {
    //   setDisable(true);
    // }
  }, [transaction.isUpdate]);

  return (
    <>
      <div className="col-md-4">
        <SelectInput
          name="InfectionTypeCode"
          label="InfectionType"
          required={true}
          disabled={disable}
          value={transaction[iAnimalHealth.AH_Constants.AHT].InfectionTypeCode}
          onChange={(e) => props.handleChange(e.target.name, e.target.value, iAnimalHealth.AH_Constants.AHT)}
          data={infectionType}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="InfectionConfirmDate"
          label="ConfirmationDate"
          disabled={disable}
          type="date"
          selectedDate={util.SelectedDate(transaction[iAnimalHealth.AH_Constants.AHT].InfectionConfirmDate)}
          required={transaction[iAnimalHealth.AH_Constants.AHT].InfectionTypeCode !== enums.InfectionTypeEnum.Suspected}
          onChange={(date) => props.handleChange("InfectionConfirmDate", util.SetDate(date), iAnimalHealth.AH_Constants.AHT)}
          maxDate={new Date()}
        />
      </div>
    </>
  );
};

export default BeforeConfirmDetails;
