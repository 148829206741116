import React, { useCallback, useEffect, useState } from "react";
import { SelectInput } from "../../Common/Validation/FormInput";
import { useSelector } from "react-redux";
import { iIncident, incidentAction, master, validation } from "../../Imports";

const RiskDegree = (props) => {
  const [riskDegreeData, setRiskDegreeData] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const transaction = useSelector((state) => state.transaction);
  const loadLkp = useCallback(async () => {
    setRiskDegreeData(await master.getRiskDegreeLkp());
  }, []);

  useEffect(() => {
    loadLkp();
  }, [loadLkp]);
  useEffect(() => {
    if (transaction.isUpdate) {
      if (transaction[iIncident.SharedDTO.IT].RiskDegreeCode != null) {
        setShowButton(false);
      }
    }
  }, [transaction.isUpdate]);

  const handleUpdateRiskDegree = async () => {
    const valid = validation.validateForm("riskDegreeForm");
    if (valid) {
      const response = await incidentAction.updateIncidentsRiskDegree(
        transaction[iIncident.SharedDTO.IT].Id,
        transaction[iIncident.SharedDTO.IT].RiskDegreeCode
      );
      if (response.data) {
        setShowButton(false);
      }
    }
  };

  return (
    <div className="  riskDegreeForm">
      <div className="  my-2 container-bg">
        <div className="col-md-8  ">
          <SelectInput
            name="RiskDegreeCode"
            label="RiskDegree"
            required={true}
            disabled={!showButton}
            value={transaction[iIncident.SharedDTO.IT].RiskDegreeCode}
            onChange={(e) =>
              props.handleChange(
                e.target.name,
                e.target.value,
                iIncident.SharedDTO.IT
              )
            }
            data-investigate="1"
            data={riskDegreeData}
          />
        </div>
        {/* <div className="col-md-4 my-4">
          {showButton && (
            <button
              id="updateRiskDegree"
              className="btn btn-success"
              onClick={handleUpdateRiskDegree}
            >
              {props.t("update")}
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default RiskDegree;
