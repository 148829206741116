import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { iFoodSafety } from "../../../Imports";
import { FactoryName, ProductDescription, ProductName } from "../SharedControl";

const ServiceData = (props) => {
  const foodConst = iFoodSafety.FS_Constants.FSPI;
  const foodConstTrans = iFoodSafety.FS_Constants.FST;
  const transaction = useSelector((state) => state.transaction);
  const handleChange = (e) => {
    console.log(e.target);
    props.handleChange(e.target.name, e.target.value, foodConst);
  };
  const handleChangeFST = (e) => {
    console.log(e.target);
    props.handleChange(e.target.name, e.target.value, foodConstTrans);
  };
  return (
    <>
      <div className="row container-bg my-2">
        <label className="control-title my-2 mb-4">
          <strong>{props.t("IncidentDetail")}</strong>
        </label>

        <ProductName transaction={transaction[foodConst]} changeControl={handleChange} />
        <FactoryName transaction={transaction[foodConst]} changeControl={handleChange} />
        <ProductDescription transaction={transaction[foodConst]} changeControl={handleChange} />

        <div className="col-md-12">
          <FormInput
            name="IncidentDescription"
            type="textarea"
            label="IncidentDescription"
            required={true}
            disabled={false}
            value={transaction[foodConstTrans].IncidentDescription}
            onChange={handleChangeFST}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceData;
