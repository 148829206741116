import { enums } from "../../../../Imports";

export const AnimalLegalViolationMapper = (data) => {
  return data.map((x) => ({
    RefNo: x?.IncidentsTransaction?.RefNo,
    Status: x?.IncidentsTransaction?.StatusCode,
    Emirate: x?.IncidentsTransaction?.EmirateLkp?.NameAr,
    LocationGPS: `North :${x?.IncidentsTransaction?.Latitude} East :${x?.IncidentsTransaction?.Longitude}`,
    Area: x.Area,
    Description: x.Description,
    IncidentType: x.ALVIncidentTypeLkp?.NameAr,
    ManufacturingCompany: x.AnimalLegalViolationDetail?.ManufacturingCompany,
    ProductName: x.AnimalLegalViolationDetail?.ProductName,
    VeterinaryEstablishment: x.AnimalLegalViolationDetail?.VeterinaryEstablishmentLkp?.NameAr,
    VeterinarySurgeonName: x.AnimalLegalViolationDetail?.VeterinarySurgeonName,
    ReporterName: x?.IncidentsTransaction?.Users?.UsersProfile?.NameAr,
    ReporterPhone: x?.IncidentsTransaction?.Users?.UsersProfile.MobileNo,
    ReporterEmail: x.IncidentsTransaction?.Users?.UsersProfile.Email,
    ReporterEmirateId: x.IncidentsTransaction?.Users?.UsersProfile.ReporterEID,
    ReporterPassport: x.IncidentsTransaction?.Users?.UsersProfile.PassportNo,
    ReporterAddress: x.IncidentsTransaction?.Users?.UsersProfile.Address,
    ActionLog: x.IncidentsTransaction?.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};
export const AnimalBorderGateMapper = (data) => {
  return data.map((x) => ({
    RefNo: x?.IncidentsTransaction?.RefNo,
    Status: x?.IncidentsTransaction?.StatusCode,
    Emirate: x?.IncidentsTransaction?.EmirateLkp?.NameAr,
    LocationGPS: `North :${x?.IncidentsTransaction?.Latitude} East :${x?.IncidentsTransaction?.Longitude}`,

    AnimalDetail__DestinationCountry: x.AnimalBorderGateAnimalDetail?.map((t) => {
      return t?.DestinationCountryLkp?.NameAr;
    }).toLocaleString(),
    AnimalDetail__SourceCountry: x.AnimalBorderGateAnimalDetail?.map((t) => {
      return t?.SourceCountryLkp?.NameAr;
    }).toLocaleString(),
    AnimalDetail__AnimalType: x.AnimalBorderGateAnimalDetail?.map((t) => {
      return t?.EserviceAnimalLkp?.NameAr;
    }).toLocaleString(),
    AnimalDetail__Unit: x.AnimalBorderGateAnimalDetail?.map((t) => {
      return t?.Unit;
    }).toLocaleString(),
    AnimalDetail__AnimalNo: x.AnimalBorderGateAnimalDetail?.map((t) => {
      return t?.AnimalNo;
    }).toLocaleString(),

    ShipmentCertificateNo: x.ShipmentCertificateNo,
    ShipmentNo: x.ShipmentNo,
    ViolationReason: x.ViolationReason,
    ContainerNo: x.ContainerNo,
    ShipmentDate: x.ShipmentDate,
    ShipmentRejectionDate: x.ShipmentRejectionDate,
    Comments: x.Comments,

    ImporterEmail: x.AnimalBorderGateShipmentDetail.ImporterEmail,
    ImporterName: x.AnimalBorderGateShipmentDetail.ImporterName,
    ImporterPhone: x.AnimalBorderGateShipmentDetail.ImporterPhone,
    ReporterName: x?.IncidentsTransaction?.Users?.UsersProfile?.NameAr,
    ReporterPhone: x?.IncidentsTransaction?.Users?.UsersProfile.MobileNo,
    ReporterEmail: x.IncidentsTransaction?.Users?.UsersProfile.Email,
    ReporterEmirateId: x.IncidentsTransaction?.Users?.UsersProfile.ReporterEID,
    ReporterPassport: x.IncidentsTransaction?.Users?.UsersProfile.PassportNo,
    ReporterAddress: x.IncidentsTransaction?.Users?.UsersProfile.Address,
    ActionLog: x.IncidentsTransaction?.WorkFlowActionHistorys.map((t) => {
      return t.CreatedBy + " | " + t.Comments;
    }).toLocaleString(),
  }));
};

export const AnimalHealthMapper = (data) => {
  let list = [];
  data.forEach((x) => {
    const Default_Infection = x.IncidentInfectionDetail?.filter((x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.Default);
    const Susceptible_Infection = x.IncidentInfectionDetail?.filter(
      (x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.DetailsOfAnimalsSusceptibleToInfection
    );
    const Suspected_Infection = x.IncidentInfectionDetail?.filter(
      (x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.DetailsOfAnimalsWithSuspectedInfection
    );
    const Clinically_Infection = x.IncidentInfectionDetail?.filter(
      (x) => x.InfectionDetailGroupType === enums.InfectionDetailGroupEnum.InfectedClinicallyAnimalsDetails
    );
    list.push({
      RefNo: x?.IncidentsTransaction?.RefNo,
      Status: x?.IncidentsTransaction?.StatusCode,
      InfectionType: x.InfectionTypeLkp?.NameAr,
      FollowUp: x.IncidentsTransaction.FollowUp > 0 ? "متابعة" : "",
      InfectionStartDate: x.InfectionStartDate,
      RiskDegree: x.RiskDegreeLkp?.NameAr,
      InfectionConfirmDate: x.InfectionConfirmDate,
      OwnerShipType: x.IncidentHoldings?.HoldingType?.NameAr,
      OwnerShipNumber: x.IncidentHoldings?.Number,
      Emirate: x?.IncidentsTransaction?.EmirateLkp?.NameAr,
      LocationGPS: `North :${x?.IncidentsTransaction?.Latitude} East :${x?.IncidentsTransaction?.Longitude}`,
      ActionLog: x.IncidentsTransaction?.WorkFlowActionHistorys.map((t) => {
        return t.CreatedBy + " | " + t.Comments;
      })
        .toLocaleString()
        .substr(0, 32760),
      InfectionDetail__AnimalType: Default_Infection?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),

      InfectionDetail__Disease: Default_Infection?.map((x) => {
        return x?.InfectionDiseaseDetails?.map((r) => {
          return r?.AnimalDisease?.NameAr + "  ";
        });
      })?.toLocaleString(),
      InfectionDetail__ClinicalInfection: Default_Infection?.map((t) => {
        return t?.InfectionSymptomDetails?.map((r) => {
          return r?.ClinicalSymptoms?.NameAr + "  ";
        });
      })?.toLocaleString(),
      InfectionDetail__NoOfInfectedAnimals: Default_Infection?.map((t) => {
        return t?.NoOfInfectedAnimals;
      })?.toLocaleString(),
      InfectionDetail__NoOfDeadAnimals: Default_Infection?.map((t) => {
        return t?.NoOfDeadAnimals;
      }).toLocaleString(),

      Clinically_Infection__AnimalType: Clinically_Infection?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),
      Clinically_Infection__Anatomy: Clinically_Infection?.map((t) => {
        return t?.Anatomy;
      }).toLocaleString(),
      Clinically_Infection__NoOfInfectedAnimals: Clinically_Infection?.map((t) => {
        return t?.NoOfInfectedAnimals;
      })?.toLocaleString(),

      Susceptible_Infection__AnimalType: Susceptible_Infection?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),
      Susceptible_Infection__NoOfAnimals: Susceptible_Infection?.map((t) => {
        return t?.NoOfAnimals;
      })?.toLocaleString(),
      Susceptible_Infection__NoOfInfectedAnimals: Susceptible_Infection?.map((t) => {
        return t?.NoOfInfectedAnimals;
      })?.toLocaleString(),

      Suspected_Infection__AnimalType: Suspected_Infection?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),
      Suspected_Infection__AnimalAge: Suspected_Infection?.map((t) => {
        return t?.AnimalAge;
      }).toLocaleString(),
      Suspected_Infection__AnimalBreed: Suspected_Infection?.map((t) => {
        return t?.AnimalBreed;
      }).toLocaleString(),
      Suspected_Infection__AnimalGender: Suspected_Infection?.map((t) => {
        return t?.AnimalGender;
      }).toLocaleString(),
      Suspected_Infection__NoOfAnimals: Suspected_Infection?.map((t) => {
        return t?.NoOfAnimals;
      }).toLocaleString(),
      Suspected_Infection__NoOfInfectedAnimals: Suspected_Infection?.map((t) => {
        return t?.NoOfInfectedAnimals;
      }).toLocaleString(),
      IsNewAnimalAddedToFarm: x.AnimalHealthInvestigation?.IsNewlyAnimalAdded,
      AnimalNewlyAdded__AnimalType: x.AnimalHealthInvestigation?.AnimalNewlyAdded?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),
      AnimalNewlyAdded__Emirate: x?.AnimalHealthInvestigation?.AnimalNewlyAdded?.map((t) => {
        return t?.EmirateLkp?.NameAr + " ";
      }).toLocaleString(),
      AnimalNewlyAdded__Address: x?.AnimalHealthInvestigation?.AnimalNewlyAdded?.map((t) => {
        return t?.Address;
      }).toLocaleString(),
      AnimalNewlyAdded__NoOfAnimals: x?.AnimalHealthInvestigation?.AnimalNewlyAdded?.map((t) => {
        return t?.NoOfAnimals;
      }).toLocaleString(),

      AnimalSlaughtered__AnimalType: x.AnimalHealthInvestigation?.AnimalSlaughtered?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),

      AnimalSlaughtered__NoOfAnimals: x.AnimalHealthInvestigation?.AnimalSlaughtered?.map((t) => {
        return t?.NoOfAnimals;
      }).toLocaleString(),

      AnimalDiscarded__AnimalType: x.AnimalHealthInvestigation?.AnimalDiscarded?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),
      AnimalDiscarded__NoOfAnimals: x.AnimalHealthInvestigation?.AnimalDiscarded?.map((t) => {
        return t?.NoOfAnimals;
      }).toLocaleString(),

      AnimalDead__AnimalType: x.AnimalHealthInvestigation?.AnimalDead?.map((t) => {
        return t?.AnimalType?.NameAr + " ";
      }).toLocaleString(),
      AnimalDead__NoOfAnimals: x.AnimalHealthInvestigation?.AnimalDead?.map((t) => {
        return t?.NoOfAnimals;
      }).toLocaleString(),
      IsNewlyAnimalAdded: x.AnimalHealthInvestigation?.IsNewlyAnimalAdded,
      LabSamplesCollected: x.AnimalHealthInvestigation?.LabSamplesCollected,
      LastImmunizationDate: x.AnimalHealthInvestigation?.LastImmunizationDate,
      TestType: x.AnimalHealthInvestigation?.TestType,
      TestDescription: x.AnimalHealthInvestigation?.TestDescription,
      ImmunizedAnimalDetails__AnimalType: x.AnimalHealthInvestigation?.ImmunizedAnimalDetails?.map((t) => {
        return t?.AnimalTypeLkp?.NameAr + " ";
      }).toLocaleString(),
      ImmunizedAnimalDetails__ImmunizationDate: x.AnimalHealthInvestigation?.ImmunizedAnimalDetails?.map((t) => {
        return t?.ImmunizationDate;
      }).toLocaleString(),
      ImmunizedAnimalDetails__ImmunizationType: x.AnimalHealthInvestigation?.ImmunizedAnimalDetails?.map((t) => {
        return t?.ImmunizationType;
      }).toLocaleString(),
      ImmunizedAnimalDetails__NoOfImmunizedAnimals: x.AnimalHealthInvestigation?.ImmunizedAnimalDetails?.map((t) => {
        return t?.NoOfImmunizedAnimals;
      }).toLocaleString(),

      AnimalControlProceduers: x.AnimalHealthInvestigation?.AnimalControlProceduers?.map((t) => {
        return t?.ControlProceduersLkp?.NameAr + " ";
      }).toLocaleString(),
      ActionsTaken: x.AnimalHealthInvestigation?.ActionsOnIncident,
      IncidentDate: x?.IncidentsTransaction?.CreatedDate,
      NearestGovernmentEntity: x?.IncidentsTransaction.GovtAgency?.NameAr,

      ReporterName: x?.IncidentsTransaction?.Users?.UsersProfile?.NameAr,
      ReporterPhone: x?.IncidentsTransaction?.Users?.UsersProfile.MobileNo,
      ReporterEmail: x.IncidentsTransaction?.Users?.UsersProfile.Email,
      ReporterEmirateId: x.IncidentsTransaction?.Users?.UsersProfile.ReporterEID,
      ReporterPassport: x.IncidentsTransaction?.Users?.UsersProfile.PassportNo,
      ReporterAddress: x.IncidentsTransaction?.Users?.UsersProfile.Address,

      FarmOwnerName: x.IncidentHoldings?.Name,
      FarmOwnerPhone: x.IncidentHoldings?.MobileNo,
      FarmOwnerEmail: x.IncidentHoldings?.Email,
      FarmNumber: x.IncidentHoldings?.Number,
    });
  });
  return list;
};
