import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { enums, iFoodSafety, master, util, validation } from "../../../Imports";
import AddButton from "../../Common/AddButton";
import DeleteButton from "../../Common/DeleteButton";

const ActionTaken = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const FSAT = iFoodSafety.FS_Constants.FSAT;
  const initialState = {
    FoodActionTakenId: "",
    FoodActionTakenName: "",
    Others: "",
  };
  const changeActionTaken = async (e) => {
    if (e.target.value) {
      setLocalVal((p) => ({
        ...p,
        FoodActionTakenId: e.target.value,
        FoodActionTakenName: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
    }
  };
  const changeControl = (e) => {
    console.log(e);
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [actionTaken, setActionTaken] = useState([]);
  const loadComLkp = useCallback(async () => {
    setActionTaken(await master.getFoodActionTakenLkp(0));
  }, []);
  const loadFormInUpdate = async () => {
    let tempInf = [];
    console.log(transaction[FSAT]);
    transaction[FSAT].forEach((e, i) => {
      tempInf = tempInf.concat(
        new iFoodSafety.FoodSafetyActionTakenDTO(
          e.FoodActionTakenId,
          util.getProperText(e.FoodActionTakenLkp?.NameAr, e.FoodActionTakenLkp?.NameEn),
          e.Others,
          new Date().getTime() + i
        )
      );
    });
    props.handleChangeDTO(tempInf, FSAT);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "foodActionTakenForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[FSAT].concat(
        new iFoodSafety.FoodSafetyActionTakenDTO(localVal.FoodActionTakenId, localVal.FoodActionTakenName, localVal.Others, new Date().getTime())
      ),
      FSAT
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[FSAT].filter((x) => x.Uid !== e.Uid),
      FSAT
    );
  };
  return (
    <div className="container foodActionTakenForm">
      <div className="row container-bg">
        <div className="col-md-6">
          <SelectInput
            name="FoodActionTakenId"
            label="FoodActionTaken"
            required={true}
            disabled={false}
            value={localVal.FoodActionTakenId}
            onChange={changeActionTaken}
            data={actionTaken}
          />
        </div>
        {localVal.FoodActionTakenId === enums.FoodActionTakenEnum.Others && (
          <div className="col-md-6">
            <FormInput label="OtherInfo" name="Others" type="text" required={false} value={localVal.Others} onChange={changeControl} />
          </div>
        )}
        <div className="col-md-3 mt-4">
          <AddButton {...props} submitHandler={submitHandler} />
        </div>

        <div className="col-md-12 my-2 row">
          {transaction[FSAT].map((e, i) => (
            <div className="card" key={i}>
              <div className="card-body row">
                <div className="col-md-6">
                  <small className="golden">{props.t("FoodActionTaken")}</small>:<small> {e.FoodActionTakenName}</small>
                </div>
                {e.Others && (
                  <div className="col-md-6">
                    <small className="golden">{props.t("OtherInfo")}</small>:<small> {e.Others}</small>
                  </div>
                )}
              </div>
              <DeleteButton
                {...props}
                deleteHandler={() => {
                  deleteItem(e);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionTaken;
