import { getUserPermission } from "../../../../Constants/AuthToken";
import { enums, incidentAction, toast, validation } from "../../../Imports";

const formInputs = (controlName) => {
  return document.getElementsByClassName(controlName).length > 0
    ? document.getElementsByClassName(controlName)[0].querySelectorAll("input, select, checkbox, textarea, radio, button")
    : null;
};
export const isShowInfectionForm = async (transaction, controlName) => {
  //const user = getUserPermission();
  //console.log("isShowInfectionForm", statusCode);
  console.log(transaction.isUpdate, transaction.StatusCode);
  if (
    !transaction.isUpdate ||
    transaction.StatusCode === enums.WorkFlowStatusCodeEnum.New ||
    transaction.StatusCode === "" ||
    (isEmployee() && transaction.StatusCode !== enums.WorkFlowStatusCodeEnum.Closed && transaction.StatusCode !== enums.WorkFlowStatusCodeEnum.FinallyClosed)
  ) {
    console.log("controlName", controlName, "enabled");
    enableDisableForm(controlName, false);
  } else {
    console.log("controlName", controlName, "disabled");
    enableDisableForm(controlName, true);
  }
};
export const resetFormByClassName = (controlName) => {
  const inputs = formInputs(controlName);
  inputs.forEach((c) => {
    if (c.attributes.focused) c.attributes.focused.value = "false";
    else if (c.attributes["aria-invalid"]) c.attributes["aria-invalid"].value = "false";
  });
  var checkboxes = getCheckBoxList(inputs);
  resetCheckBox(checkboxes);
};
export const isShowAddButton = (props) => {
  const user = getUserPermission();
  if (
    user &&
    user.UnitType === enums.UnitTypeEnum.GovernmentEntity &&
    props.formValues.IncidentsMainTransactionDTO.StatusCode === enums.WorkFlowStatusCodeEnum.New.toString()
  ) {
    return true;
  }
};
export const enableDisableForm = (controlName, isDisable) => {
  const inputs = formInputs(controlName);
  if (inputs === null) return;
  inputs.forEach((c) => {
    try {
      if (c) c.disabled = isDisable;
    } catch (e) {
      console.log(e);
    }
  });
};
const RemoveControlsFromParent = (parentData, childData) => {
  let cInputs = formInputs(childData);
  if (cInputs == null || cInputs.length === 0) return parentData;

  cInputs = Array?.from(cInputs);

  return Array.from(parentData).filter((x) => !cInputs.includes(x));
};
export const validateForm = (controlName, removeControls) => {
  let inputs = formInputs(controlName);
  if (inputs === null) return true;

  if (removeControls && removeControls !== "") {
    inputs = RemoveControlsFromParent(inputs, removeControls);
  }
  inputs = Array.from(inputs);
  if (controlName !== "CirculateToEmirates") {
    inputs = inputs.filter((i) => i.dataset["circulate"] !== "1");
  }
  const count = inputs.filter(
    (i) =>
      ((i.value === "" && i.required) || i.validity.patternMismatch || !i.validity.valid || (i.type === "number" && i.min > i.value && i.required)) &&
      i.type !== "radio"
  );

  //console.log("control required:-" + count);
  if (count.length > 0) {
    count.forEach((c) => {
      try {
        console.log("Required--- " + c.name + "--- " + c.attributes?.label?.value);
        if (c.attributes.focused) c.attributes.focused.value = "true";
        else if (c.attributes["aria-invalid"]) c.attributes["aria-invalid"].value = "true";
      } catch (e) {
        console.log(e);
      }
    });
  }
  const chkList = getCheckBoxList(inputs);
  const isChecked = validateCheckbox(chkList);
  const hasNoCustomError = document.getElementsByClassName("customError").length === 0;
  if (count.length === 0 && isChecked && hasNoCustomError) {
    return true;
  } else {
    document.getElementById(count[0].id).focus();
    return false;
  }
};
export const commonValidation = (transaction, props) => {
  const circulation = [enums.WorkFlowActionCodeEnum.Circulate, enums.WorkFlowActionCodeEnum.CirculateInternal];
  const transfer = [enums.WorkFlowActionCodeEnum.Transfer];
  if (circulation.includes(transaction.IncidentsMainTransactionDTO.WfActionCode)) {
    if (transaction.IncidentCirculationHistoryDTO.length === 0) {
      toast(props.t("PleaseChooseCirculation"), { type: "warning" });
      return false;
    }
  } else if (transfer.includes(transaction.IncidentsMainTransactionDTO.WfActionCode)) {
    if (transaction.IncidentsMainTransactionDTO.Redirect_DepartmentId === 0) {
      toast(props.t("PleaseChooseDeptTransfer"), { type: "warning" });
      return false;
    }
  }
  return true;
};

export const captchaValidation = (transaction, props) => {
  // if (validation.isUserNotLoggedIn() && !transaction?.captcha) {
  //   toast(props.t("captchaValidation"), { type: "warning" });
  //   return false;
  // }
  return true;
};
const getCheckBoxList = (inputs) => {
  const radioBox = Array.from(inputs).filter((i) => i.type === "radio" && i.disabled === false && i.required === true);
  let radioList = [];
  radioBox.forEach((e) => {
    radioList.push(e.name);
  });
  return Array.from(new Set(radioList));
};
const validateCheckbox = (radioList) => {
  let isValid = true;
  radioList.forEach((e) => {
    let inputArr = document.getElementsByName(e);
    for (let i = 0; i < inputArr.length; i++) {
      if (inputArr[i].checked) {
        isValid = true;
        break;
      } else {
        if (inputArr[i].attributes.focused) {
          inputArr[i].attributes.focused.value = "true";
          isValid = false;
        }
      }
    }
  });
  return isValid;
};
const resetCheckBox = (chkName) => {
  var ele = document.getElementsByName(chkName);
  for (var i = 0; i < ele.length; i++) ele[i].checked = false;
};

export const validateControl = (inputName) => {
  let i = document.getElementsByName(inputName)[0];
  if ((i.value === "" && i.required) || i.validity.patternMismatch || !i.validity.valid || (i.type === "number" && i.min > i.value && i.required)) {
    if (i.attributes.focused) i.attributes.focused.value = "true";
    else if (i.attributes["aria-invalid"]) i.attributes["aria-invalid"].value = "true";
  }
};
export const validateInvestigationDetailForm = () => {
  const investigationDetailForm = document.getElementById("investigationDetailForm");
  if (investigationDetailForm == null) {
    return true;
  }
  const validInputs_inv = Array.from(investigationDetailForm.elements).filter(
    (i) => i.value === "" && i.required === true && i.disabled === false && i.dataset["investigate"] === "1"
  );
  const chkListRadioBoxes = getCheckBoxList(investigationDetailForm);
  const isChecked = validateCheckbox(chkListRadioBoxes);
  return validInputs_inv.length === 0 && isChecked;
};
export const isAccessDocumentLib = () => {
  return isMinistryUser();
};
export const isAccessSystemSetting = () => {
  const user = getUserPermission();
  return isMinistryUser() && user.Department === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment.toString();
};
export const isMinistryUser = () => {
  const user = getUserPermission();
  return user.UnitType === enums.UnitTypeEnum.MinistryUser || user.UnitType === enums.UnitTypeEnum.SystemAdministrator;
};
export const isSystemAdministrator = () => {
  const user = getUserPermission();
  return user?.UnitType === enums.UnitTypeEnum.SystemAdministrator;
};
export const isGovtUser = () => {
  const user = getUserPermission();
  return (
    user?.UnitType === enums.UnitTypeEnum.GovernmentEntity ||
    user?.UnitType === enums.UnitTypeEnum.AgricultureDepartment ||
    user?.UnitType === enums.UnitTypeEnum.FocalPoint
  );
};
export const isMinistryGovtUser = () => {
  const user = getUserPermission();
  return user?.OrganizationId === "39";
};
export const isHealthFacilityUser = () => {
  const user = getUserPermission();
  return user?.UnitType === enums.UnitTypeEnum.HealthFacility;
};
export const isBorderGateUser = () => {
  const user = getUserPermission();
  return user?.UnitType === enums.UnitTypeEnum.BorderGate;
};
export const isEmployee = () => {
  return isGovtUser() || isMinistryUser() || isBorderGateUser();
};
export const getUserEmirateId = () => {
  const EmirateID_NONE = "8";
  const user = getUserPermission();
  if (user.UnitType === enums.UnitTypeEnum.MinistryUser || user.EmirateId === EmirateID_NONE) {
    return 0;
  }
  return user.EmirateId;
};

export const isUserLoggedIn = () => {
  const user = getUserPermission();
  return user.isLoggedIn && user.UserAccountType !== enums.UserAccountTypeEnum.NonGovernmentUser;
};
export const isUserNotLoggedIn = () => {
  const user = getUserPermission();
  return user.OrganizationId === "" || user.UserAccountType === enums.UserAccountTypeEnum.NonGovernmentUser;
};
export const isPublicUser = () => {
  const user = getUserPermission();
  return user.OrganizationId === "" || user.UserAccountType === enums.UserAccountTypeEnum.NonGovernmentUser;
};
export const isInitiatorGovtUser = async (transaction) => {
  return (
    transaction?.IncidentsMainTransactionDTO?.WorkFlowActionHistorys[0]?.Users.UsersProfile.UserAccountTypeCode === enums.UserAccountTypeEnum.GovernmentalUser
  );
};

export const isValidToSeeIncident = async (id) => {
  var res = await incidentAction.validateIncidentToOpen(id);
  return res.data.ResponseData;
};
export const isDisasterOperationDept = () => {
  const user = getUserPermission();
  return user.Department === enums.DepartmentEnum.EnvironmentalDisasterManagement.toString() || user.Department === enums.DepartmentEnum.SystemAdmin.toString();
};
export const isAnimalDept = () => {
  const user = getUserPermission();
  return user.Department === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment.toString() || isDisasterOperationDept();
};
export const isAgricultureDept = () => {
  const user = getUserPermission();
  return user.Department === enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment.toString() || isDisasterOperationDept();
};
export const isFoodSafetyDept = () => {
  const user = getUserPermission();
  const valid = user.Department === enums.DepartmentEnum.FoodSafetyDepartment.toString() || isDisasterOperationDept();
  return valid;
};
