import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validation } from "../../Imports";

const DeleteButton = (props) => {
  const [status, setStatus] = useState(true);
  const transaction = useSelector((state) => state.transaction);

  useEffect(() => {
    setStatus(!transaction?.isUpdate || validation.isGovtUser() || validation.isHealthFacilityUser() || validation.isMinistryUser());
  }, [transaction.isUpdate]);

  return (
    <>
      {status && (
        <div className="closebtn">
          <i style={{ cursor: "pointer", color: "red" }} className="fa fa-close" onClick={props.deleteHandler}></i>
        </div>
      )}
    </>
  );
};

export default DeleteButton;
