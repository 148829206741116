import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import pic3 from "../Theme/img/3.png";
import pic2 from "../Theme/img/2.png";
import pic1 from "../Theme/img/1.png";
import { auth, enums, encrypt } from "./Imports";
import { URLRoutes } from "./Routes/Routes";

function Home(props) {
  const [deptId, setDeptId] = useState(0);
  const [isOperationCenter, setIsOperationCenter] = useState(false);

  const user = auth.getUserPermission();
  useEffect(() => {
    console.log(user);
    if (parseInt(user.Department) > 0) setDeptId(parseInt(user.Department));
    else if (user.UserAccountType === enums.UserAccountTypeEnum.NonGovernmentUser) {
      if (user.NonGovTypeCode === enums.NonGovUserTypeEnum.Public) {
        setDeptId(0);
      } else if (user.NonGovTypeCode === enums.NonGovUserTypeEnum.AgrcultureCompany) {
      } else if (user.NonGovTypeCode === enums.NonGovUserTypeEnum.HealthFacility) {
      } else if (user.NonGovTypeCode === enums.NonGovUserTypeEnum.PlantAnimalFarm) {
      }
    } else setDeptId(0);
    setIsOperationCenter(user.UnitType === enums.UnitTypeEnum.OperationCenterUser && user.UserAccountType === enums.UserAccountTypeEnum.DisasterOperation);
  }, [deptId]);

  return (
    <div>
      <div>
        <div className="landing-page flex-grow-1 border-top mb-5">
          <div className="container-fluid d-flex flex-column flex-grow-1 flex-shrink-0">
            <div className="text-center">
              <h1 className="mb-4 mt-5">{props.t("welcome_moccae_portal")}</h1>
              <h2 className="ml-auto mr-auto w-50">{props.t("welcome_desc")}</h2>
            </div>
            <div className="container mt-5">
              <div className="row align-items-center landing-cards">
                <EnvironmentalIncident deptId={deptId} isOperationCenter={isOperationCenter} {...props} />

                <FoodSafetyDepartment deptId={deptId} isOperationCenter={isOperationCenter} {...props} />

                <AnimalHealthDepartment isOperationCenter={isOperationCenter} deptId={deptId} {...props} />

                <AgricultureDevelopment isOperationCenter={isOperationCenter} deptId={deptId} {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

const EnvironmentalIncident = (props) => {
  return (
    <></>
    // <div className="col-md-3">
    //   <div className="card-item text-center p-4">
    //     <div className="  ml-auto mr-auto mb-4">
    //       <div className="">
    //         <img src={pic3} alt="" width={120} />
    //       </div>
    //       <br />
    //     </div>
    //     <h3 className="mb-4">{props.t("environmentalIncidentNotifications")}</h3>
    //     <p className="mb-5">{props.t("environmentalIncidentNotificationsText")}</p>
    //     {(props.deptId === enums.DepartmentEnum.FoodSafetyDepartment || props.isOperationCenter || props.deptId === 0) && (
    //       <Link to={`${URLRoutes.getEnvironmentalIncident(encrypt.encrypt(0), encrypt.encrypt(0))}`} className="btn btn-primary mb-4">
    //         {props.t("reportEnvironmentalIncident")}
    //       </Link>
    //     )}
    //   </div>
    // </div>
  );
};
const FoodSafetyDepartment = (props) => {
  const user = auth.getUserPermission();
  return (
    <div className="col-md-4">
      <div className="card-item text-center p-4">
        <div className="  ml-auto mr-auto mb-4">
          <div className="">
            <img src={pic3} alt="" width={120} />
          </div>
          <br />
        </div>
        <h3 className="mb-4">{props.t("foodSaftyNotifications")}</h3>
        <p className="mb-5">{props.t("foodSaftyNotificationstext")}</p>
        {user.isLoggedIn && (props.deptId === enums.DepartmentEnum.FoodSafetyDepartment || props.isOperationCenter || props.deptId === 0) && (
          <Link to={`/foodsafety/${encrypt.encrypt(0)}/${encrypt.encrypt(0)}`} className="btn btn-primary mb-4">
            {props.t("reportFoodSafetyIncident")}
          </Link>
        )}
      </div>
    </div>
  );
};

const AnimalHealthDepartment = (props) => {
  const user = auth.getUserPermission();
  return (
    <div className="col-md-4">
      <div className="card-item text-center p-4">
        <div className="  ml-auto mr-auto mb-4">
          <div className="">
            <img src={pic2} alt="" width={120} />
          </div>
        </div>
        <h3 className="mb-4">{props.t("animalDevelopmentAndHealthNotifications")}</h3>
        <p className="mb-5">{props.t("animalDevelopmentAndHealthNotificationsText")}</p>
        {user.isLoggedIn &&
          user.UnitType !== enums.UnitTypeEnum.HealthFacility &&
          user.UnitType !== enums.UnitTypeEnum.BorderGate &&
          (props.deptId === enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment || props.isOperationCenter || props.deptId === 0) && (
            <>
              <Link to={`/animalHealthincident/${encrypt.encrypt(0)}/${encrypt.encrypt(0)}`} className="btn btn-primary mb-4">
                {props.t("reportAnimalHealthIncident")}
              </Link>
              {!props.isOperationCenter && (
                <Link to={`/animallegalviolation/${encrypt.encrypt(0)}/${encrypt.encrypt(0)}`} className="btn btn-primary mb-4">
                  {props.t("reportAnimalLegislationsViolationsIncident")}
                </Link>
              )}
            </>
          )}
        {user.UnitType === enums.UnitTypeEnum.HealthFacility && (
          <Link to={`/animalsharedisease/${encrypt.encrypt(0)}/${encrypt.encrypt(0)}`} className="btn btn-primary mb-4">
            {props.t("sharedDiseaseReport")}
          </Link>
        )}
      </div>
    </div>
  );
};

const AgricultureDevelopment = (props) => {
  const user = auth.getUserPermission();
  return (
    <div className="col-md-4">
      <div className="card-item text-center p-4">
        <div className="  ml-auto mr-auto mb-4">
          <div className="">
            <img src={pic1} alt="" width={120} />
          </div>
        </div>
        <h3 className="mb-4">{props.t("agriculturalDevelopmentAndHealthNotifications")}</h3>
        <p className="mb-5">{props.t("agriculturalDevelopmentAndHealthNotificationsText")}</p>
        {user.isLoggedIn &&
          (props.deptId === enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment || props.isOperationCenter || props.deptId === 0) &&
          user.UnitType !== enums.UnitTypeEnum.BorderGate && (
            <Link to={`/agriculturepest/${encrypt.encrypt(0)}/${encrypt.encrypt(0)}`} className="btn btn-primary mb-4">
              {props.t("reportAgriculturalPestsIncident")}
            </Link>
          )}
      </div>
    </div>
  );
};
