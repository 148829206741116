import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SelectInput } from "../../Common/Validation/FormInput";
import { iFoodSafety, master, validation } from "../../Imports";

const NotificationType = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const [notificationTypeData, setNotificationTypeData] = useState([]);
  const [disable, setDisable] = useState(true);
  const fsbgt = iFoodSafety.FS_Constants.FST;
  const loadCompLkp = useCallback(async () => {
    setNotificationTypeData(await master.getNotificationTypeLkp(0));
  }, []);
  useEffect(() => {
    loadCompLkp();
    setTimeout(() => {
      if ((!transaction.isUpdate && validation.isGovtUser()) || validation.isMinistryUser()) {
        setDisable(false);
      }
    }, 2000);
  }, [loadCompLkp, transaction.isUpdate]);
  return (
    <div className="col-md-4">
      <SelectInput
        name="NotificationTypeId"
        label="NotificationType"
        required={true}
        disabled={disable}
        value={transaction[fsbgt].NotificationTypeId}
        onChange={(e) => {
          props.handleChange(e.target.name, e.target.value, fsbgt);
        }}
        data={notificationTypeData}
      />
    </div>
  );
};

export default NotificationType;
