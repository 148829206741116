import React, { useState, useEffect } from "react";
import { incidentRedirectionAction, swal, util } from "../../Imports";
import { useCallback } from "react";

const RedirectionList = (props) => {
  const Header = [
    props.t("incidnetType"),
    props.t("TypeOfLegislativeViolation") + " / " + props.t("propertyType"),
    props.t("emirate"),
    props.t("SourceEntity"),
    props.t("TargetEntity"),
    props.t("IncidentSource"),
    props.t("Delete"),
  ];
  const [data, setData] = useState([]);

  const loadComLkp = useCallback(async () => {
    props.setLoading(true);
    const data = await incidentRedirectionAction.getAllIncidentRedirection(props.searchData);
    setData(data);
    props.setLoading(false);
  }, []);

  const onDelete = async (id) => {
    console.log("delete" + id);
    swal({
      title: props.t("AreYouSure"),
      text: props.t("DoYouWantToDelete"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const isDeleted = (await incidentRedirectionAction.deleteIncidentRedirection(id)).data.ResponseData;
        if (isDeleted) {
          setData(data.filter((x) => x.Id !== id));
          swal(props.t("success"), props.t("deletedSuccessFully"), "success");
        }
      }
    });
  };
  const getGridData = async (data) => {
    props.setLoading(true);
    const result = await incidentRedirectionAction.getAllIncidentRedirection(data);
    setData(result);
    props.setLoading(false);
  };
  const getSearchData = useCallback(async () => {
    await getGridData(props.searchData);
  }, [getGridData]);

  useEffect(() => {
    getSearchData();
  }, [props.isSearchChanged]);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);

  return (
    <div>
      <div className="table-wrapper p-2 m-3 container-bg table-responsive">
        <table className="table table-responsive table-hover table-bordered">
          <thead>
            <tr>
              {Header.map((h, i) => {
                return <th key={i}>{h}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((d, index) => {
              return (
                <tr key={index} id={"row_" + d.Id}>
                  <td>{util.getTextFromObj(d.IncidentType)}</td>
                  <td>
                    {d.ALVIncidentTypeId !== null ? util.getTextFromObj(d.ALVIncidentTypeLkp) : ""}
                    {d.HoldingOwnerShipTypeId !== null ? util.getTextFromObj(d.HoldingOwnerShipTypeLkp) : ""}
                  </td>

                  <td>{util.getTextFromObj(d?.EmirateLkp)}</td>
                  <td>{util.getTextFromObj(d?.SourceGovernmentalEntityLkp)}</td>
                  <td>{util.getTextFromObj(d?.TargetGovernmentalEntityLkp)}</td>
                  <td>{util.getTextFromObj(d?.UserAccountTypeLkp)}</td>

                  <td>
                    <a
                      rel="noreferrer"
                      className="edit p-2 text-left"
                      onClick={() => {
                        onDelete(d?.Id);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RedirectionList;
