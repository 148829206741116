import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { enums, iAnimalSD, encrypt, validation, auth, iIncident, animalSharedDiseaseAction, transAction, swal, toast } from "../../Imports";
import Attachment from "../Common/Attachment";
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import Reporter from "../Common/Reporter";
import GPSLocation from "../Common/LocationDetail/GPSLocation";
import EmirateGovEntity from "../Common/LocationDetail/EmirateGovEntity";

import PatientData from "./Details/PatientData";
import DiseaseData from "./Details/DiseaseData";

const AnimalShareDisease = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  followup = parseInt(encrypt.decrypt(followup));
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  useEffect(() => {
    // console.log(transaction);
  }, [transaction]);

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      getIncidentTransaction(id);
    }
  }, [id]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await animalSharedDiseaseAction.getAnimalSharedDiseaseTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;
      handleChangeDTO(res, iAnimalSD.ASD_Constants.ASDT);

      handleChangeDTO(res.AnimalSharedDiseaseInfectedPeople, iAnimalSD.ASD_Constants.ASDIP);
      const attachment = res.AnimalSharedDiseaseAttachment.map((file) => {
        return file.FileAttachmentsTransaction;
      });
      handleChangeDTO(attachment, iAnimalSD.ASD_Constants.ASDA);

      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);
      validation.enableDisableForm("DiseaseDataForm", true);
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iAnimalSD.AnimalSharedDiseaseTransactionDTO();
    data = transaction.AnimalSharedDiseaseTransactionDTO;

    data.AnimalSharedDiseaseAttachment = transaction.AnimalSharedDiseaseAttachmentDTO;
    data.AnimalSharedDiseaseInfectedPeople = transaction.AnimalSharedDiseaseInfectedPeopleDTO;

    data.AnimalSharedDiseaseAttachment = transaction.AnimalSharedDiseaseAttachmentDTO.map((x) => {
      return new iAnimalSD.AnimalSharedDiseaseAttachmentDTO(x.Id);
    });
    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.AnimalSharedDisease, followup);

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }

    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await animalSharedDiseaseAction.createAnimalSharedDisease(data);
    else if (followup > 0) {
      res = await animalSharedDiseaseAction.createFollowUpAnimalSharedDisease(data);
    } else {
      res = await animalSharedDiseaseAction.updateAnimalSharedDisease(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };

  const validateDetailForm_user = (e) => {
    const actionFrom = validation.validateForm("actionbuttonForm");
    const animalSharedDiseaseFrom = validation.validateForm("ShareDiseaseReportForm", "PatientDataForm");
    if (!actionFrom || !validation.validateInvestigationDetailForm() || !animalSharedDiseaseFrom) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    } else if (transaction.AnimalSharedDiseaseInfectedPeopleDTO.length === 0) {
      toast(props.t("PleaseEnterPatientData"), { type: "warning" });
      return false;
    } else if (
      transaction.FileAttachmentDTO.filter((x) => x.Code === enums.AttachmentEnum.EpidemiologicalReport).length === 0 ||
      transaction.FileAttachmentDTO.filter((x) => x.Code === enums.AttachmentEnum.Other).length === 0
    ) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }

    return true;
  };
  const formValidation = () => {
    return validateDetailForm_user();
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid container ">
          <div className="row ShareDiseaseReportForm">
            <div className="container page-title p-3 pl-4 mb-0">
              <h3>{props.t("CreateNewShareDiseaseReport")}</h3>
            </div>
            <div className="col-sm-12">
              <div className="tab-item-wrapper">{transaction.isUpdate && <IncidentStatusUpdate {...props} />}</div>
              <div className="row container-bg my-3  ">
                <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                <EmirateGovEntity
                  {...props}
                  isDisabled={transaction.isUpdate}
                  unitType={enums.UnitTypeEnum.GovernmentEntity}
                  department={enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment}
                  value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                  emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                  handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                />
              </div>
              <div>
                <PatientData transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
              </div>
              <div className="    ">
                <Reporter handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
              </div>
              <div className="row my-3 container-bg">
                <label className="control-title   mb-4">
                  <strong>{props.t("DiseaseData")}</strong>
                </label>
                <div className="tab-item-wrapper DiseaseDataForm">
                  <DiseaseData handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />

                  <Attachment
                    required={true}
                    handleChange={handleChange}
                    handleChangeDTO={handleChangeDTO}
                    code={enums.AttachmentEnum.EpidemiologicalReport}
                    topic="EpidemiologicalReport"
                    canUploadFile={validation.isHealthFacilityUser()}
                    {...props}
                  />
                  <Attachment
                    required={true}
                    handleChange={handleChange}
                    handleChangeDTO={handleChangeDTO}
                    code={enums.AttachmentEnum.Other}
                    canUploadFile={validation.isHealthFacilityUser()}
                    {...props}
                  />
                </div>
              </div>
              <div>
                {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimalShareDisease;
