import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SharedDTO } from "../../Interface/IIncidentTrans";
import FormInput, { SelectInput } from "../Common/Validation/FormInput";
import { auth, documentLibAction, encrypt, enums, iIncident, master, swal, toast, transAction, validation } from "../Imports";
import Attachment from "../Incidents/Common/Attachment";

const DocumentLibrary = (props) => {
  let { id, mode } = useParams();
  id = parseInt(encrypt.decrypt(id));
  mode = encrypt.decrypt(mode);
  const transaction = useSelector((state) => state.transaction);
  const user = auth.getUserPermission();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialState = {
    Id: 0,
    Description: "",
    DepartmentId: "",
    DocumentTypeId: "",
    FileAttachmentId: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (!validation.isAccessDocumentLib(user) && mode !== "view") {
      navigate(`/viewDocumentLib`);
    }
    if (id > 0) {
      getDocumentLibById(id);
    }
    return {};
  }, []);
  
  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };

  const getDocumentLibById = async (id) => {
    const res = await documentLibAction.GetDocumentLibraryById(id);
    if (res.data) {
      const data = res.data.ResponseData;
      setLocalVal((p) => ({
        ...p,
        DepartmentId: data.DepartmentId,
        Description: data.Description,
        DocumentTypeId: data.DocumentTypeId,
        FileAttachmentId: data.FileAttachmentId,
        Id: data.Id,
      }));

      const attachment = [];
      attachment.push(
        new iIncident.IncidentAttachment(data.FileAttachmentsTransaction.Id, enums.AttachmentEnum.DocumentLibrary, data.FileAttachmentsTransaction)
      );
      handleChangeDTO(attachment, SharedDTO.FA);
      handleChangeDTO(true, "isUpdate");
      if (mode === "view") {
        validation.enableDisableForm("DocumentLibForm", true);
      }
    }
  };
  const submitHandler = (e) => {
    const documentLibForm = validation.validateForm("DocumentLibForm");
    if (!documentLibForm) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length !== 1) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    localVal.FileAttachmentId = transaction.FileAttachmentDTO[0].FileAttachmentId;
    if (!validation.isAccessDocumentLib(user)) {
      localVal.DepartmentId = user.Department;
    }
    console.log(localVal);
    sendRequest(localVal);
    return true;
  };
  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);

    res = await documentLibAction.CreateDocumentLibrary(data);

    if (res.data && res.data.IsSuccess) {
      swal(props.t("success"), props.t("submitSuccessFully"), "success");

      props.setLoading(false);
      navigate(`/viewDocumentLib`);
    }
  };

  return (
    <div>
      <div className="page-title p-3 pl-4">
        <h3>{props.t("NewDocumentLibraryItem")}</h3>
      </div>
      <div className="container my-3 DocumentLibForm">
        <div className="row container-bg">
          {validation.isAccessDocumentLib(user) && <DepartmentInput initialState={localVal} changeControl={changeControl} />}

          <DocumentTypeInput initialState={localVal} changeControl={changeControl} />
          <div className="col-md-12">
            <FormInput
              name="Description"
              type="textarea"
              label="Description"
              required={true}
              disabled={false}
              value={localVal.Description}
              onChange={changeControl}
            />
          </div>
          <div className="col-md-12">
            <Attachment
              required={true}
              handleChangeDTO={handleChangeDTO}
              code={enums.AttachmentEnum.DocumentLibrary}
              canUploadFile={mode === "edit"}
              {...props}
            />
          </div>
          {mode !== "view" && (
            <div className="col-md-12">
              <button className="btn btn-success" type="button" onClick={submitHandler}>
                {transaction.isUpdate ? props.t("update") : props.t("add")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const DepartmentInput = ({ initialState, changeControl }) => {
  const [departmentData, setDepartmentData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setDepartmentData(await master.getDepartmentLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-6">
      <SelectInput
        name="DepartmentId"
        label="Department"
        required={true}
        disabled={false}
        value={initialState.DepartmentId}
        onChange={changeControl}
        data={departmentData}
      />
    </div>
  );
};
const DocumentTypeInput = ({ initialState, changeControl }) => {
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setDocumentTypeData(await master.getDocumentTypeLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-6">
      <SelectInput
        name="DocumentTypeId"
        label="DocumentType"
        required={true}
        disabled={false}
        value={initialState.DocumentTypeId}
        onChange={changeControl}
        data={documentTypeData}
      />
    </div>
  );
};
export default DocumentLibrary;
