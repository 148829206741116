import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import { enums, master, validation } from "../../Imports";
import * as Report from "./ReportControl";

const Filter = (props) => {
  const dispatch = useDispatch();
  const UserTypeAccountData = useSelector((state) => state.masterData.UserAccountType);
  const [localVal, setLocalVal] = useState(props.data);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const changeControl = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "HoldingOwnerShipType") {
      setLocalVal((p) => ({
        ...p,
        LegalViolationTypeId: 0,
        [name]: value,
      }));
    } else if (name === "LegalViolationTypeId") {
      setLocalVal((p) => ({
        ...p,
        HoldingOwnerShipType: 0,
        [name]: value,
      }));
    } else {
      setLocalVal((p) => ({
        ...p,
        [name]: value,
      }));
    }
  };
  const changeControlDate = (name, value) => {
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const getDropdownData = useCallback(async () => {
    if (props.type === enums.SearchCriteriaEnum.IncidentSearch) {
      dispatch(master.getUserAccountType());
    }
  }, [dispatch, props.type]);

  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);

  useEffect(() => {
    setLocalVal(props.data);
  }, [props.isSearchChanged]);

  const resetForm = (e) => {};

  const searchFormSubmit = (e) => {
    e.preventDefault();
    props.onChangeSearch(localVal);
  };

  const handleReset = (e) => {
    props.onChangeSearch(props.initialData);
    document.getElementById("search-filter-form").reset();
  };
  const toggleAdvancedSearch = async () => {
    setAdvancedSearch(!advancedSearch);
  };
  return (
    <div>
      <div className="table-filter">
        <form id="search-filter-form" onReset={resetForm} onSubmit={searchFormSubmit}>
          <div className="pt-3 pl-3 pr-3 m-3 container-bg">
            <div className="row flex-fill">
              {(props.type === enums.SearchCriteriaEnum.IncidentSearch ||
                props.type === enums.SearchCriteriaEnum.MyIncidents ||
                props.type === enums.SearchCriteriaEnum.MyTask) && (
                <>
                  <div className="col-md-2">
                    <Report.DateControl label="from" localVal={localVal.fromDate} changeControlDate={changeControlDate} />
                  </div>
                  <div className="col-md-2">
                    <Report.DateControl label="to" localVal={localVal.toDate} changeControlDate={changeControlDate} />
                  </div>
                  {props.type === enums.SearchCriteriaEnum.IncidentSearch && (
                    <div className="col-md-2">
                      <Report.EmirateLkpControl localVal={localVal.EmirateId} changeControl={changeControl} />
                    </div>
                  )}
                  {props.type === enums.SearchCriteriaEnum.MyIncidents && (
                    <div className="col-md-2">
                      <Report.WFStatusLkpControl localVal={localVal.StatusCode} changeControl={changeControl} />
                    </div>
                  )}
                  {(props.type === enums.SearchCriteriaEnum.MyIncidents || props.type === enums.SearchCriteriaEnum.MyTask) && (
                    <div className="col-md-2">
                      <Report.TextBoxControl label="IncidentNumber" name="RefNo" changeControl={changeControl} localVal={localVal.RefNo} />
                    </div>
                  )}
                </>
              )}

              {props.type === enums.SearchCriteriaEnum.IncidentSearch && (
                <>
                  <div className="col-md-2">
                    <Report.WFStatusLkpControl localVal={localVal.StatusCode} changeControl={changeControl} />
                  </div>

                  <div className="col-md-2">
                    <Report.TextBoxControl label="IncidentNumber" name="RefNo" changeControl={changeControl} localVal={localVal.RefNo} />
                  </div>
                  <div className="col-md-2 mt-4">
                    <a style={{ color: "#ce902b" }} onClick={toggleAdvancedSearch}>
                      {props.t("advanceSearch")}
                    </a>
                  </div>

                  {advancedSearch && (
                    <>
                      <div className="col-md-2">
                        <Report.IncidentTypeLkpControl localVal={localVal.IncidentTypeCode} changeControl={changeControl} />
                      </div>
                      {validation.isAnimalDept() && (
                        <>
                          <div className="col-md-2">
                            <Report.UserAccountTypeLkpControl localVal={localVal.UserAccountType} changeControl={changeControl} data={UserTypeAccountData} />
                          </div>

                          {localVal.IncidentTypeCode === enums.IncidentTypeEnum.AnimalHealth && (
                            <div className="col-md-2">
                              <Report.HoldingOwnerShipTypeLkpControl
                                localVal={localVal.HoldingOwnerShipType}
                                changeControl={changeControl}
                                name={"HoldingOwnerShipType"}
                              />
                            </div>
                          )}
                          {localVal.IncidentTypeCode === enums.IncidentTypeEnum.AnimalLegalViolation && (
                            <div className="col-md-2">
                              <Report.LegalViolationTypeLkpControl
                                localVal={localVal.LegalViolationTypeId}
                                changeControl={changeControl}
                                name="LegalViolationTypeId"
                              />
                            </div>
                          )}
                        </>
                      )}
                      <div className="col-md-2">
                        <Report.RiskDegreeLkpControl localVal={localVal.RiskDegree} changeControl={changeControl} />
                      </div>

                      <div className="col-md-2">
                        <FormInput
                          name="governmentEntity"
                          type="text"
                          label="govEntity"
                          required={false}
                          value={localVal.holdingTypeOther}
                          onChange={changeControl}
                        />
                      </div>
                      <div className="col-md-2">
                        <Report.TextBoxControl label="reporterName" name="ReporterName" changeControl={changeControl} localVal={localVal.ReporterName} />
                      </div>
                      {/* <div className="col-md-2">
                    <Report.TextBoxControl label="mobileNo" name="ReporterMobileNo" changeControl={changeControl} localVal={localVal.ReporterMobileNo} />
                  </div> */}
                      {validation.isFoodSafetyDept() && (
                        <>
                          <div className="col-md-2">
                            <Report.TextBoxControl label="ProductName" name="ProductName" changeControl={changeControl} localVal={localVal.ProductName} />
                          </div>
                          <div className="col-md-2">
                            <Report.NotificationTypeLkpControl localVal={localVal.NotificationTypeId} changeControl={changeControl} />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="col-md-3 my-4" id="searchForm" style={{ marginTop: "15px" }}>
                <button type="submit" id="btnSearch" className="btn btn-success mx-2 pt-2 pb-2">
                  {props.t("search")}
                </button>
                <button type="submit" onClick={handleReset} className="btn bt-reset mx-2  pt-2 pb-2">
                  {props.t("clear")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* {isSearch && <Inbox {...props} type={props.type} searchData={localVal} />} */}
    </div>
  );
};

export default Filter;
