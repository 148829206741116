import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { accountAction, auth, encrypt, validation, util, swal, toast } from "../../Imports";
import { useEffect } from "react";
import { useMemo } from "react";
import Pagination from "../../Common/UI/Pagination/Pagination";

const ManageAccount = (props) => {
  const Header = [
    props.t("englishName"),
    props.t("arabicName"),
    props.t("email"),
    props.t("Department"),
    props.t("govEntity"),
    props.t("userType"),
    props.t("IsActive"),
    props.t("Edit"),
    props.t("Delete"),
  ];
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [userData, setUserData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const user = auth.getUserPermission();

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return userData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, userData]);

  const loadComLkp = useCallback(async () => {
    props.setLoading(true);
    const deptId = validation.isSystemAdministrator() ? 0 : user.Department;
    const data = await accountAction.getAllUsers(deptId);

    setUserData(data);
    setSearchData(data);
    setCurrentPage(1);
    props.setLoading(false);
  }, []);

  const onDeleteUser = async (id) => {
    console.log("delete" + id);
    swal({
      title: props.t("AreYouSure"),
      text: props.t("DoYouWantToDelete"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const isDeleted = await accountAction.deleteUser(id);
        if (isDeleted) {
          setUserData(userData.filter((x) => x.UserId !== id));
          setCurrentPage(currentPage);
          swal(props.t("success"), props.t("deletedSuccessFully"), "success");
        }
      }
    });
  };
  const onSearchHandle = (e) => {
    const search = e.target.value.toLowerCase();
    if (search.length > 0) {
      setUserData(
        userData.filter(
          (x) =>
            x.Email.toLowerCase().indexOf(search) > -1 ||
            x.UsersProfile.NameEn.toLowerCase().indexOf(search) > -1 ||
            x.UsersProfile.NameAr.toLowerCase().indexOf(search) > -1
        )
      );
    } else setUserData(searchData);
  };
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);

  const switchIsActive = async (e) => {
    const userId = e.target.dataset.id;
    var preCheckedStatus = e.target.checked;
    var row = document.getElementById("row_" + userId);
    var checkbox = document.getElementById("checkbox_" + userId);
    const data = await accountAction.enableDisabledUser(userId);
    if (data) {
      checkbox.checked = preCheckedStatus;
      if (preCheckedStatus) row.classList.remove("disabled-row");
      else row.classList.add("disabled-row");
      toast(props.t("updatedSuccessFully"), { type: "success" });
    }
  };
  return (
    <div>
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("ManageAccount")}</h3>
        {validation.isAccessDocumentLib(user) && (
          <Link to={`/AddNewAccount/${encrypt.encrypt(0)}`} className="btn new-document m-2">
            {props.t("AddNewAccount")}
          </Link>
        )}
      </div>

      <div className="col-md-4 pl-3 pr-3  mt-3">
        <input type="search" className="form-control" placeholder={props.t("search")} onChange={onSearchHandle} />
      </div>
      <div className="table-wrapper p-2 m-3 container-bg table-responsive">
        <table className="table table-responsive table-hover table-bordered">
          <thead>
            <tr>
              {Header.map((h, i) => {
                return <th key={i}>{h}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {currentTableData.map((user, index) => {
              return (
                <tr key={index} id={"row_" + user.UserId}>
                  <td>{user.UsersProfile?.NameEn}</td>
                  <td>{user.UsersProfile?.NameAr}</td>
                  <td>{user?.Email}</td>
                  <td>{util.getTextFromObj(user?.UsersProfile?.OrganizationUnitsLkp?.DepartmentUnitType?.DepartmentsLkp)}</td>
                  <td>{util.getTextFromObj(user?.UsersProfile?.OrganizationUnitsLkp?.GovernmentalEntityLkp)}</td>
                  <td>{util.getTextFromObj(user.UsersProfile?.OrganizationUnitsLkp?.DepartmentUnitType?.UnitTypeLkp)}</td>

                  <td>
                    <input
                      type="checkbox"
                      data-id={user.UserId}
                      id={"checkbox_" + user.UserId}
                      checked={user.IsApproved}
                      onChange={(e) => switchIsActive(e)}
                    ></input>
                  </td>
                  <td>
                    <Link className="edit p-2 text-left" to={`/AddNewAccount/${encrypt.encrypt(user?.UserId)}`}>
                      <i className="ico icon-Group-388 mr-2"></i>
                    </Link>
                  </td>
                  <td>
                    <a
                      rel="noreferrer"
                      className="edit p-2 text-left"
                      onClick={() => {
                        onDeleteUser(user?.UserId);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {userData?.length > 0 && (
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={userData.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

export default ManageAccount;
