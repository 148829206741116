import React, { useState } from "react";
import FormInput from "../Common/Validation/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { accountAction, swal } from "../Imports";
import { useNavigate } from "react-router-dom";
import AlertBox from "../Common/UI/AlertBox";
import { UserConfirmPassword, UserPassword } from "./CommonControl";

const ForgetPassword = (props) => {
  const navigate = useNavigate();
  const isEmailExist = useSelector((state) => state.account.isEmailExist);
  const dispatch = useDispatch();
  const initialState = {
    Email: "",
    Code: "",
    NewPassword: "",
  };

  const [localVal, setLocalVal] = useState(initialState);
  const [step, setStep] = useState(1);
  const handleChange = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeEmail = (e) => {
    const value = e.target.value;
    if (value.length > 10) {
      dispatch(accountAction.isEmailExist(value));
    }
    handleChange(e);
  };
  const cancelHandler = () => {
    //navigate(`/login`);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(step);
    if (step === 1) {
      const isCodeSent = await accountAction.getCodeByEmail(localVal.Email);
      if (isCodeSent) setStep(2);
    } else if (step === 2) {
      const verifyCode = await accountAction.verifySecurityCode(localVal.Email, localVal.Code);
      if (verifyCode) setStep(3);
    } else if (step === 3) {
      const changePass = await accountAction.changePasswordBySecurityCode(localVal);
      if (changePass) {
        swal(props.t("success"), props.t("PasswordChangeSuccess"), "success");
        cancelHandler();
      }
    }
  };
  return (
    <form onSubmit={submitHandler}>
      <div className="row ">
        {step === 1 && (
          <div className="col-md-12 divEmail m-5">
            <div className="col-md-10">
              <FormInput
                name="Email"
                type="email"
                label={props.t("email")}
                placeholder={props.t("email")}
                // pattern={constants.email_regx}
                required={true}
                errorMessage={props.t("email") + props.t("incorrect")}
                value={localVal.Email}
                onChange={handleChangeEmail}
              />
              {!isEmailExist && localVal.Email && (
                <span className="red customError">
                  {props.t("email")} {props.t("doesNoExist")}
                </span>
              )}
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="col-md-10 divEmail m-5 mx-4">
            <AlertBox>
              <div>{props.t("activationCodeText")}</div>
            </AlertBox>

            <div className="col-md-8 my-2">
              <FormInput
                name="Code"
                type="text"
                label={props.t("code")}
                placeholder={props.t("code")}
                required={true}
                value={localVal.Code}
                onChange={handleChange}
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="col-md-10 divEmail m-5 mx-4">
            <div className="col-md-8 my-2">
              <UserPassword name="NewPassword" value={localVal.NewPassword} label="newPassword" handleChange={handleChange} props={props} />
            </div>
            <div className="col-md-8 my-2">
              <UserConfirmPassword name="confirmPassword" label="password" currentPassField={localVal.NewPassword} handleChange={handleChange} props={props} />
            </div>
          </div>
        )}
        <div className="col-md-12 d-flex justify-content-around  ">
          <button className="btn btn-success " type="submit">
            {props.t("next")}
          </button>
          <button className="bt-reset" type="button" onClick={cancelHandler}>
            {props.t("cancel")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ForgetPassword;
