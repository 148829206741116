import React from "react";
import PestIncidentGIS from "./PestIncidentGIS";
import PestSpreadGIS from "./PestSpreadGIS";

const GISMap = (props) => {
  const fileType = props.currentLanguage === "ar" ? "mapAr.html" : "map.html";
  return (
    <div>
      <PestIncidentGIS fileType={fileType} {...props} />
      <PestSpreadGIS fileType={fileType} {...props} />
    </div>
  );
};

export default GISMap;
