import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SelectInput } from "../../../Common/Validation/FormInput";
import { auth, enums, iIncident, master, validation } from "../../../Imports";

const RedirectAction = (props) => {
  const user = auth.getUserPermission();
  const [emirateId, setEmirateId] = useState("");
  const [govEntityData, setGovEntityData] = useState([]);
  const EmirateData = useSelector((state) => state.masterData.emirateData);
  const transaction = useSelector((state) => state.transaction);

  const onchangeEmirate = async (e) => {
    getGovtEntity(e.target.value);
    setEmirateId(e.target.value);
    props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT);
  };
  const getGovtEntity = async (emirateId) => {
    const currentGovtEntity = transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId;
    let data = await master.getGovtEntity(
      emirateId,
      validation.isFoodSafetyDept() ? enums.UnitTypeEnum.FocalPoint : enums.UnitTypeEnum.GovernmentEntity,
      user.Department
    );
    data = data.filter((x) => x.Id !== currentGovtEntity);
    setGovEntityData(data);
  };
  return (
    <div className="row  container-bg col-md-12">
      <div className="col-md-4">
        <SelectInput name="Redirect_EmirateId" label="emirate" required={true} value={emirateId} onChange={onchangeEmirate} data={EmirateData} />
      </div>
      <div className="col-md-4">
        <SelectInput
          name="Redirect_GovtEntityId"
          label="nearestGovEntity"
          required={true}
          value={transaction[iIncident.SharedDTO.IT].Redirect_GovtEntityId}
          onChange={(e) => props.handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
          data={govEntityData}
        />
      </div>
    </div>
  );
};

export default RedirectAction;
