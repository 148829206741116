export const constants = {
  //googleMap_API: "AIzaSyBdHlcLOXD9e3fTw8SOpAd0BM2M6kn_KSw",
  googleMap_API: "AIzaSyD2TKA_xdUldeY7yOZxXgRPBeCNhdr1uXM",
  allowedFileSize: 10242880,
  allowedFileExt: "WORD, XLXS, PDF, PNG, JPEG, JPG, MP, MP4",
  allowedFile:
    "application/pdf,image/jpeg,image/png,image/*,video/*,audio/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  email_regx: "[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+(.[-a-zA-Z0-9~!$%^&amp;*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(.[-a-zA-Z0-9_]+)*.([cC][oO][mM]))(:[0-9]{1,5})?",
};
const Eservices_BaseURL = "https://eservices.moccae.gov.ae/api/";
export const Eservices = {
  url: {
    GetAgriculturaltenure: Eservices_BaseURL + "bens/GetAgriculturaltenure/",
  },
  authorization: "Basic QkVOUzpUVzlqWTJGbFFFQXlNREU0",
};

const apiRoutes = {
  incident: "/Incident/",
  animalHealth: "/AnimalHealth/",
  animalLegalViolation: "/AnimalLegalViolation/",
  animalSharedDisease: "/AnimalSharedDisease/",
  animalBorderGate: "/AnimalBorderGate/",
  agriculturePest: "/AgriculturePest/",
  agricultureBorderGate: "/AgricultureBorderGate/",
  foodSafety: "/foodSafety/",
  FoodSafetyFollowUp: "/FoodSafetyFollowUp/",
  foodSafetyBorderGate: "/foodSafetyBorderGate/",
  EnvironmentalDisaster: "/EnvironmentalDisaster/",
  attchementRoute: "/Attachment/",
  masterDataRoute: "/masterdata/",
  userRoute: "/UserManage/",
  workflow: "/WorkFlow/",
  notification: "/Notification/",
  Dashboard: "/Dashboard/",
  DocumentLibrary: "/DocumentLibrary/",
  ContactUs: "/ContactUs/",
  SystemConfiguration: "/SystemConfiguration/",
  IncidentRedirection: "/IncidentRedirection/",
  EnvironmentalIncident: "/EnvironmentalIncident/",
  UAEPass: "/UAEPass/",
};

export const URLs = {
  uaePass: {
    ClientId: process.env.REACT_APP_UAEPASS_CLIENT_ID,
    RedirectURL: process.env.REACT_APP_UAEPASS_REDIRECT_URL,
    Authorization: process.env.REACT_APP_UAEPASS_BASE_URL + "authorize",
    Token: process.env.REACT_APP_UAEPASS_BASE_URL + "token",
    UserInfo: process.env.REACT_APP_UAEPASS_BASE_URL + "userinfo",
    Logout: process.env.REACT_APP_UAEPASS_BASE_URL + `logout?redirect_uri=${process.env.REACT_APP_UAEPASS_REDIRECT_URL}`,
  },
  fileURL: "https://portal.moccae.gov.ae/fileserver/BioSecAlert/",

  GetUserInfo: apiRoutes.UAEPass + "GetUserInfo",

  GetAllIncidentRedirection: apiRoutes.IncidentRedirection + "GetAllIncidentRedirection",
  DeleteIncidentRedirection: apiRoutes.IncidentRedirection + "DeleteIncidentRedirection",
  CreateIncidentRedirection: apiRoutes.IncidentRedirection + "CreateIncidentRedirection",

  CreateDocumentLibrary: apiRoutes.DocumentLibrary + "Create",
  GetDocumentLibrary: apiRoutes.DocumentLibrary + "GetDocumentLibrary",
  GetDocumentLibraryById: apiRoutes.DocumentLibrary + "GetDocumentLibraryById",
  EnableDisabledDocumentLib: apiRoutes.DocumentLibrary + "EnableDisabledDocumentLib",

  GetGeneralInfo: apiRoutes.EnvironmentalDisaster + "GetGeneralInfo",
  GetIncidentStatus: apiRoutes.EnvironmentalDisaster + "GetIncidentStatus",
  GetIncidentPerEmirate: apiRoutes.EnvironmentalDisaster + "GetIncidentPerEmirate",
  GetIncidentDangerRisk: apiRoutes.EnvironmentalDisaster + "GetIncidentDangerRisk",
  GetIncidentDetailDangerRisk: apiRoutes.EnvironmentalDisaster + "GetIncidentDetailDangerRisk",
  GetComparisonOpenCloseYearlyIncidents: apiRoutes.EnvironmentalDisaster + "GetComparisonOpenCloseYearlyIncidents",
  GetComparisonOpenCloseMonthlyIncidents: apiRoutes.EnvironmentalDisaster + "GetComparisonOpenCloseMonthlyIncidents",
  GetNumberOfIncident: apiRoutes.EnvironmentalDisaster + "GetNumberOfIncident",
  GetIncidentTypes: apiRoutes.EnvironmentalDisaster + "GetIncidentTypes",
  GetIncidentsBySource: apiRoutes.EnvironmentalDisaster + "GetIncidentsBySource",
  GetIncidentsByGovEntity: apiRoutes.EnvironmentalDisaster + "GetIncidentsByGovEntity",

  GetCountByStatus: apiRoutes.Dashboard + "GetCountByStatus",
  GetIncidentByEmirate: apiRoutes.Dashboard + "GetIncidentByEmirate",
  GetIncidentCountByEmirate: apiRoutes.Dashboard + "GetIncidentCountByEmirate",
  GetIncidentByIncidentSource: apiRoutes.Dashboard + "GetIncidentByIncidentSource",
  GetIncidentByOriginCountry: apiRoutes.Dashboard + "GetIncidentByOriginCountry",
  GetIncidentByProductCatagory: apiRoutes.Dashboard + "GetIncidentByProductCatagory",
  GetIncidentByRiskSourceGroup: apiRoutes.Dashboard + "GetIncidentByRiskSourceGroup",
  GetIncidentByTakenAction: apiRoutes.Dashboard + "GetIncidentByTakenAction",
  GetAnimalStateCount: apiRoutes.Dashboard + "GetAnimalStateCount",
  GetLeagalViolationStatCount: apiRoutes.Dashboard + "GetLeagalViolationStatCount",
  GetAnimalIncidentByEmirate: apiRoutes.Dashboard + "GetAnimalIncidentByEmirate",
  GetAgricultureStateCount: apiRoutes.Dashboard + "GetAgricultureStateCount",
  GetAgricultureIncidentByEmirate: apiRoutes.Dashboard + "GetAgricultureIncidentByEmirate",
  GetAnimalStateCount_IncidentDetail: apiRoutes.Dashboard + "GetAnimalStateCount_IncidentDetail",
  GetLeagalViolationStatCount_IncidentDetail: apiRoutes.Dashboard + "GetLeagalViolationStatCount_IncidentDetail",
  GetCountByStatus_IncidentDetail: apiRoutes.Dashboard + "GetCountByStatus_IncidentDetail",
  GetAgricultureStateCount_IncidentDetail: apiRoutes.Dashboard + "GetAgricultureStateCount_IncidentDetail",

  Login: apiRoutes.userRoute + "Login",
  Logout: apiRoutes.userRoute + "Revoke",
  AddUserProfile: apiRoutes.userRoute + "AddUserProfile",
  GetUserProfile: apiRoutes.userRoute + "GetUserProfile",
  GetAllUsers: apiRoutes.userRoute + "GetAllUsers",
  AddUserByAdmin: apiRoutes.userRoute + "AddUserByAdmin",
  ChangePassword: apiRoutes.userRoute + "ChangePassword",
  GetCodeByEmail: apiRoutes.userRoute + "GetCodeByEmail",
  VerifySecurityCode: apiRoutes.userRoute + "VerifySecurityCode",
  VerifyRecaptcha: apiRoutes.userRoute + "verify-recaptcha",
  ChangePasswordBySecurityCode: apiRoutes.userRoute + "ChangePasswordBySecurityCode",
  EnableDisabledUser: apiRoutes.userRoute + "EnableDisabledUser",
  UpdateUserByAdmin: apiRoutes.userRoute + "UpdateUserByAdmin",
  DeleteUser: apiRoutes.userRoute + "DeleteUser",
  GetUserById: apiRoutes.userRoute + "GetUserById",
  IsEmirateIDExist: apiRoutes.userRoute + "IsEmirateIDExist",
  IsPassportExist: apiRoutes.userRoute + "IsPassportExist",
  IsEmailExist: apiRoutes.userRoute + "IsEmailExist",
  LoginByUAEPass: apiRoutes.userRoute + "LoginByUAEPass",
  GetUserByEID: apiRoutes.userRoute + "GetUserByEID",
  RegisterUserAndLogin_UAEPass: apiRoutes.userRoute + "RegisterUserAndLogin_UAEPass",
  LinkedLogin_UAEPass: apiRoutes.userRoute + "LinkedLogin_UAEPass",
  LoginByEmailEID: apiRoutes.userRoute + "LoginByEmailEID",

  EmirateLkp: apiRoutes.masterDataRoute + "getemiratelkp",
  GovtEntity: apiRoutes.masterDataRoute + "GetGovernmetnalEntityLkp",
  GetAllGovernmentalEntityLkp: apiRoutes.masterDataRoute + "GetAllGovernmentalEntityLkp",
  AddOrganization: apiRoutes.masterDataRoute + "AddOrganization",
  GetPortList: apiRoutes.masterDataRoute + "GetPortList",
  GetAllOrganization: apiRoutes.masterDataRoute + "GetAllOrganization",
  GetNonGovUserTypesLkp: apiRoutes.masterDataRoute + "GetNonGovUserTypesLkp",
  GetHoldingOwnerShipTypeLkp: apiRoutes.masterDataRoute + "GetHoldingOwnerShipTypeLkp",
  GetAnimalCatagoryLkp: apiRoutes.masterDataRoute + "GetAnimalCatagoryLkp",
  GetAnimalTypeLkp: apiRoutes.masterDataRoute + "GetAnimalTypeLkp",
  GetUserAccountTypeLkp: apiRoutes.masterDataRoute + "GetUserAccountTypeLkp",
  GetControlProcedureLkp: apiRoutes.masterDataRoute + "GetControlProcedureLkp",
  GetTransportMediaLkp: apiRoutes.masterDataRoute + "GetTransportMediaLkp",
  GetProductGroupLkp: apiRoutes.masterDataRoute + "GetProductGroupLkp",
  GetRiskSourceLkp: apiRoutes.masterDataRoute + "GetRiskSourceLkp",
  GetRiskSourceGroupLkp: apiRoutes.masterDataRoute + "GetRiskSourceGroupLkp",
  GetNotificationTypeLkp: apiRoutes.masterDataRoute + "GetNotificationTypeLkp",
  GetActivityTypeLkp: apiRoutes.masterDataRoute + "GetActivityTypeLkp",
  GetFoodActionTakenLkp: apiRoutes.masterDataRoute + "GetFoodActionTakenLkp",
  GetUnitTypeLkp: apiRoutes.masterDataRoute + "GetUnitTypeLkp",
  GetUnitTypeEnumLkp: apiRoutes.masterDataRoute + "GetUnitTypeEnumLkp",

  GetIncidentTypeLkp: apiRoutes.masterDataRoute + "GetIncidentTypeLkp",
  GetDepartmentLkp: apiRoutes.masterDataRoute + "GetDepartmentLkp",
  GetOrganizationLkp: apiRoutes.masterDataRoute + "GetOrganizationLkp",
  GetDocumentTypeLkp: apiRoutes.masterDataRoute + "GetDocumentTypeLkp",
  GetOrgUnitTypeLkp: apiRoutes.masterDataRoute + "GetOrgUnitTypeLkp",
  GetVetEstablishmentLkp: apiRoutes.masterDataRoute + "GetVetEstablishmentLkp",
  GetALVIncidentTypeLkp: apiRoutes.masterDataRoute + "GetALVIncidentTypeLkp",
  GetInfectionTypeLkp: apiRoutes.masterDataRoute + "GetInfectionTypeLkp",
  GetRiskDegreeLkp: apiRoutes.masterDataRoute + "GetRiskDegreeLkp",
  GetEserviceAnimalCatagoryLkp: apiRoutes.masterDataRoute + "GetEserviceAnimalCatagoryLkp",
  GetEserviceAnimalLkp: apiRoutes.masterDataRoute + "GetEserviceAnimalLkp",
  GetCountryLkp: apiRoutes.masterDataRoute + "GetCountryLkp",
  GetEserviceAgricultureLkp: apiRoutes.masterDataRoute + "GetEserviceAgricultureLkp",
  GetAnimalDisease: apiRoutes.masterDataRoute + "GetAnimalDisease",
  AddAnimalDisease: apiRoutes.masterDataRoute + "AddAnimalDisease",
  RemoveAnimalTypeDisease: apiRoutes.masterDataRoute + "RemoveAnimalTypeDisease",
  GetAnimalDiseaseLkp: apiRoutes.masterDataRoute + "GetAnimalDiseaseLkp",
  GetClinicTypeSymptoms: apiRoutes.masterDataRoute + "GetClinicTypeSymptoms",
  GetClinicSymptomLkp: apiRoutes.masterDataRoute + "getClinicSymptomLkp",
  GetAnimalTypeScientificName: apiRoutes.masterDataRoute + "GetAnimalTypeScientificName",
  GetPlantTypesLkp: apiRoutes.masterDataRoute + "GetPlantTypesLkp",
  GetIncidentSourceLkp: apiRoutes.masterDataRoute + "GetIncidentSourceLkp",
  GetBlightDescriptionLkp: apiRoutes.masterDataRoute + "GetBlightDescriptionLkp",
  GetAllGovEntityLkp: apiRoutes.masterDataRoute + "GetAllGovEntityLkp",

  FileUpload: apiRoutes.attchementRoute + "UploadSingleFile",
  UploadMultipleFiles: apiRoutes.attchementRoute + "UploadMultipleFiles",
  DeleteFile: apiRoutes.attchementRoute + "DeleteFile",
  IncidentUploadFiles: apiRoutes.attchementRoute + "IncidentUploadFiles",
  DeleteIncidentFiles: apiRoutes.attchementRoute + "DeleteIncidentFiles/",
  AnimalSharedDiseaseUploadFiles: apiRoutes.attchementRoute + "AnimalSharedDiseaseUploadFiles/",
  DeleteAnimalSharedDiseaseFiles: apiRoutes.attchementRoute + "DeleteAnimalSharedDiseaseFiles/",
  AnimalBorderGateUploadFiles: apiRoutes.attchementRoute + "AnimalBorderGateUploadFiles/",
  DeleteAnimalBorderGateFiles: apiRoutes.attchementRoute + "DeleteAnimalBorderGateFiles/",

  CreateAnimalHealth: apiRoutes.animalHealth + "create",
  UpdateAnimalHealth: apiRoutes.animalHealth + "update",
  CreateFollowUp: apiRoutes.animalHealth + "CreateFollowUp",
  CreateFollowUpSharedDisease: apiRoutes.animalHealth + "CreateFollowUpSharedDisease",
  getIncidentsTransactionById: apiRoutes.animalHealth + "getIncidentsTransactionById",
  GetAnimalHealthReport: apiRoutes.animalHealth + "GetAnimalHealthReport",
  GetRelatedIncidents: apiRoutes.animalHealth + "GetRelatedIncidents",

  CreateAnimalLegalViolation: apiRoutes.animalLegalViolation + "create",
  UpdateAnimalLegalViolation: apiRoutes.animalLegalViolation + "update",
  CreateFollowUpAnimalLegalViolation: apiRoutes.animalLegalViolation + "CreateFollowUp",
  getAnimalLegalViolationTransactionById: apiRoutes.animalLegalViolation + "getIncidentsTransactionById",
  GetAnimalLegalViolationReport: apiRoutes.animalLegalViolation + "GetAnimalLegalViolationReport",

  CreateAnimalSharedDisease: apiRoutes.animalSharedDisease + "create",
  UpdateAnimalSharedDisease: apiRoutes.animalSharedDisease + "update",
  CreateFollowUpAnimalSharedDisease: apiRoutes.animalSharedDisease + "CreateFollowUp",
  getAnimalSharedDiseaseTransactionById: apiRoutes.animalSharedDisease + "getIncidentsTransactionById",

  CreateAnimalBorderGate: apiRoutes.animalBorderGate + "create",
  UpdateAnimalBorderGate: apiRoutes.animalBorderGate + "update",
  CreateFollowUpAnimalBorderGate: apiRoutes.animalBorderGate + "CreateFollowUp",
  getAnimalBorderGateTransactionById: apiRoutes.animalBorderGate + "getIncidentsTransactionById",
  GetAnimalBorderGateReport: apiRoutes.animalBorderGate + "GetAnimalBorderGateReport",

  CreateAgriculturePest: apiRoutes.agriculturePest + "create",
  UpdateAgriculturePest: apiRoutes.agriculturePest + "update",
  CreateFollowUpAgriculturePest: apiRoutes.agriculturePest + "CreateFollowUp",
  GetAgriculturePestIncidentsTransaction: apiRoutes.incident + "GetIncidentsTransaction",
  getAgriculturePestIncidentsTransactionById: apiRoutes.agriculturePest + "getIncidentsTransactionById",
  GetAgriculturePestReport: apiRoutes.agriculturePest + "GetAgriculturePestReport",

  CreateAgricultureBorderGate: apiRoutes.agricultureBorderGate + "create",
  UpdateAgricultureBorderGate: apiRoutes.agricultureBorderGate + "update",
  CreateFollowUpAgricultureBorderGate: apiRoutes.agricultureBorderGate + "CreateFollowUp",
  GetAgricultureBorderGateIncidentsTransaction: apiRoutes.incident + "GetIncidentsTransaction",
  getAgricultureBorderGateIncidentsTransactionById: apiRoutes.agricultureBorderGate + "getIncidentsTransactionById",
  GetAgricultureBorderGateReport: apiRoutes.agricultureBorderGate + "GetAgricultureBorderGateReport",

  CreateFoodSafety: apiRoutes.foodSafety + "create",
  UpdateFoodSafety: apiRoutes.foodSafety + "update",
  getFoodSafetyIncidentsTransactionById: apiRoutes.foodSafety + "getIncidentsTransactionById",
  GetFoodSafetyReport: apiRoutes.foodSafety + "GetFoodSafetyReport",

  CreateFoodSafetyFollowUp: apiRoutes.FoodSafetyFollowUp + "create",
  UpdateFoodSafetyFollowUp: apiRoutes.FoodSafetyFollowUp + "update",
  getFoodSafetyFollowUpIncidentsTransactionById: apiRoutes.FoodSafetyFollowUp + "getIncidentsTransactionById",

  CreateFoodSafetyBorderGate: apiRoutes.foodSafetyBorderGate + "create",
  UpdateFoodSafetyBorderGate: apiRoutes.foodSafetyBorderGate + "update",
  CreateFollowUpFoodSafetyBorderGate: apiRoutes.foodSafetyBorderGate + "CreateFollowUp",
  getFoodSafetyBorderGateIncidentsTransactionById: apiRoutes.foodSafetyBorderGate + "getIncidentsTransactionById",
  GetFoodSafetyBorderGateReport: apiRoutes.foodSafetyBorderGate + "GetFoodSafetyBorderGateReport",

  CreateEnvironmentalIncident: apiRoutes.EnvironmentalIncident + "create",
  UpdateEnvironmentalIncident: apiRoutes.EnvironmentalIncident + "update",
  getEnvironmentalIncidentTransactionById: apiRoutes.EnvironmentalIncident + "GetIncidentsTransactionById",
  GetEnvironmentalIncidentReport: apiRoutes.EnvironmentalIncident + "GetEnvironmentalIncidentReport",

  WorkflowStateLkp: apiRoutes.workflow + "WorkflowStateLkp",
  GetWorkFlowState: apiRoutes.workflow + "GetWorkFlowState",
  GetWorkFlowActionLkp: apiRoutes.workflow + "GetWorkFlowActionLkp",
  getWorkFlowNextAction: apiRoutes.workflow + "getWorkFlowNextAction",
  getWorkFlowActions: apiRoutes.workflow + "getWorkFlowActions",
  GetWorkFlowLkp: apiRoutes.workflow + "GetWorkFlowLkp",
  GetWorkFlowActionsList: apiRoutes.workflow + "GetWorkFlowActionsList",
  AddWorkFlowActions: apiRoutes.workflow + "AddWorkFlowActions",
  DeleteWorkFlowActions: apiRoutes.workflow + "DeleteWorkFlowActions",

  GetIncidentsTransaction: apiRoutes.incident + "GetIncidentsTransaction",
  GetIncidentsTransactionReport: apiRoutes.incident + "GetIncidentsTransactionReport",
  GetIncidentsTransactionById: apiRoutes.incident + "GetIncidentsTransactionById",
  GetFollowUpIncident: apiRoutes.incident + "GetFollowUpIncident",
  GetCirculationHistory: apiRoutes.incident + "GetCirculationHistory",
  UpdateIncidentsRiskDegree: apiRoutes.incident + "UpdateIncidentsRiskDegree",
  UpdateLocationCoordinate: apiRoutes.incident + "UpdateLocationCoordinate",
  ValidateFinalStepFromOtherEntities: apiRoutes.incident + "ValidateFinalStepFromOtherEntities",
  ValidateIncidentToOpen: apiRoutes.incident + "ValidateIncidentToOpen",

  getUnReadNotification: apiRoutes.notification + "getUnReadNotification",
  GetAllNotification: apiRoutes.notification + "GetAllNotification",
  MakeUnReadToReadNotification: apiRoutes.notification + "MakeUnReadToReadNotification",

  CreateUpdate_ContactUs: apiRoutes.ContactUs + "CreateUpdate",
  GetSystemConfiguration: apiRoutes.SystemConfiguration + "Get",
  CreateSystemConfiguration: apiRoutes.SystemConfiguration + "Create",
};
