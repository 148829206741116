import jwtDecode from "jwt-decode";
import cookies from "js-cookie";
import { signOut } from "../State/Actions/Auth";

class UserPermission {
  constructor(
    Name,
    UserAccountType,
    UnitType,
    UnitTypeName,
    EmirateId,
    Department,
    GovEntity,
    UserId,
    AccessToken,
    Email,
    OrganizationId,
    isLoggedIn,
    NonGovTypeCode,
    GovEntityId
  ) {
    this.Name = Name;
    this.UserAccountType = UserAccountType;
    this.UnitType = UnitType;
    this.UnitTypeName = UnitTypeName;
    this.EmirateId = EmirateId;
    this.Department = Department;
    this.GovEntity = GovEntity;
    this.UserId = UserId;
    this.AccessToken = AccessToken;
    this.Email = Email;
    this.OrganizationId = OrganizationId;
    this.isLoggedIn = isLoggedIn || false;
    this.NonGovTypeCode = NonGovTypeCode;
    this.GovEntityId = GovEntityId;
  }
}
export const checkLogin = () => {
  try {
    const token = cookies.get("AccessToken");
    let decodedToken = jwtDecode(token);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      removeUser();
      return false;
    } else {
      // console.log("Valid token");
      return true;
    }
  } catch (e) {
    console.log("token error:" + e);
    removeUser();
    return false;
  }
};
export const getUserPermission = () => {
  try {
    checkAppVersion();

    const decodedToken = jwtDecode(cookies.get("AccessToken"));

    return new UserPermission(
      decodedToken.Name,
      decodedToken.UserAccoutType,
      decodedToken.UnitType,
      decodedToken.UnitTypeName,
      decodedToken.EmirateId,
      decodedToken.Department,
      decodedToken.GovEntity,
      decodedToken.UserId,
      decodedToken.AccessToken,
      decodedToken.Email,
      decodedToken.OrganizationId,
      true,
      decodedToken.NonGovTypeCode,
      decodedToken.GovEntityId
    );
  } catch (e) {
    console.log("getUserPermission", e);
    var u = new UserPermission();
    u.isLoggedIn = false;
    removeUser();
    return u;
  }
};

export const setUserPermission = (token, refreshToken) => {
  SaveToCookies("AccessToken", token);
  SaveToCookies("RefreshToken", refreshToken);
  //   SaveToCookies("Email", decodedToken.Email);
  //   SaveToCookies("Name", decodedToken.Name);
  //   SaveToCookies("GovEntity", decodedToken.GovEntity);
  //   SaveToCookies("UnitType", decodedToken.UnitType);
  //   SaveToCookies("UserAccountType", decodedToken.UserAccountType);
  //   SaveToCookies("UserId", decodedToken.UserId);
  //   SaveToCookies("Department",decodedToken.Department);
};

export const removeUser = () => {
  RemoveCookies("AccessToken");
  RemoveCookies("RefreshToken");
  //sessionStorage.removeItem("uaepass_data");
};
const SaveToCookies = (name, value) => {
  cookies.set(name, value);
};
export const GetCookies = (name) => {
  cookies.get(name);
};

const RemoveCookies = (name) => {
  cookies.remove(name);
};
export const logout = async () => {
  const user = getUserPermission();
  const res = await signOut(user.Email);
  if (res.data) {
    removeUser();
    //window.location.reload(true);
  }
};

export const checkAppVersion = () => {
  const App_version = 6.9;
  const localStoreAppVersion = localStorage.appversion;
  if (localStoreAppVersion !== App_version.toString()) {
    removeUser();
    localStorage.appversion = App_version;
    console.warn("different app version, need to re-login");
  }
  return App_version;
};
