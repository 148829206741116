import axios from "axios"; 
import { URLs } from "../../Constants/Common";
import { UserClass, UserFoodFacility, UserPlantAnimal, UserRegistrationDTO, UserVetClinic } from "../../Interface/Registration";
import { DispatchTypes } from "../DispatchType";
import { DepartmentUnitTypeDTO, OrganizationUnitsLkpDTO, UserProfileDTO, UsersDTO } from "../../Interface/IIncidentTrans";

export const getUserProfile = async (email) => {
  const res = await axios.get(URLs.GetUserProfile + `?email=${email}`);

  if (res.data.IsSuccess) {
    return res.data.ResponseData;
  }
};
export const getAllUsers = async (deptId = 0) => {
  const res = await axios.get(URLs.GetAllUsers + `?deptId=${deptId}`);
  if (res.data.IsSuccess) {
    return res.data.ResponseData;
  }
};
export const getUserById = async (userId = 0) => {
  const res = await axios.get(URLs.GetUserById + `?userId=${userId}`);
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const changePassword = async (data) => {
  const res = await axios.post(URLs.ChangePassword, data);
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const getCodeByEmail = async (email) => {
  const res = await axios.get(URLs.GetCodeByEmail + `?email=${email}`);
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const verifySecurityCode = async (email, code) => {
  const res = await axios.get(URLs.VerifySecurityCode + `?email=${email}&code=${code}`);
  return res?.data?.ResponseData;
};
export const verifyRecaptcha = async (token) => {
  const res = await axios.post(URLs.VerifyRecaptcha + `?token=${token} `);
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const changePasswordBySecurityCode = async (data) => {
  const res = await axios.post(URLs.ChangePasswordBySecurityCode, data);
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const enableDisabledUser = async (userId) => {
  const res = await axios.get(URLs.EnableDisabledUser + `?userId=${userId} `);
  return res.data?.IsSuccess;
};
export const addUserByAdmin = async (data) => {
  console.log(data);
  var d = new UsersDTO(
    data.email,
    "",
    new UserProfileDTO(
      data.nameAr === "" ? data.nameEn : data.nameAr,
      data.nameEn,
      data.email,
      data.reporterEID,
      data.passportNo,
      data.mobileNo,
      data.fax,
      data.prefLanguage,
      0,
      new OrganizationUnitsLkpDTO(data.GovtEntityId, new DepartmentUnitTypeDTO(data.DepartmentId, data.UnitTypeCode)),
      data.lastNameEn,
      data.lastNameAr
    ),
    data.Id
  );
  console.log(d);
  let res = null;
  if (data.Id > 0) {
    res = await axios.post(URLs.UpdateUserByAdmin, d);
  } else {
    res = await axios.post(URLs.AddUserByAdmin, d);
  }
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const deleteUser = async (userId) => {
  const res = await axios.delete(URLs.DeleteUser + `?userId=${userId}`);
  if (res.data?.IsSuccess) {
    return res.data?.ResponseData;
  }
};
export const registerUserResponse = (res) => {
  return async (dispatch) => {
    dispatch({
      type: DispatchTypes.REGISTER,
      registerUser: res,
    });
  };
};
export const registerUser = async (formData) => {
  console.log(formData);

  try {
    const data = new UserClass(
      formData.email,
      formData.PasswordUI,
      new UserRegistrationDTO(
        formData.nameAr,
        formData.nameEn,
        formData.lastNameEn,
        formData.lastNameAr,
        formData.NonGovTypeCode,
        formData.reporterEID,
        formData.passportNo,
        formData.mobileNo,
        formData.email,
        formData.fax,
        formData.prefLanguage,
        new UserPlantAnimal(formData.ownershipNumber, formData.ownershipType, formData.farmOwnerName, formData.farmOwnerEmail),
        new UserVetClinic(formData.healthFacilityName, formData.healthFacilityAddress),
        new UserFoodFacility(formData.facilityName, formData.facilityPhoneNo, formData.facilityEmail),
        null,
        formData.imageId
      ),
      formData.PasswordUI
    );
    console.log(data);
    return await axios.post(URLs.AddUserProfile, data);
    // await axios
    //   .post(URLs.AddUserProfile, data)
    //   .then((res) => {
    //     //registerUserResponse(res.data);
    //     // dispatch({
    //     //   type: DispatchTypes.REGISTER,
    //     //   registerUser: res.data,
    //     // });

    //   })
    //   .catch(function (error) {
    //     console.log(error.response);
    //     registerUserResponse(error.response.data);
    //     // dispatch({
    //     //   type: DispatchTypes.REGISTER,
    //     //   registerUser: error.response.data,
    //     // });
    //   });
  } catch (e) {
    console.log(e);
  }
};

export const isEmirateIDExist = (EmirateId) => {
  return async (dispatch, getState) => {
    try {
      axios.get(URLs.IsEmirateIDExist + "?emirateId=" + EmirateId).then((res) => {
        if (res.data.IsSuccess) {
          const resData = res.data.ResponseData;
          console.log(resData);
          dispatch({
            type: DispatchTypes.IS_EMIRATEID_EXSIT,
            isEmirateIDExist: resData,
          });
        }
      });
    } catch (err) {
      throw err;
    }
  };
};
export const isEmailExist = (email) => {
  return async (dispatch, getState) => {
    try {
      axios.get(URLs.IsEmailExist + "?email=" + email).then((res) => {
        if (res.data.IsSuccess) {
          const resData = res.data.ResponseData;
          dispatch({
            type: DispatchTypes.IS_EMAIL_EXSIT,
            isEmailExist: resData,
          });
        }
      });
    } catch (err) {
      throw err;
    }
  };
};
export const isPassportExist = (passportNo) => {
  return async (dispatch, getState) => {
    try {
      axios.get(URLs.IsPassportExist + "?passportNo=" + passportNo).then((res) => {
        if (res.data.IsSuccess) {
          const resData = res.data.ResponseData;

          dispatch({
            type: DispatchTypes.IS_PASSPORT_EXSIT,
            isPassportExist: resData,
          });
        }
      });
    } catch (err) {
      throw err;
    }
  };
};
