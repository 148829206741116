import { Button, Modal } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const ModalView = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      dialogClassName={props.modalStyle}
      show={props.isOpen}
      onHide={props.handleShowModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t(`${props.title}`)} </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer className="dialog__content">
        <Button
          variant=""
          className="action btn pl-4 pr-4"
          onClick={props.handleShowModal}
        >
          {t("cancel")}
        </Button>

        {props.footer}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalView;
