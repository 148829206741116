import { DispatchTypes } from "../DispatchType";

const InitialState = {
  GetAgriculturaltenureData: {},
};

const EservicesDataReducer = (state = InitialState, action) => {
  switch (action.type) {
    case DispatchTypes.GETAGRICULTURALTENURE:
      return {
        ...state,
        GetAgriculturaltenureData: action.GetAgriculturaltenureData,
      };
    default:
      return state;
  }
};

export default EservicesDataReducer;
