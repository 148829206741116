import React from "react";
import PopupOver from "../../Common/UI/PopupOver";
import { Link } from "react-router-dom";
import * as token from "../../../Constants/AuthToken";
import { URLs } from "../../../Constants/Common";

const UserSettingUI = (props) => {
  const logoutSession = async () => {
    props.setLoading(true);
    await token.logout();
    //navigate("/");
    props.setLoading(false);
    window.open(URLs.uaePass.Logout, "_self");
    //window.location.href = URLs.uaePass.Logout;
    // window.location = "/";
  };
  return (
    <div>
      {" "}
      <div id="userSettingContainer" className="userSetting mr-4 mx-3 ">
        <PopupOver
          button={
            <Link to="#" className="">
              <i className="ico icon-Group-100"></i>
            </Link>
          }
          body={
            <div className="d-flex align-items-center m-2">
              <ul className="personalData">
                <li>
                  <Link to="/profile" rel="noreferrer">
                    <i className="fa fa-user m-2  "></i>
                    <span>{props.t("Profile")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/changepassword" rel="noreferrer">
                    <i className="fa fa-unlock-alt m-2"></i>
                    <span>{props.t("ChangePassword")}</span>
                  </Link>
                </li> */}
                <li className="divider_">
                  {/* <Link to="#" rel="noreferrer" onClick={logoutSession}>
                    <i className="ico icon-Group-246 m-2"></i>
                    <span>{props.t("SignOut")}</span>
                  </Link> */}
                  <button className="btn btn-danger mt-4" onClick={logoutSession} style={{ width: "150px" }}>
                    {props.t("SignOut")}
                  </button>
                </li>
              </ul>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default UserSettingUI;
