import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PopupOver from "../../Common/UI/PopupOver";
import { encrypt, notifyAction, util } from "../../Imports";

const NotificationUI = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const notificationData = useSelector((state) => state.notification.unReadNotificationData);
  const openRoute = (e, url) => {
    e.preventDefault();
    navigate(url);
    document.body.click();
  };
  const getNotification = useCallback(() => {
    dispatch(notifyAction.getUnReadNotification());
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, []);

  const navigatePage = async (id, isRead, url) => {
    if (!isRead) {
      const res = await notifyAction.makeUnReadToReadNotification(id);
      if (res.ResponseData) {
        window.open(url, "_blank", "noreferrer");
      }
    } else {
      window.open(url, "_blank", "noreferrer");
    }
  };
  return (
    <div>
      <div id="notificationContainer" className="notifications mr-4 mx-3 ">
        <PopupOver
          button={
            <Link to="#" className="">
              <i className="ico icon-Group-184"></i>
              {notificationData.length > 0 && <b className=" ">{notificationData.length > 9 ? "9+" : notificationData.length}</b>}
            </Link>
          }
          body={
            <div id="notifications-container">
              <div className="title d-flex justify-content-between p-2">
                <span>{props.t("Notification")}</span>
                <div>
                  <Link to="#" onClick={(e) => openRoute(e, `/notification`)}>
                    {props.t("ViewAll")}
                  </Link>
                  <i className="fa fa-angle-right pl-2 mx-1"></i>
                </div>
              </div>
              <div className="notifications-wrapper overflow-y">
                {notificationData.length > 0 &&
                  notificationData.map((e, i) => {
                    return (
                      <a
                        key={i}
                        rel="noreferrer"
                        onClick={() => {
                          navigatePage(e.Id, e.IsRead, `${e.IncidentURL}${encrypt.encrypt(e.IncidentId)}/${encrypt.encrypt(0)}`);
                        }}
                        className="notification-item p-3"
                      >
                        <div className="d-flex align-items-center">
                          {e.IsRead === true ? <i className="fa fa-envelope-open-o"></i> : <i className="fa fa-envelope-o"></i>}
                          <span className="mx-3">{util.getProperText(e.TextAr, e.TextEn)}</span>
                        </div>
                        <div className="small text-sm-end">{util.getProperDate(e.CreatedDate)}</div>
                      </a>
                    );
                  })}
                {notificationData.length === 0 && (
                  <div className="text-center mt-5 search-message" aria-hidden="false">
                    <i className="fa fa-smile-o fa-3x"></i>
                    <p className="mt-4"> {props.t("NoNewNotification")}</p>
                  </div>
                )}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default NotificationUI;
