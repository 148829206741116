import React from "react";
import { Link } from "react-router-dom";

const BoxUI = ({ link, title, count }) => {
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
        <Link
          className="issue-item d-flex flex-fill align-items-center justify-content-around p-3"
          to={`${link}`}
        >
          <span>{title}</span>
          <b className="">{count}</b>
        </Link>
      </div>
    </>
  );
};

export default BoxUI;
