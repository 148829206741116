import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const ExportToExcel = ({ fileName, customHeader, ...props }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  var dt = new Date();
  const postfix = dt.getDate() + "." + (dt.getMonth() + 1) + "." + dt.getFullYear();

  const exportToCSV = async (fileName) => {
    const apiData = await props.getExcelReport();
    // console.log(apiData);
    const ws = XLSX.utils.json_to_sheet(apiData);

    XLSX.utils.sheet_add_aoa(ws, [customHeader], {
      origin: "A1",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + "_" + postfix + fileExtension);
  };

  return (
    <button to="#" rel="noreferrer" className="download-file p-2" onClick={(e) => exportToCSV(fileName)}>
      {props.t("DownloadReport")}
      <i className="ico icon-Group-255 m-1"></i>
    </button>
  );
};
