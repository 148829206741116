import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";
import { auth, enums, master, util } from "../../Imports";
import { useDispatch, useSelector } from "react-redux";

export const paginationDto = {
  paginationDto: { pageNo: 1, pageSize: 10 },
};
export const pageSettingDto = {
  totalPages: paginationDto.paginationDto?.pageSize,
  pageSize: paginationDto.paginationDto?.pageSize,
  currentPage: paginationDto.paginationDto?.pageNo,
  showMax: 5,
  TotalItems: 0,
};
export const DateControl = ({ label, localVal, changeControlDate }) => {
  return (
    <FormInput
      name={`${label}Date`}
      label={label}
      type="date"
      selectedDate={util.SelectedDate(localVal)}
      onChange={(date) => changeControlDate(`${label}Date`, util.SetDate(date))}
      maxDate={new Date()}
      required={false}
    />
  );
};
export const TextBoxControl = ({ label, name, localVal, changeControl }) => {
  return <FormInput name={name} type="text" label={label} required={false} value={localVal} onChange={changeControl} />;
};
export const AnimalTypeLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getAnimalType(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="animalType" label="animalType" required={false} value={localVal} onChange={changeControl} data={data} />;
};
export const IncidentTypeLkpControl = ({ localVal, changeControl, isRequired }) => {
  isRequired = isRequired ?? false;
  const [data, setData] = useState([]);
  const user = auth.getUserPermission();
  const loadComLkp = useCallback(async () => {
    const deptId = user.Department === "4" ? 0 : user.Department;
    setData(await master.getIncidentTypeLkp(deptId, ""));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="IncidentTypeCode" label="IncidentType" required={isRequired} value={localVal} onChange={changeControl} data={data} />;
};
export const OrgUnitTypeLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const user = auth.getUserPermission();
  const loadComLkp = useCallback(async () => {
    setData(await master.getOrgUnitTypeLkp(user.Department));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="UnitTypeCode" label="IncidentSource" required={false} value={localVal} onChange={changeControl} data={data} />;
};
export const HoldingOwnerShipTypeLkpControl = ({ localVal, changeControl, isRequired, name }) => {
  isRequired = isRequired ?? false;
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getHoldingOwnershipType());
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name={name} label="propertyType" required={isRequired} value={localVal} onChange={changeControl} data={data} />;
};
export const LegalViolationTypeLkpControl = ({ localVal, changeControl, isRequired, name }) => {
  isRequired = isRequired ?? false;
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getALVIncidentTypeLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name={name} label="ViolationType" required={isRequired} value={localVal} onChange={changeControl} data={data} />;
};
export const AnimalDiseaseLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getAnimalDiseaseLkp());
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="AnimalDiseaseId" label="diseaseName" required={false} value={localVal} onChange={changeControl} data={data} />;
};
export const BlightDescriptionLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getBlightDescriptionLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="BlightDescriptionId" label="BlightDescription" required={false} value={localVal} onChange={changeControl} data={data} />;
};
export const PlantTypeLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getPlantTypesLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="PlantTypeId" label="PlantType" required={false} value={localVal} onChange={changeControl} data={data} />;
};
export const RiskDegreeLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getRiskDegreeLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="RiskDegree" label="RiskDegree" required={false} value={localVal} onChange={changeControl} data={data} />;
};

export const NotificationTypeLkpControl = ({ localVal, changeControl }) => {
  const [data, setData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setData(await master.getNotificationTypeLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name="NotificationTypeId" label="NotificationType" required={false} value={localVal} onChange={changeControl} data={data} />;
};

export const GovtEntityLkpControl = ({ localVal, label, name, changeControl, emirateId, departmentEnum, isRequired }) => {
  isRequired = isRequired ?? false;

  const [govEntityData, setGovEntityData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setGovEntityData(await master.getGovtEntity(emirateId, enums.UnitTypeEnum.GovernmentEntity, departmentEnum));
  }, [emirateId]);
  useEffect(() => {
    if (emirateId !== "") loadComLkp();
  }, [loadComLkp]);
  return <SelectInput name={name} label={label} required={isRequired} disabled={false} value={localVal} onChange={changeControl} data={govEntityData} />;
};
export const BorderPortLkpControl = ({ localVal, changeControl, emirateId, departmentEnum }) => {
  const [govEntityData, setGovEntityData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setGovEntityData(await master.getGovtEntity(emirateId, enums.UnitTypeEnum.BorderGate, departmentEnum));
  }, [emirateId]);
  useEffect(() => {
    if (emirateId > 0) loadComLkp();
  }, [loadComLkp]);
  return (
    <SelectInput name="BorderPortId" label="BorderPort" required={false} disabled={false} value={localVal} onChange={changeControl} data={govEntityData} />
  );
};

export const EmirateLkpControl = ({ localVal, changeControl, isRequired }) => {
  isRequired = isRequired ?? false;
  const dispatch = useDispatch();
  const EmirateData = useSelector((state) => state.masterData.emirateData);
  const getDropdownData = useCallback(async () => {
    dispatch(master.getEmirates());
  }, []);

  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);
  return <SelectInput name="EmirateId" label="emirate" required={isRequired} value={localVal} onChange={changeControl} data={EmirateData} />;
};
export const UserAccountTypeLkpControl = ({ localVal, changeControl, data, isRequired }) => {
  isRequired = isRequired ?? false;

  return <SelectInput name="UserAccountType" label="IncidentSource" required={isRequired} value={localVal} onChange={changeControl} data={data} />;
};

export const WFStatusLkpControl = ({ localVal, changeControl }) => {
  const dispatch = useDispatch();
  const WorkFlowStatusData = useSelector((state) => state.masterData.workFlowStatus);
  const getDropdownData = useCallback(async () => {
    const data = master.getWorkFlowStatus();
    dispatch(data);
  }, [dispatch]);

  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);
  return <SelectInput name="StatusCode" label="IncidentStatus" required={false} value={localVal} onChange={changeControl} data={WorkFlowStatusData} />;
};
