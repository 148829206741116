import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  util,
  master,
  iAnimalHealth,
  validation,
  enums,
} from "../../../Imports";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import QuestionWithYesNo from "./QuestionWithYesNo";
import { AddButton, DeleteButton } from "../../Common/IncidentCommonImport";

const ImmunizedAnimals = (props) => {
  const initialState = {
    AnimalTypeId: "",
    AnimalCatagoryId: "",
    AnimalCatagoryName: "",
    AnimalTypeScientificName: "",
    ImmunizationDate: "",
    ImmunizationType: "",
    NoOfImmunizedAnimals: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const answerVaccine = [
    enums.VaccinationEnum.Fully,
    enums.VaccinationEnum.Ring,
  ];
  const [animalTypeData, setAnimalTypeData] = useState([]);
  const animalCatagryData = useSelector(
    (state) => state.masterData.animalCatagoryData
  );
  const transaction = useSelector((state) => state.transaction);
  const changeControl = (e) => {
    console.log(e);
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeAnimalCatagory = async (e) => {
    if (e.target.value) {
      var index = e.nativeEvent.target.selectedIndex;
      setLocalVal((p) => ({
        ...p,
        AnimalCatagoryId: e.target.value,
        AnimalCatagoryName: e.nativeEvent.target[index].text,
        AnimalTypeScientificName: "",
      }));
      const res = await master.getAnimalType(e.target.value);
      setAnimalTypeData(res);
    }
  };
  const changeAnimalType = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      AnimalTypeId: e.target.value,
      AnimalTypeName: e.nativeEvent.target[index].text,
      AnimalTypeScientificName:
        e.nativeEvent.target[index].getAttribute("data-code"),
    }));
  };
  const changeImmunizedDate = (date) => {
    if (date) {
      setLocalVal((p) => ({
        ...p,
        ImmunizationDate: util.SetDate(date),
      }));
    }
  };
  const loadFormInUpdate = async () => {
    let tempInf = [];
    transaction[iAnimalHealth.AH_Constants.AHI].ImmunizedAnimalDetails.forEach(
      (e, i) => {
        tempInf = tempInf.concat(
          new iAnimalHealth.AnimalImmunizedDetailsDTO(
            e.AnimalTypeId,
            e.NoOfImmunizedAnimals,
            e.ImmunizationType,
            e.ImmunizationDate,
            props.currentLanguage === "ar"
              ? e.AnimalTypeLkp.NameAr
              : e.AnimalTypeLkp.NameEn,
            new Date().getTime() + i
          )
        );
      }
    );
    props.handleChangeDTO(tempInf, iAnimalHealth.AH_Constants.AID);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "immunizedAnimalsForms";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.AID].concat(
        new iAnimalHealth.AnimalImmunizedDetailsDTO(
          localVal.AnimalTypeId,
          localVal.NoOfImmunizedAnimals,
          localVal.ImmunizationType,
          localVal.ImmunizationDate,
          localVal.AnimalTypeName,
          new Date().getTime()
        )
      ),
      iAnimalHealth.AH_Constants.AID
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.AID].filter(
        (x) => x.Uid !== e.Uid
      ),
      iAnimalHealth.AH_Constants.AID
    );
  };
  return (
    <>
      <label>
        <strong>{props.t("immunizedAnimalsDetails")}</strong>
      </label>

      <div className="col-md-12 my-3 row container-bg immunizedAnimalsForms">
        <div className="col-md-3">
          <SelectInput
            name="AnimalCatagoryId"
            label="animalCatagory"
            required={true}
            value={localVal.AnimalCatagoryId}
            onChange={changeAnimalCatagory}
            data={animalCatagryData}
          />
        </div>
        <div className="col-md-3">
          <SelectInput
            name="AnimalTypeId"
            label="animalType"
            required={true}
            value={localVal.AnimalTypeId}
            onChange={changeAnimalType}
            data={animalTypeData}
          />
          {localVal.AnimalTypeScientificName && (
            <div>
              <small>
                <strong>{props.t("scientificName")}: </strong>
                {localVal.AnimalTypeScientificName}
              </small>
            </div>
          )}
        </div>
        {props.vaccineValid && (
          <>
            <div className="col-md-3">
              <FormInput
                label="immunizationDate"
                ariaInvalid="false"
                type="date"
                selectedDate={util.SelectedDate(localVal.ImmunizationDate)}
                required={true}
                onChange={(date) => changeImmunizedDate(date)}
                maxDate={new Date()}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                name="NoOfImmunizedAnimals"
                type="number"
                label="NoOfImmunizedAnimals"
                value={localVal.NoOfImmunizedAnimals}
                onChange={changeControl}
                min="1"
                required={true}
              />
            </div>

            <div className="col-md-12 row mt-2">
              <QuestionWithYesNo
                list={answerVaccine}
                {...props}
                label="immunizationType"
                controlName="ImmunizationType"
                value={localVal.ImmunizationType}
                onchangeRadio={changeControl}
              />
            </div>
          </>
        )}
        <div className="col-md-3 mt-4">
          <AddButton {...props} submitHandler={submitHandler} />
        </div>

        <div className="col-md-12 row my-2">
          {transaction[iAnimalHealth.AH_Constants.AID].map((e, i) => (
            <div className="card" key={i}>
              <div className="card-body row">
                <div className="col-md-3">
                  <small className="golden">{props.t("animalType")}</small> :
                  <small> {e.AnimalTypeName}</small>
                </div>

                {util.stringEmpty(e.ImmunizationDate) && (
                  <div className="col-md-3">
                    <small className="golden">
                      {props.t("immunizationDate")}
                    </small>
                    :<small>{e.ImmunizationDate}</small>
                  </div>
                )}
                {util.stringEmpty(e.NoOfImmunizedAnimals) && (
                  <div className="col-md-3">
                    <small className="golden">
                      {props.t("NoOfImmunizedAnimals")}
                    </small>
                    :<small> {e.NoOfImmunizedAnimals}</small>
                  </div>
                )}
                {util.stringEmpty(e.ImmunizationType) && (
                  <div className="col-md-3">
                    <small className="golden">
                      {props.t("immunizationType")}
                    </small>
                    :<small> {props.t(e.ImmunizationType)}</small>
                  </div>
                )}
              </div>
              <DeleteButton
                {...props}
                deleteHandler={() => {
                  deleteItem(e);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImmunizedAnimals;
