import React from "react";
import pic_ar from "../../../Theme/img/uaepass_logo_ar.png";
import pic_en from "../../../Theme/img/uaepass_logo_en.png";
import pic_mobile from "../../../Theme/img/UAEPASS_Logo_Only.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { URLRoutes } from "../../Routes/Routes";
import { encrypt, urls } from "../../Imports";

const UaePassLogo = (props) => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const navigate = useNavigate();
  const img_web = props.currentLanguage === "ar" ? pic_ar : pic_en;

  const openForm = () => {
    navigate(URLRoutes.getEnvironmentalIncident(encrypt.encrypt(0), encrypt.encrypt(0)));
  };
  const handleUAEPass = () => {
    const resident_url = `${urls.URLs.uaePass.Authorization}?response_type=code&client_id=${urls.URLs.uaePass.ClientId}&scope=urn:uae:digitalid:profile:general&state=HnlHOJTkTb66Y5H&redirect_uri=${urls.URLs.uaePass.RedirectURL}&ui_locales=${props.currentLanguage}&acr_values=urn:safelayer:tws:policies:authentication:level:low`;

    const visitor_url = `${urls.URLs.uaePass.Authorization}?redirect_uri=${urls.URLs.uaePass.RedirectURL}&client_id=${urls.URLs.uaePass.ClientId}&response_type=code&state=pd3PgezRwk596u2yfRwqOgru&scope=urn:uae:digitalid:profile:general urn:uae:digitalid:profile:general:profileType urn:uae:digitalid:profile:general:unifiedId&acr_values=urn:safelayer:tws:policies:authentication:level:low`;
    console.log(resident_url);
    console.log(process.env.REACT_APP_ENV);
    if (process.env.REACT_APP_ENV === "dev") {
      window.open(resident_url, "_blank");
    } else {
      window.open(resident_url, "_self");
    }
    //window.open(resident_url, "_self");
  };
  return (
    <>
      {props.isWeb && (
        <a onClick={handleUAEPass}>
          <img className="mx-2 uae-pass-logo" src={img_web} alt="" />
          {error && <div className="uaepass-login-error">{props.t("CancelUAEPassLogin")}</div>}
        </a>
      )}
      {!props.isWeb && (
        <a onClick={handleUAEPass}>
          <img className=" uae-pass-logo-mobile" src={img_web} alt="" />
          {error && <div className="uaepass-login-error">{props.t("CancelUAEPassLogin")}</div>}
        </a>
      )}
    </>
  );
};

export default UaePassLogo;
