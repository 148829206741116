import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { master, enums } from "../../../../Imports";
import * as Report from "../../ReportControl";

const ReportFilter = (props) => {
  const dispatch = useDispatch();
  const [localVal, setLocalVal] = useState(props.data);

  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeControlDate = (name, value) => {
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
  };
  const getDropdownData = useCallback(async () => {
    dispatch(master.getUserAccountType());
  }, [dispatch, props.type]);

  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);

  const resetForm = (e) => {};

  const searchFormSubmit = (e) => {
    e.preventDefault();
    props.onChangeSearch(localVal);
  };
  const handleReset = () => {
    setLocalVal(props.data);
  };
  return (
    <div>
      <div className="table-filter">
        <form id="search-filter-form" onReset={resetForm} onSubmit={searchFormSubmit}>
          <div className="pt-3 pl-3 pr-3 m-3 container-bg">
            <div className="row flex-fill">
              <div className="col-md-3">
                <Report.IncidentTypeLkpControl changeControl={changeControl} localVal={localVal.IncidentTypeCode} />
              </div>
              <div className="col-md-3">
                <Report.DateControl label="from" localVal={localVal.fromDate} changeControlDate={changeControlDate} />
              </div>
              <div className="col-md-3">
                <Report.DateControl label="to" localVal={localVal.toDate} changeControlDate={changeControlDate} />
              </div>

              <div className="col-md-3">
                <Report.OrgUnitTypeLkpControl localVal={localVal.UnitTypeCode} changeControl={changeControl} />
              </div>
              {props.type === "BorderGate" && (
                <div className="col-md-3">
                  <Report.BorderPortLkpControl
                    localVal={localVal.BorderPortId}
                    emirateId={localVal.EmirateId}
                    departmentEnum={enums.DepartmentEnum.AgriculturalDevelopmentAndHealthDepartment}
                    changeControl={changeControl}
                  />
                </div>
              )}

              <div className="col-md-3">
                <Report.WFStatusLkpControl localVal={localVal.StatusCode} changeControl={changeControl} />
              </div>

              {props.type !== "BorderGate" && <></>}
              <div className="col-md-3">
                <Report.RiskDegreeLkpControl localVal={localVal.RiskDegreeId} changeControl={changeControl} />
              </div>

              <div className="col-md-3">
                <Report.TextBoxControl label="reporterName" name="ReporterName" changeControl={changeControl} localVal={localVal.ReporterName} />
              </div>
              <div className="col-md-3">
                <Report.TextBoxControl label="mobileNo" name="ReporterMobileNo" changeControl={changeControl} localVal={localVal.ReporterMobileNo} />
              </div>
              <div className="col-md-3">
                <Report.TextBoxControl label="IncidentNumber" name="RefNo" changeControl={changeControl} localVal={localVal.RefNo} />
              </div>

              <div className="col-md-3 my-4" id="searchForm" style={{ marginTop: "15px" }}>
                <button type="submit" id="btnSearch" className="btn btn-success mx-2 pt-2 pb-2">
                  {props.t("search")}
                </button>
                <button type="reset" onClick={handleReset} className="btn bt-reset mx-2  pt-2 pb-2">
                  {props.t("clear")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportFilter;
