import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth, authAction, util } from "../../Imports";
import { setUserPermission } from "../../../Constants/AuthToken";
import { UserClass } from "../../../Interface/Registration";
import { UserProfileDTO } from "../../../Interface/IIncidentTrans";

const LoginFromMobileApp = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fullnameAR = searchParams.get("fullnameAR");
  const fullnameEN = searchParams.get("fullnameEN");
  const UserType = searchParams.get("userType");
  const Mobile = searchParams.get("mobile");
  const idn = searchParams.get("idn");
  const passportNumber = searchParams.get("passportNumber");
  const Email = searchParams.get("Email");
  const paramUUID = searchParams.get("uuid");
  // const firstnameEN = searchParams.get("firstnameEN");
  // const firstnameAR = searchParams.get("firstnameAR");
  // const lastnameEN = searchParams.get("lastnameEN");
  // const lastnameAR = searchParams.get("lastnameAR");

  const getTokenAndLoginFromMobileApp = async (uuid) => {
    props.setLoading(true);
    const checkAuth = await authAction.loginByUAEPass(uuid);
    if (checkAuth.data.ResponseData.AccessToken !== null) {
      setUserPermission(checkAuth.data.ResponseData.AccessToken, checkAuth.data.ResponseData.RefreshToken);
    } else {
      const userObj = new UserClass(
        Email,
        "test",
        new UserProfileDTO(
          fullnameAR?.replaceAll(",", " ").trim() ?? fullnameEN?.replaceAll(",", " ").trim(),
          fullnameEN?.replaceAll(",", " ").trim(),
          Email,
          util.formatEID(idn), //idn
          passportNumber ?? "NA", //passport
          Mobile,
          null,
          "ar",
          null,
          null,
          "mobileapp",
          ""
        ),
        null,
        paramUUID,
        Email,
        UserType
      );
      const register = await authAction.registerUserAndLogin_UAEPass(userObj);
      auth.setUserPermission(register.data.ResponseData.AccessToken, register.data.ResponseData.RefreshToken);
    }
    setTimeout(() => {
      navigate("/environmental-incident/MA==/MA==");
      window.location.reload();
    }, 700);

    props.setLoading(false);
  };

  useEffect(() => {
    if (paramUUID  ) {
      getTokenAndLoginFromMobileApp(paramUUID);
    } else {
      navigate("/");
    }
    // navigate()
  }, [paramUUID]);

  return <div> </div>;
};

export default LoginFromMobileApp;
