import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import PaginationUI from "../../../../Common/UI/PaginationUI";
import { animalHealthAction, util } from "../../../../Imports";
import {
  ColumnCreateDate,
  ColumnEmiratesName,
  ColumnGPSLocation,
} from "../../Grids/SearchGrid";
import { AnimalHealthMapper } from "./DataMapper";
import { pageSettingDto } from "../../ReportControl";

const AnimalHealthGrid = (props) => {
  let pageSetting = { ...pageSettingDto };
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState(pageSetting);

  const onPageChange = async (page) => {
    await getGridData(props.searchData, page);
  };
  const getGridData = async (data, page) => {
    if (!data) return;
    props.setLoading(true);
    data.paginationDto.pageNo = page;
    const resp = await animalHealthAction.getAnimalHealthReport(data);
    const result = resp.data.ResponseData;
    setData(result);
    setPagination((pre) => ({
      ...pre,
      totalPages: Math.ceil(resp.data.TotalItems / pageSetting.totalPages),
      TotalItems: resp.data.TotalItems,
      currentPage: page,
      showMax: page > pageSetting.showMax ? pageSetting.showMax : 2,
    }));
    props.setLoading(false);
  };
  const getSearchData = useCallback(async () => {
    await getGridData(props.searchData, 1);
  }, [getGridData]);

  useEffect(() => {
    getSearchData();
  }, [props.isSearchChanged]);

  const exportToPDF = async () => {
    let data = props?.searchData;
    data.paginationDto.pageSize = 0;
    const resp = await animalHealthAction.getAnimalHealthReport(data);
    const result = resp.data.ResponseData;
    return AnimalHealthMapper(result);
  };
  return (
    <div>
      <div className="table-container col flex-fill">
        <div className="table-wrapper p-2 m-3 container-bg table-responsive">
          {data?.length === 0 && (
            <div
              className="text-center mt-5 search-message"
              id="nothingFoundMessage"
            >
              <i className="ico icon-Group-420"></i>
              <p className="mt-4">
                {props.t("NoSearchResult")}
                <strong className="text-dark  "></strong>
              </p>
            </div>
          )}
          {data?.length > 0 && (
            <>
              <table className="table table-striped table-hover text-center m-0">
                <thead>
                  <tr>
                    <th>{props.t("IncidentNumber")}</th>
                    <th>{props.t("incidentLocation")} </th>
                    <th>{props.t("IncidentDate")}</th>
                    <th>{props.t("BorderPort")}</th>
                    <th>{props.t("emirate")} </th>
                    <th>{props.t("reporterName")}</th>
                    <th>{props.t("mobileNo")}</th>
                    <th>{props.t("email")}</th>
                    <th>{props.t("emiratesID")}</th>
                    <th>{props.t("passportNumber")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((trans, index) => {
                    return (
                      <tr key={index}>
                        <td>{trans.IncidentsTransaction.RefNo}</td>

                        <td>
                          <ColumnGPSLocation
                            trans={trans.IncidentsTransaction}
                          />
                        </td>
                        <td>
                          <ColumnCreateDate
                            incident={trans.IncidentsTransaction}
                          />
                        </td>
                        <td>
                          {util.getTextFromObj(
                            trans.IncidentsTransaction.GovtAgency
                          )}
                        </td>
                        <td>
                          <ColumnEmiratesName
                            incident={trans.IncidentsTransaction}
                            props={props}
                          />
                        </td>
                        <td>
                          {util.getTextFromObj(
                            trans.IncidentsTransaction.Users.UsersProfile
                          )}
                        </td>
                        <td>
                          {
                            trans.IncidentsTransaction.Users.UsersProfile
                              .MobileNo
                          }
                        </td>
                        <td>{trans.IncidentsTransaction.Users.Email}</td>
                        <td>
                          {
                            trans.IncidentsTransaction.Users.UsersProfile
                              .ReporterEID
                          }
                        </td>
                        <td>
                          {
                            trans.IncidentsTransaction.Users.UsersProfile
                              .PassportNo
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}

          {data?.length > 0 && (
            <PaginationUI
              {...props}
              TotalItems={pagination.TotalItems}
              totalPages={pagination.totalPages}
              currentPage={pagination.currentPage}
              showMax={pageSetting.showMax}
              onClick={onPageChange}
              getExcelReport={exportToPDF}
              ExcelFileName={"AnimalHealth"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AnimalHealthGrid;
