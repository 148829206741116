import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { util, master, iAnimalHealth, validation } from "../../../Imports";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { AddButton, DeleteButton } from "../../Common/IncidentCommonImport";

const NewlyAdded = (props) => {
  const initialState = {
    AnimalTypeScientificName: "",
    AnimalCatagoryId: "",
    AnimalCatagoryName: "",
    AnimalTypeName: "",
    AnimalTypeId: "",
    NoOfAnimals: "",
    EmirateId: "",
    EmirateName: "",
    Address: "",
    DateAdded: undefined,
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [animalTypeData, setAnimalTypeData] = useState([]);
  const animalCatagryData = useSelector(
    (state) => state.masterData.animalCatagoryData
  );
  const transaction = useSelector((state) => state.transaction);
  const EmirateData = useSelector((state) => state.masterData.emirateData);
  const changeControl = (e) => {
    console.log(e);
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeAnimalCatagory = async (e) => {
    if (e.target.value) {
      var index = e.nativeEvent.target.selectedIndex;
      setLocalVal((p) => ({
        ...p,
        AnimalCatagoryId: e.target.value,
        AnimalCatagoryName: e.nativeEvent.target[index].text,
        AnimalTypeScientificName: "",
      }));
      setAnimalTypeData(await master.getAnimalType(e.target.value));
    }
  };
  const changeAnimalType = async (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      AnimalTypeId: e.target.value,
      AnimalTypeName: e.nativeEvent.target[index].text,
      AnimalTypeScientificName:
        e.nativeEvent.target[index].getAttribute("data-code"),
    }));
  };
  const changeEmirates = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      EmirateId: e.target.value,
      EmirateName: e.nativeEvent.target[index].text,
    }));
  };
  const loadFormInUpdate = async () => {
    let tempInf = [];

    transaction[iAnimalHealth.AH_Constants.AHI].AnimalNewlyAdded.forEach(
      (e, i) => {
        tempInf = tempInf.concat(
          new iAnimalHealth.AnimalNewlyAddedDTO(
            e.AnimalTypeId,
            e.NoOfAnimals,
            e.EmirateId,
            e.Address,
            e.DateAdded,
            new Date().getTime() + i,
            props.currentLanguage === "ar"
              ? e.AnimalType.NameAr
              : e.AnimalType.NameEn,
            props.currentLanguage === "ar"
              ? e.EmirateLkp.NameAr
              : e.EmirateLkp.NameEn
          )
        );
      }
    );
    props.handleChangeDTO(tempInf, iAnimalHealth.AH_Constants.ANewly);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      loadFormInUpdate();
    }
  }, [transaction.isUpdate]);
  const SubmitHandler = async (e) => {
    e.preventDefault();
    const formName = "animalNewAddedForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.ANewly].concat(
        new iAnimalHealth.AnimalNewlyAddedDTO(
          localVal.AnimalTypeId,
          localVal.NoOfAnimals,
          localVal.EmirateId,
          localVal.Address,
          localVal.DateAdded,
          new Date().getTime(),
          localVal.AnimalTypeName,
          localVal.EmirateName
        )
      ),
      iAnimalHealth.AH_Constants.ANewly
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[iAnimalHealth.AH_Constants.ANewly].filter(
        (x) => x.Uid !== e.Uid
      ),
      iAnimalHealth.AH_Constants.ANewly
    );
  };
  return (
    <div className="container-bg my-2">
      <label className="mb-2">
        <strong>{props.t("NewlyAddedAnimals")}</strong>
      </label>
      <div className="row animalNewAddedForm">
        <div className="col-md-3">
          <SelectInput
            name="AnimalCatagoryId"
            label="animalCatagory"
            required={true}
            value={localVal.AnimalCatagoryId}
            onChange={changeAnimalCatagory}
            data={animalCatagryData}
          />
        </div>
        <div className="col-md-3">
          <SelectInput
            name="AnimalTypeId"
            label="animalType"
            required={true}
            value={localVal.AnimalTypeId}
            onChange={changeAnimalType}
            data={animalTypeData}
          />
          {localVal.AnimalTypeScientificName && (
            <div>
              <small>
                <strong>{props.t("scientificName")}: </strong>
                {localVal.AnimalTypeScientificName}
              </small>
            </div>
          )}
        </div>
        <div className="col-md-3">
          <FormInput
            name="NoOfAnimals"
            type="number"
            label="numberofAnimals"
            value={localVal.NoOfAnimals}
            onChange={changeControl}
            required={true}
            min="1"
          />
        </div>
        <div className="col-md-3">
          <SelectInput
            name="EmirateId"
            label="emirate"
            required={true}
            value={localVal.EmirateId}
            onChange={changeEmirates}
            data={EmirateData}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            name="Address"
            type="text"
            label="sourceAddress"
            value={localVal.Address}
            onChange={changeControl}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <FormInput
            label="date"
            type="date"
            selectedDate={util.SelectedDate(localVal.DateAdded)}
            required={true}
            onChange={(date) => {
              setLocalVal((p) => ({
                ...p,
                DateAdded: util.SetDate(date),
              }));
            }}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-3 mt-4">
          <AddButton {...props} submitHandler={SubmitHandler} />
        </div>
      </div>
      <div className="col-md-12 row">
        {transaction[iAnimalHealth.AH_Constants.ANewly].map((e, i) => (
          <div className="card" key={i}>
            <div className="card-body row">
              <div className="col-md-2">
                <small className="golden">{props.t("animalType")}</small> :
                <small> {e.AnimalTypeName}</small>
              </div>
              <div className="col-md-2">
                <small className="golden">{props.t("numberofAnimals")}</small>:
                <small> {e.NoOfAnimals}</small>
              </div>
              <div className="col-md-2">
                <small className="golden">{props.t("emirate")}</small>:
                <small> {e.EmirateName}</small>
              </div>
              <div className="col-md-3">
                <small className="golden">{props.t("sourceAddress")}</small>:
                <small> {e.Address}</small>
              </div>
              <div className="col-md-3">
                <small className="golden">{props.t("date")}</small>:
                <small>
                  {util.DateFormat(e.DateAdded).toLocaleDateString()}{" "}
                </small>
              </div>
            </div>
            <DeleteButton
              {...props}
              deleteHandler={() => {
                deleteItem(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewlyAdded;
