import axios from "axios";
import { URLs } from "../../Constants/Common";

export const getAllIncidentRedirection = async (data) => {
  const res = await axios.post(URLs.GetAllIncidentRedirection, data);
  return res.data.ResponseData;
};
export const deleteIncidentRedirection = async (id) => {
  return await axios.delete(`${URLs.DeleteIncidentRedirection}?id=${id}`);
};
export const createIncidentRedirection = async (data) => {
  const res = await axios.post(`${URLs.CreateIncidentRedirection}`, data);
  return res.data.ResponseData;
};
