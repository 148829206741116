import React, { useCallback, useEffect, useState } from "react";
import * as authAction from "../../../State/Actions/Auth";
import FormInput from "../../Common/Validation/FormInput";
import { Link, useNavigate } from "react-router-dom";
import { checkLogin, removeUser, setUserPermission } from "../../../Constants/AuthToken";

const LoginLinked = (props) => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Invalid Email address",
      label: "",
      required: true,
      fontIcon: "ico icon-Group-116",
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage: "Password is required",
      label: "",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      fontIcon: "ico icon-Path-393",
    },
  ];
  const removeUserBeforeLogin = useCallback(() => {
    removeUser();
  }, [removeUser]);

  useEffect(() => {
    if (!checkLogin()) {
      removeUserBeforeLogin();
    }
    // const userDetail = JSON.parse(sessionStorage.getItem("uaepass_data"));
    // if (userDetail != null) {
    //   navigate("/");
    // }
  }, []);

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    console.log("form submitted");
    props.setLoading(true);

    try {
      const userData = JSON.parse(sessionStorage.getItem("uaepass_data"));
      const model = {
        Email: formValues.email,
        Password: formValues.password,
        UUId: userData.uuid,
      };
      const res = await authAction.linkedLogin_UAEPass(model);
      console.log(res);
      if (res.data.IsSuccess && res.data.ResponseData.AccessToken !== null) {
        setUserPermission(res.data.ResponseData.AccessToken, res.data.ResponseData.RefreshToken);
      } else {
        setError(props.t("emailOrPasswordIsWrong"));
      }
      props.setLoading(false);
    } catch (e) {
      console.log(e);
      setError(props.t("emailOrPasswordIsWrong"));
    }
  };

  return (
    <div style={{ direction: "ltr" }}>
      <div className="login-form mt-4  ">
        <div className="text-center mx-2">
          <h4 className="pro-link">{props.t("ProfileLinkingText")}</h4>

          <div className="mt-4 mb-5">
            <h6 className="">{props.t("LoginWithBiosecCredentials")}</h6>
          </div>
        </div>

        <form onSubmit={SubmitHandler} className="mt-3">
          {inputs.map((input, i) => {
            return (
              <div className="mb-3" key={i}>
                <FormInput key={input.id} {...input} value={formValues[input.name]} onChange={onChange} />
              </div>
            );
          })}

          <div className="form-check d-flex justify-content-between">
            <div>
              <input name="cbxrememberme" type="checkbox" id="cbxrememberme" className="form-check-input  " />
              <label htmlFor="cbxrememberme" className="form-check-label d-flex align-items-center">
                {props.t("rememberMe")}
              </label>
            </div>
          </div>
          <div className="  d-flex justify-content-between mt-4  ">
            <button
              //disabled={!formValid}
              id="btn_login"
              style={{ width: "26em" }}
              className="btn btn-warning  p-2 mt-2 mx-3 "
            >
              {/* <i className="fa fa-angle-left"></i> */}
              {props.t("LinkAccountText")}
            </button>
            <div className="mt-3">
              <Link className="form-text forgot-pass" to="/forgetPassword">
                {props.t("forgetPassword")}
              </Link>
            </div>
          </div>
          <div className="form-error text-center mt-3">
            <div className="has-error">{error}</div>
          </div>
          <div className="  text-center mt-5">
            <div className=" ">{props.t("AccountLinkOnce")}</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginLinked;
