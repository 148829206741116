import React from "react";
import { Outlet } from "react-router-dom";
import Home from "../Home";
import { checkLogin } from "../../Constants/AuthToken";
import { validation } from "../Imports";

const userAuth = () => {
  return checkLogin();
};

const ProtectedRoute = (props) => {
  const isUserAuth = userAuth();

  if (isUserAuth) {
    return <Outlet />;
  } else {
    return <Home {...props} />;
  }
};

export default ProtectedRoute;

export const AnimalDataAccess = (props) => {
  return validation.isAnimalDept() ? <Outlet /> : <Home {...props} />;
};
export const AgricultureDataAccess = (props) => {
  return validation.isAgricultureDept() ? <Outlet /> : <Home {...props} />;
};
export const FoodSecurityDataAccess = (props) => {
  return validation.isFoodSafetyDept() ? <Outlet /> : <Home {...props} />;
};
export const MinistryUserDataAccess = (props) => {
  return validation.isMinistryUser() ? <Outlet /> : <Home {...props} />;
};
