import React from "react";
import imgNotFound from "../../Theme/img/notfound.jpg";

const NotFound = () => {
  return (
    <div>
      <img src={imgNotFound} alt="" />
    </div>
  );
};

export default NotFound;
