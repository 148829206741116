import React, { useEffect, useState } from "react";
import * as Report from "../../../ReportControl";
import { auth } from "../../../../../Imports";

const CountByEmirateReportFilter = (props) => {
  const user = auth.getUserPermission();
  const initialState = {
    fromDate: "",
    toDate: "",
    EmirateId: 0,
    DepartmentId: user.Department,
    StatusCode: "",
    ...Report.paginationDto,
  };
  const [localVal, setLocalVal] = useState(initialState);

  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeControlDate = (name, value) => {
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(localVal);
  }, [localVal]);

  const resetForm = (e) => {};

  const searchFormSubmit = (e) => {
    e.preventDefault();
    props.onChangeSearch(localVal);
  };
  const handleReset = () => {
    setLocalVal(initialState);
  };
  return (
    <div>
      <div className="table-filter">
        <form id="search-filter-form" onReset={resetForm} onSubmit={searchFormSubmit}>
          <div className="pt-3 pl-3 pr-3 m-3 container-bg">
            <div className="row flex-fill">
              <div className="col-md-3">
                <Report.DateControl label="from" localVal={localVal.fromDate} changeControlDate={changeControlDate} />
              </div>
              <div className="col-md-3">
                <Report.DateControl label="to" localVal={localVal.toDate} changeControlDate={changeControlDate} />
              </div>

              <div className="col-md-3">
                <Report.WFStatusLkpControl localVal={localVal.StatusCode} changeControl={changeControl} />
              </div>

              <div className="col-md-3 my-4" id="searchForm" style={{ marginTop: "15px" }}>
                <button type="submit" id="btnSearch" className="btn btn-success mx-2 pt-2 pb-2">
                  {props.t("search")}
                </button>
                <button type="reset" onClick={handleReset} className="btn bt-reset mx-2  pt-2 pb-2">
                  {props.t("clear")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CountByEmirateReportFilter;
