import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";
import { iAgrcultureBG, master, util, validation } from "../../Imports";
import AddButton from "../Common/AddButton";
import DeleteButton from "../Common/DeleteButton";
import { Country } from "../FoodSafety/SharedControl";

const CropsDetail = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const ABDCD = iAgrcultureBG.ABG_Constants.ABDCD;
  const [cropData, setCropData] = useState([]);
  const initialState = {
    OrignCountryId: "",
    CountryName: "",
    EserviceAgricultureLkpId: "",
    CropName: "",
    Amount: "",
    Unit: "",
    Commnets: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const changeOrignCountry = async (e) => {
    if (e.target.value) {
      setLocalVal((p) => ({
        ...p,
        OrignCountryId: e.target.value,
        CountryName:
          e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
    }
  };
  const changeEserviceAgriculture = async (e) => {
    if (e.target.value) {
      setLocalVal((p) => ({
        ...p,
        EserviceAgricultureLkpId: e.target.value,
        CropName: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
      }));
    }
  };
  const loadFormInUpdate = async () => {
    let tempInf = [];
    console.log(transaction[ABDCD]);
    transaction[ABDCD].forEach((e, i) => {
      tempInf = tempInf.concat(
        new iAgrcultureBG.AgricultureBorderGateCropsDetailDTO(
          e.OrignCountryId,
          util.getProperText(e.CountryLkp.NameAr, e.CountryLkp.NameEn),
          e.EserviceAgricultureLkpId,
          util.getProperText(
            e.EserviceAgricultureLkp.NameAr,
            e.EserviceAgricultureLkp.NameEn
          ),
          e.Amount,
          e.Unit,
          e.Commnets,
          new Date().getTime() + i
        )
      );
    });
    props.handleChangeDTO(tempInf, ABDCD);
  };
  useEffect(() => {
    if (transaction.isUpdate) {
      setTimeout(() => {
        loadFormInUpdate();
      }, 2000);
    }
  }, [transaction.isUpdate]);
  const loadComLkp = useCallback(async () => {
    setCropData(await master.getEserviceAgricultureLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "agrCropsDetailForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[ABDCD].concat(
        new iAgrcultureBG.AgricultureBorderGateCropsDetailDTO(
          localVal.OrignCountryId,
          localVal.CountryName,
          localVal.EserviceAgricultureLkpId,
          localVal.CropName,
          localVal.Amount,
          localVal.Unit,
          localVal.Commnets,
          new Date().getTime()
        )
      ),
      ABDCD
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[ABDCD].filter((x) => x.Uid !== e.Uid),
      ABDCD
    );
  };
  return (
    <div className="container agrCropsDetailForm">
      <div className="row container-bg my-2">
        <Country
          transaction={localVal}
          changeControl={changeOrignCountry}
          name="OrignCountryId"
          label="SourceCountry"
        />

        <div className="col-md-4">
          <SelectInput
            name="EserviceAgricultureLkpId"
            label="Type"
            required={true}
            disabled={false}
            value={localVal.EserviceAgricultureLkpId}
            onChange={changeEserviceAgriculture}
            data={cropData}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="Amount"
            type="number"
            label="Quantity"
            required={true}
            disabled={false}
            value={localVal.Amount}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="Unit"
            type="text"
            label="Unit"
            required={false}
            disabled={false}
            value={localVal.Unit}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-8">
          <FormInput
            name="Commnets"
            type="textarea"
            label="Comments"
            required={true}
            disabled={false}
            value={localVal.Commnets}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-3 mt-4">
          <AddButton {...props} submitHandler={submitHandler} />
        </div>

        <div className="col-md-12 my-2 row">
          {transaction[ABDCD].map((e, i) => (
            <div className="card" key={i}>
              <div className="card-body row">
                <div className="col-md-4">
                  <small className="golden">{props.t("Country")}</small>:
                  <small> {e.CountryName}</small>
                </div>
                <div className="col-md-4">
                  <small className="golden">{props.t("Type")}</small>:
                  <small> {e.CropName}</small>
                </div>
                <div className="col-md-4">
                  <small className="golden">{props.t("Quantity")}</small>:
                  <small> {e.Amount}</small>
                </div>
                <div className="col-md-4">
                  <small className="golden">{props.t("Unit")}</small>:
                  <small> {e.Unit}</small>
                </div>
                <div className="col-md-4">
                  <small className="golden">{props.t("Comments")}</small>:
                  <small> {e.Commnets}</small>
                </div>
              </div>
              <DeleteButton
                {...props}
                deleteHandler={() => {
                  deleteItem(e);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CropsDetail;
