import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { enums, encrypt, validation, auth, iIncident, foodSafetyBGAction, transAction, swal, iFoodSafetyBG } from "../../../Imports";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../../Common/ActionButton";
import AuditLogs from "../../Common/AuditLogs";
import IncidentStatusUpdate from "../../Common/IncidentStatusUpdate";
import GPSLocation from "../../Common/LocationDetail/GPSLocation";
import EmirateGovEntity from "../../Common/LocationDetail/EmirateGovEntity";
import ShipmentInitialData from "../../Common/ShipmentInitialData";
import ImporterExporterInfo from "./ImporterExporterInfo";
import ShipmentInfo from "./ShipmentInfo";
import ProductInfo from "./ProductInfo";
import RiskInfo from "./RiskInfo";
import AllAttachment from "../AllAttachment";
import Reporter from "../../Common/Reporter";
import RiskDegree from "../../Common/RiskDegree";

const FoodSafetyBorderGate = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  followup = parseInt(encrypt.decrypt(followup));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await foodSafetyBGAction.getFoodSafetyBorderGateTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;

      handleChangeDTO(res, iFoodSafetyBG.FSBD_Constants.FSBGT);
      handleChangeDTO(res.FoodSafetyBorderGateRiskInfo, iFoodSafetyBG.FSBD_Constants.FSBGRI);
      handleChangeDTO(res.FoodSafetyBorderGateProductInfo, iFoodSafetyBG.FSBD_Constants.FSBGPI);
      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);

      validation.isShowInfectionForm(transaction, "BasicDetailForm");
      validation.isShowInfectionForm(transaction, "ImporterInfoForm");
      validation.isShowInfectionForm(transaction, "ShipmentInfoForm");
      validation.isShowInfectionForm(transaction, "ProductInfoForm");
      validation.isShowInfectionForm(transaction, "RiskInfoForm");
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iFoodSafetyBG.FoodSafetyBorderGateTransactionDTO();
    data = transaction.FoodSafetyBorderGateTransactionDTO;

    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.FoodSafetyBorderGate, followup);
    data.FoodSafetyBorderGateProductInfo = transaction.FoodSafetyBorderGateProductInfoDTO;
    data.FoodSafetyBorderGateRiskInfo = transaction.FoodSafetyBorderGateRiskInfoDTO;

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }
    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await foodSafetyBGAction.createFoodSafetyBorderGate(data);
    else if (followup > 0) {
      res = await foodSafetyBGAction.createFollowUpFoodSafetyBorderGate(data);
    } else {
      res = await foodSafetyBGAction.updateFoodSafetyBorderGate(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };

  const validateDetailForm_user = (e) => {
    const actionBtnForm = validation.validateForm("actionbuttonForm");
    const form = validation.validateForm("BasicDetailForm", "");
    const form1 = validation.validateForm("ImporterInfoForm", "");
    const form2 = validation.validateForm("ShipmentInfoForm", "");
    const form3 = validation.validateForm("ProductInfoForm", "");
    const form4 = validation.validateForm("RiskInfoForm", "");
    if (!actionBtnForm || !form || !form1 || !form2 || !form3 || !form4) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length < 5) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    return true;
  };
  const formValidation = () => {
    return validateDetailForm_user();
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("FoodSafetyBorderGate")}</h3>
            </div>
            <div className="col-sm-12  ">
              <Tabs defaultActiveKey="mainDetail">
                <Tab eventKey="mainDetail" title={props.t("IncidentBasicDetails")}>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}
                    <div className="container BasicDetailForm ">
                      <div className="container-bg my-2">
                        <div className="row">
                          <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />

                          <EmirateGovEntity
                            {...props}
                            unitType={enums.UnitTypeEnum.FocalPoint}
                            department={enums.DepartmentEnum.FoodSafetyDepartment}
                            isDisabled={transaction.isUpdate}
                            value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                            emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                            handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                          />
                          <ShipmentInitialData
                            fsConst={iFoodSafetyBG.FSBD_Constants.FSBGT}
                            transaction={transaction}
                            handleChangeDTO={handleChangeDTO}
                            handleChange={handleChange}
                            {...props}
                          />
                        </div>
                      </div>
                      <div className=" my-2" style={{ display: "" }}>
                        <Reporter {...props} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="ImporterExporterInformation" title={props.t("ImporterExporterInformation")}>
                  <div className="ImporterInfoForm">
                    <ImporterExporterInfo transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                  </div>
                </Tab>
                <Tab eventKey="ShipmentInformation" title={props.t("ShipmentInformation")}>
                  <div className="ShipmentInfoForm">
                    <ShipmentInfo transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                  </div>
                </Tab>
                <Tab eventKey="ProductInformation" title={props.t("ProductInformation")}>
                  <div className="ProductInfoForm">
                    <ProductInfo transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                  </div>
                </Tab>

                <Tab eventKey="RiskInformation" title={props.t("RiskInformation")}>
                  <div className="RiskInfoForm">
                    <RiskInfo transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                  </div>
                  <AllAttachment transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />

                  {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                  <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodSafetyBorderGate;
