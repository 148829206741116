import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput from "../../../Common/Validation/FormInput";
import { auth, iAnimalSD, validation } from "../../../Imports";
import AddButton from "../../Common/AddButton";
import DeleteButton from "../../Common/DeleteButton";
import { constants } from "../../../../Constants/Common";

const PatientData = (props) => {
  //const user = auth.getUserPermission();
  const initialState = {
    PatientName: "",
    PatientPhoneNo: "",
    PatientEmiratesId: "",
    PatientPassport: "",
    PatientEmail: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const transaction = useSelector((state) => state.transaction);
  const asdip = iAnimalSD.ASD_Constants.ASDIP;
  const deleteItem = async (e) => {
    props.handleChangeDTO(
      transaction[asdip].filter((x) => x.Uid !== e.Uid),
      asdip
    );
  };

  const onChangeControl = (e) => {
    // console.log(e);
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formName = "PatientDataForm";
    if (!validation.validateForm(formName)) return;
    props.handleChangeDTO(
      transaction[asdip].concat(
        new iAnimalSD.AnimalSharedDiseaseInfectedPeopleDTO(
          localVal.PatientName,
          localVal.PatientPhoneNo,
          localVal.PatientEmiratesId,
          localVal.PatientPassport,
          localVal.PatientEmail
        )
      ),
      asdip
    );
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };
  useEffect(() => {
    if (!transaction.isUpdate) {
    }
  }, [transaction.isUpdate]);

  return (
    <div className="row my-1 container-bg my-3 PatientDataForm">
      <label className="control-title my-2 mb-4">
        <strong>{props.t("PatientData")}</strong>
      </label>
      {validation.isHealthFacilityUser() && (
        <>
          <div className="col-md-4">
            <FormInput name="PatientName" label="PatientName" required={true} value={localVal.PatientName} onChange={onChangeControl} />
            {/* <em className="control-description  ">{props.t("required")}</em> */}
          </div>
          <div className="col-md-4">
            <FormInput
              name="PatientPhoneNo"
              type="text"
              label="mobileNo"
              // pattern="\d*"
              maxLength={15}
              minLength={1}
              required={true}
              value={localVal.PatientPhoneNo}
              onChange={onChangeControl}
            />
            {/* <em className="control-description">{props.t("required")}</em> */}
          </div>
          <div className="col-md-4">
            <FormInput
              name="PatientEmiratesId"
              type="text"
              dir="ltr"
              placeholder="784-xxxx-xxxxxxx-x"
              errorMessage={props.t("emiratesID") + props.t("incorrect")}
              label="emiratesID"
              required={!localVal.PatientPassport}
              maxLength={18}
              //pattern="^784-[0-9]{4}-[0-9]{7}-[0-9]{1}$"
              value={localVal.PatientEmiratesId}
              onChange={onChangeControl}
            />
            <em className="control-description  ">{props.t("emirateIDPassportRequired")}</em>
          </div>
          <div className="col-md-4">
            <FormInput
              name="PatientPassport"
              type="text"
              label="passportNumber"
              required={!localVal.PatientEmiratesId}
              value={localVal.PatientPassport}
              onChange={onChangeControl}
            />
            <em className="control-description">{props.t("emirateIDPassportRequired")}</em>
          </div>
          <div className="col-md-4">
            <FormInput
              name="PatientEmail"
              type="text"
              label="email"
              errorMessage={props.t("email") + props.t("incorrect")}
              // pattern={constants.email_regx}
              required={true}
              value={localVal.PatientEmail}
              onChange={onChangeControl}
            />
            {/* <em className="control-description">{props.t("required")}</em> */}
          </div>
          <div className="col-md-3 mt-4">
            <AddButton {...props} submitHandler={submitHandler} />
          </div>
        </>
      )}
      <div className="col-md-12 row">
        {transaction[asdip] &&
          transaction[asdip].map((e, i) => (
            <div className="card" key={i}>
              <div className="card-body row">
                <div className="col-md-3">
                  <small className="golden">{props.t("PatientName")}</small> :<small> {e.Name}</small>
                </div>
                <div className="col-md-2">
                  <small className="golden">{props.t("mobileNo")}</small>:<small> {e.PhoneNo}</small>
                </div>
                <div className="col-md-3">
                  <small className="golden">{props.t("email")}</small>:<small> {e.Email}</small>
                </div>
                {e.EmiratesId && (
                  <div className="col-md-4">
                    <small className="golden">{props.t("emiratesID")}</small>:<small> {e.EmiratesId}</small>
                  </div>
                )}
                {e.Passport && (
                  <div className="col-md-3">
                    <small className="golden">{props.t("passportNumber")}</small>:<small> {e.Passport} </small>
                  </div>
                )}
              </div>
              {validation.isHealthFacilityUser() && (
                <DeleteButton
                  {...props}
                  deleteHandler={() => {
                    deleteItem(e);
                  }}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PatientData;
