import axios from "axios";
import { URLs } from "../../Constants/Common";

export const createAnimalBorderGate = async (data) => {
  return await axios.post(URLs.CreateAnimalBorderGate, data);
};
export const updateAnimalBorderGate = async (data) => {
  return await axios.post(URLs.UpdateAnimalBorderGate, data);
};
export const createFollowUpAnimalBorderGate = async (data) => {
  return await axios.post(URLs.CreateFollowUpAnimalBorderGate, data);
};

export const getAnimalBorderGateTransactionById = async (id) => {
  return await axios.get(URLs.getAnimalBorderGateTransactionById + "?id=" + id);
};
export const animalBorderGateUploadFiles = async (data, animalBorderGateId) => {
  return await axios.post(
    URLs.AnimalBorderGateUploadFiles + animalBorderGateId,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const deleteAnimalBorderGateFiles = async (
  animalBorderGateId,
  fileId
) => {
  return await axios.delete(
    `${URLs.DeleteAnimalBorderGateFiles}?FileId=${fileId}&animalBorderGateId=${animalBorderGateId}`
  );
};

export const getAnimalBorderGateReport = async (data) => {
  return await axios.post(URLs.GetAnimalBorderGateReport, data);
};
