import React, { useState } from "react";
import { useSelector } from "react-redux";
import { enums, iAnimalHealth, validation, master } from "../../../Imports";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import InfectionCard from "./InfectionCard";
import { AddButton } from "../../Common/IncidentCommonImport";

const InfectionCAD = (props) => {
  const initialState = {
    AnimalCatagoryId_1: "",
    AnimalCatagoryName: "",
    AnimalTypeId_1: "",
    AnimalTypeName: "",
    AnimalTypeOther: "",
    AnimalTypeScientificName: "",
    NumberOfInfectedAnimals: "0",
    Anatomy: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const [animalTypeData, setAnimalTypeData] = useState([]);

  const animalCatagryData = useSelector((state) => state.masterData.animalCatagoryData);
  const transaction = useSelector((state) => state.transaction);

  const changeControl = (e) => {
    setLocalVal((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }));
  };

  const changeAnimalCatagory = async (e) => {
    if (e.target.value) {
      var index = e.nativeEvent.target.selectedIndex;
      setLocalVal((p) => ({
        ...p,
        AnimalCatagoryId_1: e.target.value,
        AnimalCatagoryName: e.nativeEvent.target[index].text,
        AnimalTypeScientificName: "",
      }));

      const res = await master.getAnimalType(e.target.value);
      setAnimalTypeData(res);
    }
  };
  const changeAnimalType = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      AnimalTypeId_1: e.target.value,
      AnimalTypeName: e.nativeEvent.target[index].text,
      AnimalTypeScientificName: e.nativeEvent.target[index].getAttribute("data-code"),
    }));
  };

  const handleAddRow = () => {
    var detail = new Array(
      new iAnimalHealth.IncidentInfectionDetailsDTO(
        new Date().getTime(),
        localVal.AnimalCatagoryId_1,
        localVal.AnimalCatagoryName,
        localVal.AnimalTypeId_1,
        localVal.AnimalTypeName,
        localVal.NumberOfInfectedAnimals,
        0,
        0,
        "",
        "",
        "",
        [],
        [],
        0,
        "",
        "",
        localVal.Anatomy,
        "",
        enums.InfectionDetailGroupEnum.InfectedClinicallyAnimalsDetails,
        0,
        localVal.AnimalTypeOther
      )
    );
    props.handleChangeDTO(transaction[iAnimalHealth.AH_Constants.IID].concat(detail), iAnimalHealth.AH_Constants.IID);
    restForm();
  };
  const restForm = () => {
    document.getElementById("AnimalCatagoryId_1").value = "";
    document.getElementById("AnimalCatagoryId_1").setAttribute("focused", "false");
    document.getElementById("AnimalTypeId_1").value = "";
    document.getElementById("AnimalTypeId_1").setAttribute("focused", "false");
    setLocalVal(initialState);
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    const formName = "animalform2";
    if (!validation.validateForm(formName)) return;
    handleAddRow();
    setLocalVal(initialState);
    validation.resetFormByClassName(formName);
  };

  return (
    <div>
      <div className="container  my-2 container-bg">
        <label className="control-title my-2 mb-4">
          <strong>{props.t("infectedClinicallyAnimalsDetails")}</strong>
        </label>
        <div className={`animalform2`}>
          <div className="row">
            <div className="col-md-3">
              <SelectInput
                name="AnimalCatagoryId_1"
                label="animalCatagory"
                required={true}
                value={localVal.AnimalCatagoryId_1}
                onChange={changeAnimalCatagory}
                data={animalCatagryData}
              />
            </div>
            <div className="col-md-3">
              <SelectInput
                name="AnimalTypeId_1"
                label="animalType"
                required={true}
                value={localVal.AnimalTypeId_1}
                onChange={changeAnimalType}
                data={animalTypeData}
              />
              {localVal.AnimalTypeScientificName && (
                <div>
                  <small>
                    <strong>{props.t("scientificName")}: </strong>
                    {localVal.AnimalTypeScientificName}
                  </small>
                </div>
              )}
            </div>
            {localVal.AnimalTypeScientificName === enums.AnimalTypeEnum.Other && (
              <div className="col-md-3">
                <FormInput name="AnimalTypeOther" type="text" label="animalType" value={localVal.AnimalTypeOther} onChange={changeControl} />
              </div>
            )}
            <div className="col-md-3">
              <FormInput
                name="NumberOfInfectedAnimals"
                type="number"
                required={true}
                label="numberOfInfectedAnimalsInPossession"
                value={localVal.NumberOfInfectedAnimals}
                onChange={changeControl}
                min="1"
              />
            </div>

            <div className="col-md-9">
              <FormInput name="Anatomy" type="text" required={true} label="Anatomy" value={localVal.Anatomy} onChange={changeControl} min="1" />
            </div>

            <div className="col-md-2 mt-4">
              <AddButton submitHandler={SubmitHandler} {...props} />
            </div>
          </div>
        </div>
        <div className="row">
          <InfectionCard type={enums.InfectionDetailGroupEnum.InfectedClinicallyAnimalsDetails} {...props} />
        </div>
      </div>
    </div>
  );
};

export default InfectionCAD;
