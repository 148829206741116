import React, { useState } from "react";
import { Link } from "react-router-dom";
import PopupOver from "../../Common/UI/PopupOver";
import FormInput from "../../Common/Validation/FormInput";
import { util } from "../../Imports";

const DateUI = (props) => {
  const initialState = {
    fromDate: "",
    toDate: "",
  };
  const [localVal, setLocalVal] = useState(initialState);
  const changeControlDate = (name, value) => {
    setLocalVal((p) => ({
      ...p,
      [name]: value,
    }));
  };
  const handleApplyBtn = (type) => {
    props.handleApplyBtn(localVal.fromDate, localVal.toDate, type);
  };
  const handleClearBtn = (type) => {
    props.handleClearBtn(type);
    setLocalVal(initialState);
  };
  return (
    <>
      <PopupOver
        button={
          <Link
            to="#"
            className="align-items-center d-flex justify-content-center graph-datepicker m-1"
          >
            <i className="fa fa-calendar"></i>
          </Link>
        }
        body={
          <>
            <div className="p-3 table-filter  ">
              <FormInput
                name="fromDate"
                label="from"
                placeholderText={props.t("date")}
                type="date"
                selectedDate={util.SelectedDate(localVal.fromDate)}
                onChange={(date) =>
                  changeControlDate("fromDate", util.SetDate(date))
                }
                maxDate={new Date()}
                required={false}
              />
              <FormInput
                name="toDate"
                label="to"
                placeholderText={props.t("date")}
                type="date"
                selectedDate={util.SelectedDate(localVal.toDate)}
                onChange={(date) =>
                  changeControlDate("toDate", util.SetDate(date))
                }
                maxDate={new Date()}
                required={false}
              />
              <button
                className="btn p-2 mt-4 btn-success mx-3"
                onClick={() => handleApplyBtn(props.type)}
              >
                {props.t("Apply")}
              </button>
              <button
                className="btn p-2 mt-4 btn-success mx-3"
                onClick={() => handleClearBtn(props.type)}
              >
                {props.t("clear")}
              </button>
            </div>
          </>
        }
      />
    </>
  );
};

export default DateUI;
