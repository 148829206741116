import React from "react";
import Charts from "./Charts";
import StatusCount from "./StatusCount";

const AnimalHealth = (props) => {
  return (
    <>
      <div className="row d-flex flex-wrap">
        <span className="d-flex m-3">{props.t("Incident")}</span>
        <StatusCount {...props} />
      </div>
      <div className="row d-flex flex-wrap">
        <Charts {...props} />
      </div>
    </>
  );
};

export default AnimalHealth;
