import { URLs } from "../../Constants/Common";
import axios from "axios";
import { DispatchTypes } from "../DispatchType";

export const authenticate = (token, userId) => {
  return {
    type: DispatchTypes.AUTHENTICATE,
    token: token,
    userId: userId,
  };
};

export const signin = async (email, password) => {
  return await axios.post(URLs.Login, { email, password }, { withCredentials: true });
};
export const registerUserAndLogin_UAEPass = async (data) => {
  return await axios.post(URLs.RegisterUserAndLogin_UAEPass, data);
};
export const linkedLogin_UAEPass = async (data) => {
  return await axios.post(URLs.LinkedLogin_UAEPass, data);
};
export const loginByEmailEID = async (eid, email) => {
  return await axios.post(URLs.LoginByEmailEID + "?EID=" + eid + "&email=" + email, null);
};
export const loginByUAEPass = async (UUId) => {
  return await axios.post(URLs.LoginByUAEPass, { UUId }, { withCredentials: true });
};
export const getUserByEID = async (UUId) => {
  return await axios.get(URLs.GetUserByEID + "?uuId=" + UUId);
};
export const signOut = async (email) => {
  return await axios.post(`${URLs.Logout}/${email}`, {}, { withCredentials: true });
};
