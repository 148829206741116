import React from "react";

const LoaderUI = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <div className="preloader">
        <div
          className="spinner-border"
          style={{
            width: "2rem",
            height: "2rem",
            color: "var(--maincolor) ",
          }}
          role="status"
        ></div>
      </div>
    </div>
  );
};

export default LoaderUI;
