import axios from "axios";
import { URLs } from "../../Constants/Common";
import { DispatchTypes } from "../DispatchType";

export const getUnReadNotification = () => {
  return async (dispatch, getState) => {
    if (getState().notification.unReadNotificationData.length > 0) return false;

    axios.get(`${URLs.getUnReadNotification}`).then((res) => {
      if (res.data.IsSuccess) {
        // console.log(res.data);
        const resData = res.data.ResponseData;

        dispatch({
          type: DispatchTypes.UNREAD_NOTIFICATION,
          unReadNotificationData: resData,
        });
      }
    });
  };
};
export const updateNotificationStore = async (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DispatchTypes.UNREAD_NOTIFICATION,
      unReadNotificationData: data,
    });
  };
};
export const getAllNotification = async () => {
  const res = await axios.get(`${URLs.GetAllNotification}`);
  return res.data;
};
export const makeUnReadToReadNotification = async (id) => {
  const res = await axios.get(`${URLs.MakeUnReadToReadNotification}?id=${id}`);
  return res.data;
};
