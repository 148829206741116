import axios from "axios";
import { URLs } from "../../Constants/Common";

export const getCountByStatus = async (deptId, emirateId, fromDate, toDate) => {
  const data = {
    deptId,
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetCountByStatus}`, data);
};
export const getIncidentByEmirate = async (deptId, emirateId, fromDate, toDate) => {
  const data = {
    deptId,
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentByEmirate}`, data);
};
export const getIncidentCountByEmirate = async (deptId, emirateId, fromDate, toDate, status) => {
  const data = {
    deptId,
    fromDate,
    toDate,
    status,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentCountByEmirate}`, data);
};
export const getIncidentByIncidentSource = async (deptId, emirateId, fromDate, toDate) => {
  const data = {
    deptId,
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentByIncidentSource}`, data);
};
export const getIncidentByOriginCountry = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentByOriginCountry}`, data);
};
export const getIncidentByProductCatagory = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentByProductCatagory}`, data);
};
export const getIncidentByRiskSourceGroup = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentByRiskSourceGroup}`, data);
};
export const getIncidentByActionTaken = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetIncidentByTakenAction}`, data);
};
export const getAnimalStateCount = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetAnimalStateCount}`, data);
};
export const getLeagalViolationStatCount = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetLeagalViolationStatCount}`, data);
};
export const getAnimalIncidentByEmirate = async (emirateId, AnimalDiseaseId, fromDate, toDate) => {
  const data = {
    AnimalDiseaseId,
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetAnimalIncidentByEmirate}`, data);
};
export const getAgricultureStateCount = async (emirateId, fromDate, toDate) => {
  const data = {
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetAgricultureStateCount}`, data);
};
export const getAgricultureIncidentByEmirate = async (emirateId, BlightDescriptionId, fromDate, toDate) => {
  const data = {
    BlightDescriptionId,
    fromDate,
    toDate,
    emirateId,
  };
  return await axios.post(`${URLs.GetAgricultureIncidentByEmirate}`, data);
};
export const getAnimalStateCount_IncidentDetail = async (DBType, emirateId) => {
  return await axios.get(`${URLs.GetAnimalStateCount_IncidentDetail}?DBType=${DBType}&emirateId=${emirateId}`);
};
export const getLeagalViolationStatCount_IncidentDetail = async (DBType, emirateId) => {
  return await axios.get(`${URLs.GetLeagalViolationStatCount_IncidentDetail}?DBType=${DBType}&emirateId=${emirateId}`);
};
export const getCountByStatus_IncidentDetail = async (DBType, emirateId) => {
  return await axios.get(`${URLs.GetCountByStatus_IncidentDetail}?DBType=${DBType}&emirateId=${emirateId}`);
};
export const getAgricultureStateCount_IncidentDetail = async (DBType, emirateId) => {
  return await axios.get(`${URLs.GetAgricultureStateCount_IncidentDetail}?DBType=${DBType}&emirateId=${emirateId}`);
};
