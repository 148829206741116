import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as masterDataAction from "../../../State/Actions/MasterData";
import * as commonAction from "../../../State/Actions/Common";
import { SelectInput } from "../../Common/Validation/FormInput";
import * as Control from "../CommonControl";

function AccountDetail(props) {
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isEmirateIdFilled, setIsEmirateIdFilled] = useState(false);
  const dispatch = useDispatch();
  const userTypeLkpData = useSelector((state) => state.masterData.userTypeData);
  const isEmirateIDExist = useSelector(
    (state) => state.account.isEmirateIDExist
  );
  const isPassportExist = useSelector((state) => state.account.isPassportExist);
  const imageId = useSelector((state) => state.common.fileUpload);

  const hiddenFileInput = React.useRef(null);
  const openFileBrowser = (e) => {
    hiddenFileInput.current.click();
  };
  useEffect(() => {
    if (selectedFile) {
      onFileUpload();
      setSelectedFile(undefined);
    }
    props.handleChange({
      target: { name: "imageId", value: imageId.Id },
    });
    return {};
  }, [imageId, selectedFile]);

  const onFileChange = async (e) => {
    // setSelectedFile(URL.createObjectURL(e.target.files[0]));
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
    props.handleChange({
      target: { name: "selectedFile", value: e.target.files[0].name },
    });
    // setSelectedFile((state) => {
    //   state = e.target.files[0];    //
    // });
  };
  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    //console.log(selectedFile);
    dispatch(commonAction.uploadFile(formData));
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    console.log("form submitted");
    if (!isEmirateIDExist && !isPassportExist) {
      props.nextStep();
    }
  };
  const loadUserTypeLkp = useCallback(async () => {
    setError(null);
    try {
      dispatch(masterDataAction.getUserType());
    } catch (err) {
      setError(err.message);
    }
  }, [dispatch]);

  useEffect(() => {
    loadUserTypeLkp();
  }, []);
  return (
    <div>
      <form
        id="regStep1Form"
        name="form1"
        className="current "
        onSubmit={formSubmit}
      >
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>
                {props.t("userType")} <span className="red">*</span>
              </label>
              <SelectInput
                name="NonGovTypeCode"
                onChange={props.handleChange}
                required
                value={props.values["NonGovTypeCode"]}
                data={userTypeLkpData}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <div className="upload-file1">
                <label></label>
                <div className="input-group">
                  <input
                    type="text white-bg"
                    className="form-control"
                    readOnly=""
                    disabled="disabled"
                    placeholder={
                      props.values["selectedFile"] && imageId.Id > 0
                        ? imageId.FileName
                        : props.t("accountPicture")
                    }
                    value={
                      props.values["selectedFile"] && imageId.Id > 0
                        ? props.values["selectedFile"]
                        : ""
                    }
                  />
                  {props.values["selectedFile"] && !imageId.Id && (
                    <span className="red">
                      {props.t("fileUploadingProblem")}
                    </span>
                  )}
                  <div className="input-group-append">
                    <button
                      className="btn btn-dark upload-file"
                      type="button"
                      onClick={openFileBrowser}
                    >
                      <i className="fa fa-plus"></i>
                    </button>

                    <input
                      type="file"
                      ref={hiddenFileInput}
                      className="d-none"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Control.FirstNameEnglish
              value={props.values["nameEn"]}
              handleChange={props.handleChange}
              props={props}
            />
          </div>
          <div className="col-6">
            <Control.LastNameEnglish
              value={props.values["lastNameEn"]}
              handleChange={props.handleChange}
              props={props}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Control.FirstNameArabic
              value={props.values["nameAr"]}
              handleChange={props.handleChange}
              props={props}
            />
          </div>
          <div className="col-6">
            <Control.LastNameArabic
              value={props.values["lastNameAr"]}
              handleChange={props.handleChange}
              props={props}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Control.UserEmiratesID
              handleChange={props.handleChange}
              value={props.values["reporterEID"]}
              props={props}
              isRequired={!props.values["passportNo"]}
            />
          </div>
          <div className="col-6">
            <Control.UserPassportNo
              handleChange={props.handleChange}
              value={props.values["passportNo"]}
              props={props}
              isRequired={!props.values["reporterEID"]}
            />
          </div>
        </div>

        <div className="d-flex mt-5">
          <button type="submit" className="btn flex-fill p-2 next">
            {props.t("next")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AccountDetail;
