import React from "react";
import { Link } from "react-router-dom";
import { auth } from "../Imports";
import NewIncidentTemplate from "./HeaderComponent/NewIncidentTemplate";
import UaePassLogo from "./HeaderComponent/UaePassLogo";

function MobileHeaderTemplate(props) {
  const user = auth.getUserPermission();

  const openSideBar = () => {
    var element = document.getElementById("sidebar");
    element.classList.add("slide");
  };
  return (
    <div>
      <div className="d-lg-none app-header">
        <div className="d-flex align-items-center justify-content-between my-4">
          <div className="d-flex ">
            {user.isLoggedIn && (
              <span className="menu-btn-container mx-1">
                <a
                  onClick={openSideBar}
                  className="app-open-js mobile-login mobile-side-menu text-center pb-4 pl-2 pr-2 pt-4 w-50"
                  style={{ fontSize: "20px" }}
                >
                  <i className="fa fa-bars  "></i>
                </a>
              </span>
            )}

            {user.isLoggedIn && (
              <div className="mobile-header-links  ">
                <div id="createRequestBtn" className="create-request-mob mx-2 text-center w-100">
                  <NewIncidentTemplate {...props} />
                </div>
                {/* {!user.isLoggedIn && (
                <>
                  <span className="menu-btn-container">
                    <Link to="/login" className="mobile-login text-center pb-4 pl-2 pr-2 pt-4 w-50">
                      <i className="fa fa-user-o d-block mb-2"></i>
                      {props.t("login")}
                    </Link>
                  </span>
                  <span className="menu-btn-container">
                    <Link to="/Registration" className="mobile-login text-center pb-4 pl-2 pr-2 pt-4 w-50">
                      <i className="fa fa-user-plus d-block mb-2"></i>
                      {props.t("register")}
                    </Link>
                  </span>
                </>
              )} */}
              </div>
            )}
            <Link onClick={props.changeLangGlobal} to="/" id="lnk_languageSwitcher" className="app-lang-btn">
              <span className=""> {props.t("langText")}</span>
            </Link>
            <div className="uaepass-logo-mobile">{!user.isLoggedIn && <UaePassLogo {...props} isWeb={false} />}</div>
          </div>
          <div className="d-flex flex-column mx-1">
            <Link to="/" className="site-logo">
              <img src={props.logo} alt="" />
            </Link>

            <div className="site-title text-center mt-2">
              <h1> {props.t("app_name")}</h1>
              <h2> {props.t("app_name2")}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileHeaderTemplate;
