import React from "react";
import { useState } from "react";
import ReportFilter from "./ReportFilter";
import AgriPestGrid from "./AgriPestGrid";
import { auth, enums, validation } from "../../../../Imports";
import { useParams } from "react-router-dom";
import AgriBGGrid from "./AgriBGGrid";
import { paginationDto } from "../../ReportControl";

const AgricultureReport = (props) => {
  let { type } = useParams();
  const [isSearchChanged, setIsSearchChanged] = useState(false);
  const user = auth.getUserPermission();
  const initialState = {
    fromDate: "",
    toDate: "",
    EmirateId: validation.getUserEmirateId(),
    RefNo: "",
    DepartmentId: user.Department,
    ReporterName: "",
    ReporterMobileNo: "",
    StatusCode: "",
    RiskDegree: "",
    BlightDescriptionId: 0,
    PlantTypeId: 0,
    BorderPortId: 0,
    ...paginationDto,
  };
  const [data, setData] = useState(initialState);

  const onChangeSearch = (localVal) => {
    setIsSearchChanged(!isSearchChanged);
    setData(localVal);
  };

  return (
    <div className="d-flex flex-column  justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("agriculturalPestsReport")}</h3>
      </div>
      <div className="d-flex flex-column justify-content-center search-page mt-1 container-fluid d-flex flex-shrink-0">
        <ReportFilter {...props} type={type} onChangeSearch={onChangeSearch} data={data} />

        {type === enums.IncidentTypeEnum.AgriculturePestIncident && <AgriPestGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />}
        {type === enums.IncidentTypeEnum.AgricultureBorderGate && <AgriBGGrid {...props} searchData={data} isSearchChanged={isSearchChanged} />}
      </div>
    </div>
  );
};

export default AgricultureReport;
