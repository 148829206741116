import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, enums } from "../../Imports";
import Inbox from "./Inbox";
import Filter from "./Filter";
import AlertBox from "../../Common/UI/AlertBox";
import { paginationDto } from "./ReportControl";

const Tasks = (props) => {
  const user = auth.getUserPermission();
  const [isSearchChanged, setIsSearchChanged] = useState(false);
  let { type } = useParams();

  const initialData = {
    criteria: type,
    DepartmentId: user.Department,
    fromDate: "",
    toDate: "",
    EmirateId: 0,
    RefNo: "",
    IncidentTypeCode: "",
    ReporterName: "",
    ReporterMobileNo: "",
    StatusCode: "",
    ProductName: "",
    NotificationTypeId: 0,
    UserAccountType: "",
    LegalViolationTypeId: 0,
    HoldingOwnerShipType: 0,
    ...paginationDto,
  };
  const [data, setData] = useState(initialData);

  const onChangeSearch = (localVal) => {
    setData(localVal);
    setIsSearchChanged(!isSearchChanged);
  };

  useEffect(() => {
    setData(initialData);
    setIsSearchChanged(!isSearchChanged);
  }, [type]);

  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t(type)}</h3>

        {type === enums.SearchCriteriaEnum.IncidentSearch && (
          <AlertBox>
            <span>{props.t("New_Conf_Cir_Fup")}</span>
          </AlertBox>
        )}

        {type === enums.SearchCriteriaEnum.MyTask && user.UnitType === enums.UnitTypeEnum.MinistryUser && (
          <AlertBox>
            <span>{props.t("New_Rej_Notif_Closed")}</span>
          </AlertBox>
        )}
        {type === enums.SearchCriteriaEnum.MyTask && user.UnitType !== enums.UnitTypeEnum.MinistryUser && (
          <AlertBox>
            <span>{props.t("New_Notified")}</span>
          </AlertBox>
        )}
      </div>
      <div className="d-flex flex-column justify-content-center search-page mt-1 container-fluid d-flex flex-shrink-0">
        {type !== enums.SearchCriteriaEnum.CirculatedIncident && (
          <Filter type={type} data={data} isSearchChanged={isSearchChanged} {...props} onChangeSearch={onChangeSearch} initialData={initialData} />
        )}

        <Inbox {...props} type={type} searchData={data} isSearchChanged={isSearchChanged} />
      </div>
    </div>
  );
};

export default Tasks;
