import React from "react";
import Excel from "exceljs";

const WHOReport = (props) => {
  const DownloadReport = async () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");
    worksheet.properties.defaultRowHeight = 40;

    // worksheet.getColumn(1).fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "F08080" },
    // };

    // worksheet.getRow(1).fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "#fefefe" },
    //   bgColor: { argb: "#ffffff" },
    // };
    // worksheet.getColumn(2).fill = {
    //   type: "pattern",
    //   pattern: "solid",
    //   fgColor: { argb: "#fefefe" },
    //   bgColor: { argb: "#ffffff" },
    // };
    // worksheet.getCell("A1").fill = {
    //   type: "pattern",
    //   pattern: "darkVertical",
    //   fgColor: { argb: "FFFF0000" },
    // };

    worksheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "Name", key: "name", width: 32 },
      { header: "D.O.B.", key: "dob", width: 15 },
    ];
    worksheet.addRow({ id: 1, name: "John Doe", dob: new Date(1970, 1, 1) });
    worksheet.addRow({ id: 2, name: "Jane Doe", dob: new Date(1965, 1, 7) });

    const firstCol = worksheet.getColumn(1);
    firstCol.eachCell((cell) => {
      worksheet.getCell(cell?.address).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F08080" },
      };
    });

    // save under export.xlsx
    var dt = new Date();
    const postfix = dt.getDate() + "." + (dt.getMonth() + 1) + "." + dt.getFullYear();
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "WHO_Report_" + postfix + ".xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });

    console.log("File is written");
  };
  return (
    <div>
      <button type="button" onClick={DownloadReport}>
        Download
      </button>
    </div>
  );
};

export default WHOReport;
