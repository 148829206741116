import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { dashboardAction, validation } from "../../../../../Imports";
import PaginationUI from "../../../../../Common/UI/PaginationUI";
import { FoodSafetyCountByEmirate } from "../DataMapper";

const CountByEmirateGrid = (props) => {
  const [data, setData] = useState(null);
  const emirateId = validation.getUserEmirateId();

  const getGridData = async (data, page) => {
    if (!data) return;
    props.setLoading(true);
    data.paginationDto.pageNo = page;

    console.log(data);
    setData((await dashboardAction.getIncidentCountByEmirate(data.DepartmentId, emirateId, data.fromDate, data.toDate, data.wfStatus)).data.ResponseData);

    props.setLoading(false);
  };
  const getSearchData = useCallback(async () => {
    console.log(props.searchData);
    await getGridData(props?.searchData, 1);
  }, [getGridData]);

  useEffect(() => {
    getSearchData();
  }, [props.isSearchChanged]);

  const exportToPDF = async () => {
    return FoodSafetyCountByEmirate(data);
  };
  return (
    <div>
      <div className="table-container col flex-fill">
        <div className="table-wrapper p-2 m-3 container-bg table-responsive">
          {data?.length === 0 && (
            <div className="text-center mt-5 search-message" id="nothingFoundMessage">
              <i className="ico icon-Group-420"></i>
              <p className="mt-4">
                {props.t("NoSearchResult")}
                <strong className="text-dark  "></strong>
              </p>
            </div>
          )}
          {data?.length > 0 && (
            <>
              <table className="table table-striped table-hover text-center m-0">
                <thead>
                  <tr>
                    <th>{props.t("emirate")}</th>
                    <th>{props.t("IncidentCount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((trans, index) => {
                    return (
                      <tr key={index}>
                        <td>{trans.Name}</td>
                        <td>{trans.Count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
          {data?.length > 0 && (
            <PaginationUI
              {...props}
              TotalItems={data.length}
              totalPages={1}
              currentPage={1}
              pageSize={1}
              showMax={10}
              getExcelReport={exportToPDF}
              ExcelFileName={"FoodSafetyCountByEmirate"}
              CustomHeader={""}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CountByEmirateGrid;
