import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { enums, iAnimalLV, encrypt, validation, auth, iIncident, animalLegalViolationAction, transAction, swal, toast } from "../../Imports";
import Attachment from "../Common/Attachment";
import ActionButton from "../Common/ActionButton";
import AuditLogs from "../Common/AuditLogs";
import IncidentStatusUpdate from "../Common/IncidentStatusUpdate";
import Reporter from "../Common/Reporter";
import GPSLocation from "../Common/LocationDetail/GPSLocation";
import EmirateGovEntity from "../Common/LocationDetail/EmirateGovEntity";
import ALVServiceDetail from "./Details/ALVServiceDetail";
import { useCallback } from "react";

const AnimalLegalViolation = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  followup = parseInt(encrypt.decrypt(followup));
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  useEffect(() => {
    // console.log(transaction);
  }, [transaction]);

  const getIncident = useCallback(async () => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      getIncidentTransaction(id);
    } else {
      props.setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getIncident();
  }, [id]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await animalLegalViolationAction.getAnimalLegalViolationTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;

      handleChangeDTO(res.AnimalLegalViolationDetail, iAnimalLV.ALV_Constants.ALVD);
      handleChange("ALVIncidentTypeLkp", res.ALVIncidentTypeLkp, iAnimalLV.ALV_Constants.ALVT);
      handleChange("Area", res.Area, iAnimalLV.ALV_Constants.ALVT);
      handleChange("Description", res.Description, iAnimalLV.ALV_Constants.ALVT);
      handleChange("ALVIncidentTypeId", res.ALVIncidentTypeId, iAnimalLV.ALV_Constants.ALVT);
      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);
      //validation.enableDisableForm("animalLVFrom", true);
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    console.log("reached to submit");
    e.preventDefault();
    const data = new iAnimalLV.AnimalLegalViolationTransactionDTO();
    const trans = transaction.AnimalLegalViolationTransactionDTO;
    data.Area = trans.Area;
    data.Description = trans.Description;
    data.ALVIncidentTypeId = trans.ALVIncidentTypeId;
    data.GovernmetnalEntityId = transaction.IncidentsMainTransactionDTO.GovernmetnalEntityId;
    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.AnimalLegalViolation, followup);
    data.AnimalLegalViolationDetail = transaction.AnimalLegalViolationDetailDTO;
    data.AnimalLegalViolationDetail.VeterinaryEstablishmentLkp = null;
    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }
    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await animalLegalViolationAction.createAnimalLegalViolation(data);
    else if (followup > 0) {
      res = await animalLegalViolationAction.createFollowUpAnimalLegalViolation(data);
    } else {
      res = await animalLegalViolationAction.updateAnimalLegalViolation(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };
  const validateDetailForm_public = (e) => {
    const inputFeilds_main = validation.validateForm("animalLVFrom");

    if (!inputFeilds_main || transaction.IncidentInfectionDetailsDTO === 0) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (!validation.captchaValidation(transaction, props)) {
      return false;
    }
    return true;
  };
  const validateDetailForm_user = (e) => {
    const actionBtnForm = validation.validateForm("actionbuttonForm");
    const animalLVFrom = validation.validateForm("animalLVFrom");
    if (!validation.commonValidation(transaction, props)) {
      return false;
    }
    if (!actionBtnForm || !validation.validateInvestigationDetailForm() || !animalLVFrom) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    return true;
  };
  const formValidation = () => {
    if (auth.checkLogin()) {
      return validateDetailForm_user();
    } else {
      return validateDetailForm_public();
    }
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("reportAnimalLegislationsViolationsIncident")}</h3>
            </div>
            <div className="col-sm-12">
              <Tabs defaultActiveKey="mainDetail">
                <Tab eventKey="mainDetail" title={props.t("reporterDetail")}>
                  <div className="tab-item-wrapper">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}

                    <div className="container ">
                      <Reporter handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="incidentDetail" title={props.t("IncidentDetail")}>
                  <div className="container animalLVFrom">
                    <div className="row container-bg my-2">
                      <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                      <EmirateGovEntity
                        {...props}
                        unitType={enums.UnitTypeEnum.GovernmentEntity}
                        department={enums.DepartmentEnum.AnimalDevelopmentAndHealthDepartment}
                        isDisabled={transaction.isUpdate}
                        value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                        emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                        handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                      />
                      <ALVServiceDetail handleChange={handleChange} {...props} />
                    </div>
                  </div>

                  <Attachment
                    required={true}
                    handleChangeDTO={handleChangeDTO}
                    code={enums.AttachmentEnum.Other}
                    canUploadFile={validation.isGovtUser()}
                    {...props}
                  />

                  {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}

                  <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimalLegalViolation;
