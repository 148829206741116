import axios from "axios";
import { URLs } from "../../Constants/Common";

export const CREATE_ANIMALHEALTH = "CREATE_ANIMALHEALTH";

export const createAnimalHealth = async (data) => {
  return await axios.post(URLs.CreateAnimalHealth, data);
};
export const updateAnimalHealth = async (data) => {
  return await axios.post(URLs.UpdateAnimalHealth, data);
};
export const createFollowUpAnimalHealth = async (data) => {
  return await axios.post(URLs.CreateFollowUp, data);
};
export const createFollowUpSharedDisease = async (data) => {
  return await axios.post(URLs.CreateFollowUpSharedDisease, data);
};

export const getIncidentsTransactionById = async (id) => {
  return await axios.get(URLs.getIncidentsTransactionById + "?id=" + id);
};
export const getAnimalHealthReport = async (data) => {
  return await axios.post(URLs.GetAnimalHealthReport, data);
};
export const getRelatedIncidents = async (incidentTransactionId) => {
  return await axios.get(
    URLs.GetRelatedIncidents + `?incidentTransactionId=${incidentTransactionId}`
  );
};
