import React from "react";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import { iAgrcultureBG, util } from "../../Imports";
import * as Control from "../FoodSafety/SharedControl";

const ShipmentDetail = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const ABGSD = iAgrcultureBG.ABG_Constants.ABGSD;
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, ABGSD);
  };
  const handleDateChange = (name, value) => {
    props.handleChange(name, value, ABGSD);
  };
  return (
    <div className="row container-bg my-2">
      <Control.ExporterCountry transaction={transaction[ABGSD]} changeControl={changeControl} />
      <Control.TransportMedia transaction={transaction[ABGSD]} changeControl={changeControl} />
      <div className="col-md-4">
        <FormInput
          name="Transporter"
          type="text"
          label="Tanker"
          required={true}
          disabled={false}
          value={transaction[ABGSD].Transporter}
          onChange={changeControl}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="LadingNoBill"
          type="text"
          label="LadingNoBill"
          required={true}
          disabled={false}
          value={transaction[ABGSD].LadingNoBill}
          onChange={changeControl}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="ShipmentArrivalDate"
          type="date"
          label="ArrivalDate"
          selectedDate={util.SelectedDate(transaction[ABGSD].ShipmentArrivalDate)}
          required={true}
          disabled={false}
          onChange={(date) => {
            handleDateChange("ShipmentArrivalDate", util.SetDate(date));
          }}
          maxDate={new Date()}
        />
      </div>
      <div className="col-md-4">
        <FormInput
          name="RejectionDate"
          type="date"
          label="RejectionDate"
          selectedDate={util.SelectedDate(transaction[ABGSD].RejectionDate)}
          required={true}
          disabled={false}
          onChange={(date) => {
            handleDateChange("RejectionDate", util.SetDate(date));
          }}
          maxDate={new Date()}
        />
      </div>
      <div className="col-md-12">
        <FormInput
          name="RejectionReason"
          type="textarea"
          label="RejectionReason"
          required={true}
          disabled={false}
          value={transaction[ABGSD].RejectionReason}
          onChange={changeControl}
        />
      </div>
    </div>
  );
};

export default ShipmentDetail;
