import React, { useCallback, useEffect, useState } from "react";
import { encrypt, notifyAction, util } from "../../../Imports";

const Notification = (props) => {
  const [data, setData] = useState([]);
  //const user = auth.getUserPermission();
  const getNotification = useCallback(async () => {
    const notification = await notifyAction.getAllNotification();
    setData(notification.ResponseData);
  }, []);

  useEffect(() => {
    getNotification();
  }, []);

  const handleMarkComplete = (id) => {
    // 1. Find the todo with the provided id
    const currentTodoIndex = data.findIndex((todo) => todo.id === id);
    // 2. Mark the todo as complete
    const updatedTodo = { ...data[currentTodoIndex], isComplete: true };
    // 3. Update the todo list with the updated todo
    const newTodos = [...data.slice(0, currentTodoIndex), updatedTodo, ...data.slice(currentTodoIndex + 1)];
    setData(newTodos);
  };

  const updateItem = (id) => {
    const newData = data.map((x) => {
      if (x.Id === id) {
        return { ...x, IsRead: true };
      }
      return x;
    });
    // console.log(newData);
    setData(newData);
  };
  const navigatePage = async (id, isRead, url) => {
    if (!isRead) {
      const res = await notifyAction.makeUnReadToReadNotification(id);
      if (res.ResponseData) {
        updateItem(id);
        window.open(url, "_blank", "noreferrer");
      }
    } else {
      window.open(url, "_blank", "noreferrer");
    }
  };
  return (
    <div className="d-flex flex-column justify-content-center search-page">
      <div className="page-title p-3 pl-4 d-flex flex-row justify-content-between">
        <h3>{props.t("Notification")}</h3>
      </div>
      <div className="container my-1">
        <div className="row">
          {data.length === 0 && (
            <div className="text-center mt-5 search-message" aria-hidden="false">
              <i className="fa fa-smile-o fa-3x"></i>
              <p className="mt-4"> {props.t("NoNewNotification")}</p>
            </div>
          )}
          {data.length > 0 &&
            data.map((x, i) => (
              <div key={i} className="my-1 container-bg" style={x.IsRead === true ? { backgroundColor: "white" } : {}}>
                <a
                  className="d-flex flex-row justify-content-start"
                  rel="noreferrer"
                  onClick={() => {
                    navigatePage(x.Id, x.IsRead, `${x.IncidentURL}${encrypt.encrypt(x.IncidentId)}/${encrypt.encrypt(0)}`);
                  }}
                >
                  {x.IsRead === true ? <i className="fa fa-envelope-open-o"></i> : <i className="fa fa-envelope-o"></i>}
                  <div className="mx-3 ">
                    <span className="mx-0 notification-link">{util.getProperText(x.TextAr, x.TextEn)}</span>
                  </div>
                  <div className="small text-sm-end">{util.getProperDate(x.CreatedDate)}</div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Notification;
