import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";

import * as Control from "../FoodSafety/SharedControl";

const ShipmentInitialData = (props) => {
  const transaction = useSelector((state) => state.transaction);
  const fsbgt = props.fsConst;
  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, fsbgt);
  };
  const loadCompLkp = useCallback(async () => {}, []);
  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);
  return (
    <div className="  my-2">
      <div className="row container-bg">
        <Control.ShipmentNumber transaction={transaction[fsbgt]} changeControl={changeControl} isRequired={true} />
        <Control.BorderPort transaction={transaction[fsbgt]} changeControl={changeControl} emirateId={transaction.IncidentsMainTransactionDTO.EmirateId} />
        <div className="col-md-4">
          <FormInput
            name="ContainerNo"
            type="text"
            label="ContainerNumber"
            required={true}
            disabled={false}
            value={transaction[fsbgt].ContainerNo}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            name="ShipmentCertificateNo"
            type="text"
            label="ShipmentCertificateNumber"
            required={true}
            disabled={false}
            value={transaction[fsbgt].ShipmentCertificateNo}
            onChange={changeControl}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipmentInitialData;
