import {
  iAgrculturePest,
  iAnimalHealth,
  iAnimalLV,
  iIncident,
  iAnimalSD,
  iFoodSafety,
  iAnimalBG,
  iFoodSafetyBG,
  iAgrcultureBG,
  iEnvIncident,
} from "../../Components/Imports";
import { DispatchTypes } from "../DispatchType";

const initialState = {
  ...iIncident.IncidentTransactionState,
  ...iAnimalLV.IncidentTransactionState,
  ...iAnimalHealth.IncidentTransactionState,
  ...iAgrculturePest.IncidentTransactionState,
  ...iAgrcultureBG.IncidentTransactionState,
  ...iAnimalSD.IncidentTransactionState,
  ...iAnimalBG.IncidentTransactionState,
  ...iFoodSafety.IncidentTransactionState,
  ...iFoodSafetyBG.IncidentTransactionState,
  ...iEnvIncident.IncidentTransactionState,
};
const TransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DispatchTypes.SET_TRANSACTION_DTO:
      return {
        ...state,
        [action.transactionState]: action.transactionDto,
      };
    case DispatchTypes.SET_NEW_TRANSACTION:
      return initialState;

    case DispatchTypes.SET_TRANSACTION_NAME:
      return {
        ...state,
        [action.transactionState]: {
          ...state[action.transactionState],
          [action.transactionName]: action.transactionDto,
        },
      };
    default:
      return state;
  }
};

export default TransactionReducer;
