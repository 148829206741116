import axios from "axios";
import { URLs } from "../../Constants/Common";

export const CreateDocumentLibrary = async (data) => {
  return await axios.post(URLs.CreateDocumentLibrary, data);
};

export const GetDocumentLibrary = async (departmentId, documentTypeId) => {
  return await axios.get(`${URLs.GetDocumentLibrary}?departmentId=${departmentId}&documentTypeId=${documentTypeId}`);
};
export const GetDocumentLibraryById = async (id) => {
  return await axios.get(`${URLs.GetDocumentLibraryById}?id=${id}`);
};
export const EnableDisabledDocumentLib = async (id) => {
  return await axios.get(`${URLs.EnableDisabledDocumentLib}?id=${id}`);
};
