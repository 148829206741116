import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormInput, { SelectInput } from "../../../Common/Validation/FormInput";
import { iAnimalSD, master } from "../../../Imports";

const DiseaseData = (props) => {
  const diseaseConst = iAnimalSD.ASD_Constants.ASDT;
  const [animalDisease, setAnimalDisease] = useState([]);
  const transaction = useSelector((state) => state.transaction);
  const loadLkp = async () => {
    setAnimalDisease(await master.getAnimalDiseaseLkp(true));
  };
  useEffect(() => {
    loadLkp();
  }, []);
  useEffect(() => {
    setTimeout(() => {}, 100);
  }, [transaction]);

  const changeControl = (e) => {
    props.handleChange(e.target.name, e.target.value, diseaseConst);
  };
  return (
    <div className="container  my-3  DiseaseDataForm">
      <div className="row container-bg">
        <div className="col-md-4">
          <SelectInput
            name="AnimalDiseaseLkpId"
            label="DiseaseName_Cause"
            required={true}
            value={transaction[diseaseConst].AnimalDiseaseLkpId}
            onChange={changeControl}
            data={animalDisease}
          />
        </div>
        <div className="col-md-4">
          <FormInput
            label="NoOfInfectedCases"
            name="NoOfInfectedPeople"
            type="text"
            required={true}
            value={transaction[diseaseConst].NoOfInfectedPeople}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-12">
          <FormInput
            label="CaseDescription"
            name="IncidentDescription"
            type="textarea"
            required={true}
            value={transaction[diseaseConst].IncidentDescription}
            onChange={changeControl}
          />
        </div>
        <div className="col-md-12">
          <FormInput label="Comments" name="Notes" type="textarea" required={false} value={transaction[diseaseConst].Notes} onChange={changeControl} />
        </div>
      </div>
    </div>
  );
};

export default DiseaseData;
