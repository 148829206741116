import React, { useState } from "react";
import { SelectInput } from "../Common/Validation/FormInput";
import { enums, master, swal, toast, util } from "../Imports";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useCallback } from "react";

const AnimalDiseaseConfig = (props) => {
  const initialState = {
    AnimalCatagoryId: "",
    AnimalTypeId: "",
    AnimalTypeName: "",
    AnimalTypeScientificName: "",
  };

  const [localVal, setLocalVal] = useState(initialState);
  const [animalTypeData, setAnimalTypeData] = useState([]);
  const [diseasesData, setDiseasesData] = useState([]);
  const [allDiseases, setAllDiseases] = useState([]);
  const [tempDisease, setTempDisease] = useState([]);

  const animalCategoryData = useSelector((state) => state.masterData.animalCatagoryData);

  const dispatch = useDispatch();
  const changeAnimalCatagory = async (e) => {
    if (e.target.value) {
      var index = e.nativeEvent.target.selectedIndex;
      setLocalVal((p) => ({
        ...p,
        AnimalCatagoryId: e.target.value,
        AnimalCatagoryName: e.nativeEvent.target[index].text,
        AnimalTypeScientificName: "",
      }));
      const res = await master.getAnimalType(e.target.value);
      setAnimalTypeData(res);
    }
  };
  const changeAnimalType = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    setLocalVal((p) => ({
      ...p,
      AnimalTypeId: e.target.value,
      AnimalTypeName: e.nativeEvent.target[index].text,
      AnimalTypeScientificName: e.nativeEvent.target[index].getAttribute("data-code"),
    }));
    loadSymptomDiseaseData(localVal.AnimalCatagoryId, e.target.value);
  };
  const loadSymptomDiseaseData = async (animalCatagoryId, animalId) => {
    const res = await master.getAnimalTypeDiseases(animalId, animalCatagoryId);
    //console.log(res);
    const resAll = await master.getAnimalDiseaseLkp();
    //console.log(resAll);
    setAllDiseases(resAll);
    setDiseasesData(res);
  };

  const OnChangeDiseases = async (value, e) => {
    //console.log(value);
    var tempList = {
      AnimalDiseaseId: value.Id,
      AnimalTypeId: localVal.AnimalTypeId,
    };
    let data;
    tempList = {
      AnimalDiseaseId: value.Id,
      AnimalTypeId: localVal.AnimalTypeId,
    };
    if (e.target.checked) {
      setDiseasesData(
        diseasesData.concat({
          Id: value.Id,
          NameAr: value.NameAr,
          NameEn: value.NameEn,
          IsSharedDisease: value.Code,
        })
      );
      data = await master.addAnimalDisease(tempList);
    } else {
      setDiseasesData(diseasesData.filter((x) => x.Id !== value.Id));
      data = await master.removeAnimalTypeDisease(tempList);
    }
    if (data) {
      toast(props.t("updatedSuccessFully"), { type: "success" });
    }
  };

  useEffect(() => {
    //console.log("diseasesData", diseasesData);
  }, [diseasesData]);

  const loadCompLkp = useCallback(async () => {
    dispatch(master.getAnimalCatagry());
  }, [dispatch]);

  useEffect(() => {
    loadCompLkp();
  }, [loadCompLkp]);

  return (
    <div>
      <div className="container page-title p-3 pl-4 mb-3">
        <h3>{props.t("updateSystemSetting")}</h3>
      </div>
      <div className="container ">
        <div className="row container-bg">
          <div className="col-md-3">
            <SelectInput
              name="AnimalCatagoryId_2"
              label="animalCatagory"
              required={true}
              value={localVal.AnimalCatagoryId}
              onChange={changeAnimalCatagory}
              data={animalCategoryData}
            />
          </div>
          <div className="col-md-3">
            <SelectInput
              name="AnimalTypeId"
              label="animalType"
              required={true}
              value={localVal.AnimalTypeId}
              onChange={changeAnimalType}
              data={animalTypeData}
            />
          </div>
          {allDiseases.length > 0 && (
            <div className="col-md-12">
              <div className="row mt-3">
                <label className="mb-2">
                  <strong>{props.t("diseaseName")}</strong>
                  <span className="red">*</span>
                </label>

                {allDiseases.map((x, i) => (
                  <div className="col-md-3" key={i}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={diseasesData.some((t) => t.Id === x.Id)}
                        id={`disease_${x.Id}`}
                        onChange={(e) => OnChangeDiseases(x, e)}
                        data-shared={x.Code}
                      />
                      <label className="form-check-label" htmlFor={`disease_${x.Id}`}>
                        {util.getTextFromObj(x)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnimalDiseaseConfig;
