var base64 = require("base-64");
var utf8 = require("utf8");

export const encrypt = (text) => {
  text = text.toString();
  var bytes = utf8.encode(text);
  return base64.encode(bytes);
};

export const decrypt = (text) => {
  var bytes = base64.decode(text);
  return utf8.decode(bytes);
};
