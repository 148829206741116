import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import fileImg from "../../../Theme/img/paper.png";
import { iIncident, commonAction, urls, auth, toast, validation } from "../../Imports";
import ShowFiles from "./ShowFiles";

const Attachment = ({ code, topic, handleChangeDTO, required, colWidth, ...props }) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [isFileSubmitAllowed, setIsFileSubmitAllowed] = useState(false);
  const [fileHandle, setFileHandle] = useState([]);
  const [canUploadFile, setCanUploadFile] = useState(true);
  const [openFilePopup, setOpenFilePopup] = useState(false);
  const [fileURL, setFileUR] = useState("");
  const hiddenFileInput = React.useRef(null);
  const transaction = useSelector((state) => state.transaction);
  const user = auth.getUserPermission();

  const filterBySize = (size) => {
    console.log(size);
    return size < urls.constants.allowedFileSize;
  };

  const openFileBrowser = (e) => {
    hiddenFileInput.current.click();
  };
  const onFileUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append(`files`, selectedFile[i], selectedFile[i].name);
    }

    if (isFileSubmitAllowed) {
      console.log("submitted file");
      if (selectedFile.length > 0) uploadFiles(formData);
    }
  };
  const uploadFiles = async (formData) => {
    let res = null;
    if (transaction.isUpdate && transaction[iIncident.SharedDTO.IT].Id > 0) {
      res = await commonAction.incidentUploadFiles(formData, transaction[iIncident.SharedDTO.IT].Id, code);
    } else {
      res = await commonAction.uploadMultipleFiles(formData);
    }
    if (res.data) {
      setFileHandle(fileHandle.concat(res.data.ResponseData));
      const finalAtt = res.data.ResponseData.map((x) => {
        return new iIncident.IncidentAttachment(x.Id, code);
      });
      console.log(finalAtt);
      handleChangeDTO(
        transaction[iIncident.SharedDTO.FA].concat(finalAtt),
        //finalAtt,
        iIncident.SharedDTO.FA
      );
      props.setLoading(false);
    }
  };

  useEffect(() => {
    if (transaction.isUpdate) {
      const fileAttDto = transaction.FileAttachmentDTO.filter((x) => x.Code === code);
      console.log("fileAttDto", fileAttDto);
      setFileHandle(
        fileAttDto.map((x) => {
          return x.FileAttachment;
        })
      );
    } else {
      setFileHandle([]);
    }
    setCanUploadFile(!transaction.isUpdate || props.canUploadFile);
  }, [transaction.isUpdate]);

  const isAllowedFileType = (file) => {
    console.log(file.type);
    return (
      file.type.match("image.*") ||
      file.type.match("video.*") ||
      file.type.match("audio.*") ||
      file.type.match("pdf.*") ||
      file.type.match("sheet.*") ||
      file.type.match("word.*")
    );
  };
  const onFileChange = async (e) => {
    e.preventDefault();
    let files = e.target.files;
    if (files.length === 0) return false;
    for (let i = 0; i < files.length; i++) {
      if (!isAllowedFileType(files[i])) {
        setIsFileSubmitAllowed(false);
        toast(props.t("fileDoesNotSupport"), { type: "error" });
        return false;
      }
      if (!filterBySize(files[i].size)) {
        setIsFileSubmitAllowed(false);
        toast(props.t("fileSizeSupport"), { type: "error" });
        return false;
      }
    }
    props.setLoading(true);
    setIsFileSubmitAllowed(true);
    setSelectedFile(files);
    setTimeout(() => {
      e.target.value = null;
    }, 1000);
  };
  const handleDeleteFile = async (fileId) => {
    console.log("fileId", fileId);
    //const fileId = e.target.getAttribute("data-fileid");
    let res = null;
    if (transaction.isUpdate && transaction[iIncident.SharedDTO.IT].Id > 0) {
      res = await commonAction.deleteIncidentFiles(transaction[iIncident.SharedDTO.IT].Id, fileId);
    } else {
      res = await commonAction.deleteFiles(fileId);
    }

    if (res.data) {
      console.log(res.data);
      var filToDelete = fileHandle.filter((x) => x.FileId === fileId);
      var resultFiles = fileHandle.filter((x) => x.FileId !== fileId);

      setFileHandle(resultFiles);
      handleChangeDTO(
        transaction[iIncident.SharedDTO.FA].filter((x) => x.FileAttachmentId !== filToDelete[0].Id),
        iIncident.SharedDTO.FA
      );
    }
  };

  const openFileInPopup = (url) => {
    //console.log(url);
    setFileUR(url);
    setOpenFilePopup(true);
  };

  useEffect(() => {
    if (selectedFile) {
      onFileUpload();
      setSelectedFile("");
    }
  }, [selectedFile]);

  return (
    <div className="container ">
      {openFilePopup && (
        <ShowFiles
          showModal={true}
          fileURL={fileURL}
          modalStyle="modal-style-file"
          handleShowModal={() => {
            setOpenFilePopup(false);
          }}
        />
      )}

      <div className="row my-1 container-out-bg">
        {topic ? (
          <>
            <label className=" ">
              <strong>{props.t(topic)}</strong>
              {required && <span className="red"> *</span>}
            </label>
          </>
        ) : (
          <label className="control-title my-2 mb-2">
            <strong>{props.t("attachments")}</strong>
            {required && <span className="red"> *</span>}
            <small className="mx-4 file_desc">
              {props.t("allowedExtension")} {urls.constants.allowedFileExt}
            </small>
          </label>
        )}

        <div className="col-12">
          <div className="form-group">
            <div className="upload-file1">
              <div className="input-group">
                {selectedFile && fileHandle && <span className="red">{props.t("fileUploadingProblem")}</span>}

                {canUploadFile && (
                  <div className="input-group-append">
                    <label className="create-request align-self-start p-2 pl-3 pr-3 ml-3 mt-2" onClick={openFileBrowser}>
                      <span className="file-input-btn">
                        <i className="fa fa-plus-square fa-icon mx-1" aria-hidden="true"></i>
                        {props.t("selectFiles")}
                      </span>
                    </label>

                    <input
                      type="file"
                      name="uploadControl"
                      multiple
                      ref={hiddenFileInput}
                      className="d-none uploadControl"
                      accept={urls.constants.allowedFile}
                      onChange={onFileChange}
                    />
                  </div>
                )}
              </div>
              {fileHandle.length > 0 && (
                <div className="row my-3">
                  {fileHandle.map((e) => (
                    <div className={`col-md-${!colWidth ? "3" : colWidth}`} id={e.FileId} key={e.FileId}>
                      <div className="img-box">
                        {(e.CreatedBy === user.Name || validation.isUserNotLoggedIn()) && (
                          <div className="closebtn">
                            <i
                              style={{ cursor: "pointer", color: "red" }}
                              className="fa fa-close"
                              onClick={() => {
                                handleDeleteFile(e.FileId);
                              }}
                            ></i>
                          </div>
                        )}
                        <img src={e.ThumbPath} width={100} alt="" />
                        {/* <a href={e.FilePath} target="_blank" rel="noreferrer" onClick={openFileInPopup}> */}
                        <a
                          rel="noreferrer"
                          onClick={() => {
                            openFileInPopup(e.FilePath);
                          }}
                        >
                          <span>{e.FileName.length > 13 ? e.FileName.substr(0, 12) : e.FileName}</span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {transaction[iIncident.SharedDTO.IFA]?.length > 0 && (
                <div className="row my-3">
                  {transaction[iIncident.SharedDTO.IFA].map((e, i) => (
                    <div className={`col-md-3`} id={i} key={i}>
                      <div className="img-box-file">
                        <img src={fileImg} width={100} alt="" />
                        <a href={e.FileURL} target="_blank" rel="noreferrer">
                          <span>{e.FileName.length > 13 ? e.FileName.substr(0, 12) : e.FileName}</span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attachment;
