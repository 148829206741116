export const ABD_Constants = {
  ABGT: "AnimalBorderGateTransactionDTO",
  ABGAD: "AnimalBorderGateAnimalDetailDTO",
  ABGSD: "AnimalBorderGateShipmentDetailDTO",
};

export class AnimalBorderGateTransactionDTO {
  constructor(
    IncidentsTransactionId,
    IncidentsTransaction,
    ShipmentNo,
    ContainerNo,
    ShipmentCertificateNo,
    ViolationReason,
    Comments,
    AnimalBorderGateAnimalDetail,
    AnimalBorderGateShipmentDetail
  ) {
    this.IncidentsTransactionId = IncidentsTransactionId;
    this.IncidentsTransaction = IncidentsTransaction;
    this.ShipmentNo = ShipmentNo;
    this.ContainerNo = ContainerNo;
    this.ShipmentCertificateNo = ShipmentCertificateNo;
    this.ViolationReason = ViolationReason;
    this.Comments = Comments;
    this.AnimalBorderGateAnimalDetail = AnimalBorderGateAnimalDetail;
    this.AnimalBorderGateShipmentDetail = AnimalBorderGateShipmentDetail;
  }
}
export class AnimalBorderGateAnimalDetailDTO {
  constructor(
    DestinationCountryId,
    DestinationCountryName,
    SourceCountryId,
    SourceCountryName,
    EserviceAnimalId,
    EserviceAnimalName,
    EserviceCategoryId,
    EserviceAnimalCatagoryName,
    AnimalNo,
    Unit,
    Comments,
    Uid
  ) {
    this.DestinationCountryId = DestinationCountryId;
    this.DestinationCountryName = DestinationCountryName;
    this.SourceCountryId = SourceCountryId;
    this.SourceCountryName = SourceCountryName;
    this.EserviceAnimalId = EserviceAnimalId;
    this.EserviceCategoryId = EserviceCategoryId;
    this.EserviceAnimalName = EserviceAnimalName;
    this.EserviceAnimalCatagoryName = EserviceAnimalCatagoryName;
    this.AnimalNo = AnimalNo;
    this.Unit = Unit;
    this.Comments = Comments;
    this.Uid = Uid ?? Date.now();
  }
}
export class AnimalBorderGateShipmentDetailDTO {
  constructor(AnimalBorderGateTransactionId, AnimalBorderGateTransaction, ShipmentDate, ImporterName, ImporterPhone, ImporterEmail, ShipmentRejectionDate) {
    this.AnimalBorderGateTransactionId = AnimalBorderGateTransactionId;
    this.AnimalBorderGateTransaction = AnimalBorderGateTransaction;
    this.ShipmentDate = ShipmentDate;
    this.ImporterName = ImporterName;
    this.ImporterPhone = ImporterPhone;
    this.ImporterEmail = ImporterEmail;
    this.ShipmentRejectionDate = ShipmentRejectionDate;
  }
}

export class AnimalBorderGateAnimalDetail {
  constructor(
    AnimalBorderGateTransactionId,
    AnimalBorderGateTransaction,
    DestinationCountryId,
    DestinationCountryLkp,
    SourceCountryId,
    SourceCountryLkp,
    EserviceAnimalId,
    EserviceAnimalLkp,
    AnimalNo,
    Unit,
    Comments
  ) {
    this.AnimalBorderGateTransactionId = AnimalBorderGateTransactionId;
    this.AnimalBorderGateTransaction = AnimalBorderGateTransaction;
    this.DestinationCountryId = DestinationCountryId;
    this.DestinationCountryLkp = DestinationCountryLkp;
    this.SourceCountryId = SourceCountryId;
    this.SourceCountryLkp = SourceCountryLkp;
    this.EserviceAnimalId = EserviceAnimalId;
    this.EserviceAnimalLkp = EserviceAnimalLkp;
    this.AnimalNo = AnimalNo;
    this.Unit = Unit;
    this.Comments = Comments;
  }
}
export const IncidentTransactionState = {
  AnimalBorderGateTransactionDTO: new AnimalBorderGateTransactionDTO(),
  AnimalBorderGateAnimalDetailDTO: [],
  AnimalBorderGateShipmentDetailDTO: new AnimalBorderGateShipmentDetailDTO(),
  AnimalBorderGateAttachmentDTO: [],
};
