import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FormInput from "../../Common/Validation/FormInput";
import RedirectAction from "./WorkFlow/RedirectAction";
import TransferAction from "./WorkFlow/TransferAction";
import ConfirmAction from "./WorkFlow/ConfirmAction";
import { iIncident, iFoodSafety, enums, encrypt, auth, workflowAction, validation, incidentAction, util } from "../../Imports";
import { URLRoutes } from "../../Routes/Routes";
import CirculateDetail from "../AnimalHealth/InvestigationDetail/CirculateDetail";
import CaptchaUI from "../../Common/UI/CaptchaUI";
import RiskDegree from "./RiskDegree";
import Attachment from "./Attachment";

const ActionButton = (props) => {
  let { id, followup } = useParams();
  followup = parseInt(encrypt.decrypt(followup));
  id = parseInt(encrypt.decrypt(id));
  const user = auth.getUserPermission();
  const navigate = useNavigate();
  //let location = useLocation();

  const [btnAction, setBtnAction] = useState([]);
  const [showComponent, setShowComponent] = useState(true);
  const [isConfirmBtn, setIsConfirmBtn] = useState(false);
  const [isShowFollowUpBtn, setIsShowFollowUpBtn] = useState(false);
  const [followUpURL, setFollowUpURL] = useState("");
  const [isRedirectBtn, setIsRedirectBtn] = useState(false);
  const [isTransferBtn, setIsTransferBtn] = useState(false);
  const [isReOpenBtn, setIsReOpenBtn] = useState(false);
  const [isCirculation, setIsCirculation] = useState(false);
  const [isCirculationNotification, setIsCirculationNotification] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const [showRiskDegree, setShowRiskDegree] = useState(false);
  const [isPositiveOrNegAction, setIsPositiveOrNegAction] = useState(false);

  const transaction = useSelector((state) => state.transaction);
  //const [wfInitialState, setWfInitialState] = useState("");

  const handleSubmitForm = (e) => {
    props.handleSubmitForm(e);
  };
  const handleCancelBtn = () => {
    try {
      window.close();
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    } catch {}
  };

  const onChangeActionCode = (e) => {
    const value = e.target.value;
    const incidentType = transaction[iIncident.SharedDTO.IT].IncidentTypeCode;
    props.handleChange(e.target.name, value, iIncident.SharedDTO.IT);
    setIsConfirmBtn(value === enums.WorkFlowActionCodeEnum.Confirm);

    setIsRedirectBtn(value === enums.WorkFlowActionCodeEnum.Redirect);

    setIsTransferBtn(value === enums.WorkFlowActionCodeEnum.Transfer);

    setIsReOpenBtn(value === enums.WorkFlowActionCodeEnum.ReOpen);

    setIsShowFollowUpBtn(
      value === enums.WorkFlowActionCodeEnum.FollowUp &&
        ((incidentType === enums.IncidentTypeEnum.AnimalHealth && followup === 0) || incidentType === enums.IncidentTypeEnum.FoodSafetyIncident)
    );
    setFollowUpURL(
      incidentType === enums.IncidentTypeEnum.AnimalHealth
        ? URLRoutes.getAnimalHealthIncident(encrypt.encrypt(id), encrypt.encrypt(1))
        : incidentType === enums.IncidentTypeEnum.FoodSafetyIncident
        ? URLRoutes.getFoodSafetyFollowUp(encrypt.encrypt(id), encrypt.encrypt(0))
        : ""
    );
    setIsCirculation(
      value === enums.WorkFlowActionCodeEnum.Circulate ||
        value === enums.WorkFlowActionCodeEnum.CirculateInternal ||
        transaction.IncidentCirculationHistoryDTO.filter((x) => x.IsCircularNotification === false).length > 0
    );

    setIsCirculationNotification(
      (value === enums.WorkFlowActionCodeEnum.Circulate && validation.isFoodSafetyDept()) ||
        transaction.IncidentCirculationHistoryDTO.filter((x) => x.IsCircularNotification === true).length > 0
    );

    setIsPositiveOrNegAction(value === enums.WorkFlowActionCodeEnum.Positive || value === enums.WorkFlowActionCodeEnum.Negative);
  };

  const isInitiatorAndCompletedByOtherGovEntity = async () => {
    let isOtherEntityCompleted = await incidentAction.validateFinalStepFromOtherEntities(transaction?.IncidentsMainTransactionDTO.Id);
    return isOtherEntityCompleted.data.ResponseData;
  };

  const getWorkFlowActionsExtension = async () => {
    var condition = user.UnitType ? user.UnitType : user.UserAccountType;
    const wfActions = await workflowAction.getWorkFlowActions(
      transaction[iIncident.SharedDTO.IT].IncidentTypeCode,
      transaction[iIncident.SharedDTO.IT].StatusCode,
      followup > 0 ? `${user.UnitType}_${enums.WorkFlowActionCodeEnum.FollowUp}` : condition
    );

    if (wfActions.data) {
      console.log("wfActions", wfActions.data.ResponseData);
      let res = wfActions.data.ResponseData;

      // if the current incident has not the same initiator then hide close action. - for only FS dept.
      if (validation.isFoodSafetyDept() && !(await isInitiatorAndCompletedByOtherGovEntity())) {
        res = res.filter((x) => x.WFActionCode !== enums.WorkFlowActionCodeEnum.Close);
      }

      //if the initiator is gov user, then hide the redirect and reopen option to min user.
      if ((await validation.isInitiatorGovtUser(transaction)) && res.findIndex((x) => x.WFActionCode === enums.WorkFlowActionCodeEnum.Redirect) > -1) {
        res = res.filter((x) => x.WFActionCode !== enums.WorkFlowActionCodeEnum.Redirect);
      }
      if (btnAction.findIndex((x) => x.WFActionCode.Code === enums.WorkFlowActionCodeEnum.Save) > -1) {
        // console.log("WfActionCode", btnAction);
        props.handleChange("WfActionCode", enums.WorkFlowActionCodeEnum.Save, iIncident.SharedDTO.IT);
      }
      setBtnAction(res);
    }
  };
  const loadComLkp = useCallback(async () => {
    var isValid = transaction.isUpdate ? await validation.isValidToSeeIncident(id) : true;

    if (transaction.isUpdate && !isValid) {
      setShowComponent(false);
    }
    //hide action button - incase FS notification type is "toBeInformed". or if its mark as notification in circulation history.
    if (validation.isFoodSafetyDept() && validation.isGovtUser()) {
      const fsNotificationType = transaction[iFoodSafety.FS_Constants.FST].NotificationTypeId === enums.FSNotificationTypeEnum.ToBeInformed;
      if (transaction[iFoodSafety.FS_Constants.FST].NotificationTypeId && fsNotificationType) {
        setShowComponent(false);
      }
      if (
        transaction[iIncident.SharedDTO.ICH].length > 0 &&
        transaction[iIncident.SharedDTO.ICH].filter((x) => x.IsCircularNotification === true && x.GovernmentalEntityId === parseInt(user.GovEntityId)).length >
          0
      ) {
        console.log("enter to circulation mode");
        setShowComponent(false);
      }
    }
    if (transaction.isUpdate) {
      const incidentType = transaction[iIncident.SharedDTO.IT].IncidentTypeCode;
      getWorkFlowActionsExtension();
      setIsCirculation(transaction.IncidentCirculationHistoryDTO.filter((x) => x.IsCircularNotification === false).length > 0);
      setIsCirculationNotification(transaction.IncidentCirculationHistoryDTO.filter((x) => x.IsCircularNotification === true).length > 0);
      setShowSubmitBtn(transaction[iIncident.SharedDTO.IT].StatusCode !== enums.WorkFlowStatusCodeEnum.FinallyClosed);

      setShowRiskDegree(
        user.UnitType === enums.UnitTypeEnum.MinistryUser &&
          transaction.StatusCode !== enums.WorkFlowStatusCodeEnum.New &&
          (incidentType === enums.IncidentTypeEnum.AnimalHealth ||
            incidentType === enums.IncidentTypeEnum.FoodSafetyIncident ||
            incidentType === enums.IncidentTypeEnum.AgriculturePestIncident)
      );
    } else {
      setIsShowFollowUpBtn(false);
      setBtnAction([]);
    }
  }, [transaction.isUpdate]);
  useEffect(() => {
    loadComLkp();
  }, [transaction.isUpdate]);

  return (
    <>
      {(isPositiveOrNegAction ||
        (transaction.IncidentsMainTransactionDTO.IncidentTypeCode === enums.IncidentTypeEnum.AgriculturePestIncident &&
          transaction.FileAttachmentDTO.findIndex((x) => x.Code === enums.AttachmentEnum.LabTestReport) > -1)) && (
        <div className="mb-3">
          <Attachment
            required={true}
            topic="LabReportAttachment"
            handleChangeDTO={props.handleChangeDTO}
            code={enums.AttachmentEnum.LabTestReport}
            canUploadFile={validation.isGovtUser()}
            {...props}
          />
        </div>
      )}
      <div className="container ">
        <div className=" ">
          {isCirculation && <CirculateDetail title={"CirculationHistory"} IsCircularNotification={false} {...props} />}

          {isCirculationNotification && <CirculateDetail title={"CirculationNotification"} IsCircularNotification={true} {...props} />}
        </div>
      </div>
      {showComponent && (
        <div className="container my-2 ">
          {showRiskDegree && <RiskDegree handleChange={props.handleChange} handleChangeDTO={props.handleChangeDTO} {...props} />}

          <div className="row col-md-12">
            {btnAction.length > 0 &&
              btnAction.findIndex((x) => x.WorkFlowActionNameLkp.Code === enums.WorkFlowActionCodeEnum.Save) < 0 &&
              transaction.isUpdate && (
                <>
                  <h6>
                    {btnAction.findIndex((x) => x.WorkFlowActionNameLkp.Code === enums.WorkFlowActionCodeEnum.Positive) > -1 ? (
                      <strong>{props.t("labTestResult")}</strong>
                    ) : (
                      <strong>{props.t("Decision")}</strong>
                    )}
                  </h6>
                  <div className="row my-2">
                    {btnAction.map((btn, i) => {
                      const name = util.getTextFromObj(btn.WorkFlowActionNameLkp);
                      return (
                        <div className="col-md-2" key={btn.Id}>
                          <div className="form-check">
                            <FormInput
                              style={{
                                Color: `${btn.WorkFlowActionNameLkp.Color}`,
                              }}
                              key={btn.Id}
                              type="radio"
                              required={false}
                              id={btn.WFActionCode}
                              name="WfActionCode"
                              onChange={onChangeActionCode}
                              htmlFor={name}
                              value={btn.WFActionCode}
                              data-actioncode={btn.WFActionCode}
                              data-nextstate={btn.NextStateId}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
          </div>

          {followup === 0 && (transaction.StatusCode !== enums.WorkFlowStatusCodeEnum.New || validation.isUserLoggedIn()) && <ConfirmAction {...props} />}

          {isRedirectBtn && <RedirectAction {...props} />}
          {isTransferBtn && <TransferAction {...props} />}

          <div className="col-md-12 row  mt-4">
            {isShowFollowUpBtn && (
              <div className="col-md-3">
                <a rel="noreferrer" href={followUpURL} target="_blank" className="btn btn-success mx-3">
                  {props.t(`AddInvestigationReport`)}
                </a>
              </div>
            )}
            {transaction.StatusCode === enums.WorkFlowStatusCodeEnum.Confirmed &&
              transaction[iIncident.SharedDTO.IT].IncidentTypeCode === enums.IncidentTypeEnum.AnimalSharedDisease &&
              user.UnitType === enums.UnitTypeEnum.GovernmentEntity && (
                <div className="col-md-3">
                  {/* <a
                    rel="noreferrer"
                    href={`/animalHealthincident/${encrypt.encrypt(0)}/${encrypt.encrypt(0)}/${id}`}
                    target="_blank"
                    className="btn btn-success mx-3"
                  >
                    {props.t(`AddInvestigationReport`)}
                  </a> */}
                </div>
              )}

            {showSubmitBtn && !isShowFollowUpBtn && (!transaction.isUpdate || btnAction.length > 0) && (
              <>
                {/* {validation.isUserNotLoggedIn() && (
                  <div className="mb-5">
                    <CaptchaUI handleChangeDTO={props.handleChangeDTO} {...props} />
                  </div>
                )} */}
                <div className="col-md-3">
                  <button className="btn btn-success mx-3" type="button" data-actioncode={enums.WorkFlowActionCodeEnum.Submit} onClick={handleSubmitForm}>
                    {props.t(`${enums.WorkFlowActionCodeEnum.Submit}`)}
                  </button>
                </div>
              </>
            )}
            <div className="col-md-3">
              <button className="btn btn-success mx-3 cancel-btn-mobile" type="button" onClick={handleCancelBtn}>
                {props.t("cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
      {!showComponent && (
        <div className="container">
          <button className="btn btn-success mx-3 onlyCancelBtn" type="button" onClick={handleCancelBtn}>
            {props.t("cancel")}
          </button>
        </div>
      )}
    </>
  );
};

export default ActionButton;
