import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { enums, encrypt, validation, auth, iIncident, foodSafetyAction, transAction, swal, iFoodSafety } from "../../../Imports";
import { useDispatch, useSelector } from "react-redux";
import Attachment from "../../Common/Attachment";
import ActionButton from "../../Common/ActionButton";
import AuditLogs from "../../Common/AuditLogs";
import IncidentStatusUpdate from "../../Common/IncidentStatusUpdate";
import Reporter from "../../Common/Reporter";
import GPSLocation from "../../Common/LocationDetail/GPSLocation";
import EmirateGovEntity from "../../Common/LocationDetail/EmirateGovEntity";
import ServiceData from "./ServiceData";
import NotificationType from "../NotificationType";
import CompanyInfo from "./CompanyInfo";
import ProductInfo from "./ProductInfo";
import RiskInfo from "./RiskInfo";
import AllAttachment from "../AllAttachment";
import ActionTaken from "./ActionTaken";

const FoodSafety = (props) => {
  let { id, followup } = useParams();
  id = parseInt(encrypt.decrypt(id));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  followup = parseInt(encrypt.decrypt(followup));
  const user = auth.getUserPermission();
  const transaction = useSelector((state) => state.transaction);

  const [showFromAfterLogin, setShowFromAfterLogin] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 3;

  const handleChangeDTO = (dto, state) => {
    dispatch(transAction.setTransactionDto(dto, state));
  };
  const handleChange = (name, value, state) => {
    dispatch(transAction.setTransactionbyName(name, value, state));
  };

  useEffect(() => {
    // console.log(transaction);
  }, [transaction.FoodSafetyCompanyDetailDTO]);

  useEffect(() => {
    dispatch(transAction.newTransaction());
    if (id > 0) {
      getIncidentTransaction(id);
    } else {
      setShowFromAfterLogin(
        (validation.isUserLoggedIn() && transaction.FoodSafetyCompanyDetailDTO.Id === 0) || user.NonGovTypeCode === enums.NonGovUserTypeEnum.FoodFacility
      );
      props.setLoading(false);
    }
  }, [id, dispatch, showFromAfterLogin]);

  const getIncidentTransaction = async (id) => {
    props.setLoading(true);
    const response = await foodSafetyAction.getTransactionById(id);
    if (response.data) {
      const res = response.data.ResponseData;
      handleChangeDTO(res, iFoodSafety.FS_Constants.FST);
      handleChangeDTO(res.FoodSafetyCompanyDetail, iFoodSafety.FS_Constants.FSCD);
      handleChangeDTO(res.FoodSafetyProductInfo, iFoodSafety.FS_Constants.FSPI);
      handleChangeDTO(res.FoodSafetyActionTaken, iFoodSafety.FS_Constants.FSAT);
      handleChangeDTO(res.FoodSafetyRiskInfo, iFoodSafety.FS_Constants.FSRI);

      await iIncident.getIncidentTransactionData(res, followup, handleChangeDTO, handleChange);
      setShowFromAfterLogin(res.FoodSafetyCompanyDetail !== null || user.NonGovTypeCode === enums.NonGovUserTypeEnum.FoodFacility);

      validation.isShowInfectionForm(transaction, "foodsafetyFormDiv");
      validation.isShowInfectionForm(transaction, "CompanyDetail");
      validation.isShowInfectionForm(transaction, "ProductInfo");
      validation.isShowInfectionForm(transaction, "RiskInfo");
      validation.enableDisableForm("action_div", false);
      validation.enableDisableForm("gpsLocationFrom", true);
      validation.isShowInfectionForm(transaction, "IncidentDetail");
      props.setLoading(false);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let data = new iFoodSafety.FoodSafetyTransactionDTO();
    data = transaction.FoodSafetyTransactionDTO;
    // data.Circulate = trans.Circulate;

    if (validation.isUserNotLoggedIn()) {
      data.FoodSafetyCompanyDetail = null;
      data.FoodSafetyProductInfo = transaction.FoodSafetyProductInfoDTO;
      data.FoodSafetyRiskInfo = null;
      data.FoodSafetyActionTaken = null;
    } else {
      data.FoodSafetyCompanyDetail = transaction.FoodSafetyCompanyDetailDTO;
      data.FoodSafetyProductInfo = transaction.FoodSafetyProductInfoDTO;
      data.FoodSafetyRiskInfo = transaction.FoodSafetyRiskInfoDTO;
      data.FoodSafetyActionTaken = transaction.FoodSafetyActionTakenDTO;
    }
    data.IncidentsTransaction = iIncident.setIncidentTransaction(transaction, enums.IncidentTypeEnum.FoodSafetyIncident);

    console.log("data from sever: ", data);
    if (!formValidation()) {
      return false;
    }

    await sendRequest(data);
  };

  const sendRequest = async (data) => {
    let res = null;
    props.setLoading(true);
    if (!transaction.isUpdate && followup === 0) res = await foodSafetyAction.create(data);
    else {
      res = await foodSafetyAction.update(data);
    }
    if (res.data && res.data.IsSuccess) {
      const respData = res.data.ResponseData;
      if (!transaction.isUpdate) {
        swal(props.t("success"), props.t("submitSuccessFully") + props.t("yourRefNoIs") + respData.IncidentsTransaction.RefNo, "success");
      } else {
        swal(props.t("success"), props.t("submitSuccessFully"), "success");
      }
      props.setLoading(false);
      navigate(`/manageincident/${enums.SearchCriteriaEnum.MyIncidents}`);
    }
  };
  const validateDetailForm_public = (e) => {
    const foodsafetyForm = validation.validateForm("foodsafetyForm");

    if (!foodsafetyForm) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (!validation.captchaValidation(transaction, props)) {
      return false;
    }
    return true;
  };
  const validateDetailForm_user = (e) => {
    const foodsafetyForm = validation.validateForm("foodsafetyForm", "foodActionTakenForm");

    if (!foodsafetyForm) {
      toast(props.t("fillAllRequiredField"), { type: "warning" });
      return false;
    }
    if (transaction.FoodSafetyCompanyDetailDTO === null && transaction.FileAttachmentDTO.length === 0) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    } else if (transaction.FoodSafetyCompanyDetailDTO !== null && transaction.FileAttachmentDTO.length < 5) {
      toast(props.t("uploadRequiredFiles"), { type: "warning" });
      return false;
    }
    if (!validation.commonValidation(transaction, props)) {
      return false;
    }

    return true;
  };
  const formValidation = () => {
    if (auth.checkLogin()) {
      return validateDetailForm_user();
    } else {
      return validateDetailForm_public();
    }
  };

  return (
    <div>
      <div className="tab-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="container page-title p-3 pl-4 mb-3">
              <h3>{props.t("newFoodSafetyIncident")}</h3>
            </div>
            <div className="col-sm-12 foodsafetyForm">
              <Tabs defaultActiveKey="mainDetail" className="" defaulttab={selectedTab.toString()}>
                <Tab eventKey="mainDetail" title={props.t("mainDetail")}>
                  <div className="tab-item-wrapper foodsafetyHeader">
                    {transaction.isUpdate && <IncidentStatusUpdate {...props} />}

                    <div className="container  foodsafetyFormDiv">
                      <div className="row container-bg my-2 gpsLocationFrom">
                        <GPSLocation transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                        <EmirateGovEntity
                          unitType={enums.UnitTypeEnum.FocalPoint}
                          department={enums.DepartmentEnum.FoodSafetyDepartment}
                          value={transaction[iIncident.SharedDTO.IT].GovernmetnalEntityId}
                          isDisabled={transaction.isUpdate}
                          emirateId={transaction[iIncident.SharedDTO.IT].EmirateId}
                          handleOnChange={(e) => handleChange(e.target.name, e.target.value, iIncident.SharedDTO.IT)}
                          {...props}
                        />
                        {showFromAfterLogin && <NotificationType handleChangeDTO={handleChangeDTO} handleChange={handleChange} />}
                      </div>
                      <div style={{ display: "display" }}>
                        <Reporter {...props} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </Tab>
                {showFromAfterLogin && (
                  <Tab eventKey="CompanyDetail" title={props.t("CompanyDetail")}>
                    <div className="CompanyDetail container">
                      <CompanyInfo handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                    </div>
                  </Tab>
                )}
                {showFromAfterLogin && (
                  <Tab eventKey="ProductInformation" title={props.t("ProductInformation")}>
                    <div className="ProductInfo">
                      <ProductInfo handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                    </div>
                  </Tab>
                )}
                {showFromAfterLogin && (
                  <Tab eventKey="RiskInformation" title={props.t("RiskInformation")}>
                    <div className="RiskInfo">
                      <RiskInfo handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                    </div>
                  </Tab>
                )}

                {showFromAfterLogin && (
                  <Tab eventKey="Attachment" title={props.t("attachments")}>
                    <div className="tab-item-wrapper">
                      <AllAttachment transaction={transaction} handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                      <ActionTaken {...props} handleChangeDTO={handleChangeDTO} />

                      <div className="action_div"></div>
                      {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}
                      <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                    </div>
                  </Tab>
                )}

                {/* not login user tabs  */}
                {/* {(validation.isUserNotLoggedIn() || transaction.FoodSafetyCompanyDetailDTO === null) && (
                  <Tab eventKey="ReporterData" title={props.t("ReporterData")}>
                    <div className="tab-item-wrapper">
                      <div className="container my-2">
                        <Reporter {...props} handleChangeDTO={handleChangeDTO} handleChange={handleChange} />
                      </div>
                    </div>
                  </Tab>
                )} */}
                {(validation.isUserNotLoggedIn() || transaction.FoodSafetyCompanyDetailDTO === null) && (
                  <Tab eventKey="IncidentDetail" title={props.t("IncidentDetail")}>
                    <div className="tab-item-wrapper">
                      <div className="container IncidentDetail">
                        <ServiceData handleChangeDTO={handleChangeDTO} handleChange={handleChange} {...props} />
                      </div>

                      <Attachment
                        code={enums.AttachmentEnum.Other}
                        handleChange={handleChange}
                        handleChangeDTO={handleChangeDTO}
                        canUploadFile={validation.isGovtUser()}
                        {...props}
                      />

                      {user && <AuditLogs isUpdate={transaction.isUpdate} {...props} />}
                      <ActionButton handleChange={handleChange} handleChangeDTO={handleChangeDTO} handleSubmitForm={handleSubmitForm} {...props} />
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodSafety;
