import React, { useState, useCallback, useEffect } from "react";
import FormInput, { SelectInput } from "../../Common/Validation/FormInput";
import { master, util, enums, auth, validation } from "../../Imports";

export const ProductGroup = ({ transaction, changeControl }) => {
  const [productGroupData, setProductGroupData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setProductGroupData(await master.getProductGroupLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput
        name="ProductGroupId"
        label="ProductDescriptionMainGroup"
        required={true}
        disabled={false}
        value={transaction.ProductGroupId}
        onChange={changeControl}
        data={productGroupData}
      />
    </div>
  );
};
export const ShipmentNumber = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ShipmentNo"
        type="text"
        label="ShipmentNumber"
        required={isRequired}
        disabled={false}
        value={transaction.ShipmentNo}
        onChange={changeControl}
      />
    </div>
  );
};
export const ImporterName = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ImporterName"
        type="text"
        label="ImporterName"
        required={isRequired}
        disabled={false}
        value={transaction.ImporterName}
        onChange={changeControl}
      />
    </div>
  );
};
export const ImporterAddress = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ImporterAddress"
        type="text"
        label="ImporterAddress"
        required={isRequired}
        disabled={false}
        value={transaction.ImporterAddress}
        onChange={changeControl}
      />
    </div>
  );
};
export const ExporterName = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ExporterName"
        type="text"
        label="ExporterName"
        required={isRequired}
        disabled={false}
        value={transaction.ExporterName}
        onChange={changeControl}
      />
    </div>
  );
};
export const ExporterAddress = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ExporterAddress"
        type="text"
        label="ExporterAddress"
        required={isRequired}
        disabled={false}
        value={transaction.ExporterAddress}
        onChange={changeControl}
      />
    </div>
  );
};
export const ContainerNumber = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ContainerNo"
        type="text"
        label="ContainerNo"
        required={isRequired}
        disabled={false}
        value={transaction.ContainerNo}
        onChange={changeControl}
      />
    </div>
  );
};
export const ProductMainCategory = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductMainCategory"
        type="text"
        label="ProductDescriptionMainCategory"
        required={true}
        disabled={false}
        value={transaction.ProductMainCategory}
        onChange={changeControl}
      />
    </div>
  );
};
export const ProductSubCategory1 = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductSubCategory1"
        type="text"
        label="ProductDescriptionSubCategory1"
        required={true}
        disabled={false}
        value={transaction.ProductSubCategory1}
        onChange={changeControl}
      />
    </div>
  );
};
export const ProductSubCategory2 = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductSubCategory2"
        type="text"
        label="ProductDescriptionSubCategory2"
        required={false}
        disabled={false}
        value={transaction.ProductSubCategory2}
        onChange={changeControl}
      />
    </div>
  );
};
export const ProductSubCategory3 = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductSubCategory3"
        type="text"
        label="ProductDescriptionSubCategory3"
        required={false}
        disabled={false}
        value={transaction.ProductSubCategory3}
        onChange={changeControl}
      />
    </div>
  );
};
export const ProductName = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="ProductName" type="text" label="ProductName" required={true} disabled={false} value={transaction.ProductName} onChange={changeControl} />
    </div>
  );
};
export const ProductTradeName = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductTradeName"
        type="text"
        label="ProductTradeName"
        required={true}
        disabled={false}
        value={transaction.ProductTradeName}
        onChange={changeControl}
      />
    </div>
  );
};
export const Volume = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="Volume" type="text" label="Volume" required={true} disabled={false} value={transaction.Volume} onChange={changeControl} />
    </div>
  );
};
export const PackageType = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="PackageType" type="text" label="PackageType" required={true} disabled={false} value={transaction.PackageType} onChange={changeControl} />
    </div>
  );
};
export const BatchNumber = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="BatchNumber" type="text" label="BatchNumber" required={true} disabled={false} value={transaction.BatchNumber} onChange={changeControl} />
    </div>
  );
};
export const ProductDescription = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductDescription"
        type="text"
        label="ProductDescription"
        required={false}
        disabled={false}
        value={transaction.ProductDescription}
        onChange={changeControl}
      />
    </div>
  );
};
export const OtherInfo = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-12">
      <FormInput
        name="OtherInfo"
        type="textarea"
        label="ProductDescription"
        required={false}
        disabled={false}
        value={transaction.OtherInfo}
        onChange={changeControl}
      />
    </div>
  );
};
export const FactoryName = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="FactoryName" type="text" label="FactoryName" required={true} disabled={false} value={transaction.FactoryName} onChange={changeControl} />
    </div>
  );
};
export const FactoryAddress = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="FactoryAddress"
        type="text"
        label="FactoryAddress"
        required={true}
        disabled={false}
        value={transaction.FactoryAddress}
        onChange={changeControl}
      />
    </div>
  );
};
export const ItemsCount = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput name="ItemsCount" type="number" label="ItemsCount" required={isRequired} value={transaction.ItemsCount} onChange={changeControl} />
    </div>
  );
};
export const TotalAmount = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput name="TotalAmount" type="number" label="TotalAmount" required={isRequired} value={transaction.TotalAmount} onChange={changeControl} />
    </div>
  );
};
export const FactoryOrigin = ({ transaction, changeControl }) => {
  const [factoryOrginData, setFactoryOrginData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setFactoryOrginData(await master.getCountryLkp(0));
  }, []);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput
        name="FactoryOrginId"
        label="FactoryOrigin"
        required={false}
        disabled={false}
        value={transaction.FactoryOrginId}
        onChange={changeControl}
        data={factoryOrginData}
        data-exclude="1"
      />
    </div>
  );
};
export const ProductProductionDate = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductProductionDate"
        type="date"
        label="ProductionDate"
        selectedDate={util.SelectedDate(transaction.ProductProductionDate)}
        required={false}
        disabled={false}
        onChange={(date) => {
          changeControl("ProductProductionDate", util.SetDate(date));
        }}
        maxDate={new Date()}
      />
    </div>
  );
};
export const ProductExpiryDate = ({ transaction, changeControl }) => {
  function updateDateControl(date) {
    if (date >= util.SelectedDate(transaction.ProductProductionDate)) {
      changeControl("ProductExpiryDate", util.SetDate(date));
    }
  }
  return (
    <div className="col-md-4">
      <FormInput
        name="ProductExpiryDate"
        type="date"
        label="ExpiryDate"
        selectedDate={util.SelectedDate(transaction.ProductExpiryDate)}
        required={false}
        disabled={false}
        onChange={(date) => updateDateControl(date)}
        minDate={util.SelectedDate(transaction.ProductProductionDate)}
      />
    </div>
  );
};
export const ArrivalDate = ({ transaction, changeControl }) => {
  function updateDateControl(date) {
    if (date <= util.SelectedDate(transaction.ProductExpiryDate) && date >= util.SelectedDate(transaction.ProductProductionDate)) {
      changeControl("ArrivalDate", util.SetDate(date));
    } else {
      validation.validateControl("ProductProductionDate");
      validation.validateControl("ProductExpiryDate");
    }
  }
  return (
    <div className="col-md-4">
      <FormInput
        name="ArrivalDate"
        type="date"
        label="ArrivalDate"
        selectedDate={util.SelectedDate(transaction.ArrivalDate)}
        required={false}
        disabled={false}
        onChange={(date) => updateDateControl(date)}
        minDate={util.SelectedDate(transaction.ProductProductionDate)}
        maxDate={util.SelectedDate(transaction.ProductExpiryDate)}
      />
    </div>
  );
};
export const Unit = ({ transaction, changeControl }) => {
  const [unitData, setUnitData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setUnitData(await master.getUnitTypeLkp(0));
  }, []);

  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput name="UnitId" label="Unit" required={true} disabled={false} value={transaction.UnitId} onChange={changeControl} data={unitData} />
    </div>
  );
};
export const Country = ({ transaction, changeControl, name, label }) => {
  const [countryData, setCountryData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setCountryData(await master.getCountryLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput
        name={name}
        label={label}
        required={true}
        disabled={false}
        value={transaction[name]}
        onChange={changeControl}
        data={countryData}
        data-exclude="1"
      />
    </div>
  );
};
export const ExporterCountry = ({ transaction, changeControl }) => {
  const [countryData, setCountryData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setCountryData(await master.getCountryLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput
        name="ExportCountryId"
        label="ExporterCountry"
        required={false}
        disabled={false}
        value={transaction.ExportCountryId}
        onChange={changeControl}
        data={countryData}
        data-exclude="1"
      />
    </div>
  );
};
export const TransportMedia = ({ transaction, changeControl }) => {
  const [transportMediaData, setTransportMediaData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setTransportMediaData(await master.getTransportMediaLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput
        name="TransportMediaId"
        label="TransportMedia"
        required={false}
        disabled={false}
        value={transaction.TransportMediaId}
        onChange={changeControl}
        data={transportMediaData}
        data-exclude="1"
      />
    </div>
  );
};
export const BorderPort = ({ transaction, changeControl, emirateId }) => {
  const [govEntityData, setGovEntityData] = useState([]);

  const loadComLkp = useCallback(async () => {
    setGovEntityData(await master.getPortList(emirateId));
  }, [emirateId]);
  useEffect(() => {
    if (emirateId > 0) loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-4">
      <SelectInput
        name="BorderPortId"
        label="BorderPort"
        required={false}
        disabled={false}
        value={transaction.BorderPortId}
        onChange={changeControl}
        data={govEntityData}
      />
    </div>
  );
};
export const FSActionTaken = ({ transaction, changeControl }) => {
  const [actionTaken, setActionTaken] = useState([]);
  const loadComLkp = useCallback(async () => {
    setActionTaken(await master.getFoodActionTakenLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-6">
      <SelectInput
        name="FoodActionTakenId"
        label="FoodActionTaken"
        required={true}
        disabled={false}
        value={transaction.FoodActionTakenId}
        onChange={changeControl}
        data={actionTaken}
      />
    </div>
  );
};
export const RiskSource = ({ transaction, changeControl }) => {
  const [riskSourceData, setRiskSourceData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setRiskSourceData(await master.getRiskSourceLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-6">
      <SelectInput
        name="RiskSourceId"
        label="RiskSource"
        required={true}
        disabled={false}
        value={transaction.RiskSourceId}
        onChange={changeControl}
        data={riskSourceData}
      />
    </div>
  );
};

export const RiskSourceGroup = ({ transaction, changeControl }) => {
  const [riskSourceGroupData, setRiskSourceGroupData] = useState([]);
  const loadComLkp = useCallback(async () => {
    setRiskSourceGroupData(await master.getRiskSourceGroupLkp(0));
  }, []);
  useEffect(() => {
    loadComLkp();
  }, [loadComLkp]);
  return (
    <div className="col-md-6">
      <SelectInput
        name="RiskSourceGroupId"
        label="RiskSourceGroup"
        required={true}
        disabled={false}
        value={transaction.RiskSourceGroupId}
        onChange={changeControl}
        data={riskSourceGroupData}
      />
    </div>
  );
};
export const HazardMain = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="HazardMain" type="text" label="MainHazard" required={true} disabled={false} value={transaction.HazardMain} onChange={changeControl} />
    </div>
  );
};
export const MaxAllowedResult = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="MaxAllowedResult"
        type="text"
        label="MaxAllowedResult"
        required={false}
        disabled={false}
        value={transaction.MaxAllowedResult}
        onChange={changeControl}
      />
    </div>
  );
};
export const HazardSub = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="HazardSub" type="text" label="SubHazard" required={true} disabled={false} value={transaction.HazardSub} onChange={changeControl} />
    </div>
  );
};
export const HazardDescription = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="HazardDescription"
        type="text"
        label="HazardDescription"
        required={false}
        disabled={false}
        value={transaction.HazardDescription}
        onChange={changeControl}
      />
    </div>
  );
};
export const LabName = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="LabName" type="text" label="LabName" required={false} disabled={false} value={transaction.LabName} onChange={changeControl} />
    </div>
  );
};
export const LabAddress = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput name="LabAddress" type="text" label="LabAddress" required={false} disabled={false} value={transaction.LabAddress} onChange={changeControl} />
    </div>
  );
};
export const LabTestType = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="LabTestType"
        type="text"
        label="LabTestType"
        required={false}
        disabled={false}
        value={transaction.LabTestType}
        onChange={changeControl}
      />
    </div>
  );
};
export const SampleCount = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="SampleCount"
        type="number"
        label="SampleCount"
        required={false}
        disabled={false}
        value={transaction.SampleCount}
        onChange={changeControl}
      />
    </div>
  );
};
export const SampleLocation = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="SampleLocation"
        type="text"
        label="SampleLocation"
        required={false}
        disabled={false}
        value={transaction.SampleLocation}
        onChange={changeControl}
      />
    </div>
  );
};
export const LabTestResult = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="LabTestResult"
        type="text"
        label="LabTestResult"
        required={false}
        disabled={false}
        value={transaction.LabTestResult}
        onChange={changeControl}
      />
    </div>
  );
};
export const LegalReference = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="LegalReference"
        type="text"
        label="LeagalReference"
        required={true}
        disabled={false}
        value={transaction.LegalReference}
        onChange={changeControl}
      />
    </div>
  );
};
export const ExtraLegalReference = ({ transaction, changeControl }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="ExtraLegalReference"
        type="text"
        label="ExtraLeagalReference"
        required={false}
        disabled={false}
        value={transaction.ExtraLegalReference}
        onChange={changeControl}
      />
    </div>
  );
};
export const LabTestDate = ({ transaction, changeControl, isRequired }) => {
  return (
    <div className="col-md-4">
      <FormInput
        name="LabTestDate"
        type="date"
        label="LabTestDate"
        selectedDate={util.SelectedDate(transaction.LabTestDate)}
        required={isRequired}
        disabled={false}
        onChange={(date) => {
          changeControl("LabTestDate", util.SetDate(date));
        }}
        maxDate={new Date()}
      />
    </div>
  );
};
