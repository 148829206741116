import React, { useEffect } from "react";
import { useState } from "react";
import { authAction } from "../../Imports";
import { setUserPermission } from "../../../Constants/AuthToken";
import { useNavigate } from "react-router-dom";

const MultipleAccounts = (props) => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    const userList = JSON.parse(sessionStorage.getItem("uaepass_userList"));
    if (userList.length > 0) {
      setUserList(userList);
    }
  }, []);

  const loginByEmailEID = async (email, EID) => {
    const res = await authAction.loginByEmailEID(EID, email);
    if (res.data.ResponseData.AccessToken !== null) {
      setUserPermission(res.data.ResponseData.AccessToken, res.data.ResponseData.RefreshToken);
      navigate("/");

      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
    console.log(email, EID);
  };

  return (
    <div className="text-center my-4">
      <div className="text-center mx-2">
        <h4 className="pro-link">{props.t("login")}</h4>
      </div>
      <div className="container">
        <div className="row">
          {userList.map((x, i) => {
            return (
              <div className="mt-4 mx-1" key={i}>
                <button className="btn btn-success mx-3" onClick={() => loginByEmailEID(x.Email, x.UsersProfile.ReporterEID)}>
                  {x.Email}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultipleAccounts;
