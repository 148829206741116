import React from "react";

function PrivacyPolicy(props) {
  return (
    <>
      <div className="page-title p-3 pl-4">
        <h3> {props.t("PrivacyPolicy")}</h3>
      </div>
      <div
        className="m-4"
        dangerouslySetInnerHTML={{
          __html: props.t("PrivacyPolicy_Description"),
        }}
      ></div>
    </>
  );
}

export default PrivacyPolicy;
